// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/views/contrates-hotels',
    name: 'dashboard-contrates-hotels',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/dashboards/b2b/ContratesShow.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
    },
  },
]

export default formTable
