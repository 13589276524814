import Vue from 'vue'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()
const options = {
  idleTime: 20 * 60000,
  eventEmitter: eventsHub,
}

Vue.use(IdleVue, options)
