<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('lbl.prices')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <!--<v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col> -->
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <strong>{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
              <fragment v-if="model.operador_id">
                <strong class="ml-10">{{ $t('menu.operador') }}</strong>: {{ model.operador.name }}
              </fragment>
              <fragment v-if="model.proveedor_id">
                <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
              </fragment>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="hotel_pactado"
                class="pt-2"
                :items="itemsHotelsPactados"
                :search-input.sync="searchHotelsPactados"
                hide-details
                hide-selected
                :label="$t('lbl.hotels')"
                outlined
                dense
                item-text="nameComodin"
                item-value="id"
                clearable
                single-line
                @change="changeHotelsPactados"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotels') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.nameComodin"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <!--<v-col
              v-if="hotel_pactado"
              cols="12"
              md="2"
            >
              <v-select
                v-model="regimen_hotel"
                :items="regimenNomAll"
                item-text="siglas"
                item-value="id"
                :label="$t('lbl.regimen')"
                class="pt-2"
                outlined
                dense
                hide-details
                :disabled="
                  regimenNomAll.filter(e => e.id === regimen_hotel && e.siglas === 'TI').length > 0 ? true : false
                "
              ></v-select>
            </v-col>-->
            <v-col
              v-if="hotel_pactado"
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="room_hotel"
                class="pt-2"
                :items="itemsRooms"
                :search-input.sync="searchRooms"
                hide-details
                :label="$t('lbl.asociateRoom')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.asociateRoom') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="hotel_pactado"
              cols="12"
              md="1"
            >
              <v-tooltip
                top
                class="text-right"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="my-2"
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="asociarRoonHotel()"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiCheckUnderline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="season_hotel.length > 0 && rooms_hotel.length > 0">
            <v-col
              cols="12"
              md="1"
            >
              <v-tooltip
                top
                class="text-right"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    color="primary"
                    :loading="isUpdatePrices"
                    @click="save"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiUpdate }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.apply') }}</span>
              </v-tooltip>
            </v-col>
            <!--<v-col
              cols="12"
              md="2"
            >
              <v-btn
                :disabled="cantPaxs === 3"
                class="mx-2"
                fab
                x-small
                color="primary"
                @click="decrementPaxs"
              >
                <v-icon>
                  {{ icons.mdiMinus }}
                </v-icon>
              </v-btn>
              <v-tooltip
                top
                class="text-right"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ cantPaxs }}
                  </span>
                </template>
                <span>Paxs</span>
              </v-tooltip>
              <v-btn
                :disabled="cantPaxs === 8"
                class="mx-2"
                fab
                x-small
                color="primary"
                @click="incrementPaxs"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-col>-->

            <v-col
              cols="12"
              md="12"
            >
              <PricesTable
                v-if="!isLoadingPrices && regimen_hotel > 0"
                :season_hotel="season_hotel"
                :hotel_id="hotel_pactado"
                :rooms_hotel="rooms_hotel"
                :rooms-nom-all="roomsNomAll"
                :regimen-nom-all="regimenNomAll"
                :regimen-alimenticio="regimen_hotel"
                :regimen-hotel="regimenNomAll.filter(e => e.id === regimen_hotel)[0]"
                @deleteRoom="deleteRoom"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiCheckUnderline,
  mdiMinus,
} from '@mdi/js'

import PricesTable from '../utils/PricesTable.vue'

export default {
  components: {
    AppCardCode,
    PricesTable,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isLoadingPrices: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiCheckUnderline,
        mdiMinus,
      },
      model: {
        on_request: 0,
        calcule_tarif_all_day_by_first: true,
      },
      loading: false,
      loadingSeason: true,
      isUpdatePrices: false,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      hotelsNomAll: [],
      roomsNomAll: [],
      regimenNomAll: [],
      itemsRooms: [],
      searchRooms: null,
      seasonsContrate: [],
      hotel_pactado: null,
      room_hotel: null,
      season_hotel: [],
      rooms_hotel: [],
      regimen_hotel: 0,
      cantPaxs: 3,
      itemsHotelsPactados: [],
      searchHotelsPactados: null,
      isDialogVisibleDelete: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      temporadasContrateHotel: state => state.app.temporadasContrateHotel,
      hotelsAsociadosTemporadas: state => state.app.hotelsAsociadosTemporadas,
      idsHotelsAsociadosTemporadas: state => state.app.idsHotelsAsociadosTemporadas,
      pricesHotelContrate: state => state.app.pricesHotelContrate,
    }),
    hotelesAll() {
      const hPactados = []
      this.hotelsNomAll.forEach(hote => {
        this.idsHotelsAsociadosTemporadas.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            hPactados.push(hote)
          }
        })
      })

      /* let hPactados = []
      if (this.hotelsAsociadosTemporadas.length === 0) {
        hPactados = this.hotelsNomAll
      } else {
        this.hotelsNomAll.forEach(hote => {
          if (this.hotelsAsociadosTemporadas.includes(hote.id)) {
            hPactados.push(hote)
          }
        })
      } */

      return hPactados
    },
  },
  watch: {
    searchHotelsPactados(val) {
      if (val !== null && val.length > 1) {
        this.filterHotelsPactados(val.toLowerCase())
      }
    },
    searchRooms(val) {
      if (val !== null && val.length > 1) {
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getItem()
    this.getSeasons()
    this.getHoteles()
    this.getRooms()
    this.getRegimen()
  },

  methods: {
    ...mapMutations([
      'updateHotelesPactadosContrateHotel',
      'addTemporadaContrateHotel',
      'updateIdsHotelsContrateHotel',
      'updateTemporadaContrateHotel',
      'updateCantPaxsContrate',
      'updatePricesHotelContrate',
      'addPricesHotelContrate',
    ]),
    incrementPaxs() {
      this.cantPaxs += 1
      this.updateCantPaxsContrate(this.cantPaxs)
    },
    decrementPaxs() {
      this.cantPaxs -= 1
      this.updateCantPaxsContrate(this.cantPaxs)
    },
    filterHotelsPactados(v) {
      this.itemsHotelsPactados = []
      if (v === '') {
        this.itemsHotelsPactados = []
      } else {
        this.itemsHotelsPactados = this.hotelesAll.filter(e => e.nameComodin.toLowerCase())
      }
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.roomsNomAll.filter(e => e.name.toLowerCase())
      }
    },
    getItem() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.updateHotelesPactadosContrateHotel(this.model.hotels_pactados)
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    getSeasons() {
      this.axios
        .get(
          `contrate_hotels/index-season?page=${this.pagination.current}&per_page=${
            this.itemsPerPage
          }&contrate_id=${localStorage.getItem('hotels-contrate-id')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.seasonsContrate = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            // this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loadingSeason = false))
    },
    addSeasonContrateHotel() {
      const json = {
        hotels: this.hotelsAsociadosTemporadas,
        seasons: this.temporadasContrateHotel,
        contrate_id: localStorage.getItem('hotels-contrate-id'),
      }

      if (this.hotelsAsociadosTemporadas.length > 0) {
        let proceder = true

        if (this.temporadasContrateHotel.length > 0) {
          json.temporada_id = this.temporadasContrateHotel[0].temporada_id
          this.temporadasContrateHotel.forEach(element => {
            if (!element.name) {
              proceder = false
            }
            element.dates.forEach(date => {
              if (!date.from || !date.to) {
                proceder = false
              }
            })
          })
        }

        if (proceder) {
          this.loading = true
          this.axios
            .post('contrate_hotels/season', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error('Contrato no existente')
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
                this.updateTemporadaContrateHotel([])
                this.updateIdsHotelsContrateHotel([])
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.getSeasons()
              this.loading = false
            })
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.seasonDelete.id = iten.id
      this.seasonDelete.name = iten.seasons[0].name
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`contrate_hotels/delete-season/${this.seasonDelete.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.errorDeleteHotel', { item: this.item.name }))
            }
          } else {
            this.getSeasons()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.seasonDelete = {
        id: null,
        name: null,
      }
      this.loading = false
      this.getSeasons()
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.roomsNomAll = res.data.data
          }
        })
    },
    getRegimen() {
      this.axios
        .get('nom_regimen_alimenticio?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.regimenNomAll = res.data.data
          }
        })
    },
    changeHotelsPactados() {
      this.isLoadingPrices = true
      if (this.hotel_pactado) {
        this.regimen_hotel = 0
        const json = {
          hotel_id: this.hotel_pactado,
          contrate_id: localStorage.getItem('hotels-contrate-id'),
        }
        this.axios
          .post('contrate_hotels/season-by-hotel', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else if (res.data.data.data.seasonsContrate.length > 0) {
              this.season_hotel = res.data.data.data.seasonsContrate
              this.rooms_hotel = res.data.data.data.roomsHotel
              this.regimen_hotel = res.data.data.data.regimenAlimenticio
              this.prices_hotel = res.data.data.data.pricesHotel
              this.cantPaxs = res.data.data.data.cantPaxs

              this.updateCantPaxsContrate(this.cantPaxs)
              if (this.prices_hotel.length > 0) {
                this.updatePricesHotelContrate(this.prices_hotel)
              } else {
                const pricc = []
                this.season_hotel.forEach(season => {
                  this.rooms_hotel.forEach(room => {
                    const ppp = this.insertPrices()

                    pricc.push({
                      contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
                      regimen_id: this.regimen_hotel,
                      temporada_id: season.temporada_id,
                      hotel_id: this.hotel_pactado,
                      room_id: room,
                      prices: ppp,
                    })
                  })
                })

                this.updatePricesHotelContrate(pricc)
              }
            } else {
              this.$toast.error(
                this.$t('lbl.noSeasonHotel', {
                  item: this.hotelsNomAll.filter(e => e.id === this.hotel_pactado)[0].nameComodin,
                }),
              )
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            setTimeout(() => {
              this.isLoadingPrices = false
            }, 100)
          })
      } else {
        this.isLoadingPrices = false
        this.season_hotel = []
      }
    },
    insertPrices() {
      const ppp = []
      ppp.push({
        pax: 'sgl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })
      ppp.push({
        pax: 'dbl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })
      ppp.push({
        pax: 'tpl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })

      /* ppp.push({
        pax: 'pax4',
        price: null,
      })
      ppp.push({
        pax: 'pax5',
        price: null,
      })
      ppp.push({
        pax: 'pax6',
        price: null,
      })
      ppp.push({
        pax: 'pax7',
        price: null,
      })
      ppp.push({
        pax: 'pax8',
        price: null,
      })
      ppp.push({
        pax: 'map',
        price: null,
      })
      ppp.push({
        pax: 'ap',
        price: null,
      })
      ppp.push({
        pax: 'breakfast',
        price: null,
      }) */

      return ppp
    },
    asociarRoonHotel() {
      if (this.room_hotel) {
        if (!this.rooms_hotel.includes(this.room_hotel)) {
          this.rooms_hotel.push(this.room_hotel)

          this.season_hotel.forEach(season => {
            const ppp = this.insertPrices()

            const newRoom = {
              contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
              regimen_id: this.regimen_hotel,
              temporada_id: season.temporada_id,
              hotel_id: this.hotel_pactado,
              room_id: this.room_hotel,
              prices: ppp,
            }

            this.addPricesHotelContrate(newRoom)
          })
        }
        this.room_hotel = null
      }
    },
    deleteRoom(roomId) {
      const index = this.rooms_hotel.findIndex(e => e === roomId)
      this.rooms_hotel.splice(index, 1)
    },
    save() {
      const json = {
        hotel_id: this.hotel_pactado,
        rooms: this.rooms_hotel,

        // regimen_id: this.regimen_hotel,
        // cant_paxs: this.cantPaxs,
        contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
        seasons: this.season_hotel,
        prices: this.pricesHotelContrate,
      }
      if (this.rooms_hotel.length > 0 && this.regimen_hotel && this.pricesHotelContrate.length > 0) {
        const proceder = true

        /* this.pricesHotelContrate.forEach(priceHotel => {
          priceHotel.prices.forEach(price => {
            if (price.pax === 'sgl' && !price.price) {
              proceder = false
            }
            if (price.pax === 'dbl' && !price.price) {
              proceder = false
            }
          })
        }) */
        if (proceder) {
          this.isUpdatePrices = true
          this.axios
            .post('contrate_hotels/update-prices', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              // eslint-disable-next-line no-empty
              if (res.data.success === false) {
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.isUpdatePrices = false))
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>

<!--<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: center;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>-->
