<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col
        cols="12"
        md="5"
      >
        <v-select
          v-model="room.room_id"
          :items="allRooms"
          :label="$t('lbl.rooms')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          multiple
          disabled
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.room') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="1"
      >
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-row
          v-for="(ocupa, indO) in room.ocupations"
          :key="indO"
        >
          <v-col cols="2">
            <v-text-field
              v-model="ocupa.adult"
              label="ADL"
              outlined
              dense
              hide-details="auto"
              type="number"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ocupa.mnr"
              label="MNR"
              outlined
              dense
              hide-details="auto"
              type="number"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ocupa.inf"
              label="INF"
              outlined
              dense
              hide-details="auto"
              type="number"
              disabled
              @input="reajustPoliticasChildSeasonContrateHotel()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      ruleAdlMin: [v => !!v || 'Campo requerido', v => this.isOneOrZero(v) || 'Valor permitido 0 o 1'],
      ruleMnrMax: [v => !!v || 'Campo requerido', v => this.isInterger(v) || 'Valor debe ser >= 0'],
      rulePaxMax: [v => !!v || 'Campo requerido', v => this.isNumber(v) || 'Valor debe ser > 0'],
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      maxOcupationRoomsSeasonContrateHotel: state => state.app.maxOcupationRoomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          let estaIncluida = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.maxOcupationRoomsSeasonContrateHotel.length; index++) {
            if (this.maxOcupationRoomsSeasonContrateHotel[index].rooms_id.includes(element.id) && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            if (this.room.rooms_id.length > 0) {
              this.roomsSeasonContrateHotel.forEach(rSeason => {
                if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                  estaIncluida = true
                }
              })

              if (!estaIncluida) {
                result.push({
                  ...element,
                  disabled: true,
                })
              } else {
                result.push(element)
              }
            } else {
              result.push(element)
            }
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.maxOcupationRoomsSeasonContrateHotel.length; index++) {
            if (this.maxOcupationRoomsSeasonContrateHotel[index].rooms_id.includes(element)) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
    disabledTeen() {
      let disable = false
      if (this.room.rooms_id) {
        this.roomsSeasonContrateHotel.forEach(rSeason => {
          if (rSeason.rooms_id.includes(this.room.rooms_id[0])) {
            if (!rSeason.has_a_teen) {
              this.room.ocupations.teenMax = 0
              disable = true
            }
          }
        })
      }

      return disable
    },
    disabledMnr() {
      let disable = false
      if (this.room.rooms_id) {
        this.roomsSeasonContrateHotel.forEach(rSeason => {
          if (rSeason.rooms_id.includes(this.room.rooms_id[0])) {
            if (!rSeason.has_a_mnr) {
              this.room.ocupations.childMax = 0
              disable = true
            }
          }
        })
      }

      return disable
    },
    disabledInf() {
      let disable = false
      if (this.room.rooms_id) {
        this.roomsSeasonContrateHotel.forEach(rSeason => {
          if (rSeason.rooms_id.includes(this.room.rooms_id[0])) {
            if (!rSeason.has_a_inf) {
              this.room.ocupations.infMax = 0
              disable = true
            }
          }
        })
      }

      return disable
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  unmounted() {
    this.reajustPoliticasChildSeasonContrateHotel()
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteRangeAgeRoomsSeasonContrateHotel',
      'addMaxOcupationRoomsSeasonContrateHotel',
      'reajustPoliticasChildSeasonContrateHotel',
    ]),
    filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    isOneOrZero(value) {
      try {
        if (value >= 0 && value <= 1) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    isInterger(value) {
      try {
        if (value >= 0) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    isNumber(value) {
      try {
        if (value > 0) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    setRooms() {
      if (this.room.rooms_id) {
        this.roomsSeasonContrateHotel.forEach(rSeason => {
          if (rSeason.rooms_id.includes(this.room.rooms_id[0])) {
            if (rSeason.childAge.from === 'N/A' && rSeason.infantAge.from === 'N/A') {
              this.room.ocupations.childMax = 0
            }
          }
        })
      }

      // REAJUSTAR LAS POLITICAS DE NIÑOS
      this.reajustPoliticasChildSeasonContrateHotel()
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>
