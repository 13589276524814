<template>
  <v-row>
    <!--v-if="!isLoading"-->
    <v-col cols="12">
      <v-tabs :key="tabsKey">
        <v-tab
          v-for="(season, index) in season_hotel"
          :key="index"
          :href="`#tab-${index}`"
        >
          <fragment>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="mt-1 mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ season.name }}
                  <!--<span
                    v-for="(date, indDate) in season.dates"
                    :key="indDate"
                  >
                    {{ date.from | moment('DD/MM/Y') }} - {{ date.to | moment('DD/MM/Y') }}
                    <br />
                  </span>-->
                </span>
              </template>
              <span>
                <span
                  v-for="(date, indDate) in season.dates"
                  :key="indDate"
                >
                  {{ date.from | moment('DD/MMM/Y') }} - {{ date.to | moment('DD/MMM/Y') }}
                  <br />
                </span>
              </span>
            </v-tooltip>
          </fragment>
        </v-tab>
        <v-tab-item
          v-for="(season, index) in season_hotel"
          :key="index"
          :value="'tab-' + index"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              class="mt-2"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <span class="text-uppercase ml-5">{{ $t('lbl.modeloPrice') }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-radio-group
                    v-model="season.typePayment"
                    row
                    class="pt-0 pb-0 mt-0 mb-0"
                    no-gutters
                  >
                    <v-radio
                      :label="$t('lbl.paxNight')"
                      value="paxByNight"
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                    ></v-radio>
                    <v-radio
                      :label="$t('lbl.roomNight')"
                      value="roomByNight"
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="season.showTarifas"
                    :label="$t('lbl.tarifa')"
                    dense
                    hide-details
                    class="mb-0 mt-0"
                    @change="recalculatePaxs(season.showTarifas, season.temporada_id, season)"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-simple-table class="mt-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-uppercase">
                    {{ $t('lbl.room') }}
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="setShowRooms(season.temporada_id)"
                    >
                      {{ season.showRooms ? icons.mdiArrowUpDropCircleOutline : icons.mdiArrowDownDropCircleOutline }}
                    </v-icon>
                  </td>
                  <td
                    class="text-uppercase"
                    width="100px"
                  >
                    <span>{{ $t('lbl.plan') }}</span>
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyRegimen(season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td
                    v-if="!season.showTarifas"
                    class="text-uppercase"
                    width="120px"
                  >
                    <span>Pax</span>
                  </td>
                  <td
                    width="100px"
                    class="text-uppercase"
                  >
                    DBL
                  </td>
                  <td width="100px">
                    <span class="pt-2">SGL</span>
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applySGL(season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td width="100px">
                    <span class="pt-2">TPL</span>
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyTPL(season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <!--PAX 4-->
                  <td>
                    Pax 4
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyPax('pax4', season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td>
                    Pax 5
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyPax('pax5', season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td>
                    Pax 6
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyPax('pax6', season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td>
                    Pax 7
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyPax('pax7', season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                  <td>
                    Pax 8
                    <v-icon
                      v-if="!season.showTarifas"
                      color="primary"
                      class="ml-2"
                      dark
                      small
                      style="cursor: pointer;"
                      @click="applyPax('pax8', season.temporada_id)"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </td>
                </tr>
                <!--PARA LOS SUPLEMENTOS O REDUC-->
                <tr v-if="season.showRooms">
                  <td></td>
                  <td></td>
                  <td v-if="!season.showTarifas"></td>
                  <td></td>
                  <!--SGL-->
                  <td>
                    <v-radio-group
                      v-model="season.formSGL.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('sgl', season.formSGL.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.supl')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formSGL.type === 'reduc'"
                      v-model="season.formSGL.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('sgl', season.formSGL.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--TPL-->
                  <td>
                    <v-radio-group
                      v-model="season.formTPL.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('tpl', season.formTPL.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formTPL.type === 'reduc'"
                      v-model="season.formTPL.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('tpl', season.formTPL.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--PAX4-->
                  <td>
                    <v-radio-group
                      v-model="season.formPAX4.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('pax4', season.formPAX4.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formPAX4.type === 'reduc'"
                      v-model="season.formPAX4.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('pax4', season.formPAX4.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--PAX5-->
                  <td>
                    <v-radio-group
                      v-model="season.formPAX5.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('pax5', season.formPAX5.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formPAX5.type === 'reduc'"
                      v-model="season.formPAX5.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('pax5', season.formPAX5.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--PAX6-->
                  <td>
                    <v-radio-group
                      v-model="season.formPAX6.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('pax6', season.formPAX6.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formPAX6.type === 'reduc'"
                      v-model="season.formPAX6.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('pax6', season.formPAX6.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--PAX7-->
                  <td>
                    <v-radio-group
                      v-model="season.formPAX7.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('pax7', season.formPAX7.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formPAX7.type === 'reduc'"
                      v-model="season.formPAX7.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('pax7', season.formPAX7.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <!--PAX8-->
                  <td>
                    <v-radio-group
                      v-model="season.formPAX8.type"
                      column
                      class="pt-0 pb-0 mt-0 mb-0"
                      no-gutters
                      @change="changeType('pax8', season.formPAX8.type, season.temporada_id)"
                    >
                      <v-radio
                        :label="$t('lbl.price')"
                        value="price"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        :label="$t('lbl.reduc')"
                        value="reduc"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="season.formPAX8.type === 'reduc'"
                      v-model="season.formPAX8.reduc"
                      column
                      class="pt-0 pb-0 mt-0 mb-0 ml-2"
                      no-gutters
                      @change="changeTypeReduc('pax8', season.formPAX8.reduc, season.temporada_id)"
                    >
                      <v-radio
                        label="%"
                        value="%"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                      <v-radio
                        label="$"
                        value="$"
                        class="pt-0 pb-0 mt-0 mb-0"
                        no-gutters
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>

                <fragment v-if="!deleting">
                  <tr
                    v-for="(room, i) in rooms_hotel"
                    :key="i"
                  >
                    <td>
                      {{
                        roomsNomAll.filter(e => e.id === room).length > 0
                          ? roomsNomAll.filter(e => e.id === room)[0].name
                          : ''
                      }}
                      <v-tooltip
                        top
                        color="error"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="desvincular(room, i)"
                          >
                            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <v-select
                          v-if="
                            pricesHotel.hotel_id === hotel_id &&
                              pricesHotel.temporada_id === season.temporada_id &&
                              pricesHotel.room_id === room
                          "
                          v-model="pricesHotel.regimen_id"
                          style="width:85px;"
                          :items="regimenNomAll"
                          item-text="siglas"
                          item-value="id"
                          outlined
                          dense
                          hide-details
                          :disabled="season.showTarifas"
                          class="caption"
                        ></v-select>
                      </fragment>
                    </td>
                    <!--PAXS-->
                    <td v-if="!season.showTarifas">
                      <v-row>
                        <v-col cols="6">
                          <v-tooltip
                            top
                            color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                x-small
                                v-bind="attrs"
                                color="primary"
                                v-on="on"
                                @click="addPaxHab(room, season.temporada_id)"
                              >
                                <v-icon>{{ icons.mdiPlus }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('btn.insert') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6">
                          <v-tooltip
                            top
                            color="error"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                x-small
                                v-bind="attrs"
                                color="error"
                                v-on="on"
                                @click="deletePaxHab(room, season.temporada_id)"
                              >
                                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('btn.delete') }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </td>
                    <!--DBL-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'dbl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room
                            "
                            v-model="pricesValue.priceAll"
                            :disabled="season.showTarifas"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--SGL-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'sgl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'sgl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formSGL.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'sgl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formSGL.reduc === '$' &&
                                season.formSGL.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.supl')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'sgl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formSGL.reduc === '%' &&
                                season.formSGL.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.supl')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--TPL-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'tpl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'tpl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formTPL.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'tpl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formTPL.reduc === '$' &&
                                season.formTPL.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'tpl' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formTPL.reduc === '%' &&
                                season.formTPL.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--PAX 4-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'pax4' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax4' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX4.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax4' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX4.reduc === '$' &&
                                season.formPAX4.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax4' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX4.reduc === '%' &&
                                season.formPAX4.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--PAX 5-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'pax5' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax5' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX5.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax5' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX5.reduc === '$' &&
                                season.formPAX5.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax5' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX5.reduc === '%' &&
                                season.formPAX5.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--PAX 6-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'pax6' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax6' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX6.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax6' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX6.reduc === '$' &&
                                season.formPAX6.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax6' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX6.reduc === '%' &&
                                season.formPAX6.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--PAX 7-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'pax7' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax7' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX7.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax7' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX7.reduc === '$' &&
                                season.formPAX7.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax7' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX7.reduc === '%' &&
                                season.formPAX7.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                    <!--PAX 8-->
                    <td>
                      <fragment
                        v-for="(pricesHotel, indP) in pricesHotelContrate"
                        :key="indP"
                      >
                        <fragment
                          v-for="(pricesValue, indV) in pricesHotel.prices"
                          :key="indV"
                        >
                          <!--VALOR CALCULADO SI ACTIVA LAS TARIFAS-->
                          <v-text-field
                            v-if="
                              pricesValue.pax === 'pax8' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                season.showTarifas
                            "
                            v-model="pricesValue.priceAll"
                            disabled
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR CALCULADO SI DESACTIVA TARIFAS Y TYPE = PRICE-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax8' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX8.type === 'price'
                            "
                            v-model="pricesValue.priceAll"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.price')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--VALOR SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax8' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX8.reduc === '$' &&
                                season.formPAX8.type === 'reduc'
                            "
                            v-model="pricesValue.priceReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            prefix="$"
                            class="caption"
                          ></v-text-field>
                          <!--% SUPL SI DESACTIVA TARIFAS Y TYPE = REDUC-->
                          <v-text-field
                            v-else-if="
                              pricesValue.pax === 'pax8' &&
                                pricesHotel.hotel_id === hotel_id &&
                                pricesHotel.temporada_id === season.temporada_id &&
                                pricesHotel.room_id === room &&
                                !season.showTarifas &&
                                season.formPAX8.reduc === '%' &&
                                season.formPAX8.type === 'reduc'
                            "
                            v-model="pricesValue.pcReduc"
                            style="width:85px;"
                            outlined
                            :label="$t('lbl.reduc')"
                            dense
                            hide-details="auto"
                            suffix="%"
                            class="caption"
                          ></v-text-field>
                        </fragment>
                      </fragment>
                    </td>
                  </tr>
                </fragment>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.desvincule') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.desvinculeRooms') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDesvincular()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiDeleteOutline,
  mdiMenuDown,
  mdiMenuRight,
  mdiPlus,
  mdiArrowUpDropCircleOutline,
  mdiArrowDownDropCircleOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    season_hotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    rooms_hotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    roomsNomAll: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    regimenNomAll: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    hotel_id: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    regimen_hotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    regimenHotel: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
        mdiPlus,
        mdiArrowUpDropCircleOutline,
        mdiArrowDownDropCircleOutline,
      },
      isLoading: true,
      allTarifas: [],
      categories: {},
      brands: {},
      tabsKey: 0,
      showRow: false,
      indexRow: -1,
      // eslint-disable-next-line no-unused-vars
      numberRule: [
        // eslint-disable-next-line no-unused-expressions, no-shadow
        // v => !!v || this.$t('lbl.required'),
        v => Number(v) >= 0 || this.$t('lbl.onlyNumber'),
      ],
      radioSGL: '%',
      valueSGL: null,
      radioTPL: '%',
      valueTPL: null,
      regimenHeader: null,
      isDialogVisibleDelete: false,
      posRoomDelete: -1,
      roomIdDelete: 0,
      loading: false,
      deleting: false,
    }
  },
  computed: {
    ...mapState({
      cantPaxsContrate: state => state.app.cantPaxsContrate,
      pricesHotelContrate: state => state.app.pricesHotelContrate,
    }),
  },
  mounted() {
    this.verifyAllSeason()
  },
  created() {
    this.regimenHeader = this.regimenHotel
  },
  methods: {
    ...mapMutations([
      'addCategoryContrateCar',
      'updateCategoryContrateCar',
      'addMarcaModeloContrateCar',
      'updateMarcaModeloContrateCar',
      'addTemporadaContrateCar',
      'addPricesHotelContrate',
      'deletePricesHotelContrate',
    ]),
    verifyAllSeason() {
      this.season_hotel.forEach(season => {
        let existeSeason = false
        this.pricesHotelContrate.forEach(price => {
          if (season.temporada_id === price.temporada_id) {
            existeSeason = true
          }
        })
        if (!existeSeason) {
          // eslint-disable-next-line camelcase
          this.rooms_hotel.forEach(room_hotel => {
            const ppp = this.insertPrices()
            const priceNew = {
              contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
              regimen_id: this.regimenHotel ? this.regimenHotel.id : 0,
              temporada_id: season.temporada_id,
              hotel_id: this.hotel_id,
              room_id: room_hotel,
              prices: ppp,
            }

            this.addPricesHotelContrate(priceNew)
          })

          setTimeout(() => {
            this.isLoading = false
          }, 500)
        }
      })
    },
    insertPrices() {
      const ppp = []
      ppp.push({
        pax: 'sgl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })
      ppp.push({
        pax: 'dbl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })
      ppp.push({
        pax: 'tpl',
        priceAll: null,
        priceReduc: null,
        pcReduc: null,
      })

      return ppp
    },

    applyTPL(temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)
      if (prices.length > 0) {
        if (prices[0].prices.length > 0) {
          let priceAll = null
          let priceReduc = null
          let pcReduc = null
          prices[0].prices.forEach(price => {
            if (price.pax === 'tpl') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            }
          })

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < prices.length; index++) {
            const element = prices[index]
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < element.prices.length; j++) {
              const ppp = element.prices[j]
              if (ppp.pax === 'tpl') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              }
            }
          }
        }
      }
    },
    applySGL(temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)
      if (prices.length > 0) {
        if (prices[0].prices.length > 0) {
          let priceAll = null
          let priceReduc = null
          let pcReduc = null
          prices[0].prices.forEach(price => {
            if (price.pax === 'sgl') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            }
          })

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < prices.length; index++) {
            const element = prices[index]
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < element.prices.length; j++) {
              const ppp = element.prices[j]
              if (ppp.pax === 'sgl') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              }
            }
          }
        }
      }
    },
    applyRegimen(temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)

      if (prices.length > 0) {
        if (prices[0].regimen_id) {
          const regimenId = prices[0].regimen_id
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < prices.length; index++) {
            prices[index].regimen_id = regimenId
          }
        }
      }
    },
    applyPax(pax, temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)
      if (prices.length > 0) {
        if (prices[0].prices.length > 0) {
          let priceAll = null
          let priceReduc = null
          let pcReduc = null
          prices[0].prices.forEach(price => {
            if (price.pax === 'pax4' && pax === 'pax4') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            } else if (price.pax === 'pax5' && pax === 'pax5') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            } else if (price.pax === 'pax6' && pax === 'pax6') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            } else if (price.pax === 'pax7' && pax === 'pax7') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            } else if (price.pax === 'pax8' && pax === 'pax8') {
              if (price.priceAll) {
                priceAll = price.priceAll
              }
              if (price.priceReduc) {
                priceReduc = price.priceReduc
              }
              if (price.pcReduc) {
                pcReduc = price.pcReduc
              }
            }
          })

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < prices.length; index++) {
            const element = prices[index]
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < element.prices.length; j++) {
              const ppp = element.prices[j]
              if (ppp.pax === 'pax4' && pax === 'pax4') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              } else if (ppp.pax === 'pax5' && pax === 'pax5') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              } else if (ppp.pax === 'pax6' && pax === 'pax6') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              } else if (ppp.pax === 'pax7' && pax === 'pax7') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              } else if (ppp.pax === 'pax8' && pax === 'pax8') {
                if (priceAll) {
                  element.prices[j].priceAll = priceAll
                }
                if (priceReduc) {
                  element.prices[j].priceReduc = priceReduc
                }
                if (pcReduc) {
                  element.prices[j].pcReduc = pcReduc
                }
              }
            }
          }
        }
      }
    },

    addPaxHab(room, temporadaId) {
      if (room && temporadaId) {
        const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId && e.room_id === room)
        if (prices.length > 0) {
          const cantPax = prices[0].prices.length
          if (cantPax === 3) {
            prices[0].prices.push({
              pax: 'pax4',
              priceAll: null,
              priceReduc: null,
              pcReduc: null,
            })
          } else if (cantPax === 4) {
            prices[0].prices.push({
              pax: 'pax5',
              priceAll: null,
              priceReduc: null,
              pcReduc: null,
            })
          } else if (cantPax === 5) {
            prices[0].prices.push({
              pax: 'pax6',
              priceAll: null,
              priceReduc: null,
              pcReduc: null,
            })
          } else if (cantPax === 6) {
            prices[0].prices.push({
              pax: 'pax7',
              priceAll: null,
              priceReduc: null,
              pcReduc: null,
            })
          } else if (cantPax === 7) {
            prices[0].prices.push({
              pax: 'pax8',
              priceAll: null,
              priceReduc: null,
              pcReduc: null,
            })
          }
        }
      }
    },
    deletePaxHab(room, temporadaId) {
      if (room && temporadaId) {
        const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId && e.room_id === room)
        if (prices.length > 0) {
          const cantPax = prices[0].prices.length
          if (cantPax > 3) {
            prices[0].prices.splice(cantPax - 1, 1)
          }
        }
      }
    },
    setShowRooms(temporadaId) {
      this.season_hotel.filter(e => e.temporada_id === temporadaId)[0].showRooms = !this.season_hotel.filter(
        e => e.temporada_id === temporadaId,
      )[0].showRooms
    },

    changeType(pax, type, temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)
      if (prices.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < prices.length; index++) {
          const element = prices[index]
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < element.prices.length; j++) {
            const ppp = element.prices[j]
            if (ppp.pax === 'sgl' && pax === 'sgl') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'tpl' && pax === 'tpl') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'pax4' && pax === 'pax4') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'pax5' && pax === 'pax5') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'pax6' && pax === 'pax6') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'pax7' && pax === 'pax7') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            } else if (ppp.pax === 'pax8' && pax === 'pax8') {
              if (type === 'price') {
                element.prices[j].priceReduc = null
                element.prices[j].pcReduc = null
              } else {
                element.prices[j].priceAll = null
              }
            }
          }
        }
      }
    },
    changeTypeReduc(pax, reduc, temporadaId) {
      const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)
      if (prices.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < prices.length; index++) {
          const element = prices[index]
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < element.prices.length; j++) {
            const ppp = element.prices[j]
            if (ppp.pax === 'sgl' && pax === 'sgl') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'tpl' && pax === 'tpl') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'pax4' && pax === 'pax4') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'pax5' && pax === 'pax5') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'pax6' && pax === 'pax6') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'pax7' && pax === 'pax7') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            } else if (ppp.pax === 'pax8' && pax === 'pax8') {
              if (reduc === '%') {
                element.prices[j].priceReduc = null
              } else {
                element.prices[j].pcReduc = null
              }
            }
          }
        }
      }
    },

    recalculatePaxs(showTarifas, temporadaId, season) {
      if (showTarifas) {
        this.season_hotel.filter(e => e.temporada_id === temporadaId)[0].showRooms = false
        const prices = this.pricesHotelContrate.filter(e => e.temporada_id === temporadaId)

        if (prices.length > 0) {
          // console.log(season)

          // TODO: TRABAJO CON LA SGL
          const typeSGL = season.formSGL.type
          const typeReducSGL = season.formSGL.reduc

          // TODO: TRABAJO CON LA TPL
          const typeTPL = season.formTPL.type
          const typeReducTPL = season.formTPL.reduc

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < prices.length; index++) {
            const element = prices[index]

            let priceDBLMutar = 0
            element.prices.forEach(price => {
              if (price.pax === 'dbl') {
                priceDBLMutar = price.priceAll
              }
            })
            if (priceDBLMutar) {
              // TODO: TRABAJO CON LA SGL
              if (typeSGL === 'reduc') {
                if (typeReducSGL === '$') {
                  // eslint-disable-next-line no-plusplus
                  for (let j = 0; j < element.prices.length; j++) {
                    const ppp = element.prices[j]
                    if (ppp.pax === 'sgl') {
                      const priceAllSGL = (
                        parseFloat(priceDBLMutar) + parseFloat(element.prices[j].priceReduc)
                      ).toFixed(2)
                      if (priceAllSGL) {
                        element.prices[j].priceAll = priceAllSGL
                      }
                    }
                  }
                } else if (typeReducSGL === '%') {
                  // eslint-disable-next-line no-plusplus
                  for (let j = 0; j < element.prices.length; j++) {
                    const ppp = element.prices[j]
                    if (ppp.pax === 'sgl') {
                      const priceAllSGL = (
                        parseFloat(priceDBLMutar)
                        * (1 + parseFloat(element.prices[j].pcReduc) / 100)
                      ).toFixed(2)
                      if (priceAllSGL) {
                        element.prices[j].priceAll = priceAllSGL
                      }
                    }
                  }
                }
              }

              // TODO: TRABAJO CON LA TPL
              if (typeTPL === 'reduc') {
                if (typeReducTPL === '$') {
                  // eslint-disable-next-line no-plusplus
                  for (let j = 0; j < element.prices.length; j++) {
                    const ppp = element.prices[j]
                    if (ppp.pax === 'tpl') {
                      const priceAllTPL = (
                        (parseFloat(priceDBLMutar) * 2 + parseFloat(element.prices[j].priceReduc))
                        / 3
                      ).toFixed(2)
                      if (priceAllTPL) {
                        element.prices[j].priceAll = priceAllTPL
                      }
                    }
                  }
                } else if (typeReducTPL === '%') {
                  // eslint-disable-next-line no-plusplus
                  for (let j = 0; j < element.prices.length; j++) {
                    const ppp = element.prices[j]
                    if (ppp.pax === 'tpl') {
                      const priceAllTPL = (
                        (parseFloat(priceDBLMutar) * 2
                          + (parseFloat(priceDBLMutar)
                            - parseFloat(priceDBLMutar) * (parseFloat(element.prices[j].pcReduc) / 100)))
                        / 3
                      ).toFixed(2)
                      if (priceAllTPL) {
                        element.prices[j].priceAll = priceAllTPL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    desvincular(room, pos) {
      this.isDialogVisibleDelete = true
      this.roomIdDelete = room
      this.posRoomDelete = pos
    },
    confirmDesvincular() {
      this.loading = true
      const json = {
        contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
        hotel_id: this.hotel_id,
        room_id: this.roomIdDelete,
      }
      this.axios
        .post('contrate_hotels/delete-rooms-hotel', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.reload()
          }
        })
        .finally(() => this.reseat())
    },
    reload() {
      this.deleting = true
      this.deletePricesHotelContrate({
        contrate_id: parseInt(localStorage.getItem('hotels-contrate-id'), 10),
        hotel_id: this.hotel_id,
        room_id: this.roomIdDelete,
      })
      this.$emit('deleteRoom', this.roomIdDelete)
      this.posRoomDelete = -1
      this.roomIdDelete = 0
      setTimeout(() => {
        this.deleting = false
      }, 100)
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.loading = false
    },
  },
}
</script>
