<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card>
    <v-row class="mx-2">
      <v-col
        cols="4"
      >
        <iframe
          src="http://grafana.wamasol.com:3000/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=1736694045397&to=1736780445397&theme=light&panelId=77"
          width="450"
          height="200"
          frameborder="0"
        ></iframe>
        <!--<iframe
          id="grafanaDashboard"
          width="100%"
          height="600"
          frameborder="0"
        ></iframe>-->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/11.png'),
      qr: null,
      isLoadingQR: true,
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      chatBotRead: state => state.app.chatBotRead,
    }),
  },

  created() {
    document.addEventListener('DOMContentLoaded', () => {
      const iframe = document.getElementById('grafanaDashboard')

      // const dashboardURL = 'http://grafana.wamasol.com:3000/d/your-dashboard-uid?orgId=1'
      const dashboardURL = 'http://grafana.wamasol.com:3000/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=1736689516295&to=1736775916295&panelId=77'

      // If using API Token
      const apiToken = 'Bearer glsa_6oD4QnyW6G83HyLhMAkNKkLx9C19Wxgo_f23473e3'
      iframe.src = dashboardURL
      iframe.onload = () => {
        iframe.contentWindow.postMessage({ message: 'set_token', token: apiToken }, '*')
      }
    })
  },
}
</script>
<style lang="scss" scoped>
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
</style>
