<template>
  <fragment>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="5%">
              <!--<v-checkbox
                v-model="item.check"
                hide-details
                class="my-0"
              ></v-checkbox>-->
            </th>
            <th>
              <span v-if="item.code">
                {{ item.code }}
              </span>
            </th>
            <th width="45%"></th>
            <th>
              {{ item.data_service.items.rooms[0].priceWithDescuento | currency(item.data_service.items.currencyCode ? item.data_service.items.currencyCode : '$') }}
              <template v-if="item.data_service.items.currencyBaseCode">
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      color="primary"
                      :style="`color: ${$vuetify.theme.themes.light.primary}`"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      <v-icon>mdi-information</v-icon>
                    </span>
                  </template>
                  <span>
                    1 {{ item.data_service.items.currencyBaseCode }} = {{ item.data_service.items.tasaCambioContrate | number('0.0000') }} {{ item.data_service.items.contrateCurrencyCode }}<br />
                    <span v-if="item.data_service.items.contrateCurrencyCode !== item.data_service.items.currencyBaseCode">
                      1 {{ item.data_service.items.currencyBaseCode }} = {{ item.data_service.items.tasaCambioFacturar | number('0.0000') }} {{ item.data_service.items.currencyCodeFacturar }}
                    </span>
                  </span>
                </v-tooltip>
              </template>
            </th>
            <th width="5%">
              <!--<v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.share') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="editServive">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="deleteItem">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>-->
            </th>
          </tr>
        </thead>
        <tbody v-if="!isLoading">
          <tr>
            <td
              colspan="2"
              class="pb-8"
            >
              <v-img
                :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
                size="100"
                contain
                width="300"
                class="my-5"
                style="text-align: right !important;"
              >
                <template>
                  <v-chip
                    class="mr-2 mt-2"
                    color="primary"
                  >
                    <span v-if="checkOnRequest">On Request</span>
                    <span v-else>Free Sale</span>
                  </v-chip>
                </template>
              </v-img>

              <b :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ item.data_service.items.hotel.name }}</b>
              <br />
              <v-row no-gutters>
                <v-col
                  cols="12"
                  :md="item.data_service.items.hotel.categoria.plus ? '4' : '12'"
                >
                  <v-rating
                    background-color="default"
                    color="primary"
                    :empty-icon="icons.mdiStarOutline"
                    :full-icon="icons.mdiStar"
                    :half-icon="icons.mdiStarHalfFull"
                    length="5"
                    readonly
                    size="20"
                    dense
                    half-increments
                    :value="item.data_service.items.hotel.categoria.cant_estrellas"
                  ></v-rating>
                </v-col>
                <v-col
                  v-if="item.data_service.items.hotel.categoria.plus"
                  cols="12"
                  md="2"
                >
                  PLUS
                </v-col>
              </v-row>

              <br />
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('menu.operador')}:` }} </span>
              <span v-if="contrate">
                <span v-if="contrate.operators">
                  <span v-if="contrate.operators.length > 0">
                    {{ contrate.operators[0] }}
                  </span>
                </span>
              </span>
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('lbl.proveedor')}:` }} </span>
              <span v-if="contrate">
                <span v-if="contrate.proveedor">
                  {{ contrate.proveedor.name_comercial }}
                </span>
              </span>
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
                {{ $t('lbl.typeDestinyFilters') }}:
              </span>
              {{ item.data_service.items.hotel.tipo_destino.name }}
            </td>
            <td
              style="vertical-align: top;border: 0;"
              colspan="3"
            >
              <br />
              <b>
                <span class="text-uppercase">
                  {{ $t('lbl.rentToHotels') }}
                  ({{ `${item.data_service.items.diasReservar} ${$t('lbl.nights')}` }})
                </span>
                <!--<span v-if="item.data_service.items.category_id">
                  {{ `, ${item.data_service.items.category_id.name} ${$t('lbl.withSecureInclude')}` }}
                </span>-->
              </b>
              <br />
              <br />
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.entry') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateIn).format('DD MMM, Y') }},
                  16:00
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.exit') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateOut).format('DD MMM, Y') }},
                  12:00
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-5 mb-5"
                >
                  <b>{{ $t('lbl.travels') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                  class="mt-5 mb-5"
                >
                  <template v-if="countAdults > 0">
                    <v-icon
                      large
                    >
                      mdi-human-male
                    </v-icon> x {{ countAdults }}
                  </template>
                  <template v-if="countChilds > 0">
                    <v-icon class="mt-2">
                      mdi-human-male
                    </v-icon> x {{ countChilds }}
                  </template>
                  <template v-if="countInf > 0">
                    <v-icon>
                      mdi-baby-bottle-outline
                    </v-icon> x {{ countInf }}
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.rooms') }}:</b><br />
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      {{ nameRooms }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="text-center"
                >
                  <span v-if="item.code && checkInfoPendient">
                    <v-chip
                      color="red"
                      text-color="white"
                      @click="editServive"
                    >
                      {{ $t('lbl.completeInfo') }}
                    </v-chip>
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="showModalDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${$t('lbl.service')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteServicio', { item: '' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalDelete = !showModalDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDeleteService"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiSendOutline,
  mdiCurrencyUsd,
  mdiPrinter,
  mdiSquareEditOutline,
  mdiClipboardCheckOutline,
  mdiAccountCashOutline,
  mdiDotsVertical,
  mdiHeartPlusOutline,
  mdiDeleteOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      showModalDelete: false,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiPrinter,
        mdiSquareEditOutline,
        mdiClipboardCheckOutline,
        mdiAccountCashOutline,
        mdiDotsVertical,
        mdiHeartPlusOutline,
        mdiDeleteOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      contrate: {
        operators: [],
        proveedor: {},
      },
      image: [],
      resource: 'local',
      itemsRooms: [],
      nameRooms: '',
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      carItems: state => state.app.carItems,
      loadingBtn: state => state.app.loadingBtn,
    }),
    checkOnRequest() {
      let onRequest = false
      if (
        this.item.data_service.items.tipo_reserva === 'on-request'
      ) {
        onRequest = true
      }

      return onRequest
    },

    checkInfoPendient() {
      let info = false
      if (!this.item.data_service) {
        info = true
      } else if (!this.item.data_service.ocupation) {
        info = true
      } else {
        this.item.data_service.ocupation.forEach(element => {
          if (!element.paxs[0].name || !element.paxs[0].apellidos) {
            info = true
          }
        })
      }

      return info
    },
    countAdults() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        result += element.adults
      })

      return result
    },
    countChilds() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        element.edades.forEach(elementEdad => {
          if (elementEdad.edad > 2) {
            result += 1
          }
        })
      })

      return result
    },
    countInf() {
      let result = 0
      this.item.data_service.ocupation.forEach(element => {
        element.edades.forEach(elementEdad => {
          if (elementEdad.edad <= 2) {
            result += 1
          }
        })
      })

      return result
    },
  },
  mounted() {
    this.getContrate()
  },
  created() {
    this.getRooms()
    this.loadHotel()
  },

  methods: {
    ...mapMutations(['updateItemToCar', 'emptyClientCarShop', 'updateLoadingBtn', 'deleteItemToCar']),
    loadHotel() {
      if (this.item.data_service.items.hotel.galery === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else if (this.item.data_service.items.hotel.galery_random === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else {
        this.image = this.item.data_service.items.hotel.galery_random
        this.resource = this.item.data_service.items.hotel.resource_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.itemsRooms = res.data.data

            const ids = []
            this.itemsRooms.forEach(element => {
              let cont = 0
              if (!ids.includes(element.id)) {
                this.item.data_service.ocupation.forEach(elementOcup => {
                  if (elementOcup.room_id === element.id) {
                    ids.push(element.id)
                    cont += 1
                  }
                })
              }

              if (cont > 0) {
                if (this.nameRooms === '') {
                  this.nameRooms = `${element.name} x ${cont}`
                } else {
                  this.nameRooms = `${this.nameRooms}, ${element.name} x ${cont}`
                }
              }
            })
          }
        })

      // .finally(() => this.initRoom())
    },
    getContrate() {
      this.axios
        .get(`contrate_hotels/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data
        })
    },
    deleteItem() {
      this.showModalDelete = true
    },
    confirmDeleteService() {
      this.showModalDelete = false

      const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
      cars.splice(this.pos, 1)
      if (cars.length === 0) {
        localStorage.removeItem('car-shop')
        this.$router.push({ name: 'cotizador' })
      }

      localStorage.setItem('car-shop', JSON.stringify(cars))
      this.deleteItemToCar(this.pos)
    },
    printInvoice() {
      window.print()
    },
    editServive() {
      // eslint-disable-next-line no-unused-vars
      const json = {
        pos: this.pos,
        item: this.item,
      }

      const ccc = []
      ccc.push(this.item)

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('car-shop', JSON.stringify(ccc))
      localStorage.setItem('route-redirect', 'cotizations-update')

      // GUARDAR LA MONEDA Y EL CODE
      localStorage.setItem('currencyPVP', this.item.data_service.items.currency)
      localStorage.setItem('currencyCodePVP', this.item.data_service.items.currencyCode)

      this.$router.push({ name: 'car-shop-item-update' })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
