<template>
  <v-row
    class="match-height"
  >
    <v-col cols="12">
      <Loading
        v-if="modificando"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />

      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.importFiles')}`">
        <v-card-text>
          <template v-if="isLoading">
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="image"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="mb-4 me-3"
                  @click="$router.push({ name: 'hotels-contrate-update' })"
                >
                  <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                  <span>{{ $t('btn.back') }}</span>
                </v-btn>
              </v-col>
              <v-col
                v-if="lote !== null"
                cols="12"
                md="2"
              >
                <!--:disabled="!lote.listo"-->
                <!-- || name_proveedor.toLocaleLowerCase().includes('blue-diamond') -->
                <!--:disabled="listoUpload"-->
                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  :loading="processUpload"
                  :disabled="verifyUpload"
                  @click="acceptUpload()"
                >
                  <span>{{ $t('btn.update') }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="lote !== null"
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="mb-4 me-3"
                  @click="canceleUpload()"
                >
                  <span>{{ $t('btn.canceleUpload') }}</span>
                  <v-icon>{{ icons.mdiCancel }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-form class="multi-col-validation">
              <v-row v-if="lote === null && !modificando">
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-file-input
                    v-model="file"
                    accept=".xls, .xlsx"
                    exc
                    show-size
                    outlined
                    dense
                    :label="`${$t('lbl.file')}s`"
                    hide-details
                    multiple
                    @change="setFile"
                  >
                    <v-tooltip
                      slot="append-outer"
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('lbl.cantFilesUpload', { item: 30 }) }}</span>
                    </v-tooltip>
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-img
                    lazy-src="@/assets/img/placeholder_import_hotetec.png"
                    src="@/assets/img/placeholder_import_hotetec.png"
                    contain
                    size="350"
                  >
                  </v-img>
                </v-col>
              </v-row>

              <v-row v-if="!modificando">
                <fragment v-if="lote !== null">
                  <!--<v-col
                    cols="10"
                    offset-md="1"
                  >
                    <v-expansion-panels>
                      !--HOTELS--
                      <v-expansion-panel v-if="hotelsChange.length > 0">
                        <v-expansion-panel-header>
                          <template v-slot:default>
                            <v-row>
                              <v-col cols="2">
                                <h4 class="mt-2">
                                  {{ $t('lbl.hotels') }}
                                </h4>
                              </v-col>
                              <v-col cols="1">
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('lbl.problemsNameHotel') }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="(item, index) in hotelsChange"
                                  :key="Math.random() * (index + 1)"
                                >
                                  <td width="80%">
                                    {{ item }}
                                  </td>
                                  <td>
                                    <v-tooltip
                                      top
                                      color="primary"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="modalHotel(item)"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiSquareEditOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('btn.edit') }}</span>
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      !--ROOMS--
                      !--<template v-if="rommsChange.length > 0 && planesChange.length === 0 && hotelsChange.length === 0">
                        <template v-for="(hot, indH) in hotelsList">
                          <v-expansion-panel
                            v-if="hot.rooms.length > 0"
                            :key="indH"
                          >
                            <v-expansion-panel-header>
                              <template v-slot:default>
                                <v-row>
                                  <v-col cols="10">
                                    <span class="mt-2">
                                      <b>B2B:</b> {{ allHotel.filter(e => e.id === hot.id)[0].name }}<br />
                                      <b>Hotetec:</b> {{ hot.name }}
                                    </span>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-tooltip
                                      top
                                      color="error"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="error">
                                            {{ icons.mdiInformationOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('lbl.problemsHabitaciones') }}</span>
                                    </v-tooltip>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content :key="Math.random() * (indH + 1)">
                              <v-simple-table
                                v-for="(room, indR) in hot.rooms"
                                :key="indR"
                              >
                                <template v-slot:default>
                                  <tbody>
                                    <ImportFilesRoom
                                      :lote="lote"
                                      :hotel="hot"
                                      :pos="indR"
                                      :room="room"
                                      :rooms="hot.roomsNom"
                                      @asociate="confirmAddAsociateRoom"
                                    />
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </template>
                      </template>--
                      <v-expansion-panel v-if="rommsChange.length > 0">
                        <v-expansion-panel-header :disabled="hotelsChange.length > 0">
                          <template v-slot:default>
                            <v-row>
                              <v-col cols="2">
                                <h4 class="mt-2">
                                  {{ $t('menu.habitaciones') }}
                                </h4>
                              </v-col>
                              <v-col cols="1">
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('lbl.problemsHabitaciones') }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="(item, index) in rommsChange"
                                  :key="Math.random() * (index + 1)"
                                >
                                  <td width="80%">
                                    {{ item }}
                                  </td>
                                  <td>
                                    <v-tooltip
                                      top
                                      color="primary"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="modalRoomOld(item)"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiSquareEditOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('btn.edit') }}</span>
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      !--REGIMEN ALIMENTICIO--
                      <v-expansion-panel v-if="planesChange.length > 0">
                        <v-expansion-panel-header :disabled="hotelsChange.length > 0">
                          <template v-slot:default>
                            <v-row>
                              <v-col cols="2">
                                <h4 class="mt-2">
                                  {{ $t('menu.regimenAlimenticio') }}
                                </h4>
                              </v-col>
                              <v-col cols="1">
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('lbl.problemsPlanAlim') }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="(item, index) in planesChange"
                                  :key="Math.random() * (index + 1)"
                                >
                                  <td width="80%">
                                    {{ item }}
                                  </td>
                                  <td>
                                    <v-tooltip
                                      top
                                      color="primary"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="modalPlanes(item)"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiSquareEditOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('btn.edit') }}</span>
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <fragment v-if="lote.listo">
                    <v-col cols="12">
                      <fragment
                        v-for="(item, index) in lote.lote"
                        :key="Math.random() * (index + 1)"
                      >
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <th width="30%">
                                  <v-row>
                                    <v-col cols="8">
                                      <b>B2B:</b>
                                      <span v-if="item.hotel.name.action !== 'add'">
                                        {{ allHotel.filter(e => e.id === item.hotel.name.id)[0].name }}<br />
                                      </span>
                                      <span v-else> {{ item.hotel.name.name }}<br /></span>
                                      <b>{{ nameHeaderAPI }}:</b> {{ item.hotel.name.valueCsv }}
                                    </v-col>
                                    <v-col cols="2">
                                      <v-btn
                                        icon
                                        @click="showItem(index)"
                                      >
                                        <v-icon>
                                          {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </th>
                                <th width="20%">
                                  <span v-if="showRow && index === indexRow">{{ $t('lbl.rooms') }}</span>
                                </th>
                                <th
                                  width="10%"
                                  class="text-left"
                                >
                                  <span v-if="showRow && index === indexRow">{{ $t('lbl.ocupations') }}</span>
                                </th>
                                <th
                                  width="15%"
                                  class="text-left"
                                >
                                  <span v-if="showRow && index === indexRow">{{ $t('menu.regimenAlimenticio') }}</span>
                                </th>
                              </tr>

                              <fragment v-if="showRow && index === indexRow">
                                <tr
                                  v-for="(cupo, indU) in item.rooms"
                                  :key="Math.random() * (indU + 1)"
                                >
                                  <td></td>
                                  <td>
                                    <b>B2B</b>:
                                    <span v-if="cupo.room.action !== 'add'">
                                      {{ allRooms.filter(e => e.id === cupo.room.id)[0].name }}<br />
                                    </span>
                                    <span v-else> {{ cupo.room.name }}<br /></span>
                                    <b>{{ nameHeaderAPI }}</b>:{{ cupo.room.valueCsv }}
                                  </td>
                                  <td class="text-left">
                                    <span
                                      v-for="(roomC, indR) in cupo.room.ocupation"
                                      :key="Math.random() * (indR + 1)"
                                    >
                                      <b>Adult:</b>{{ roomC.adult }} <b>Mnr:</b>{{ roomC.mnr }} <b>Inf:</b>{{ roomC.inf
                                      }}<br />
                                    </span>
                                  </td>
                                  <td class="text-left">
                                    <span
                                      v-for="(plan, indP) in item.regimen"
                                      :key="indP"
                                    >
                                      <b>B2B:</b> {{ allPlanes.filter(e => e.id === plan.id)[0].siglas }}<br />
                                      <b>{{ nameHeaderAPI }}:</b> {{ plan.name }} <br />
                                      <v-divider></v-divider>
                                    </span>
                                  </td>
                                </tr>
                              </fragment>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </fragment>
                    </v-col>
                  </fragment>-->
                  <ImportFilesHotel
                    v-for="(hotel, indH) in hotelsChanges"
                    :key="indH"
                    :lote="lote"
                    :hotel="hotel"
                    :pos="indH"
                    :list-hotel="listHotel"
                    :hotels-pact="hotelsPact"
                    :hotels-pact-uses="hotelsPactUses"
                    :all-rooms="allRooms"
                    :all-plans="allPlanes"
                    :all-categories-tarifarios="allCategoriesTarifarios"
                    @getLote="getLote"
                    @setModify="setModify"
                  />
                </fragment>
              </v-row>
            </v-form>
          </template>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDeleteItem"
      scrollable
      max-width="380px"
    >
      <v-card
        v-if="model.name"
        max-height="400px"
      >
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.addDelete', { item: model.name.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteItem = !isDialogVisibleDeleteItem"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.processUpload', { item: 'contratos de hoteles' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { item: 'contratos de hoteles' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              item:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addRemplace', {
              remplace:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : '',
              item:
                type === 'hotel'
                  ? model.hotel.id
                    ? itemsHotel.filter(e => e.id === model.hotel.id).length > 0
                      ? itemsHotel.filter(e => e.id === model.hotel.id)[0].name
                      : ''
                    : ''
                  : type === 'room'
                    ? model.room.id
                      ? itemsRooms.filter(e => e.id === model.room.id).length > 0
                        ? itemsRooms.filter(e => e.id === model.room.id)[0].name
                        : ''
                      : ''
                    : type === 'planes'
                      ? model.planes.id
                        ? allPlanes.filter(e => e.id === model.planes.id).length > 0
                          ? allPlanes.filter(e => e.id === model.planes.id)[0].name
                          : ''
                        : ''
                      : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', {
              asociate:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
              item:
                type === 'hotel'
                  ? model.hotel.id
                    ? itemsHotel.filter(e => e.id === model.hotel.id).length > 0
                      ? itemsHotel.filter(e => e.id === model.hotel.id)[0].name
                      : ''
                    : ''
                  : type === 'room'
                    ? model.room.id
                      ? itemsRooms.filter(e => e.id === model.room.id).length > 0
                        ? itemsRooms.filter(e => e.id === model.room.id)[0].name
                        : ''
                      : ''
                    : type === 'planes'
                      ? model.planes.id
                        ? allPlanes.filter(e => e.id === model.planes.id).length > 0
                          ? allPlanes.filter(e => e.id === model.planes.id)[0].name
                          : ''
                        : ''
                      : type === 'suplementos'
                        ? model.suplementos.id
                          ? allSuplementos.filter(e => e.id === model.suplementos.id).length > 0
                            ? allSuplementos.filter(e => e.id === model.suplementos.id)[0].name
                            : ''
                          : ''
                        : type === 'reducciones'
                          ? model.reducciones.id
                            ? allReducciones.filter(e => e.id === model.reducciones.id).length > 0
                              ? allReducciones.filter(e => e.id === model.reducciones.id)[0].name
                              : ''
                            : ''
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addDelete', {
              item:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE HOTEL -->
    <v-dialog
      v-model="isChangeHotel"
      max-width="650px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.hotel"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="model.hotel.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="model.hotel.id"
                :items="itemsHotel"
                :search-input.sync="searchHotel"
                hide-details
                hide-selected
                :label="$t('lbl.hotel')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeHotel = !isChangeHotel"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.hotel"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('hotel')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id"
                    v-on="on"
                    @click="showRemplace('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id"
                    v-on="on"
                    @click="showAsociate('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('hotel', model.hotel.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE ROOM -->
    <v-dialog
      v-model="isChangeRoom"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.room"
            class="mt-5"
          >
            <!--<v-col
              cols="12"
              md="12"
            >
              <b>{{ model.room.nameHot }}</b>
            </v-col>-->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="model.room.name"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="model.room.id"
                :items="itemsRooms"
                :search-input.sync="searchRooms"
                hide-details
                hide-selected
                :label="$t('lbl.room')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <!--<v-select
                v-model="model.room.id"
                :items="model.room.allRooms"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
              ></v-select>-->
            </v-col>

            <!--<v-col
              v-if="loadingHotels"
              cols="12"
              md="12"
            >
              <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            </v-col>
            <v-col
              v-else
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="hotelsIds"
                :items="hotelsIds"
                hide-details
                hide-selected
                :label="$t('lbl.hotelsAsociado')"
                outlined
                dense
                disabled
                chips
                multiple
              >
              </v-autocomplete>
            </v-col>-->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeRoom = !isChangeRoom"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.room"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('room')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.room.id"
                    v-on="on"
                    @click="showRemplace('room', model.room)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.room.id"
                    v-on="on"
                    @click="showAsociate('room', model.room)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <!--<v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('room', model.room.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>-->
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE PLANES -->
    <v-dialog
      v-model="isChangePlanes"
      max-width="750px"
    >
      <v-card height="250px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.planes"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.planes.name"
                :label="$t('menu.regimenAlimenticio')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.planes.id"
                :items="itemsPlanes"
                :search-input.sync="searchPlanes"
                hide-details
                hide-selected
                :label="$t('menu.regimenAlimenticio')"
                outlined
                dense
                item-text="siglas"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.regimenAlimenticio') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.siglas"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.siglas }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangePlanes = !isChangePlanes"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.planes"
              cols="12"
              md="6"
              offset-md="2"
            >
              <!--<v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('planes')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.planes.id"
                    v-on="on"
                    @click="showRemplace('planes', model.tipo_destino)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>-->

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.planes.id"
                    v-on="on"
                    @click="showAsociate('planes', model.planes)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <!--<v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('planes', model.planes.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>-->
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

import ImportFilesHotel from '../utils/ImportFilesHotel.vue'
import ImportFilesRoom from '../utils/ImportFilesRoom.vue'

export default {
  components: {
    AppCardCode,
    Loading,
    // eslint-disable-next-line vue/no-unused-components
    ImportFilesRoom,
    ImportFilesHotel,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      modificando: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_proveedor: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {
        proveedor_id: null,
      },
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      edad_min: {},
      loading: false,
      loadingDelete: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsHotel: [],
      allHotel: [],
      listHotel: [],
      searchHotel: null,

      itemsRooms: [],
      allRooms: [],
      searchRooms: null,
      hotelsIds: [],
      loadingHotels: false,

      planesChange: [],
      tarifasChange: [],
      itemsPlanes: [],
      allPlanes: [],
      searchPlanes: null,
      allCategoriesTarifarios: [],

      suplementosChange: [],
      itemsSuplementos: [],
      allSuplementos: [],
      searchSuplementos: null,

      reduccionesChange: [],
      itemsReducciones: [],
      allReducciones: [],
      searchReducciones: null,

      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: [],
      processUpload: false,
      cancelarUpload: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      isChangeHotel: false,
      isChangeRoom: false,
      isChangePlanes: false,
      isChangeSuplementos: false,
      isChangeReducciones: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      hotelsChange: [],
      hotelsList: [],
      rommsChange: [],
      categoriasChange: [],
      tiposDestinosChange: [],
      edadesChange: [],
      serviciosChange: [],
      interesesChange: [],
      logo: null,
      valueRemplace: null,
      typeRemplace: null,
      valueCsv: null,
      codeHot: null,
      idRemplace: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,
      proveedor_id: 0,
      operadorsId: [],
      use_with_hotetec: false,
      nameHotetec: '',
      sources: [],
      sourcesAvalibled: [],
      operadoresApiExtern: [],
      contrate: null,
      hotelsPact: [],
      hotelsPactUses: [],
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
      hotelsChanges: state => state.app.hotelsChanges,
    }),
    listoUpload() {
      let result = false

      this.hotelsList.forEach(elementHot => {
        elementHot.rooms.forEach(elementRoom => {
          if (elementRoom.id === 0 || elementRoom.id === null) {
            result = true
          }
        })
      })

      return result
    },
    verifyUpload() {
      let result = false

      this.hotelsChanges.forEach(elementHot => {
        if (!elementHot.name.id) {
          if (elementHot.name.action !== 'add') {
            result = true
          }
        }
        elementHot.rooms.forEach(element => {
          if (!element.room.id) {
            if (element.room.action !== 'add') {
              result = true
            }
          }
        })
        elementHot.plans.forEach(element => {
          if (!element.id) {
            result = true
          }
        })
        elementHot.tarifas.forEach(element => {
          if (!element.category_id && element.action !== 'delete') {
            result = true
          }
        })
      })

      return result
    },
    nameHeaderAPI() {
      let result = ''
      this.sourcesAvalibled.forEach(sourceA => {
        this.contrate.source.forEach(sourceC => {
          if (sourceA.slug === sourceC) {
            result = sourceA.name
          }
        })
      })

      return result
    },
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },

    searchOperador(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchHotel(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      } else {
        this.itemsHotel = this.allHotel
      }
    },
    searchPlanes(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterPlanes(val.toLowerCase())
    },
    searchRooms(val) {
      this.itemsRooms = []
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRooms(val.toLowerCase())
      } else {
        this.itemsRooms = []
      }
    },
    searchSuplementos(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterSuplementos(val.toLowerCase())
      } else {
        this.itemsSuplementos = []
      }
    },
    searchReducciones(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterReducciones(val.toLowerCase())
      } else {
        this.itemsReducciones = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.fetchDataFromEndpoints()

    // this.getLote()
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
      'updateHotelsChange',
    ]),
    async fetchDataFromEndpoints() {
      const id = localStorage.getItem('hotels-contrate-id')
      try {
        const [
          resProveedors,
          resContrate,
          resHotels,
          resRooms,
          resPlans,
          resCateTarifarios,
        ] = await Promise.all([
        this.axios
          .post('affiliate/byproduct/hotels', { user: 1 }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          }),
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          }),
        this.axios
          .post(
            'hotels/list',
            { per_page: 1000 },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          ),
        this.axios
          .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          }),
        this.axios
          .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          }),
        this.axios
          .get('nom_categories_tarifarios_hotels?per_page=1000', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          }),
        ])

        this.proveedores = resProveedors.data.data
        this.contrate = resContrate.data.data.data
        if (resContrate.data.data.data.operadorsId) {
          this.operadorsId = resContrate.data.data.data.operadorsId
        }
        this.hotelsPact = this.contrate.hotels_pactados

        resHotels.data.data.forEach(element => {
          if (this.operadorsId.includes(element.operador_id)) {
            this.allHotel.push(element)
            this.listHotel.push(element)
          }
        })

        this.allRooms = resRooms.data.data

        this.allPlanes = resPlans.data.data
        this.itemsPlanes = resPlans.data.data

        this.allCategoriesTarifarios = resCateTarifarios.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.getLote()
      }
    },
    async getLote() {
      await this.axios
        .get(`lotes-contrate-hotetec?contrate_id=${localStorage.getItem('hotels-contrate-id')}&product=hotel`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.lote = res.data.data.data

          if (this.lote) {
            this.hotelsPactUses = this.lote.ids_used
            this.updateHotelsChange(res.data.data.data.hoteles)
            this.rommsChange = res.data.data.data.habitaciones
            this.hotelsList = res.data.data.data.hotelesList
            this.planesChange = res.data.data.data.planes
            this.tarifasChange = res.data.data.data.tarifas
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getSources() {
      /* this.sources.push({
        name: 'Local',
        slug: 'local',
      }) */
      this.axios
        .post(
          'api_keys/verify-status',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.status) {
            this.nameHotetec = res.data.data.api_name
            this.sources.push({
              name: res.data.data.api_name,
              slug: 'hotetec',
            })
          }
        })
        .finally(() => {
          this.getApiExtern()
        })
    },
    getApiExtern() {
      this.axios
        .post(
          'api_extern/show',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            if (res.data.data.proveedores) {
              this.operadoresApiExtern = res.data.data.proveedores

              if (this.operadoresApiExtern.filter(e => e === this.contrate.proveedor_id).length > 0) {
                this.sourcesAvalibled = this.sources
              } else {
                this.sourcesAvalibled = []
              }
            }
          }
        })
    },
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.allRooms.filter(e => e.name.toLowerCase())

        // this.itemsRooms = this.model.room.allRooms.filter(e => e.name.toLowerCase())
      }
    },
    filterPlanes() {
      this.itemsPlanes = this.allPlanes.filter(e => e.siglas.toLowerCase())
    },
    filterSuplementos(v) {
      this.itemsSuplementos = []
      if (v === '') {
        this.itemsSuplementos = []
      } else {
        this.itemsSuplementos = this.allSuplementos.filter(e => e.name.toLowerCase())
      }
    },
    filterReducciones(v) {
      this.itemsReducciones = []
      if (v === '') {
        this.itemsReducciones = []
      } else {
        this.itemsReducciones = this.allReducciones.filter(e => e.name.toLowerCase())
      }
    },

    getNomAll() {
      this.getProveedores()
      this.getPlanes()
      this.getCategoriesTarifarios()
    },
    async getPlanes() {
      await this.axios
        .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allPlanes = res.data.data
          this.itemsPlanes = res.data.data
        })
    },
    async getCategoriesTarifarios() {
      await this.axios
        .get('nom_categories_tarifarios_hotels?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allCategoriesTarifarios = res.data.data
        })
    },
    async getProveedores() {
      await this.axios
        .post('affiliate/byproduct/hotels', { user: 1 }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
        //.finally(() => this.getContrate())
    },

    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
    },
    setOperadores() {
      this.allHotel = []
      if (this.model.hotel.operador_id) {
        this.allHotel = this.listHotel.filter(e => e.operador_id === this.model.hotel.operador_id)
        this.itemsHotel = []

        /* if (this.allOperadores.filter(e => e.id === this.model.hotel.operador_id)[0].marcas.length > 0) {
          this.allHotel = this.allOperadores.filter(e => e.id === this.model.hotel.operador_id)[0].marcas
          this.itemsHotel = this.allHotel
        } */
      }
    },
    getSuplementos() {
      this.axios
        .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allSuplementos = res.data.data
        })
    },
    setSuplemento() {
      if (this.model.suplementos) {
        if (this.model.suplementos.id) {
          if (this.allSuplementos.filter(e => e.id === this.model.suplementos.id).length > 0) {
            this.model.suplementos.modality = this.allSuplementos.filter(
              e => e.id === this.model.suplementos.id,
            )[0].modality
          }
        } else {
          this.model.suplementos.modality = null
        }
      } else {
        this.model.suplementos.modality = null
      }
    },
    getReducciones() {
      this.axios
        .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allReducciones = res.data.data
        })
    },
    getSeasons() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const json = {
          contrate_id: localStorage.getItem('hotels-contrate-id'),
        }
        this.axios
          .post('contrate_hotels/list-season-contrate', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (!res.data.success) { } else {
              res.data.data.forEach(element => {
                if (element.seasons) {
                  if (element.seasons.ocupations) {
                    if (element.seasons.ocupations.length > 0) {
                      element.hotels.forEach(hhh => {
                        if (!this.hotelsPactUses.includes(hhh)) {
                          this.hotelsPactUses.push(hhh)
                        }
                      })
                    }
                  }
                }
              })
            }
          })
      }
    },

    acceptUpload() {
      this.isDialogUpdate = true
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmUpdateAll() {
      this.modificando = true
      const json = {
        id: this.lote.id,
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        items: this.hotelsChanges,
      }

      this.axios
        .post(
          'lotes-contrate-hotetec/process-all', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    confirmCancele() {
      this.cancelarUpload = true
      this.axios
        .delete(`lotes-contrate-hotetec/${this.lote.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    modalHotel(item) {
      this.type = 'hotel'
      this.model = {
        hotel: {
          name: item,
          operador_id: null,
          valueCsv: item,
        },
      }
      this.isChangeHotel = true
    },
    modalRoom(item, hotel) {
      this.model = {
        room: {
          allRooms: hotel.roomsNom,
          codeHot: hotel.code,
          nameHot: hotel.name,
          name: item.name,
          valueCsv: item.name,
        },
      }
      this.loadingHotels = true
      this.isChangeRoom = true

      // this.getHotelsIdsLote()
    },
    modalRoomOld(item) {
      this.type = 'room'
      this.model = {
        room: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeRoom = true

      // this.loadingHotels = true
      // this.getHotelsIdsLote()
    },
    getHotelsIdsLote() {
      if (this.model.room) {
        this.axios
          .post(
            `lotes-contrate-hotetec/get-hotels-asociate/${this.lote.id}`,
            { room_name: this.model.room.valueCsv },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.hotelsIds = res.data.data.hotels
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadingHotels = false))
      }
    },
    modalPlanes(item) {
      this.model = {
        planes: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangePlanes = true
    },
    modalSuplementos(item) {
      this.model = {
        suplementos: {
          name: item.name,
          valueCsv: item.name,
          type: item.type,
          modality: null,
        },
      }
      this.isChangeSuplementos = true
    },
    modalReducciones(item) {
      this.model = {
        reducciones: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeReducciones = true
    },

    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    showRemplace(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      if (type === 'suplementos') {
        this.typeRemplace = newValue.type
      } else {
        this.typeRemplace = null
      }
      this.valueCsv = newValue.valueCsv
      this.codeHot = newValue.codeHot ? newValue.codeHot : ''
      this.idRemplace = newValue.id
      this.isAddRemplace = true
    },
    showAsociate(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      if (type === 'suplementos') {
        this.typeRemplace = newValue.type
      } else {
        this.typeRemplace = null
      }
      this.valueCsv = newValue.valueCsv
      this.codeHot = newValue.codeHot ? newValue.codeHot : ''
      this.idRemplace = newValue.id
      this.isAddAsociate = true
    },
    showDelete(type, newValue) {
      this.type = type
      this.valueRemplace = newValue

      if (type === 'suplementos') {
        this.typeRemplace = this.model.suplementos.type
      } else {
        this.typeRemplace = null
      }
      this.isAddDelete = true
    },

    confirmChange(type) {
      this.axios
        .put(
          `lotes/update/${this.lote.id}`,
          { type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddChange() {
      this.addChange = true
      this.axios
        .post(
          `lotes-contrate-hotetec/add-change/${this.lote.id}`,
          { type: this.type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        valueCsv: this.valueCsv,
        codeHot: this.codeHot,
        idValue: this.idRemplace,
        type: this.type,
        use_with_hotetec: this.use_with_hotetec,
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        valueCsv: this.valueCsv,
        codeHot: this.codeHot,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociateRoom(json) {
      this.addChange = true

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.$toast.success(this.$t('msg.infoSuccess'))
          // this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
      // eslint-disable-next-line no-return-assign
      // .finally(() => this.resetFile())
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        type: this.type,
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    deleteItem(item) {
      this.isDialogVisibleDeleteItem = true
      this.model = {
        name: {
          id: item.id,
          name: item.name.name,
        },
      }
    },
    confirmDelete() {
      this.loadingDelete = true
      const json = {
        id: this.model.name.id,
      }

      this.axios
        .post(`lotes-contrate-hotetec/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .finally(() => this.resetFile())
    },

    setFile() {
      if (this.file.length > 0) {
        if (this.file.length <= 30) {
          this.modificando = true
          const formData = new FormData()
          this.file.forEach(element => {
            formData.append('files[]', element)
          })
          formData.append(
            'data',
            JSON.stringify({
              contrate_id: localStorage.getItem('hotels-contrate-id'),
              product: 'hotel',
            }),
          )

          this.axios
            .post('contrate_hotels/import-files', formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              if (response.data.success === false) {
                this.$toast.error(
                  this.$t('msg.errorInput', {
                    file: response.data.file,
                    sheet: response.data.tab,
                    colum: response.data.colum,
                    item: response.data.item,
                    element: response.data.element,
                  }),
                )
              } else {
                this.$toast.success(this.$t('msg.verifiqueInfo'))
              }

              this.getLote()
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.resetFile())
        } else {
          this.$toast.error(this.$t('lbl.execFilesUpload', { item: 30 }))
          this.file = []
        }
      }
    },
    setModify() {
      this.modificando = true
    },
    resetFile() {
      this.file = []
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.modificando = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangeHotel = false
      this.isChangeRoom = false
      this.isChangePlanes = false
      this.isChangeSuplementos = false
      this.isChangeReducciones = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.typeRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchOperador = null
      this.searchMarcas = null
      this.searchTipoDestino = null
      this.searchServicios = null
      this.searchIcons = null
      this.searchIntereses = null
      this.name_proveedor = null
      this.hotelsIds = []
    },

    getContrate() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.contrate = res.data.data.data
            if (res.data.data.data.operadorsId) {
              this.operadorsId = res.data.data.data.operadorsId
            }
            this.hotelsPact = this.contrate.hotels_pactados
          })
          .finally(() => {
            this.getHotel()
            this.getRooms()
            this.getSources()
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    getHotel() {
      this.axios
        .post(
          'hotels/list',
          { per_page: 1000 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          res.data.data.forEach(element => {
            if (this.operadorsId.includes(element.operador_id)) {
              this.allHotel.push(element)
              this.listHotel.push(element)
            }
          })
        })
        .finally(() => {
          this.getLote()
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
  },
}
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.8rem !important;
  height: 2rem !important;
}
</style>
