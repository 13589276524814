<template>
  <div v-if="!isLoading">
    <fragment
      v-for="(car, indCar) in reserva.car_shop"
      :key="indCar"
    >
      <HistorialCar
        v-if="car.entity === 'cars'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
      />
      <HistorialHotel
        v-else-if="car.entity === 'hotels'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
      />
    </fragment>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'

import HistorialCar from './HistorialCar.vue'
import HistorialHotel from './HistorialHotel.vue'

export default {
  components: {
    HistorialCar,
    HistorialHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisibleDelete: false,
      isDialogVisible: false,
      item: {},
      itemId: null,
      loading: false,
      reservando: false,
      permisos: localStorage.getItem('permisos_auth'),
      states: [],
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
