<template>
  <v-col cols="12">
    <v-row :class="pos > 0 ? 'mt-10' : ''">
      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="suplement.suplement_id"
              :disabled="$store.state.app.onlyShow"
              :items="suplementsReductionsFree"
              :label="$t('lbl.suplemento')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.suplemento') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="suplementsAlimenticiosSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              @click="deleteSuplementsAlimenticiosSeasonContrateHotel(pos)"
            >
              <v-icon
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              :disabled="verifyRoomsFree"
              v-on="on"
              @click="addSuplementsAlimenticiosSeasonContrateHotel"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>

      <v-col
        cols="12"
        md="7"
        offset-md="1"
      >
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <b>{{ $t('lbl.equipaje_personal.para') }}: </b>
            <v-radio-group
              v-model="suplement.applyBy"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                v-for="(ap, ind) in apply"
                :key="ind"
                :label="ap.name"
                :value="ap.slug"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <!--<SeasonNamesHotelTarifa
        :pos="pos"
        :suplement="suplement"
        :show-optional="true"
        :from="'supAlimenticios'"
      />-->
      <v-col
        cols="12"
        md="2"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.optional"
          :label="$t('lbl.optional')"
          hide-details
          class="pt-0 mt-0"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.is_tarifa"
          :label="$t('lbl.tarifa')"
          hide-details
          class="pt-0 mt-0"
          @click="setTarifa"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="1"
          ></v-col>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(season, indR) in seasonsContrateHotel"
                :key="indR"
                style="max-width: 85px; margin-left: 10px; margin-bottom: 10px"
                class="pt-0 mt-0"
              >
                <v-text-field
                  v-model="season.name"
                  class="my-input"
                  hide-details
                  disabled
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <v-col
        cols="12"
        md="3"
      >
        <v-textarea
          v-model="suplement.incluye"
          :label="$t('lbl.incluye')"
          :disabled="$store.state.app.onlyShow"
          outlined
          no-resize
          rows="7"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <!--ADL-->
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="ADL"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceAld, indC) in suplement.adl.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px; margin-top: 10px"
              >
                <v-text-field
                  v-model="priceAld.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.price')"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    priceAld.price ? (!priceAld.price.includes('N') && !priceAld.price.includes('F') ? '$' : '') : '$'
                  "
                  :append-icon="suplement.is_tarifa ? 'mdi-arrow-down' : ''"
                  @keypress="checkPricePc($event, priceAld.price, suplement.adl.in_pc)"
                  @input="val => (priceAld.price = priceAld.price.toUpperCase())"
                  @click:append="
                    suplement.is_tarifa
                      ? replicaSuplementsAlimenticiosSeasonContrateHotel({
                        pos: pos,
                        posSeason: indC,
                        price: priceAld.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--TEEN-->
        <v-row v-if="hasTeen">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="Teen"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.teen.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceTeen, indC) in suplement.teen.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px; margin-top: 10px"
              >
                <v-text-field
                  v-model="priceTeen.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="suplement.is_tarifa ? $t('lbl.price') : 'Red.'"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.teen.in_pc
                      ? ''
                      : priceTeen.price
                        ? !priceTeen.price.includes('N') && !priceTeen.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.teen.in_pc
                      ? priceTeen.price
                        ? !priceTeen.price.includes('N') && !priceTeen.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  @keypress="checkPricePc($event, priceTeen.price, suplement.mnr.in_pc)"
                  @input="val => (priceTeen.price = priceTeen.price.toUpperCase())"
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--MNR-->
        <v-row v-if="hasMnr">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="MNR"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.mnr.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceMnr, indC) in suplement.mnr.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px; margin-top: 10px"
              >
                <v-text-field
                  v-model="priceMnr.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="suplement.is_tarifa ? $t('lbl.price') : 'Red.'"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.mnr.in_pc
                      ? ''
                      : priceMnr.price
                        ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.mnr.in_pc
                      ? priceMnr.price
                        ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  @keypress="checkPricePc($event, priceMnr.price, suplement.mnr.in_pc)"
                  @input="val => (priceMnr.price = priceMnr.price.toUpperCase())"
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--INF-->
        <v-row v-if="hasMnr">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="INF"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.inf.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceInf, indC) in suplement.inf.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px; margin-top: 10px"
              >
                <v-text-field
                  v-model="priceInf.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="suplement.is_tarifa ? $t('lbl.price') : 'Red.'"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.inf.in_pc
                      ? ''
                      : priceInf.price
                        ? !priceInf.price.includes('N') && !priceInf.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.inf.in_pc
                      ? priceInf.price
                        ? !priceInf.price.includes('N') && !priceInf.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  @keypress="checkPricePc($event, priceInf.price, suplement.mnr.in_pc)"
                  @input="val => (priceInf.price = priceInf.price.toUpperCase())"
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import SeasonNamesHotelTarifa from './SeasonNamesHotelTarifa.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonNamesHotelTarifa,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),

      // allSuplement: [],
      // allRooms: [],
      itemsRooms: [],

      itemsSuplement: [],
      searchSuplement: null,
      bases: [],
      apply: [],
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      suplementsAlimenticiosSeasonContrateHotel: state => state.app.suplementsAlimenticiosSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),
    suplementsReductionsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO PLAN ALIMENTOS
        if (element.model === 'plan-alimentos' && !element.is_reduction) {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsAlimenticiosSeasonContrateHotel.length; index++) {
            if (
              this.suplementsAlimenticiosSeasonContrateHotel[index].suplement_id === element.id
              && index !== this.pos
            ) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      let arrResult = []

      // suplement.suplement_id
      if (this.suplement.suplement_id) {
        // if (result.filter(e => e.id === this.suplement.sup_red_id[0]).length > 0) {
        const v = result.filter(e => e.id === this.suplement.suplement_id)[0]
        if (v.is_reduction) {
          result.forEach(elementOrder => {
            if (elementOrder.is_reduction) {
              arrResult.push(elementOrder)
            } else {
              arrResult.push({
                ...elementOrder,
                disabled: true,
              })
            }
          })
        } else {
          result.forEach(elementOrder => {
            if (!elementOrder.is_reduction) {
              arrResult.push(elementOrder)
            } else {
              arrResult.push({
                ...elementOrder,
                disabled: true,
              })
            }
          })
        }

        // }
      } else {
        arrResult = result
      }

      return arrResult
    },
    verifyRoomsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO ALOJAMIENTO
        if (element.model === 'plan-alimentos' && !element.is_reduction) {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsAlimenticiosSeasonContrateHotel.length; index++) {
            if (this.suplementsAlimenticiosSeasonContrateHotel[index].suplement_id === element.id) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },

    hasTeen() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          has = true
        }
      })

      return has
    },
    hasMnr() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_mnr || element.has_a_inf) {
          has = true
        }
      })

      return has
    },
  },
  watch: {
    searchSuplement(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterSuplement(val.toLowerCase())
      }
    },
  },
  created() {
    this.apply.push({
      name: 'PPP',
      slug: 'ppp',
    })
    this.apply.push({
      name: 'PPH',
      slug: 'pph',
    })
    this.apply.push({
      name: 'PPN',
      slug: 'ppn',
    })
    this.apply.push({
      name: 'PPPN',
      slug: 'pppn',
    })
    this.apply.push({
      name: 'PPHN',
      slug: 'pphn',
    })
  },
  methods: {
    ...mapMutations([
      'deleteSuplementsAlimenticiosSeasonContrateHotel',
      'addSuplementsAlimenticiosSeasonContrateHotel',
      'replicaSuplementsAlimenticiosSeasonContrateHotel',
    ]),
    filterSuplement() {
      const suplem = this.allSuplement.filter(e => e.name.toLowerCase())
      suplem.forEach(element => {
        let esta = false
        this.suplementsSeasonContrateHotel.forEach(rrr => {
          if (rrr.sup_red_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsSuplement.push(element)
        }
      })
    },
    setTarifa() {
      if (this.suplement.is_tarifa) {
        this.suplement.teen.in_pc = false
        this.suplement.mnr.in_pc = false
        this.suplement.inf.in_pc = false
      }
    },
    checkPricePc(elEvento, model, inPc) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (inPc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!inPc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (elEvento.key === '.' && model.length === 0) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
