<template>
  <v-form class="multi-col-validation mt-1">
    <v-container
      v-if="!loading"
      grid-list-xs
    >
      <HotelsForm />
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

import HotelsForm from '../utils/HotelsForm.vue'

export default {
  components: {
    HotelsForm,
  },
  data() {
    return {
      tab: 0,
      isLoadingFlihgt: true,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      paginando: false,
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      aerolineas: [],
      radioGroup: 0,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,
      per_page: 10,

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      searchCotizadorFlights: state => state.app.searchCotizadorFlights,
      pageSearchingFlightCotizador: state => state.app.pageSearchingFlightCotizador,
    }),
  },
  created() {
    this.loading = false
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'emptyDestinoCotizadorFligth',
      'resetCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
      'setResultsCotizadorIdaFligth',
      'setResultsCotizadorRegresoFligth',
      'setAerolineCotizadorIdaFligth',
      'setAerolineCotizadorRegresoFligth',
      'setFiltrosCotizadorFlight',
      'setSearchingFlightCotizador',
      'setPageSearchingFlightCotizador',
      'setAerolineCotizadorDestinosFligth',
      'clearCotizadorFlightFavorite',
    ]),
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

.vc-pane-layout {
  z-index: 99999;
}
</style>
