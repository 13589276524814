<template>
  <div v-if="!isLoading">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="5%"
              class="text-uppercase"
            >
              <v-checkbox
                v-model="checkAllLocal"
                hide-details
                class="my-0"
                @change="checkinAll"
              ></v-checkbox>
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.code') }}
            </th>
            <th width="5%"></th>
            <th class="text-uppercase">
              {{ $t('lbl.importe') }}
            </th>
            <th class="text-uppercase">
              {{ $t('register.moneda') }}
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.emitidoBy') }}
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.wayMethod') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.acredor') }}
            </th>
            <th
              width="30%"
              class="text-uppercase"
            >
              {{ $t('menu.ctasXP') }}
            </th>
            <th
              width="5%"
              class="text-uppercase"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(cta, index) in pays"
            :key="index"
          >
            <td>
              <v-checkbox
                v-model="cta.check"
                hide-details
                class="my-0"
                @change="checkOne(cta)"
              ></v-checkbox>
            </td>
            <td width="15%">
              <span
                class="d-inline-block text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{ cta.code }}
              </span>
            </td>
            <td>
              <v-btn
                icon
                @click="showItem(index)"
              >
                <v-icon color="primary">
                  {{ showRow && index === indexRow ? icons.mdiArrowDownCircle : icons.mdiArrowRightCircle }}
                </v-icon>
              </v-btn>
            </td>
            <td>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ cta.monto | currency }} </span>
            </td>
            <td>{{ cta.moneda }}</td>
            <td>
              {{ cta.user.name }}<br />
              <span class="text-caption">{{ cta.user.date }}</span>
            </td>
            <td>
              <span v-if="cta.method_pay_slug === 'transfer'">{{ $t('lbl.transferBank') }}</span>
              <span v-else-if="cta.method_pay_slug === 'credit'">{{ $t('lbl.credit') }}</span>
              <span v-else-if="cta.method_pay_slug === 'payCard'">{{ $t('lbl.payCard') }}</span>
              <span v-else-if="cta.method_pay_slug === 'linkPay'">{{ $t('lbl.linkPay') }}</span>
              <span v-else-if="cta.method_pay_slug === 'checkBank'">{{ $t('lbl.checkBank') }}</span>
              <span v-else-if="cta.method_pay_slug === 'cash'">{{ $t('lbl.cash') }}</span>
            </td>
            <td>
              <!--<span v-if="cta.company_pay.agente_id">
                {{ cta.company_pay.agente_name }}
              </span>
              <span v-else>
                {{ cta.company_pay.company_name }}
              </span>-->
              {{ cta.acredor.user_name }}
            </td>
            <td>
              <fragment v-if="showRow && index === indexRow">
                <span
                  v-for="(ctaXP, indCXP) in cta.ctas_x_pagar"
                  :key="indCXP"
                > {{ ctaXP.code }}<br /> </span>
              </fragment>
            </td>
            <td width="5%">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.send') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="cta.evidencia"
                    link
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        mdi-tray-arrow-down
                      </v-icon>
                      <span>{{ $t('btn.dowload') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <!--<td>{{ cta.data_service.date_in | moment('D/MM/Y') }}</td>-->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiSendOutline,
  mdiMenuDown,
  mdiMenuRight,
  mdiArrowDownCircle,
  mdiArrowRightCircle,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pays: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    statesCtas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    payments: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      checkAllLocal: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiSendOutline,
        mdiMenuDown,
        mdiMenuRight,
        mdiArrowDownCircle,
        mdiArrowRightCircle,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      checkAllCobros: state => state.app.checkAllCobros,
      itemsCheckPaysEmit: state => state.app.itemsCheckPaysEmit,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'setCheckAllPaysEmit', 'addItemCheckPaysEmit', 'deleteItemCheckPaysEmit']),
    editItem(iten) {
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },

    checkinAll() {
      this.setCheckAllPaysEmit(this.checkAllLocal)
      if (this.checkAllLocal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.pays.length; index++) {
          this.pays[index].check = true

          // VERIFICAR Q NO DUPLIQUE LA CTA
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheckPaysEmit.length; j++) {
            if (this.itemsCheckPaysEmit[j].code === this.pays[index].code) {
              esta = true
            }
          }
          if (!esta) {
            this.addItemCheckPaysEmit(this.pays[index])
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.pays.length; index++) {
          this.pays[index].check = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheckPaysEmit.length; j++) {
            if (this.itemsCheckPaysEmit[j].code === this.pays[index].code) {
              this.deleteItemCheckPaysEmit(j)
            }
          }
        }
      }
    },
    checkOne(cta) {
      if (cta.check) {
        this.addItemCheckPaysEmit(cta)

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckPaysEmit.length === this.pays.length) {
          this.setCheckAllPaysEmit(true)
          this.checkAllLocal = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < this.itemsCheckPaysEmit.length; j++) {
          if (this.itemsCheckPaysEmit[j].code === cta.code) {
            this.deleteItemCheckPaysEmit(j)
          }
        }

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckPaysEmit.length !== this.pays.length) {
          this.setCheckAllPaysEmit(false)
          this.checkAllLocal = false
        }
      }
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
