<template>
  <v-container
    v-if="!isLoadingTarifaEcon"
    id=""
    grid-list-xs
  >
    <v-dialog
      v-model="showModal"
      persistent
      max-width="850"
    >
      <v-card>
        <!--<v-card-title class="text-h5">
          Use Google's location service?
        </v-card-title>-->
        <v-card-text>
          <v-card
            elevation="0"
            :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}`"
            class="mb-5"
          >
            <v-icon
              large
              style="float:right"
              @click="showModal = false"
            >
              {{ icons.mdiClose }}
            </v-icon>
            <v-container grid-list-xs>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="3">
                      <h3>{{ $t('lbl.hacia') }} {{ city }}</h3>
                      <p
                        caption
                        class="mb-0 d-flex justify-left align-baseline"
                      >
                        {{ destinosCotizadorFlight[0].from | moment('MMMM D, YYYY, dddd') }} <br />
                        {{ aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].tarifa }}
                        {{ aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].clase }}
                      </p>
                    </v-col>
                    <v-col
                      cols="3"
                      class="align-content-center justify-center align-self-center"
                    >
                      <div class="d-flex align-center align-content-center">
                        <v-icon
                          class="ml-5"
                          x-large
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                        >
                          {{ icons.mdiBagChecked }}
                        </v-icon>
                        <p class="p14Mine ml-2 text-caption mb-0 d-flex justify-right align-baseline">
                          {{ dataModal.cant_maletas_bodega === null ? 0 : dataModal.cant_maletas_bodega }}
                          {{ $t('lbl.equipaje_bodega.eq') }} <br />
                          {{ $t('lbl.equipaje_bodega.eqB') }}
                          {{
                            dataModal.cant_maletas_bodega === null
                              ? '0 Kg'
                              : dataModal.equipaje_bodega
                                ? dataModal.equipaje_bodega.peso
                                  ? dataModal.equipaje_bodega.name
                                  : '0 Kg'
                                : '0 Kg'
                          }}
                          {{
                            dataModal.cant_maletas_bodega === null
                              ? ''
                              : dataModal.equipaje_bodega
                                ? dataModal.equipaje_bodega.um
                                  ? dataModal.equipaje_bodega.um
                                  : ''
                                : ''
                          }}
                        </p>
                      </div>
                      <span
                        v-if="dataModal.cant_maletas_bodega !== null"
                        class="p14Mine ml-2 text-caption mb-0 d-flex justify-center align-baseline"
                      >
                        {{ dataModal.dimensiones_bodega.name }}
                      </span>
                    </v-col>
                    <v-col
                      cols="3"
                      class="align-content-center justify-center align-self-center"
                    >
                      <div class="d-flex align-center align-content-center">
                        <v-icon
                          class="mr-5 ml-0"
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <v-icon
                          x-large
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                        >
                          {{ icons.mdiBagSuitcase }}
                        </v-icon>
                        <p class="p14Mine text-caption mb-0 d-flex justify-center align-baseline">
                          {{
                            dataModal.cant_maletas_cabina === null || dataModal.cant_maletas_cabina === 0
                              ? 0
                              : dataModal.cant_maletas_cabina
                          }}
                          {{ $t('lbl.equipaje_cabina.eq') }} <br />
                          {{ $t('lbl.equipaje_cabina.eqB') }}
                          {{
                            dataModal.cant_maletas_cabina === null || dataModal.cant_maletas_cabina === 0
                              ? '0 Kg'
                              : dataModal.equipaje_cabina
                                ? dataModal.equipaje_cabina.peso
                                  ? dataModal.equipaje_cabina.peso.name
                                  : '0 Kg'
                                : '0 Kg'
                          }}
                          {{
                            dataModal.cant_maletas_cabina === null || dataModal.cant_maletas_cabina === 0
                              ? ''
                              : dataModal.equipaje_cabina
                                ? dataModal.equipaje_cabina.um
                                  ? dataModal.equipaje_cabina.um
                                  : ''
                                : ''
                          }}
                        </p>
                      </div>
                      <span
                        v-if="dataModal.cant_maletas_cabina !== null && dataModal.cant_maletas_cabina !== 0"
                        class="p14Mine ml-2 text-caption mb-0 d-flex justify-center align-baseline"
                      >
                        {{ dataModal.dimensiones_cabina.name }}
                      </span>
                    </v-col>
                    <v-col
                      cols="3"
                      class="align-content-center justify-center align-self-center"
                    >
                      <div class="d-flex align-center align-content-center">
                        <v-icon
                          class="mr-5"
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <v-icon
                          x-large
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                        >
                          {{ icons.mdiBagPersonal }}
                        </v-icon>
                        <p
                          v-if="dataModal.equipaje_personal !== null"
                          class="p14Mine mb-0 text-caption d-flex justify-center align-baseline"
                        >
                          {{ $t('lbl.equipaje_personal.title') }}
                          {{
                            dataModal.equipaje_personal.peso === null
                              ? '0 Kg'
                              : dataModal.equipaje_personal.peso
                                ? dataModal.equipaje_personal.peso.name
                                : '0 Kg'
                          }}
                          {{
                            dataModal.equipaje_personal.peso === null
                              ? ''
                              : dataModal.equipaje_personal.um
                                ? dataModal.equipaje_personal.um
                                : ''
                          }}
                        </p>
                        <p
                          v-else
                          class="p14Mine mb-0 text-caption d-flex justify-center align-baseline"
                        >
                          {{ $t('lbl.equipaje_personal.title') }} 0 Kg
                        </p>
                      </div>
                      <span
                        v-if="dataModal.equipaje_personal !== null"
                        class="p14Mine ml-2 text-caption mb-0 d-flex justify-center align-baseline"
                      >
                        {{ dataModal.dimensiones_personal.name }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card-text>
        <!--<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="showModal = false"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>

    <v-row>
      <v-col
        cols="12"
        md="8"
        class="pt-0"
      >
        <v-radio-group
          v-model="radioGroup"
          mandatory
        >
          <v-card
            elevation="0"
            :style="
              aerolineCotizadorIdaFlight[indAero].indTarifa === 0
                ? `border: 1px solid ${$vuetify.theme.themes.light.primary}`
                : 'border: 1px solid #e3e1e4;'
            "
            class="mb-5"
          >
            <v-container grid-list-xs>
              <v-row>
                <v-col cols="9">
                  <v-row class="pt-4">
                    <v-col
                      v-if="showOtherTarifas && indAero === rowShowOtherTarifas"
                      cols="1"
                    >
                      <v-radio
                        class="ml-5 d-flex justify-center align-right"
                        :value="0"
                        label=""
                        @click="setRadioIda(0)"
                      ></v-radio>
                    </v-col>
                    <v-col cols="11">
                      <p class="mb-0 d-flex justify-start">
                        <b>{{ aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].tarifa }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-start">
                        {{ $t('lbl.clase') }}:
                        <b class="pl-2">{{
                          aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].clase
                        }}</b>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">
                      <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                    </v-col>
                    <v-col
                      cols="4"
                      class="px-0"
                    >
                      <p
                        caption
                        class="p14Mine mb-0 d-flex justify-center align-baseline"
                      >
                        {{ $t('msg.verifyTarifa') }}
                      </p>
                    </v-col>
                    <v-col cols="7">
                      <div
                        v-if="
                          aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0]
                            .cant_maletas_bodega !== null
                        "
                        class="d-flex justify-start align-center"
                      >
                        <v-icon style="color: #53af24;">
                          {{ icons.mdiBagSuitcase }}
                        </v-icon>
                        <p
                          style="color: #53af24;"
                          class="p14Mine mb-0 d-flex justify-center align-baseline"
                        >
                          {{ $t('lbl.bag') }}:
                          {{
                            $tc(
                              'lbl.pieza',
                              aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0]
                                .cant_maletas_bodega,
                            )
                          }}
                        </p>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-start align-center"
                      >
                        <v-icon color="default">
                          {{ icons.mdiBagSuitcaseOffOutline }}
                        </v-icon>
                        <p
                          color="primeary"
                          class="p14Mine mb-0 d-flex justify-center align-baseline"
                        >
                          {{ $t('lbl.bagNoInclude') }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col
                      cols="1"
                      class="pt-0"
                    >
                      <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="px-0 pt-0"
                    >
                      <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                        {{ $t('msg.verifyReembolso') }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="7"
                      class="pt-0"
                    >
                      <div class="d-flex justify-start align-center">
                        <v-icon style="color: #53af24;">
                          {{ icons.mdiBagPersonal }}
                        </v-icon>
                        <p
                          style="color: #53af24;"
                          class="p14Mine mb-0 d-flex justify-center align-baseline"
                        >
                          {{ $t('lbl.bagHand') }}:
                          {{
                            `${
                              aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0]
                                .cant_maletas_cabina > 0
                                ? 'incluido'
                                : ''
                            }`
                          }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <div class="d-block">
                    <p
                      v-if="showOnlyGestor"
                      class="mb-0 pr-6 d-flex justify-center align-center"
                    >
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].price_total
                          | currency
                      }}
                    </p>
                    <p
                      v-else
                      class="mb-0 pr-6 d-flex justify-center align-center"
                      style="color: #FFFFFF"
                    >
                      .
                    </p>
                    <div class="d-flex justify-center">
                      <p class="mb-0 pr-1 d-flex justify-center align-center">
                        <b>{{
                          aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].price_total
                            | currency
                        }}</b>
                      </p>
                      <!--<v-tooltip
                        v-if="showOnlyGestor"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiInformation }}
                          </v-icon>
                        </template>
                        <span>
                          <div class="d-block">
                            <div class="d-flex justify-start align-center">
                              <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                            </div>
                            <div class="d-flex justify-space-between align-center">
                              <p class="mb-0 pr-6">Tarifa</p>
                              <p class="mb-0">776,87 $</p>
                            </div>
                            <div class="d-flex justify-space-between align-center">
                              <p
                                class="mb-0 pr-6"
                                subtitle-2
                              >Impuestos y tasas</p>
                              <p
                                class="mb-0"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                            <v-divider
                              color="white"
                              class="mt-2"
                            ></v-divider>
                            <div class="d-flex justify-space-between align-center">
                              <p class="my-2"><b>Total</b></p>
                              <p
                                class="my-2"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                            <div
                              style="background-color: #ffffff6b"
                              class="mb-4 pa-2"
                            >
                              <div class="d-flex justify-space-between align-center">
                                <p class="mb-0">Tu comisión</p>
                                <p
                                  class="mb-0"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                              <div class="d-flex justify-space-between align-center">
                                <p class="mb-0"><b>PVP</b></p>
                                <p
                                  class="mb-0"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                            </div>
                          </div>
                        </span>
                      </v-tooltip>-->
                    </div>
                    <p
                      v-if="showOnlyGestor"
                      class="mb-0 pr-6 p12Mine d-flex justify-end"
                      style="color:#ce2121"
                    >
                      {{ $t('lbl.cupo') }} ({{
                        aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate[0].cupo_disponible
                      }})
                    </p>
                    <a
                      style="cursor: pointer"
                      @click="rulesTarifa(0)"
                    >
                      <p
                        style="position: absolute; bottom:15px"
                        class="p14Mine mb-0 d-flex justify-center align-center"
                      >
                        {{ $t('lbl.rulesTarifa') }}
                      </p>
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!--OTRAS TARIFAS-->
          <template v-if="showOtherTarifas && indAero === rowShowOtherTarifas">
            <fragment
              v-for="(tarifa, indexCon) in aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate"
              :key="indexCon"
            >
              <!--v-if="
                  tarifa.class_id !==
                    aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifa_economica.class_id &&
                    tarifa.tarifa_id !==
                    aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifa_economica.tarifa_id
                "-->
              <v-card
                v-if="indexCon > 0"
                elevation="0"
                :style="
                  aerolineCotizadorIdaFlight[indAero].indTarifa === indexCon
                    ? `border: 1px solid ${$vuetify.theme.themes.light.primary}`
                    : 'border: 1px solid #e3e1e4;'
                "
                class="mb-5"
              >
                <v-container grid-list-xs>
                  <v-row class="pt-2">
                    <v-col cols="9">
                      <v-row class="pt-4">
                        <v-col cols="1">
                          <v-radio
                            class="ml-5 d-flex justify-center align-right"
                            :value="indexCon"
                            label=""
                            @click="setRadioIda(indexCon)"
                          ></v-radio>
                        </v-col>
                        <v-col cols="11">
                          <p class="mb-0 d-flex justify-start">
                            <b>{{ tarifa.tarifa }}</b>
                          </p>
                          <p class="mb-0 d-flex justify-start">
                            {{ $t('lbl.clase') }}: <b class="pl-2">{{ tarifa.clase }}</b>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="pt-4">
                        <v-col cols="1">
                          <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                        </v-col>
                        <v-col
                          cols="4"
                          class="px-0"
                        >
                          <p
                            caption
                            class="p14Mine mb-0 d-flex justify-center align-baseline"
                          >
                            {{ $t('msg.verifyTarifa') }}
                          </p>
                        </v-col>
                        <v-col cols="7">
                          <div
                            v-if="tarifa.cant_maletas_bodega !== null"
                            class="d-flex justify-start align-center"
                          >
                            <v-icon style="color: #53af24;">
                              {{ icons.mdiBagSuitcase }}
                            </v-icon>
                            <p
                              style="color: #53af24;"
                              class="p14Mine mb-0 d-flex justify-center align-baseline"
                            >
                              {{ $t('lbl.bag') }}: {{ $tc('lbl.pieza', tarifa.cant_maletas_bodega) }}
                            </p>
                          </div>
                          <div
                            v-else
                            class="d-flex justify-start align-center"
                          >
                            <v-icon color="default">
                              {{ icons.mdiBagSuitcaseOffOutline }}
                            </v-icon>
                            <p
                              color="primeary"
                              class="p14Mine mb-0 d-flex justify-center align-baseline"
                            >
                              {{ $t('lbl.bagNoInclude') }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0">
                        <v-col
                          cols="1"
                          class="pt-0"
                        >
                          <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="px-0 pt-0"
                        >
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('msg.verifyReembolso') }}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="7"
                          class="pt-0"
                        >
                          <div class="d-flex justify-start align-center">
                            <v-icon style="color: #53af24;">
                              {{ icons.mdiBagPersonal }}
                            </v-icon>
                            <p
                              style="color: #53af24;"
                              class="p14Mine mb-0 d-flex justify-center align-baseline"
                            >
                              {{ $t('lbl.bagHand') }}: {{ `${tarifa.cant_maletas_cabina > 0 ? 'incluido' : ''}` }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <div class="d-block">
                        <p
                          v-if="showOnlyGestor"
                          class="mb-0 pr-6 d-flex justify-center align-center"
                        >
                          {{ tarifa.price_total | currency }}
                        </p>
                        <p
                          v-else
                          class="mb-0 pr-6 d-flex justify-center align-center"
                          style="color: #FFFFFF"
                        >
                          .
                        </p>
                        <div class="d-flex justify-center">
                          <p class="mb-0 pr-1 d-flex justify-center align-center">
                            <b>{{ tarifa.price_total | currency }}</b>
                          </p>
                          <!--<v-tooltip
                            v-if="showOnlyGestor"
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ icons.mdiInformation }}
                              </v-icon>
                            </template>
                            <span>
                              <div class="d-block">
                                <div class="d-flex justify-start align-center">
                                  <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                </div>
                                <div class="d-flex justify-space-between align-center">
                                  <p class="mb-0 pr-6">Tarifa</p>
                                  <p class="mb-0">776,87 $</p>
                                </div>
                                <div class="d-flex justify-space-between align-center">
                                  <p
                                    class="mb-0 pr-6"
                                    subtitle-2
                                  >Impuestos y tasas</p>
                                  <p
                                    class="mb-0"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                                <v-divider
                                  color="white"
                                  class="mt-2"
                                ></v-divider>
                                <div class="d-flex justify-space-between align-center">
                                  <p class="my-2"><b>Total</b></p>
                                  <p
                                    class="my-2"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                                <div
                                  style="background-color: #ffffff6b"
                                  class="mb-4 pa-2"
                                >
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="mb-0">Tu comisión</p>
                                    <p
                                      class="mb-0"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="mb-0"><b>PVP</b></p>
                                    <p
                                      class="mb-0"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </v-tooltip>-->
                        </div>
                        <p
                          v-if="showOnlyGestor"
                          class="mb-0 pr-6 p12Mine d-flex justify-end"
                          style="color:#ce2121"
                        >
                          {{ $t('lbl.cupo') }} ({{ tarifa.cupo_disponible }})
                        </p>
                        <a
                          style="cursor: pointer"
                          @click="rulesTarifa(indexCon)"
                        >
                          <p
                            style="position: absolute; bottom:15px"
                            class="p14Mine mb-0 d-flex justify-center align-center"
                          >
                            {{ $t('lbl.rulesTarifa') }}
                          </p>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </fragment>
          </template>
        </v-radio-group>
      </v-col>
      <!--BTONES TARIFAS-->
      <v-col
        cols="12"
        md="4"
        class="pt-0"
      >
        <v-btn
          color="primary"
          width="100%"
          class="mb-2"
          @click="setFlight"
        >
          {{ $t('btn.select') }}
        </v-btn>
        <div class="d-flex justify-center align-center">
          <v-container grid-list-xs>
            <v-row>
              <v-col
                cols="2"
                class="pl-0"
              >
                <a
                  style="cursor: pointer"
                  @click="insertPreferidos"
                >
                  <v-icon
                    large
                    :color="idFlightFavorite.includes(aerolineCotizadorIdaFlight[indAero].id_result) ? 'primary' : ''"
                  >{{
                    idFlightFavorite.includes(aerolineCotizadorIdaFlight[indAero].id_result)
                      ? icons.mdiHeart
                      : icons.mdiHeartOutline
                  }}</v-icon>
                </a>
              </v-col>
              <v-col
                cols="10"
                class="pr-0"
              >
                <v-btn
                  :disabled="aerolineCotizadorIdaFlight[indAero].flights[indexTarifa].tarifas_contrate.length <= 1"
                  color="#e3e1e4"
                  width="100%"
                  @click="openTarifasContrate(indexTarifa, indAero)"
                >
                  {{ $t('lbl.otherTarifa')
                  }}<v-icon>{{ showOtherTarifas ? icons.mdiChevronUp : icons.mdiChevronDown }} </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiChevronUp,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiBagChecked,
  mdiClose,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indexTarifa: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      showOnlyGestor: true,
      radioGroup: 0,
      items: [],
      showModal: false,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      city: null,
      dataModal: {
        cant_maletas_bodega: null,
        cant_maletas_cabina: null,
        equipaje_bodega: {
          peso: null,
          um: null,
        },
        equipaje_cabina: {
          peso: null,
          um: null,
        },
        equipaje_personal: {
          peso: null,
          um: null,
        },
        dimensiones_personal: {
          name: null,
        },
        dimensiones_cabina: {
          name: null,
        },
        dimensiones_bodega: {
          name: null,
        },
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiChevronUp,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiHeart,
        mdiHeartOutline,
        mdiBagChecked,
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorIdaFlight: state => state.app.aerolineCotizadorIdaFlight,
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
      idFlightFavorite: state => state.app.idFlightFavorite,
    }),
  },
  mounted() {
    this.getCity()
  },
  created() {
    window.addEventListener('keydown', e => {
      if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
        this.changePrice()
      }
    })
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'insertCotizadorFlightFavorite',
      'deleteCotizadorFlightFavorite',
      'setCotizadorFlightSelect',
      'setIndTarifaEconCotizadorIdaFligth',
      'insertIdFlightFavorite',
      'deleteIdFlightFavorite',
    ]),
    getCity() {
      this.axios
        .get(`airport/${this.destinosCotizadorFlight[0].destino.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.city = res.data.data.data.city
        })
    },
    openTarifasContrate(indexFlight, indexAeroline) {
      // this.aerolineCotizadorIdaFlight[indexAeroline].flights[indexFlight].tarifas_contrate
      // console.log('indexFlight', indexFlight)
      // console.log('indexAeroline', indexAeroline)
      this.showOtherTarifas = !this.showOtherTarifas
      this.rowShowOtherTarifas = indexAeroline
    },
    insertPreferidos() {
      const destinos = []
      this.destinosCotizadorFlight.forEach(element => {
        destinos.push({
          origen: element.origen,
          destino: element.destino,
          from: element.from,
          to: element.to,
        })
      })
      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        destinos,
        item_ida: this.aerolineCotizadorIdaFlight[this.indAero].flights[this.indexTarifa],
        indTarifa: this.aerolineCotizadorIdaFlight[this.indAero].indTarifa,
        id_result: this.aerolineCotizadorIdaFlight[this.indAero].id_result,
      }
      if (!this.idFlightFavorite.includes(this.aerolineCotizadorIdaFlight[this.indAero].id_result)) {
        this.insertCotizadorFlightFavorite(json)
        this.insertIdFlightFavorite(this.aerolineCotizadorIdaFlight[this.indAero].id_result)
      } else {
        this.deleteCotizadorFlightFavorite(json)
        this.deleteIdFlightFavorite(this.aerolineCotizadorIdaFlight[this.indAero].id_result)
      }
    },
    setFlight() {
      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        destinos: this.destinosCotizadorFlight,
        item_ida: this.aerolineCotizadorIdaFlight[this.indAero].flights[this.indexTarifa],
        indTarifa: this.aerolineCotizadorIdaFlight[this.indAero].indTarifa,
        indRegreso: null,
        item_regreso: null,
      }

      // console.log(json)
      this.setCotizadorFlightSelect(json)

      this.$router.push({ name: 'selected-cotizador-flights' })
    },
    setRadioIda(index) {
      this.setIndTarifaEconCotizadorIdaFligth({ indAero: this.indAero, index })
    },
    rulesTarifa(pos) {
      this.showModal = true
      const rrr = this.aerolineCotizadorIdaFlight[this.indAero].flights[this.indexTarifa].tarifas_contrate[pos]

      /*
      // eslint-disable-next-line camelcase
      let equipaje_personal = null
      // eslint-disable-next-line camelcase
      let dimensiones_personal = null
      // eslint-disable-next-line camelcase
      let equipaje_cabina = null
      // eslint-disable-next-line camelcase
      let dimensiones_cabina = null
      // eslint-disable-next-line camelcase
      let equipaje_bodega = null
      // eslint-disable-next-line camelcase
      let dimensiones_bodega = null
      this.axios
        .get(`nom_peso_equipaje_flights/${rrr.equipaje_personal.peso}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            // eslint-disable-next-line camelcase
            equipaje_personal = { peso: res.data.data.data.peso, um: res.data.data.data.um }
          }
        })
      this.axios
        .get(`nom_peso_equipaje_flights/${rrr.equipaje_cabina.peso}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res1 => {
          // eslint-disable-next-line no-empty
          if (res1.data.success === false) {
          } else {
            // eslint-disable-next-line camelcase
            equipaje_cabina = { peso: res1.data.data.data.peso, um: res1.data.data.data.um }
          }
        })
      this.axios
        .get(`nom_peso_equipaje_flights/${rrr.equipaje_bodega.peso}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res2 => {
          // eslint-disable-next-line no-empty
          if (res2.data.success === false) {
          } else {
            // eslint-disable-next-line camelcase
            equipaje_bodega = { peso: res2.data.data.data.peso, um: res2.data.data.data.um }
          }
        })

      // DIMENSIONES
      if (rrr.equipaje_personal.dimensiones > 0) {
        this.axios
          .get(`nom_dimensiones_flights/${rrr.equipaje_personal.dimensiones}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              // eslint-disable-next-line camelcase
              dimensiones_personal = { name: res.data.data.data.nameUM }
            }
          })
      } else {
        // eslint-disable-next-line
        dimensiones_personal = { name: null }
      }
      if (rrr.equipaje_cabina.dimensiones > 0) {
        this.axios
          .get(`nom_dimensiones_flights/${rrr.equipaje_cabina.dimensiones}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              // eslint-disable-next-line camelcase
              dimensiones_cabina = { name: res.data.data.data.nameUM }
            }
          })
      } else {
        // eslint-disable-next-line
        dimensiones_cabina = { name: null }
      }
      if (rrr.equipaje_bodega.dimensiones > 0) {
        this.axios
          .get(`nom_dimensiones_flights/${rrr.equipaje_bodega.dimensiones}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              // eslint-disable-next-line camelcase
              dimensiones_bodega = { name: res.data.data.data.nameUM }
            }
          })
      } else {
        // eslint-disable-next-line
        dimensiones_bodega = { name: null }
      } */

      setTimeout(() => {
        this.dataModal = {
          cant_maletas_bodega: rrr.cant_maletas_bodega,
          cant_maletas_cabina: rrr.cant_maletas_cabina,
          equipaje_bodega: rrr.equipaje_bodega,
          equipaje_cabina: rrr.equipaje_cabina,
          equipaje_personal: rrr.equipaje_personal,
          dimensiones_personal: rrr.equipaje_personal ? rrr.equipaje_personal.dimensiones : null,
          dimensiones_cabina: rrr.equipaje_cabina ? { name: rrr.equipaje_cabina.dimensiones.name } : null,
          dimensiones_bodega: rrr.equipaje_bodega ? { name: rrr.equipaje_bodega.dimensiones.nameUM } : null,
        }

        // console.log(this.dataModal)
      }, 500)

      // this.dataModal = this.aerolineCotizadorIdaFlight[this.indAero].flights[this.indexTarifa].tarifas_contrate[pos]
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
  },
}
</script>
