<template>
  <v-col cols="12">
    <v-row :class="pos > 0 ? 'mt-10' : ''">
      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="suplement.sup_red_id"
              :disabled="$store.state.app.onlyShow"
              :items="suplementsReductionsFree"
              placeholder="Sup / Red"
              :label="
                suplement.is_tarifa
                  ? $t('lbl.tarif')
                  : suplement.is_suplement
                    ? $t('lbl.suplemento')
                    : suplement.is_reduction
                      ? $t('lbl.reduccion')
                      : ''
              "
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
              @change="setSuplement"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.suplemento') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="suplementsReduccTarifasSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              @click="deleteSuplementsReduccTarifasSeasonContrateHotel(pos)"
            >
              <v-icon
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              :disabled="verifyRoomsFree"
              v-on="on"
              @click="addSuplementsReduccTarifasSeasonContrateHotel"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>

      <v-col
        cols="12"
        md="7"
        offset-md="1"
      >
        <v-row>
          <v-col cols="4">
            <v-select
              v-if="!suplement.is_tarifa && slugSuplement !== 'habitacion-de-uso-individual'"
              v-model="suplement.base"
              :disabled="$store.state.app.onlyShow"
              :items="bases"
              label="Base"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
              @change="setBase"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Base
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="8">
            <b>{{ $t('lbl.equipaje_personal.para') }}: </b>
            <v-radio-group
              v-model="suplement.applyBy"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                v-for="(ap, ind) in apply"
                :key="ind"
                :label="ap.name"
                :value="ap.slug"
                :disabled="
                  (slugSuplement === 'habitacion-de-uso-individual' && ap.slug !== 'pphn')
                    ||
                    (slugSuplement === '3er-pax' && ap.slug !== 'pppn' && ap.slug !== 'pphn')
                "
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <!--<SeasonNamesHotelTarifa
        :pos="pos"
        :suplement="suplement"
        :show-optional="false"
        :from="'supRedTar'"
      />-->
      <v-col
        cols="12"
        class="pt-0 mt-0"
      >
        <v-row class="pt-0 mt-0">
          <v-col
            cols="12"
            md="1"
            offset-md="2"
            class="pt-0 mt-0 text-right"
            style="text-align: right"
          >
            <v-switch
              v-model="suplement.is_tarifa"
              :label="$t('lbl.prices')"
              hide-details
              class="pt-0 mt-0"
              @click="setTarifa"
            ></v-switch>
          </v-col>
          <v-col
            cols="1"
            class="pt-0 mt-0"
          ></v-col>
          <v-col
            cols="12"
            md="7"
            offset-md="1"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(season, indR) in seasonsContrateHotel"
                :key="indR"
                style="max-width: 90px; margin-left: 20px; margin-bottom: 10px"
                class="pt-0 mt-0"
              >
                <v-text-field
                  v-model="season.name"
                  class="my-input"
                  hide-details
                  disabled
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <SeasonSuplementsReduccionsTarifasHotelRooms
        v-for="(room, indR) in suplement.rooms"
        :key="indR"
        :pos="indR"
        :pos-suplement="pos"
        :hotel="hotel"
        :room="room"
        :suplement="suplement"
        :all-rooms="allRooms"
      />
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import SeasonNamesHotelTarifa from './SeasonNamesHotelTarifa.vue'
import SeasonSuplementsReduccionsTarifasHotelRooms from './SeasonSuplementsReduccionsTarifasHotelRooms.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonNamesHotelTarifa,
    SeasonSuplementsReduccionsTarifasHotelRooms,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),

      // allSuplement: [],
      // allRooms: [],
      itemsRooms: [],

      itemsSuplement: [],
      searchSuplement: null,
      bases: [],
      apply: [],
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      suplementsReduccTarifasSeasonContrateHotel: state => state.app.suplementsReduccTarifasSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),
    suplementsReductionsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO ALOJAMIENTO
        if (element.model === 'alojamiento') {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
            if (
              this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id.includes(element.id)
              && index !== this.pos
            ) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      let arrResult = []

      // suplement.suplement_id
      if (this.suplement.sup_red_id.length > 0) {
        if (result.filter(e => e.id === this.suplement.sup_red_id[0]).length > 0) {
          const v = result.filter(e => e.id === this.suplement.sup_red_id[0])[0]
          if (v.is_reduction) {
            result.forEach(elementOrder => {
              if (elementOrder.is_reduction) {
                let disabled = true
                if (v.id === elementOrder.id) {
                  disabled = false
                }

                // arrResult.push(elementOrder)
                arrResult.push({
                  ...elementOrder,
                  disabled,
                })
              } else {
                arrResult.push({
                  ...elementOrder,
                  disabled: true,
                })
              }
            })
          } else {
            result.forEach(elementOrder => {
              if (!elementOrder.is_reduction) {
                let disabled = true
                if (v.id === elementOrder.id) {
                  disabled = false
                }

                // arrResult.push(elementOrder)
                arrResult.push({
                  ...elementOrder,
                  disabled,
                })
              } else {
                arrResult.push({
                  ...elementOrder,
                  disabled: true,
                })
              }
            })
          }
        }
      } else {
        arrResult = result
      }

      return arrResult
    },
    verifyRoomsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO ALOJAMIENTO
        if (element.model === 'alojamiento') {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
            if (this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id.includes(element.id)) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
    verifyRoomsFreeOld() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO ALOJAMIENTO
        if (element.model === 'alojamiento') {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
            if (
              this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id.includes(element.id)
              && this.suplementsReduccTarifasSeasonContrateHotel[index].is_reduction
              && element.is_reduction
            ) {
              esta = true
            } else if (
              this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id.includes(element.id)
              && !this.suplementsReduccTarifasSeasonContrateHotel[index].is_reduction
              && !element.is_reduction
            ) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      let arrResult = []

      // suplement.suplement_id
      if (this.suplement.sup_red_id.length > 0) {
        if (result.filter(e => e.id === this.suplement.sup_red_id[0]).length > 0) {
          const v = result.filter(e => e.id === this.suplement.sup_red_id[0])[0]
          if (v.is_reduction) {
            result.forEach(elementOrder => {
              if (elementOrder.is_reduction) {
                arrResult.push(elementOrder)
              } else {
                arrResult.push({
                  ...elementOrder,
                  disabled: true,
                })
              }
            })
          } else {
            result.forEach(elementOrder => {
              if (!elementOrder.is_reduction) {
                arrResult.push(elementOrder)
              } else {
                arrResult.push({
                  ...elementOrder,
                  disabled: true,
                })
              }
            })
          }
        }
      } else {
        arrResult = result
      }

      if (arrResult.length === 0) {
        return true
      }

      return false
    },
    roomsFree() {
      const result = []
      this.allRooms.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].room_id === element.id) {
            esta = true
          }
        }
        if (esta) {
          result.push(element)
        }
      })

      return result
    },
    slugSuplement() {
      let name = ''
      this.allSuplement.forEach(element => {
        if (this.suplement.is_suplement
        && this.suplement.sup_red_id.includes(element.id)
        ) {
          name = element.slug
        }
      })

      if (name === '') {
        this.allSuplement.forEach(element => {
          if (this.suplement.is_reduction
          && this.suplement.sup_red_id.includes(element.id)
          ) {
            name = element.slug
          }
        })
      }

      return name
    },
  },
  watch: {
    searchSuplement(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterSuplement(val.toLowerCase())
      }
    },
  },
  created() {
    // this.getSuplements()
    // this.getRooms()
    if (this.roomsFree.length === 1) {
      this.suplement.rooms_id = [this.roomsFree[0].id]
    }

    this.bases.push({
      name: 'ADL en DBL',
      slug: 'adl_in_dbl',
    })
    this.bases.push({
      name: 'ADL en SGL',
      slug: 'adl_in_sgl',
    })
    this.bases.push({
      name: 'Habitación DBL',
      slug: 'room_dbl',
    })
    this.bases.push({
      name: 'Habitación SGL',
      slug: 'room_sgl',
    })

    this.apply.push({
      name: 'PPP',
      slug: 'ppp',
    })
    this.apply.push({
      name: 'PPH',
      slug: 'pph',
    })
    this.apply.push({
      name: 'PPN',
      slug: 'ppn',
    })
    this.apply.push({
      name: 'PPPN',
      slug: 'pppn',
    })
    this.apply.push({
      name: 'PPHN',
      slug: 'pphn',
    })
  },
  methods: {
    ...mapMutations([
      'deleteSuplementsReduccTarifasSeasonContrateHotel',
      'addSuplementsReduccTarifasSeasonContrateHotel',
      'setTarifaSuplementsReduccTarifasSeasonContrateHotel',
      'setSuplementsReduccTarifasOnSeasonContrateHotel',
      'setSuplementsReduccTarifasOffSeasonContrateHotel',
    ]),
    filterSuplement() {
      const suplem = this.allSuplement.filter(e => e.name.toLowerCase())
      suplem.forEach(element => {
        let esta = false
        this.suplementsSeasonContrateHotel.forEach(rrr => {
          if (rrr.sup_red_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsSuplement.push(element)
        }
      })
    },
    setSuplement() {
      if (!this.suplement.is_tarifa) {
        if (this.suplement.sup_red_id.length > 0) {
          const sss = this.allSuplement.filter(e => e.id === this.suplement.sup_red_id[0])[0]
          if (!sss.is_reduction) {
            this.suplement.is_suplement = true
            this.suplement.is_reduction = false
          } else if (sss.is_reduction) {
            this.suplement.is_suplement = false
            this.suplement.is_reduction = true
          }
        } else {
          this.suplement.is_suplement = false
          this.suplement.is_reduction = false
        }
      }

      this.allSuplement.forEach(element => {
        if (this.suplement.is_suplement
        && this.suplement.sup_red_id.includes(element.id)
        ) {
          if (element.slug === 'habitacion-de-uso-individual') {
            this.suplement.applyBy = 'pphn'
          }
        } else if (this.suplement.is_reduction
        && this.suplement.sup_red_id.includes(element.id)
        ) {
          if (element.slug === '3er-pax') {
            this.suplement.applyBy = 'pphn'
          }
        }
      })

      // this.suplement.name = this.allSuplement.filter(e => e.id === this.suplement.suplement_id)[0].name
    },
    setBase() {
      if (this.suplement.base === 'adl_in_dbl' || this.suplement.base === 'adl_in_sgl') {
        this.suplement.applyBy = 'pppn'
      } else if (this.suplement.base === 'room_dbl' || this.suplement.base === 'room_sgl') {
        this.suplement.applyBy = 'pphn'
      }
    },
    setTarifa() {
      if (this.suplement.is_tarifa) {
        this.setTarifaSuplementsReduccTarifasSeasonContrateHotel(this.pos)

        // this.setSuplementsReduccTarifasOnSeasonContrateHotel(this.pos)
        const rooms = []
        this.roomsSeasonContrateHotel.forEach(element => {
          element.rooms_id.forEach(elementRoom => {
            const prices = []
            this.seasonsContrateHotel.forEach(() => {
              prices.push({ price: null })
            })
            rooms.push({
              rooms_id: [elementRoom],
              in_pc: false,
              is_main: true,
              prices,
            })
          })
        })
        this.suplement.rooms = rooms
      } else {
        const prices = []
        this.seasonsContrateHotel.forEach(() => {
          prices.push({ price: null })
        })
        this.suplement.rooms = [
          {
            rooms_id: [],
            in_pc: false,
            is_main: false,
            prices,
          },
        ]
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
