<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row>
        <v-col
          v-if="(permisos.includes('flight:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.operador') }}</strong>
          <!--<v-select
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="aerolineas"
            item-text="name"
            item-value="id"
            :label="$t('lbl.operador')"
            outlined
            dense
            hide-details
            @change="changeAerolinea"
          ></v-select>-->
          <v-autocomplete
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="itemsAerolinea"
            :search-input.sync="searchAerolinea"
            hide-details
            hide-selected
            :label="$t('lbl.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="changeAerolinea"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.numeroVuelo') }}</strong>
          <v-text-field
            ref="numero_de_vuelo"
            v-model="model.numero_de_vuelo"
            class="pt-2"
            :label="$t('lbl.numeroVuelo')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.semanaDay') }}</strong>
          <v-sheet class="py-2">
            <v-slide-group
              v-model="model.frecuencia"
              multiple
            >
              <v-slide-item
                v-for="(sema, index) in semanas"
                v-slot="{ active, toggle }"
                :key="index"
                class="px-1"
              >
                <v-btn
                  class=""
                  :input-value="active"
                  active-class="primary white--text"
                  depressed
                  rounded
                  small
                  @click="toggle"
                >
                  {{ sema }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.origen"
            :items="itemsOrigen"
            :search-input.sync="searchOrigen"
            hide-details
            hide-selected
            :label="$t('lbl.origen')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.origenSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <strong style="color: #FFFFFF">{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destinoSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="model.aerolinea_id || model.numero_de_vuelo || model.frecuencia || model.origen || model.destino"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.vuelos') }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              width="10%"
            >
              <span
                v-if="orderByVuelo === null"
                style="cursor: pointer;"
                @click="orderBy('numero_de_vuelo')"
              >
                {{ $t('lbl.vuelo') }}
              </span>
              <v-btn
                v-else
                style="background-color: #FAFAFA;"
                @click="orderBy('numero_de_vuelo')"
              >
                {{ $t('lbl.vuelo') }}
                <v-icon
                  small
                  right
                  :color="orderByVuelo === null ? '' : 'primary'"
                >
                  {{ orderByVuelo !== null ? (orderByVuelo ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                </v-icon>
              </v-btn>
            </th>
            <th class="text-uppercase">
              <span
                v-if="orderByAerolinea === null"
                style="cursor: pointer;"
                @click="orderBy('aerolinea')"
              >
                {{ $t('lbl.operador') }}
              </span>
              <v-btn
                v-else
                style="background-color: #FAFAFA;"
                @click="orderBy('aerolinea')"
              >
                {{ $t('lbl.operador') }}
                <v-icon
                  small
                  right
                  :color="orderByAerolinea === null ? '' : 'primary'"
                >
                  {{ orderByAerolinea !== null ? (orderByAerolinea ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                </v-icon>
              </v-btn>
            </th>
            <th class="text-uppercase">
              <span
                v-if="orderByOrigen === null"
                style="cursor: pointer;"
                @click="orderBy('origen')"
              >
                {{ $t('lbl.origen') }}
              </span>
              <v-btn
                v-else
                style="background-color: #FAFAFA;"
                @click="orderBy('origen')"
              >
                {{ $t('lbl.origen') }}
                <v-icon
                  small
                  right
                  :color="orderByOrigen === null ? '' : 'primary'"
                >
                  {{ orderByOrigen !== null ? (orderByOrigen ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                </v-icon>
              </v-btn>
            </th>
            <th class="text-uppercase">
              <span
                v-if="orderByDestino === null"
                style="cursor: pointer;"
                @click="orderBy('destino')"
              >
                {{ $t('lbl.destino') }}
              </span>
              <v-btn
                v-else
                style="background-color: #FAFAFA;"
                @click="orderBy('destino')"
              >
                {{ $t('lbl.destino') }}
                <v-icon
                  small
                  right
                  :color="orderByDestino === null ? '' : 'primary'"
                >
                  {{ orderByDestino !== null ? (orderByDestino ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                </v-icon>
              </v-btn>
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.departure') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.arrival') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.escalas') }}
            </th>
            <th
              v-if="showRow"
              class="text-uppercase"
            >
              {{ $t('lbl.frecuencias') }}
            </th>
            <th
              v-if="showRow"
              class="text-uppercase"
            >
              {{ $t('lbl.departure') }}
            </th>
            <th
              v-if="showRow"
              class="text-uppercase"
            >
              {{ $t('lbl.arrival') }}
            </th>
            <th
              v-if="permisos.includes('flight:edit') || permisos.includes('*')"
              width="150px"
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ iten.numero_de_vuelo }}</td>
            <td>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  sm="2"
                >
                  <v-avatar
                    v-if="iten.aerolinea.isotipo === null"
                    color="primary"
                    size="35"
                  >
                    <span class="white--text text-h5">{{ iten.aerolinea.slug }}</span>
                  </v-avatar>
                  <v-img
                    v-else
                    :src="rutaPublic + iten.aerolinea.isotipo"
                    :alt="iten.aerolinea.slug"
                    width="260"
                    contain
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                >
                  {{ iten.aerolinea.name }}
                </v-col>
              </v-row>
            </td>
            <td>
              <span v-if="iten.origen !== null">
                {{ iten.origen.destino.iataComodin }}
                <!--{{ iten.origen.destino !== null ? iten.origen.destino.name : '' }}<br>
                {{ iten.origen.terminal !== null ? `${$t('lbl.terminal')} ${iten.origen.terminal}` : '' }}<br>
                {{ iten.origen.puerta !== null ? `${$t('lbl.puerta')}  ${iten.origen.puerta}` : '' }}-->
              </span>
            </td>
            <td>
              <span v-if="iten.destino !== null">
                {{ iten.destino.destino.iataComodin }}
                <!--{{ iten.destino.destino !== null ? iten.destino.destino.name : '' }}<br>
                {{ iten.destino.terminal !== null ? `${$t('lbl.terminal')} ${iten.destino.terminal}` : '' }}<br>
                {{ iten.destino.puerta !== null ? `${$t('lbl.puerta')}  ${iten.destino.puerta}` : '' }}-->
              </span>
            </td>
            <!--<td>{{ iten.hour_departure.slice(0, 5) }}</td>
            <td>{{ iten.hour_arrival.slice(0, 5) }}</td>-->
            <td align="center">
              <span v-if="iten.escalas !== null"> +{{ iten.escalas.length }} </span>
              <span v-else>{{ $t('lbl.escala2') }}</span>
              <!--<span
                v-for="(escala, i) in iten.escalas"
                :key="i"
              >
                {{ escala.city.iataComodin }} ({{ escala.duration_h > 0 ? `${escala.duration_h} h` : '' }}
                {{ escala.duration_m > 0 ? `${escala.duration_m} min` : '' }})
                <br>
              </span>-->
            </td>
            <td v-if="showRow">
              <v-row>
                <v-col
                  v-if="showRow && index === indexRow"
                  cols="12"
                  md="12"
                >
                  <template>
                    <span
                      v-for="(frec, j) in iten.frecuenciasWeek"
                      :key="j"
                    >
                      <span v-if="frec === 0">Domingo</span>
                      <span v-else-if="frec === 1">Lunes</span>
                      <span v-else-if="frec === 2">Martes</span>
                      <span v-else-if="frec === 3">Miércoles</span>
                      <span v-else-if="frec === 4">Jueves</span>
                      <span v-else-if="frec === 5">Viernes</span>
                      <span v-else-if="frec === 6">Sábado</span>
                      <span v-if="j !== iten.frecuenciasWeek.length - 1"><br /></span>
                    </span>
                  </template>
                </v-col>
              </v-row>
            </td>
            <td v-if="showRow">
              <v-row>
                <v-col
                  v-if="showRow && index === indexRow"
                  cols="12"
                  md="12"
                >
                  <template>
                    <span
                      v-for="j in iten.frecuenciasWeek"
                      :key="j"
                    >
                      {{ iten.origen.salida }}
                      <span v-if="j !== iten.frecuenciasWeek.length - 1"><br /></span>
                    </span>
                  </template>
                </v-col>
              </v-row>
            </td>
            <td v-if="showRow">
              <v-row>
                <v-col
                  v-if="showRow && index === indexRow"
                  cols="12"
                  md="12"
                >
                  <template>
                    <span
                      v-for="j in iten.frecuenciasWeek"
                      :key="j"
                    >
                      {{ iten.destino.llegada }}
                      <span v-if="j !== iten.frecuenciasWeek.length - 1"><br /></span>
                    </span>
                  </template>
                </v-col>
              </v-row>
            </td>
            <td v-if="permisos.includes('flight:edit') || permisos.includes('*')">
              <v-tooltip
                v-if="permisos.includes('flight:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="permisos.includes('flight:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="cloneItem(iten)"
                  >
                    <v-icon>{{ icons.mdiEyePlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.clone') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('flight:destroy') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-btn
                icon
                @click="showItem(index)"
              >
                <v-icon>{{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteFlight', { item: item.numero_de_vuelo }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      orderByVuelo: null,
      orderByAerolinea: null,
      orderByOrigen: null,
      orderByDestino: null,
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
      },
      isDialogVisible: false,
      model: {},
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      offline: false,
    }
  },
  watch: {
    searchAerolinea(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAerolinea(val.toLowerCase())
      } else {
        this.itemsAerolinea = []
      }
    },
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.itemsOrigen = []
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
  },
  created() {
    this.profile()
    this.getAerolineas()
    this.loadAll()
    this.load()

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')
  },
  methods: {
    ...mapMutations(['updateFrecuencia']),
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    orderBy(attr) {
      if (attr === 'numero_de_vuelo') {
        if (this.orderByVuelo === null) {
          this.orderByVuelo = true
        } else {
          this.orderByVuelo = !this.orderByVuelo
        }

        // APAGAR
        this.orderByAerolinea = null
        this.orderByOrigen = null
        this.orderByDestino = null
      } else if (attr === 'aerolinea') {
        if (this.orderByAerolinea === null) {
          this.orderByAerolinea = true
        } else {
          this.orderByAerolinea = !this.orderByAerolinea
        }

        // APAGAR
        this.orderByVuelo = null
        this.orderByOrigen = null
        this.orderByDestino = null
      } else if (attr === 'origen') {
        if (this.orderByOrigen === null) {
          this.orderByOrigen = true
        } else {
          this.orderByOrigen = !this.orderByOrigen
        }

        // APAGAR
        this.orderByVuelo = null
        this.orderByAerolinea = null
        this.orderByDestino = null
      } else if (attr === 'destino') {
        if (this.orderByDestino === null) {
          this.orderByDestino = true
        } else {
          this.orderByDestino = !this.orderByDestino
        }

        // APAGAR
        this.orderByVuelo = null
        this.orderByAerolinea = null
        this.orderByOrigen = null
      }

      this.load()
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.loading = true

      // this.axios.get(`flights?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.model}&perfil_slug=${localStorage.getItem('perfil')}`, {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        // eslint-disable-next-line no-nested-ternary
        orderByVuelo: this.orderByVuelo !== null ? (this.orderByVuelo ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByAerolinea: this.orderByAerolinea !== null ? (this.orderByAerolinea ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByOrigen: this.orderByOrigen !== null ? (this.orderByOrigen ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByDestino: this.orderByDestino !== null ? (this.orderByDestino ? 11 : 22) : null,
        perfil_slug: localStorage.getItem('perfil'),
      }

      // console.log(json)
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    searchAll() {
      this.pagination.current = 1
      this.load()
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: localStorage.getItem('perfil'),
      }
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('flight-id')
      localStorage.removeItem('flight-clone')
      this.updateFrecuencia([])
      this.$router.push({ name: 'flights-update' })
    },
    editItem(iten) {
      this.$store.state.app.loadEscalas = true
      localStorage.setItem('flight-id', iten.id)
      localStorage.removeItem('flight-clone')
      this.$router.push({ name: 'flights-update' })
    },
    cloneItem(iten) {
      this.$store.state.app.loadEscalas = true
      localStorage.setItem('flight-id', iten.id)
      localStorage.setItem('flight-clone', true)
      this.$router.push({ name: 'flights-update' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`flight/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.errorDeleteFlight', { item: this.item.numero_de_vuelo }))
            }
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.itemId = null
      this.loading = false
      this.load()
    },

    clearSearch() {
      this.model = {}
      this.load()
    },

    changeAerolinea() {
      const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
      this.model.numero_de_vuelo = `${air.slug} `
      this.$refs.numero_de_vuelo.focus()
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
