<template>
  <div class="d-none d-md-block">
    <v-col
      ref="statiCard"
      cols="12"
      md="3"
      class=""
      style="position: fixed; z-index: 6; width: available"
    >
      <v-row
        v-if="showOnlyGestor"
        class="mb-3"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4"
        >
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <h1
                  primary-title
                  class="pa-0"
                >
                  PVP
                </h1>
              </v-col>
              <!--CALCULADORA-->
              <v-row class="pt-2">
                <v-col
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="markup"
                    label="Mark-up"
                    hide-details
                    outlined
                    width="auto"
                    class="descuento"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueMarkup"
                    hide-details
                    dense
                    class="mt-0 pt-0 descuento"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="agregarDescuento"
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="descuento"
                    class="descuento"
                    :label="$t('lbl.descuento')"
                    hide-details
                    outlined
                    width="auto"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="agregarDescuento"
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueDescuento"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10">
                  <v-checkbox
                    v-model="agregarDescuento"
                    :label="$t('lbl.addDescuento')"
                    dense
                    hide-details
                    outlined
                    class="descuento my-0 py-0"
                    value="1"
                    @change="changeAgregarDescuento"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="2"
                  class="align-self-center align-right"
                >
                  <v-btn
                    color="primary"
                    @click="applyMarkups"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="mt-12"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ tarifa.price_inicial | currency }}
                  </p>
                </div>
              </v-row>
              <v-row class="mt-6 d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conMarkups') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithMarkup | currency }}
                  </p>
                </div>
              </v-row>
              <v-row
                v-if="agregarDescuento"
                class="mt-6 d-flex justify-center align-center"
              >
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conDescuento') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithDescuento | currency }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <!--MOSTRAR PARA EL GESTOR-->
      <fragment v-if="showOnlyGestor">
        <v-row>
          <v-card
            width="100%"
            class="mx-2 pa-4"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-2">
                {{ $t('lbl.billetesFligh') }}
              </h4>
              <h4>
                <h4>
                  {{ tarifa.price_inicial | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h3>
                {{ $t('lbl.total') }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block mb-2">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p class="mb-0 pr-6">Tarifa</p>
                        <p class="mb-0">776,87 $</p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p
                          class="mb-0 pr-6"
                          subtitle-2
                        >Impuestos y tasas</p>
                        <p
                          class="mb-0"
                          subtitle-2
                        >776,87 $</p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
              </h3>

              <h3>
                <h4>
                  {{ tarifa.price_inicial | currency }}
                </h4>
              </h3>
            </div>
          </v-card>
        </v-row>
        <v-row class="my-6">
          <v-card
            width="100%"
            class="mx-2 pa-4"
          >
            <v-card-title
              primary-title
              class="px-0 py-2"
            >
              {{ $t('lbl.priceByCliente') }}
            </v-card-title>
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-2">
                {{ $t('lbl.priceTotal') }}
              </h4>
              <h4>
                <h4>
                  {{ tarifa.price_total | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h5>
                {{ $t('lbl.incluyeComition') }}
                <fragment
                  v-if="cotizadorFlightSelectMarkups.markup !== 0 && cotizadorFlightSelectMarkups.valueMarkup !== null"
                >
                  ({{ cotizadorFlightSelectMarkups.markup }}
                  <span v-if="cotizadorFlightSelectMarkups.valueMarkup === '%'">%</span>
                  <span v-else-if="cotizadorFlightSelectMarkups.valueMarkup === '$'">USD</span>)
                </fragment>
              </h5>
              <h4>
                {{ tarifa.with_markup | currency }}
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h5>
                {{ $t('lbl.incluyeDescuento') }}
                <fragment
                  v-if="
                    cotizadorFlightSelectMarkups.descuento !== 0 && cotizadorFlightSelectMarkups.valueDescuento !== null
                  "
                >
                  ({{ cotizadorFlightSelectMarkups.descuento }}
                  <span v-if="cotizadorFlightSelectMarkups.valueDescuento === '%'">%</span>
                  <span v-else-if="cotizadorFlightSelectMarkups.valueDescuento === '$'">USD</span>)
                </fragment>
              </h5>
              <h4>
                {{ tarifa.with_descuento | currency }}
              </h4>
            </div>
          </v-card>
        </v-row>
      </fragment>

      <!--MOSTRAR PARA EL CLIENTE-->
      <v-row v-if="!showOnlyGestor">
        <v-card
          width="100%"
          class="mx-2 pa-4"
        >
          <div class="d-flex justify-space-between align-center">
            <h4 class="my-2">
              {{ $t('lbl.billetesFligh') }}
            </h4>
            <h4>
              <h4>
                {{ tarifa.price_total | currency }}
              </h4>
            </h4>
          </div>
          <div class="d-flex justify-space-between align-center">
            <h4 class="my-2">
              {{ $t('lbl.total') }}
            </h4>
            <h4>
              <h4>
                {{ tarifa.price_total | currency }}
              </h4>
            </h4>
          </div>
        </v-card>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
} from '@mdi/js'

// import Filtros from './Filtros.vue'
// import Ida from './Ida.vue'
// import IdaBack from './IdaBack.vue'
// import IdaDestinos from './IdaDestinos.vue'

export default {
  // components: {
  //   Filtros,
  //   Ida,
  //   IdaBack,
  //   IdaDestinos,
  // },
  data() {
    return {
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      tarifa: null,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
      },
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      cotizadorFlightSelect: state => state.app.cotizadorFlightSelect,
      cotizadorFlightSelectMarkups: state => state.app.cotizadorFlightSelectMarkups,
    }),
    precioWithMarkup() {
      let priceTotal = this.tarifa.price_inicial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
        } // USD
        if (this.valueMarkup === '$') {
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.tarifa.price_inicial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        }
      }

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = (parseFloat(priceTotal) * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        } // USD
        if (this.valueDescuento === '$') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = parseFloat(this.descuento)
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        }
      }

      return priceTotal
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)

    if (this.permisos.includes('pvp:list')) {
      window.addEventListener('keydown', e => {
        if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
          this.changePrice()
        }
      })
    } else {
      this.changePrice()
    }
    this.getTarifa()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations([
      'deleteDestinoCotizadorFligth',
      'setSearchingFlightCotizador',
      'setCotizadorFlightSelectMarkups',
      'setCotizadorFlightSelectIdaBackPrice',
    ]),
    handleScroll() {
      if (window.scrollY >= 60) {
        this.$refs.statiCard.classList.add('topMine')
      } else {
        this.$refs.statiCard.classList.remove('topMine')
      }
    },
    getTarifa() {
      // this.tarifa = this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa]
      // eslint-disable-next-line camelcase
      const price_total = parseFloat(
        this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].price_total,
      )
        + parseFloat(
          this.cotizadorFlightSelect.item_regreso.tarifas_contrate[this.cotizadorFlightSelect.indRegreso].price_total,
        )
      // eslint-disable-next-line camelcase
      const price_inicial = parseFloat(
        this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].price_inicial,
      )
        + parseFloat(
          this.cotizadorFlightSelect.item_regreso.tarifas_contrate[this.cotizadorFlightSelect.indRegreso].price_inicial,
        )
      this.tarifa = {
        tarifa: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].tarifa,
        clase: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].clase,
        cant_maletas_bodega: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa]
          .cant_maletas_bodega,
        cant_maletas_cabina: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa]
          .cant_maletas_cabina,
        price_total,
        price_inicial,
      }
    },
    goBack() {
      this.setSearchingFlightCotizador(false)
      this.$router.push({ name: 'cotizador' })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
    changeAgregarDescuento(e) {
      if (e === null) {
        this.descuento = null
        this.valueDescuento = null
      }
    },
    applyMarkups() {
      // eslint-disable-next-line camelcase
      const price_inicial = parseFloat(
        this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].price_inicial,
      )
        + parseFloat(
          this.cotizadorFlightSelect.item_regreso.tarifas_contrate[this.cotizadorFlightSelect.indRegreso].price_inicial,
        )
      // eslint-disable-next-line camelcase
      if (price_inicial < this.precioWithDescuento) {
        this.setCotizadorFlightSelectMarkups({
          markup: this.markup,
          valueMarkup: this.valueMarkup,
          descuento: this.descuento,
          valueDescuento: this.valueDescuento,
        })

        this.setCotizadorFlightSelectIdaBackPrice({
          with_markup: this.markup / 2,
          with_descuento: this.descuento / 2,
        })

        // ACTUALIZAR TARIFA
        this.getTarifa()
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
