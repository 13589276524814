<template>
  <div>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.iata}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">{{ $t('msg.deleteItem', { item: item.iata }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR IMPORT -->
    <v-dialog
      v-model="isDialogVisibleImport"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.import')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card>
                  <v-card-text>
                    <p class="text--primary text-base">
                      <v-file-input
                        v-model="jsonFile"
                        type="file"
                        accept="application/JSON"
                        show-size
                        outlined
                        dense
                        label="JSON"
                        hide-details
                      ></v-file-input>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!--<v-row>
              <v-col
                cols="12"
                md="6"
              >
                <LeafletExample></LeafletExample>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleImport = !isDialogVisibleImport"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loadingImport"
            @click="confirmImport()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('airport:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('airport:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="importItem"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="model.iata"
            class="pt-2"
            :label="$t('lbl.iata')"
            outlined
            dense
            hide-details
            @input="load"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.name"
            class="pt-2"
            :label="$t('lbl.airport')"
            outlined
            dense
            hide-details
            @input="load"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.destino"
            class="pt-2"
            :label="$t('lbl.destino')"
            outlined
            dense
            hide-details
            @input="load"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="model.iata || model.name || model.destino"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="text-center"
        >
          <!--<v-btn
            :loading="loading"
            color="primary"
            @click="load()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>-->
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.airports') }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              width="10%"
            >
              IATA
            </th>
            <th
              class="text-uppercase"
              width="20%"
            >
              {{ $t('lbl.airport') }}
            </th>
            <th
              class="text-uppercase"
              width="50%"
            >
              {{ $t('lbl.destino') }}
            </th>
            <!--<th
              class="text-uppercase"
              width="10%"
            >
              JSON
            </th>-->
            <!--<th class="text-uppercase">
              {{ $t('lbl.longitud') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.latitud') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.details') }}
            </th>-->
            <th
              v-if="permisos.includes('airport:edit') ||
                permisos.includes('airport:list') ||
                permisos.includes('airport:destroy') ||
                permisos.includes('*')"
              class="text-uppercase"
              width="15%"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ iten.iata }}</td>
            <td>{{ iten.name }}</td>
            <td>
              <span
                v-for="(detail, indexN) in iten.nominatim"
                :key="Math.random() * (indexN + 1)"
              >
                {{ detail.value }}<span v-if="indexN < iten.nominatim.length - 1">,</span>
              </span>
            </td>
            <!--<td>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card class="mx-auto">
                    <v-card-text>
                      <span
                        v-for="(detail, indexN) in iten.nominatim"
                        :key="Math.random() * (indexN + 1)"
                      >
                        <i>{{ detail.key }}</i>: <b>{{ detail.value }}</b><br>
                      </span>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>
            </td>-->
            <td
              v-if="permisos.includes('airport:edit') ||
                permisos.includes('airport:list') ||
                permisos.includes('airport:destroy') ||
                permisos.includes('*')"
            >
              <v-tooltip
                v-if="(permisos.includes('airport:list') ||
                  permisos.includes('*'))"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showItem(iten)"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.see') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="(permisos.includes('airport:edit') ||
                  permisos.includes('*'))"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="(permisos.includes('airport:destroy') ||
                  permisos.includes('*'))"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiCloudUpload,
  mdiEraserVariant,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      jsonFile: null,
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiCloudUpload,
        mdiEraserVariant,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleImport: false,
      item: {},
      model: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      loadingImport: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.profile()
    this.load()
    this.loadAll()
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'resetGeoTag', 'updateAirport', 'resetAirport']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        perfil_slug: localStorage.getItem('perfil'),
      }
      this.axios.post('airport/list', json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        if (res.data.success === false) {
          // this.$toast.error(this.$t('msg.noAutorice'))
          // this.$store.commit('logout')
        } else {
          this.items = res.data.data
          this.pagination.current = res.data.meta.current_page
          this.pagination.total = res.data.meta.last_page

          this.totalF = res.data.meta.total
        }
      })
    },
    loadAll() {
      const json = {
        per_page: 100000,
      }
      this.axios.post('airport/list', json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        // eslint-disable-next-line no-empty
        if (res.data.success === false) { } else {
          this.totalItems = res.data.meta.total
        }
      })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('airport-id')
      localStorage.removeItem('airport-show')
      this.resetGeoTag()
      this.resetAirport()
      this.$router.push({ name: 'airport-flights-update' })
    },
    importItem() {
      this.isDialogVisibleImport = true
    },
    editItem(item) {
      localStorage.setItem('airport-id', item.id)
      localStorage.removeItem('airport-show')
      this.resetGeoTag()
      this.resetAirport()
      this.$router.push({ name: 'airport-flights-update' })
    },
    showItem(item) {
      localStorage.setItem('airport-id', item.id)
      localStorage.setItem('airport-show', true)
      this.resetGeoTag()
      this.resetAirport()
      this.$router.push({ name: 'airport-flights-update' })
    },
    save() {
      if (this.item.modelo) {
        this.loading = true
        this.item.perfil_slug = localStorage.getItem('perfil')
        if (this.itemId === null) {
          this.axios
            .post('airport', this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.airplaneExist', { identificador: this.item.modelo }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.reseat()))
        } else {
          this.axios
            .put(`airport/${this.itemId}`, this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.reseat()))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`airport/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },
    confirmImport() {
      if (this.jsonFile) {
        this.loadingImport = true
        const formData = new FormData()
        formData.append('json', this.jsonFile)
        this.axios
          .post('import-json/airport', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // console.log(response.data)
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              this.$toast.success(this.$t('msg.fileUpload'))
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.reset())
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reset() {
      this.loadingImport = false
      this.isDialogVisibleImport = false
      this.jsonFile = null
    },

    clearSearch() {
      this.model = {}
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
