<template>
  <fragment>
    <template>
      <v-col
        v-if="(permisos.includes('chatbot:list') || permisos.includes('*')) && cardsDashboard.includes('chatBot')"
        cols="4"
        order="1"
        class="align-self-top"
      >
        <ChatPendient
          :user="user"
        />
      </v-col>
    </template>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import ChatPendient from './chatBot/ChatPendient.vue'

export default {
  components: {
    ChatPendient,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      salesMonthPreview: {
        name: null,
      },
      isLoadingSales: true,
      sales: {},
      salesByContrate: [],
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
    }),
  },
  created() {
    // this.getSalesMonthPreview()
  },
  methods: {
    getSalesMonthPreview() {
      if (localStorage.getItem('salesMonthPreview')) {
        const saless = JSON.parse(localStorage.getItem('salesMonthPreview'))

        const userId = localStorage.getItem('user_id')
        if (saless.user_id === userId) {
          this.salesMonthPreview = saless
        }
      }
    },
  },
}
</script>
