<template>
  <div>
    <!-- MOSTRAR FORM -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('menu.suplementos') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.name"
                :label="$t('lbl.name')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-col
                v-for="(checkType, indCH) in checkTypes"
                :key="indCH"
                cols="6"
                class="my-0 py-0"
              >
                <v-checkbox
                  v-model="itemModality"
                  :label="checkType.name"
                  :value="checkType.slug"
                  hide-details
                ></v-checkbox>
              </v-col>
              <!--<v-radio-group
                v-model="item.modality"
                row
                hide-details
              >
                <v-radio
                  :label="$t('lbl.byUnidad')"
                  value="by_unidad"
                  class="col-4"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.byPeso')"
                  value="by_peso"
                  class="col-4"
                ></v-radio>
              </v-radio-group>-->
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          {{ $t('msg.deleteItem', { item: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_suplemento_contrate_fligths:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.suplementos') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byUnidad') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byPeso') }}
            </th>
            <th
              v-if="
                permisos.includes('nom_suplemento_contrate_fligths:edit') ||
                  permisos.includes('nom_suplemento_contrate_fligths:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ iten.name }}</td>
            <td>
              <v-icon
                v-if="iten.by_unidad"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td>
              <v-icon
                v-if="iten.by_peso"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td
              v-if="
                permisos.includes('nom_suplemento_contrate_fligths:edit') ||
                  permisos.includes('nom_suplemento_contrate_fligths:destroy') ||
                  permisos.includes('*')
              "
            >
              <!--(iten.user_id === user.id || iten.user_id === user.id_father)-->
              <v-tooltip
                v-if="permisos.includes('nom_suplemento_contrate_fligths:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('nom_suplemento_contrate_fligths:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemModality: [],
      checkTypes: [],
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.checkTypes.push({
      name: this.$t('lbl.byUnidad'),
      slug: 'by_unidad',
    })
    this.checkTypes.push({
      name: this.$t('lbl.byPeso'),
      slug: 'by_peso',
    })

    this.profile()
    this.load()
    this.loadAll()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(
          `nom_suplemento_contrate/flights?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${localStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      this.axios
        .get('nom_suplemento_contrate/flights?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      this.item = {}
      this.itemModality = []
      this.itemId = null
      this.isDialogVisible = true
    },
    editItem(item) {
      this.isDialogVisible = true
      this.item = item
      this.itemModality = this.item.modality
      this.itemId = item.id
    },
    save() {
      if (this.item.name && this.itemModality.length > 0) {
        this.loading = true
        this.item.modality = this.itemModality
        this.item.for_fligth = true
        this.item.perfil_slug = localStorage.getItem('perfil')

        if (this.itemId === null) {
          this.axios
            .post('nom_suplemento_contrate', this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(
                    this.$t('msg.nom_suplemento_contrate_fligthsExist', { identificador: this.item.modelo }),
                  )
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .put(`nom_suplemento_contrate/${this.itemId}`, this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_suplemento_contrate/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
