<template>
  <v-row>
    <v-col
      v-if="!isLoading"
      cols="12"
    >
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.cars')} / ${$t('menu.export')}`">
        <Form
          :is-table="isTable"
          ruta-back="cars-contrate-list"
          :tabla="tabla"
          :campos-entity-list="camposEntityList"
          :operadores-hotel="operadoresHotel"
          :marcas-hotel="marcasHotel"
          :tipos-destinos-hotel="tiposDestinosHotel"
          :categories="categories"
          :intereses-hotel="interesesHotel"
          :servicios-hotel="serviciosHotel"
          :edades-hotel="edadesHotel"
          :rooms-hotel="roomsHotel"
          :countries="countries"
          :states="states"
          :states-list="statesList"
          :cities="cities"
        />
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

// eslint-disable-next-line import/no-unresolved
import Form from '@/views/utils/exportations/form/Form.vue'

export default {
  components: {
    AppCardCode,
    Form,
  },
  data() {
    return {
      isLoading: true,
      tabla: {
        name: 'Contratos de autos',
        tabla: 'cars_contrate',
        campos: [],
      },
      user: {},

      isTable: false,
      entitiesList: [],
      camposEntityList: [],
      operadoresHotel: [],
      marcasHotel: [],
      tiposDestinosHotel: [],
      categories: [],
      interesesHotel: [],
      serviciosHotel: [],
      edadesHotel: [],
      roomsHotel: [],
      countries: [],
      states: [],
      statesList: [],
      cities: [],
    }
  },
  mounted() {
    this.profile()
  },
  created() {
    this.getEntities()

    // NOM HOTELS
    // this.getOperadores()
    // this.getMarcasHotels()
    // this.getCategorias()
    // this.getTipoDestino()
    // this.getIntereses()
    // this.getServicios()
    // this.getEdadesHotel()
    // this.getRooms()
    // this.getDestinosHotel()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false

          // this.isLoading = false
        })
    },

    getEntities() {
      this.axios
        .get('export-csv/list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          console.log(res)
          if (res.data.success) {
            this.entitiesList = res.data.data.list

            this.isTable = true
            if (this.entitiesList.filter(e => e.tabla === this.tabla.tabla).length > 0) {
              const result = this.entitiesList.filter(e => e.tabla === this.tabla.tabla)[0]
              this.camposEntityList = result.campos
            }
          }
        })
    },

    // NOMENCLADORES HOTELES
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadoresHotel = res.data.data
        })
    },
    getMarcasHotels() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcasHotel = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tiposDestinosHotel = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.interesesHotel = res.data.data
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.serviciosHotel = res.data.data
        })
    },
    getEdadesHotel() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edadesHotel = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsHotel = res.data.data
        })
    },
    getDestinosHotel() {
      this.axios
        .post(
          'hotels/destinos',
          {
            countries: 'all',
            states: 'all',
            cities: 'all',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.countries = res.data.data.countries
          this.states = res.data.data.states
          this.statesList = res.data.data.statesList
          this.cities = res.data.data.cities
        })
    },
  },
}
</script>
