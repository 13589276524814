<template>
  <v-row>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDateFormatted"
            :label="$t('lbl.from')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            :disabled="$store.state.app.onlyShow"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date.from"
          no-title
          :min="new Date(Date.now()).toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="
            menuFrom = false
            activeToDate()
          "
        ></v-date-picker>
      </v-menu>
      <!--<vc-date-picker
        v-model="date.from"
        outlined
        dense
        :min-date="new Date()"
        mode="date"
        :model-config="modelConfig"
        @input="activeToDate"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :label="$t('lbl.from')"
            outlined
            dense
            :value="inputValue"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>-->
    </v-col>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <v-menu
        v-if="date.from"
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedToDateFormatted"
            :label="$t('lbl.to')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            :disabled="$store.state.app.onlyShow"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date.to"
          no-title
          locale="es"
          :show-current="false"
          :min="
            $moment(date.from)
              .add(1, 'day')
              .toDate()
              .toISOString()
              .substr(0, 10)
          "
          @input="menuTo = false"
        ></v-date-picker>
      </v-menu>
      <!--<vc-date-picker
        v-model="date.to"
        outlined
        dense
        :min-date="
          $moment(date.from)
            .add(1, 'day')
            .toDate()
        "
        mode="date"
        :model-config="modelConfig"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :ref="`dateTo-${indSeason}-${indDate}`"
            :label="$t('lbl.to')"
            outlined
            dense
            :value="inputValue"
            :disabled="date.from === null"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>-->
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
    >
      <v-tooltip
        v-if="total > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteDateTemporadaContrateCar({ posSeason: indSeason, posDate: indDate })"
          >
            <v-icon small>
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="indDate === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            class="ml-1"
            v-on="on"
            @click="addDateTemporadaContrateCar(indSeason)"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiPlus } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    date: {
      type: Object,
    },
    indDate: {
      type: Number,
      default: 0,
    },
    indSeason: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiPlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      menuFrom: false,
      menuTo: false,
      from: null,
      to: null,
      tarifas: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
      temporadasContrateCar: state => state.app.temporadasContrateCar,
    }),
    computedFromDateFormatted() {
      return this.date.from ? this.$moment(this.date.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.date.to ? this.$moment(this.date.to).format('D-MMM-YY') : ''
    },
  },
  methods: {
    ...mapMutations([
      'verifyPricesTemporadasContrateCar',
      'addDateTemporadaContrateCar',
      'deleteDateTemporadaContrateCar',
    ]),
    activeToDate() {
      this.menuTo = true

      /* setTimeout(() => {
        this.$refs.dateTo.focus()
      }, 100) */

      // this.verifyPricesTemporadasContrateCar()
    },
    getTarifaText(tarifa) {
      return tarifa.min !== null && tarifa.max !== null
        ? `${tarifa.min} ${this.$t('lbl.a')} ${tarifa.max}`
        : `${tarifa.min}+`
    },
    getTarifas() {
      this.axios
        .get('nom_tarifas_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.tarifas = res.data.data
          }
        })
    },
  },
}
</script>
