<template>
  <fragment>
    <v-row class="pt-5">
      <v-col cols="12">
        <template>
          <v-tabs>
            <v-tab @click="byMasEconomico">
              {{ $t('lbl.byMasEconomico') }}
            </v-tab>
            <v-tab @click="byMejorOpcion">
              {{ $t('lbl.byMejorOpcion') }}
            </v-tab>
            <v-tab @click="byMasRapido">
              {{ $t('lbl.byMasRapido') }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ma-1"
              dark
              outlined
              @click="isEventHandlerDrawerActive = true"
            >
              {{ $t('lbl.filtros') }}
            </v-btn>
          </v-tabs>
        </template>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      width="400"
    >
      <FiltrosActions @close-drawer="isEventHandlerDrawerActive = false" />
    </v-navigation-drawer>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

import FiltrosActions from './FiltrosActions.vue'

export default {
  components: {
    FiltrosActions,
  },
  data() {
    return {
      isLoadingFlihgt: true,
      isEventHandlerDrawerActive: false,
      loading: false,
      dialog: false,
      dialogm1: false,
      aerolineas: [],
      aerolinea: [],
      clases: [],
      aerolin: null,
      frecuencias: [],
      itemsOrigen: [],
      searchOrigen: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      filtrosCotizadorFlight: state => state.app.filtrosCotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorIdaFlight: state => state.app.aerolineCotizadorIdaFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
    }),
  },
  methods: {
    ...mapMutations([
      'setFiltrosCotizadorFlight',
      'setAerolineCotizadorIdaFligth',
      'setAerolineCotizadorRegresoFligth',
      'setSearchCotizadorIdaFlight',
      'setSearchCotizadorFlights',
    ]),
    byMejorOpcion() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: true,
        byMasEconomico: false,
        byMasRapido: false,
      })
      this.aplicarFiltros()
    },
    byMasEconomico() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: false,
        byMasEconomico: true,
        byMasRapido: false,
      })
      this.aplicarFiltros()
    },
    byMasRapido() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: false,
        byMasEconomico: false,
        byMasRapido: true,
      })
      this.aplicarFiltros()
    },
    aplicarFiltros() {
      // this.dialog = false
      // console.log(this.filtrosCotizadorFlight)
      this.setFiltrosCotizadorFlight({
        filtrosCotizadorFlight: this.filtrosCotizadorFlight,
      })
      this.setSearchCotizadorIdaFlight(true)
      this.setSearchCotizadorFlights(true)

      // console.log(this.filtrosCotizadorFlight)

      this.loading = true

      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,

        destinos: this.destinosCotizadorFlight,

        aerolinea: this.filtrosCotizadorFlight.aerolinea,
        escala: this.filtrosCotizadorFlight.escala,
        duracionEscala: this.filtrosCotizadorFlight.duracionEscala,
        duracionViaje: this.filtrosCotizadorFlight.duracionViaje,
        frecuencias: this.filtrosCotizadorFlight.frecuencias,
        airport: this.filtrosCotizadorFlight.airport,
        clase: this.filtrosCotizadorFlight.clase,
        equipaje: this.filtrosCotizadorFlight.equipaje,
        byMejorOpcion: this.filtrosCotizadorFlight.byMejorOpcion,
        byMasEconomico: this.filtrosCotizadorFlight.byMasEconomico,
        byMasRapido: this.filtrosCotizadorFlight.byMasRapido,
      }

      // console.log(json)

      this.axios
        .post('cotizador-search-flight', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log([res.data.data.aerolineIda[0]])

          // console.log(res)

          this.setAerolineCotizadorIdaFligth(res.data.data.aerolineIda)

          this.setAerolineCotizadorRegresoFligth(res.data.data.aerolineRegreso)

          this.dialog = false
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.setSearchCotizadorIdaFlight(false)
        })

      /* if (this.cotizadorFlight.onlyIda) {
        console.log(this.aerolineCotizadorIdaFlight)
      } else if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        console.log(this.aerolineCotizadorRegresoFlight)
      } else {
        console.log('multidestinos')
      } */
    },
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

/* PARA LA FECHA DE VCALENDAR*/
.vc-popover-content-wrapper {
  margin: 0px;
  position: fixed !important;
  inset: 0px auto auto 0px;
  transform: translate(150px, 80px);
}
</style>
