<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="room.rooms_id"
              :disabled="$store.state.app.onlyShow"
              :items="roomsFree"
              :label="$t('lbl.rooms')"
              class="my-input mr-2"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.room') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip
              v-if="estaciaMaxRoomsSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteEstanciaMaxRoomSeasonContrateHotel(pos)"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip
              v-if="!$store.state.app.onlyShow && pos === 0"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 ml-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  v-bind="attrs"
                  :disabled="verifyRoomsFree"
                  v-on="on"
                  @click="addEstanciaMaxRoomSeasonContrateHotel()"
                >
                  <v-icon small>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.insert') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <v-row>
          <div
            v-for="(estanc, indC) in room.estancia_max"
            :key="indC"
            style="max-width: 90px; margin-left: 20px"
            class="mt-2"
          >
            <!--
              :append-icon="estanc.days && pos === 0 ? 'mdi-arrow-down' : ''"
              @click:append="iconSet(estanc.days, indC)"
            -->
            <v-text-field
              v-model="estanc.days"
              :append-icon="indC === 0 && room.estancia_max.length > 1 ? 'mdi-arrow-right' : ''"
              :disabled="$store.state.app.onlyShow"
              :label="$t('lbl.day')"
              outlined
              dense
              hide-details
              type="number"
              @keypress="checkEstancia($event, estanc.days)"
              @click:append="
                indC === 0
                  ? replicaEstanciaMaxSeasonContrateHotel({
                    posRoom: pos,
                    days: estanc.days,
                  })
                  : ''
              "
            ></v-text-field>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      estaciaMaxRoomsSeasonContrateHotel: state => state.app.estaciaMaxRoomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.estaciaMaxRoomsSeasonContrateHotel.length; index++) {
            if (this.estaciaMaxRoomsSeasonContrateHotel[index].rooms_id.includes(element.id) && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.estaciaMaxRoomsSeasonContrateHotel.length; index++) {
            if (this.estaciaMaxRoomsSeasonContrateHotel[index].rooms_id.includes(element)) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteEstanciaMaxRoomSeasonContrateHotel',
      'addEstanciaMaxRoomSeasonContrateHotel',
      'replicaEstanciaMaxSeasonContrateHotel',
    ]),
    checkEstancia(elEvento, model) {
      const numeros = '0123456789'
      const numerosCaracteres = numeros

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES  CERO DE PRIMERO
      if (elEvento.keyCode === 48) {
        if (model.length === 0) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
  },
}
</script>
