<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <v-select
        v-model="room.room_id"
        :disabled="$store.state.app.onlyShow"
        :items="roomsFree"
        :label="$t('lbl.room')"
        outlined
        dense
        hide-details
        item-text="name"
        item-value="id"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.room') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="hotel.rooms_by_price_suplement.length > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            v-bind="attrs"
            fab
            dark
            x-small
            color="error"
            v-on="on"
            @click="deleteRoomByPriceBySuplementHotelOfertEBB({ pos: posHotel, posRoom: pos })"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>

      <v-tooltip
        v-if="pos === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addRoomByPriceBySuplementHotelOfertEBB(posHotel)"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="7"
      class="mt-0 pt-0"
    >
      <!--FECHA VIAJE-->
      <v-row class="mt-0">
        <v-col
          cols="12"
          md="2"
          class="mt-3"
        >
          <strong class="text-center">{{ $t('lbl.travel') }}</strong>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="menuFromViaje"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateViajeFormatted"
                :label="$t('lbl.from')"
                :disabled="$store.state.app.onlyShow"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                class="my-input mb-0"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="room.fromViaje"
              no-title
              :min="new Date(Date.now()).toISOString().substr(0, 10)"
              locale="es"
              :show-current="false"
              @input="
                menuFromViaje = false
                activeToDateViaje()
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="menuToViaje"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateViajeFormatted"
                :label="$t('lbl.to')"
                :disabled="room.fromViaje === null || $store.state.app.onlyShow"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                class="my-input"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="room.toViaje"
              no-title
              locale="es"
              :show-current="false"
              :min="
                room.fromViaje
                  ? $moment(room.fromViaje)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              @input="menuToViaje = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="room.plan_id"
            :disabled="$store.state.app.onlyShow"
            :items="allPlanes"
            :label="$t('lbl.plan')"
            outlined
            dense
            hide-details
            item-text="siglas"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.plan') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!--DOBLE-->
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="mt-2"
        >
          <strong v-if="!ofertEBBDates.price_by_room">{{ $t('lbl.priceByPaxDoble') }}</strong>
          <strong v-else>{{ $t('lbl.priceByRoomDoble') }}</strong>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="room.price_doble"
            :label="$t('lbl.monto')"
            :disabled="$store.state.app.onlyShow"
            class="my-input"
            outlined
            dense
            hide-details
            type="number"
            prefix="$"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <!--SUPLEMENTOS-->
    <v-col
      cols="12"
      md="3"
      offset-md="5"
      class="mt-2"
    >
      <strong>{{ $t('menu.suplementos') }}</strong>
    </v-col>
    <OfertsHotelPricesSuplement
      v-for="(suplement, indexSup) in room.suplement"
      :key="indexSup"
      :suplement="suplement"
      :pos="indexSup"
      :room="room"
      :pos-room="pos"
      :pos-hotel="posHotel"
      :all-suplement="allSuplement"
    />

    <!--REDUCCIONES-->
    <v-col
      cols="12"
      md="3"
      offset-md="5"
      class="mt-2"
    >
      <strong>{{ $t('menu.reducciones') }}</strong>
    </v-col>
    <OfertsHotelPricesReduction
      v-for="(reduction, indexRedux) in room.reduction"
      :key="indexRedux"
      :reduction="reduction"
      :pos="indexRedux"
      :room="room"
      :edad="edad"
      :pos-room="pos"
      :pos-hotel="posHotel"
      :all-reduction="allReductions"
    />

    <!--BENEFICIOS-->
    <v-col
      cols="12"
      md="7"
      class="mt-2"
      offset-md="5"
    >
      <v-switch
        v-model="room.show_beneficios"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.beneficiosAdd')"
        hide-details
      ></v-switch>
      <v-textarea
        v-if="room.show_beneficios"
        v-model="room.beneficios"
        :disabled="$store.state.app.onlyShow"
        outlined
        name="input-7-4"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
} from '@mdi/js'

import OfertsHotelPricesSuplement from './OfertsHotelPricesSuplement.vue'
import OfertsHotelPricesReduction from './OfertsHotelPricesReduction.vue'

export default {
  components: {
    OfertsHotelPricesSuplement,
    OfertsHotelPricesReduction,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posHotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotelRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReductions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    edad: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsRooms: [],
      calculators: [],
      menuFromViaje: false,
      menuToViaje: false,
      menuFromReserva: false,
      menuToReserva: false,
      menuRooming: false,
      menuFromPagar: false,
      menuFecha: false,
    }
  },
  computed: {
    ...mapState({
      ofertEBBDates: state => state.app.ofertEBBDates,
    }),
    roomsFree() {
      const result = []
      this.allRooms.forEach(element => {
        if (this.hotelRooms.includes(element.id)) {
          result.push(element)
        }
      })

      const rrr = []
      result.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotel.rooms_by_price.length; index++) {
          if (this.hotel.rooms_by_price[index].room_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          rrr.push(element)
        }
      })

      return rrr
    },
    computedFromDateViajeFormatted() {
      return this.room.fromViaje ? this.$moment(this.room.fromViaje).format('D-MMM-YY') : ''
    },
    computedToDateViajeFormatted() {
      return this.room.toViaje ? this.$moment(this.room.toViaje).format('D-MMM-YY') : ''
    },
  },
  created() {
    if (this.roomsFree.length === 1) {
      this.room.room_id = this.roomsFree[0].id
    }
  },
  methods: {
    ...mapMutations(['deleteRoomByPriceBySuplementHotelOfertEBB', 'addRoomByPriceBySuplementHotelOfertEBB']),
    activeToDateViaje() {
      setTimeout(() => {
        // this.$refs.dateToViaje.focus()
        this.menuToViaje = true
      }, 100)
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
