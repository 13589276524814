<template>
  <v-card>
    <v-card-title>{{ $t('traslate') }} / {{ $t('web') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('traslate') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              SLUG
            </th>
            <th class="text-uppercase">
              ES
            </th>
            <th class="text-uppercase">
              PT
            </th>
            <th class="text-uppercase">
              EN
            </th>
            <th class="text-uppercase">
              DE
            </th>
            <th class="text-uppercase">
              RU
            </th>
            <th class="text-uppercase">
              FR
            </th>
            <th
              v-if="permisos.includes('traslate_pages:edit')"
              width="15px"
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <b><i>{{ iten.slug }}</i></b>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.es | truncate(30) }}</span>
                </template>
                <span>{{ iten.es }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.pt | truncate(30) }}</span>
                </template>
                <span>{{ iten.pt }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.en | truncate(30) }}</span>
                </template>
                <span>{{ iten.en }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.de | truncate(30) }}</span>
                </template>
                <span>{{ iten.de }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.ru | truncate(30) }}</span>
                </template>
                <span>{{ iten.ru }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.fr | truncate(30) }}</span>
                </template>
                <span>{{ iten.fr }}</span>
              </v-tooltip>
            </td>
            <td
              v-if="permisos.includes('traslate_pages:edit')"
              style="text-align: center;"
            >
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="permisos.includes('traslate_pages:edit')"
                    link
                    @click="editItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.profile()
    this.fetchDataFromEndpoints()
  },
  methods: {
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      this.isLoading = false
    },
    async fetchDataFromEndpoints() {
      const jsonAll = {
        page: 1,
        per_page: 1000000000000000,
      }
      const jsonLoad = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,

        search: this.model,
      }
      try {
        const [
          resAll,
          resLoad,
        ] = await Promise.all([
          this.axios
            .post('traslate/pages', jsonAll, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('traslate/pages', jsonLoad, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resAll.data.success === false) { } else {
          this.totalItems = resAll.data.meta.total
        }
        // eslint-disable-next-line no-empty
        if (resLoad.data.success === false) { } else {
          this.items = resLoad.data.data
          this.pagination.current = resLoad.data.meta.current_page
          this.pagination.total = resLoad.data.meta.last_page

          this.totalF = resLoad.data.meta.total
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
        permisos: this.permisos.split(','),
      }
      this.axios
        .post('tutorials/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      const json = {
        per_page: 100000,
        permisos: this.permisos.split(','),
      }
      this.axios
        .post('tutorials/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('tutorials-id')
      localStorage.removeItem('tutorials-show')
      this.$router.push({ name: 'tutorials-create' })
    },
    view(iten) {
      localStorage.setItem('tutorials-id', iten.id)
      this.$router.push({ name: 'tutorials-view' })
    },
    editItem(iten) {
      localStorage.setItem('traslate-page-id', iten.id)
      this.$router.push({ name: 'pages-web-update' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`tutorials/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
