// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // DNS
  {
    path: '/configuration/dns',
    name: 'dns',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/notifications/DNSEmails.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'smtp_emails',
    },
  },
  {
    path: '/configuration/dns/update',
    name: 'dns-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/notifications/form/DNSUpdate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'smtp_emails',
    },
  },

  // BOT TELEGRAM
  {
    path: '/configuration/bot-telegram',
    name: 'bot-telegram',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/notifications/BotTelegram.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'bot_telegram',
    },
  },
  {
    path: '/configuration/bot-telegram/update',
    name: 'bot-telegram-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/notifications/form/BotTelegramUpdate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'bot_telegram',
    },
  },
  {
    path: '/configuration/dashboards/update',
    name: 'dashboard-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/notifications/form/Dashboard.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'dashboard_cards',
    },
  },
]

export default formTable
