<template>
  <v-row
    no-gutters
    class="mb-2"
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="pay.daysPagar"
        :label="$t('lbl.daysBefore')"
        :disabled="$store.state.app.onlyShow"
        class="my-input mb-0 mr-4"
        outlined
        dense
        hide-details
        type="number"
      ></v-text-field>
      <!--<v-menu
        v-model="menuFromPagar"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDatePagarFormatted"
            :label="$t('lbl.before')"
            :disabled="$store.state.app.onlyShow"
            class="my-input mb-0 mr-4"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pay.fromPagar"
          no-title
          :min="new Date(Date.now()).toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="menuFromPagar = false"
        ></v-date-picker>
      </v-menu>-->
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="pay.valuePagar"
        :label="$t('lbl.monto')"
        :disabled="$store.state.app.onlyShow"
        class="my-input ml-2 mr-2"
        outlined
        dense
        hide-details
        type="number"
        :prefix="!pay.in_pc_pagar ? '$' : ''"
        :suffix="pay.in_pc_pagar ? '%' : ''"
      ></v-text-field>
    </v-col>
    <!--<v-col
      cols="12"
      md="2"
    >
      <v-row class="mt-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="pay.in_pc_pagar"
            :disabled="$store.state.app.onlyShow"
            label="%"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>-->
    <v-col
      cols="12"
      md="1"
      class="mr-2"
    >
      <v-tooltip
        v-if="ofertEBBDates.pays.length > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            v-bind="attrs"
            fab
            dark
            x-small
            color="error"
            v-on="on"
            @click="deleteOfertPay(pos)"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="1"
      class="ml-2"
    >
      <v-tooltip
        v-if="pos === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addOfertPay()"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pay: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      menuFromPagar: false,
    }
  },
  computed: {
    ...mapState({
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      ofertEBBDates: state => state.app.ofertEBBDates,
    }),
    computedFromDatePagarFormatted() {
      return this.pay.fromPagar ? this.$moment(this.pay.fromPagar).format('D-MMM-YY') : ''
    },
  },
  methods: {
    ...mapMutations(['deleteOfertPay', 'addOfertPay']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
