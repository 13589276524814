<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <Loading
      v-if="searching"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <v-col cols="12">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="error"
              class="mb-4 me-3"
              @click="$router.push({ name: rutaBack })"
            >
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
              <span>{{ $t('btn.back') }}</span>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              class="me-3"
              :disabled="!isTable || campos.length === 0"
              @click="search()"
            >
              <span>{{ $t('btn.search') }}</span>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              class="me-3"
              :disabled="!isTable || campos.length === 0"
              @click="activeFilter()"
            >
              <span>{{ $t('lbl.filtros') }}</span>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="isTable"
            cols="12"
            md="12"
          >
            <v-select
              v-model="campos"
              :items="camposEntityList"
              :label="$t('lbl.inputsExport')"
              multiple
              outlined
              dense
              item-text="string"
              item-value="name"
              class="pt-2"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="campos.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('lbl.selectAll') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <!--<v-select
              v-model="campos"
              :items="camposEntityList"
              :label="$t('lbl.inputsExport')"
              multiple
              outlined
              dense
              item-text="string"
              item-value="name"
              class="pt-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.inputs') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>-->
            <!--<v-autocomplete
              v-model="campos"
              :items="camposList"
              :search-input.sync="camposSearch"
              hide-details
              hide-selected
              :label="$t('lbl.inputsExport')"
              outlined
              dense
              item-text="name"
              item-value="tabla"
              class="pt-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.inputs') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>-->
          </v-col>
        </v-row>

        <v-row v-if="isTable">
          <v-col
            cols="12"
            md="12"
          >
            <Table
              :tabla="tabla"
              :items="items"
              :headers="headers"
              :total-f="totalF"
              :total-items="totalItems"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-col>

    <fragment v-if="isTable">
      <SidebarDataFilter
        v-if="tabla"
        v-model="isSidebarDataFilterActive"
        :tabla="tabla"
        :marcas-cars="marcasCars"
        :transmisiones="transmisiones"
        :type-auto="typeAuto"
        :combustibles="combustibles"
        :caracteristicas="caracteristicas"
        :operadores-hotel="operadoresHotel"
        :marcas-hotel="marcasHotel"
        :categories="categories"
        :tipos-destinos-hotel="tiposDestinosHotel"
        :intereses-hotel="interesesHotel"
        :servicios-hotel="serviciosHotel"
        :edades-hotel="edadesHotel"
        :rooms-hotel="roomsHotel"
        :countries="countries"
        :states="states"
        :states-list="statesList"
        :cities="cities"
        @search="search"
      />
    </fragment>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

import SidebarDataFilter from '../utils/SidebarDataFilter.vue'
import Table from '../utils/Table.vue'

export default {
  components: {
    Table,
    SidebarDataFilter,
    Loading,
  },
  props: {
    isTable: {
      type: Boolean,
      default() {
        return true
      },
    },
    rutaBack: {
      type: String,
      default() {
        return ''
      },
    },
    tabla: {
      type: Object,
      default() {
        return ''
      },
    },
    camposEntityList: {
      type: Array,
      default() {
        return []
      },
    },
    operadoresHotel: {
      type: Array,
      default() {
        return []
      },
    },
    marcasHotel: {
      type: Array,
      default() {
        return []
      },
    },
    tiposDestinosHotel: {
      type: Array,
      default() {
        return []
      },
    },
    categories: {
      type: Array,
      default() {
        return []
      },
    },
    interesesHotel: {
      type: Array,
      default() {
        return []
      },
    },
    serviciosHotel: {
      type: Array,
      default() {
        return []
      },
    },
    edadesHotel: {
      type: Array,
      default() {
        return []
      },
    },
    roomsHotel: {
      type: Array,
      default() {
        return []
      },
    },
    countries: {
      type: Array,
      default() {
        return []
      },
    },
    states: {
      type: Array,
      default() {
        return []
      },
    },
    statesList: {
      type: Array,
      default() {
        return []
      },
    },
    cities: {
      type: Array,
      default() {
        return []
      },
    },

    // CARS
    marcasCars: {
      type: Array,
      default() {
        return []
      },
    },
    transmisiones: {
      type: Array,
      default() {
        return []
      },
    },
    typeAuto: {
      type: Array,
      default() {
        return []
      },
    },
    combustibles: {
      type: Array,
      default() {
        return []
      },
    },
    caracteristicas: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      searching: false,
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      isSidebarDataFilterActive: false,
      loading: false,
      campos: [],
      entities: [],
      camposList: [],
      camposSearch: null,
      items: [],
      headers: [],
      totalF: 0,
      totalItems: 0,
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
      filtrosExport: state => state.app.filtrosExport,
    }),

    selectAllInputs() {
      return this.campos.length === this.camposEntityList.length
    },
    selectSomeInputs() {
      return this.campos.length > 0 && !this.selectAllInputs
    },
    icon() {
      if (this.selectAllInputs) return 'mdi-close-box'
      if (this.selectSomeInputs) return 'mdi-minus-box'

      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    camposSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCampos(val.toLowerCase())
      } else {
        this.camposList = []
      }
    },
  },
  created() {
    this.setFiltrosExport({})
  },

  methods: {
    ...mapMutations(['setOnlyShow', 'setFiltrosExport']),
    filterCampos(v) {
      this.camposList = []
      if (v === '') {
        this.camposList = []
      } else {
        this.camposList = this.camposEntityList.filter(e => e.name.toLowerCase())
      }
    },
    toggle() {
      if (this.selectAllInputs) {
        this.campos = []
      } else {
        this.campos = []
        this.camposEntityList.forEach(element => {
          this.campos.push(element.name)
        })
      }
    },

    resetValues() {
      this.isSidebarDataFilterActive = false
      this.setFiltrosExport({})
      this.headers = []
      this.items = []
      this.totalF = 0
      this.totalItems = 0
      this.campos = []
      this.camposEntityList = []
    },

    search() {
      this.isSidebarDataFilterActive = false
      this.searching = true
      const json = {
        table: this.tabla.tabla,
        inputs: this.campos,
        search: this.filtrosExport,
      }

      this.axios
        .post('export-csv/search', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.headers = res.data.data.headers
            this.items = res.data.data.list
            this.totalF = this.items.length
            this.totalItems = res.data.data.totalItems
          }
        })
        .finally(() => {
          this.searching = false
        })
    },
    activeFilter() {
      this.isSidebarDataFilterActive = true
    },
  },
}
</script>
