<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { item: itemDelete.name }) }}</v-card-text>
        <v-card-text>{{ $t('msg.deleteToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="(permisos.includes('app_backoffice:create') || permisos.includes('*'))"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newApp()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.add') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <!--MANGER-DATABASE-->
        <template
          v-if="permisos.includes('app_backoffice:list') || permisos.includes('*')"
        >
          <APPData
            :apps="apps"
            :user="user"
            type_app="manager_database"
            :config="config"
            @getAPPS="getAPPS"
          />
        </template>
      </v-row>
    </v-card-text>
    <SidebarDataAPPsCreate
      v-if="isSidebarDataAPPActive"
      v-model="isSidebarDataAPPActive"
      @getAPPS="getAPPS"
    />
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiKeyChain,
  mdiLinkVariant,
  mdiLink,
} from '@mdi/js'
import APPData from '../utils/APPData.vue'
import SidebarDataAPPsCreate from '../utils/SidebarDataAPPsCreate.vue'

export default {
  components: {
    APPData,
    SidebarDataAPPsCreate,
  },
  data() {
    return {
      pos: 0,
      url_doc: process.env.VUE_APP_DOC_URL,
      isLoading: true,
      generando: false,
      search: '',
      items: [],
      onlyInactivo: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiKeyChain,
        mdiLinkVariant,
        mdiLink,
      },
      isDialogVisible: false,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      itemRegenerate: {},
      isDialogVisibleDelete: false,
      companies: [],
      visibleCreateApiKey: false,
      apikey: {},
      loading: false,
      itemEdicActive: {},
      tokensEdicActive: {},
      showEditTokenDialog: false,
      showEditTokenDialogPropio: false,
      showEditTokenActiveDialog: false,
      showEditTokenActiveDialogPropio: false,
      user: {},
      offline: false,
      apps: [],
      isSidebarDataAPPActive: false,
      config: {
        logo: null,
        name: '',
      },
    }
  },
  mounted() {
    this.profile()
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getAPPS()
        })
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getAPPS() {
      this.apps = []
      this.isSidebarDataAPPActive = false
      this.axios
        .post(
          'apps',
          { type: 'manager_database' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (this.permisos.includes('app_backoffice:list')) {
              this.apps.push(element)
            }
          })
        })
        .catch(error => console.log(error))
    },
    newApp() {
      this.isSidebarDataAPPActive = true
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .post('api_keys/delete-token', this.itemDelete, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.closeDialogDelete())
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
