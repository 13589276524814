<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="3"
      ></v-col>
      <v-col
        cols="12"
        md="6"
      >
        <div
          id="cajaIntNoResultMine"
          class="d-block justify-center align-center"
        >
          <div class="d-flex justify-center align-center">
            <h2>{{ $t('msg.noResultFlightCotizacionTitle') }}</h2>
          </div>
          <div class="d-flex justify-center align-center mt-2">
            <h5>{{ $t('msg.noResultFlightCotizacionBody') }}</h5>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
      ></v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

export default {
  data() {
    return {
      tab: 0,
      isLoadingFlihgt: true,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      aerolineas: [],
      radioGroup: 0,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
    }),
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
    ]),
  },
}
</script>

<style scoped>
  /* Caja Intermedia */
  #cajaIntMine {
    margin-top: 2em;
  }

  /* Caja intermedia no resultado */
  #cajaIntNoResultMine {
    margin-top: 2em;
  }
</style>
