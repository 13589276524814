<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.configuration')} / ${$t('permiso.notifications')} / DNS - Email` }}</v-card-title>
        <v-card-text>
          <DNSEmails />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import DNSEmails from './form/DNSEmails.vue'

export default {
  components: {
    DNSEmails,
  },
}
</script>
