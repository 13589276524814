<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="892.6139"
    height="540.80203"
    viewBox="0 0 892.6139 540.80203"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>season_change</title>
    <circle
      cx="73.89615"
      cy="71.32961"
      r="46"
      fill="#ff6584"
    />
    <ellipse
      cx="444.00318"
      cy="522.80203"
      rx="444.00318"
      ry="18"
      fill="#e6e6e6"
    />
    <circle
      cx="113"
      cy="184.2194"
      r="113"
      :fill="color"
    />
    <polygon
      points="123.892 524.581 100.747 524.581 111.298 167.201 123.892 524.581"
      fill="#3f3d56"
    />
    <polygon
      points="113.34 234.933 138.187 200.557 113 243.442 110.277 238.677 113.34 234.933"
      fill="#3f3d56"
    />
    <polygon
      points="110.617 269.65 85.771 235.274 110.958 278.159 113.681 273.394 110.617 269.65"
      fill="#3f3d56"
    />
    <polygon
      points="827.505 524.957 804.361 524.957 814.912 167.577 827.505 524.957"
      fill="#3f3d56"
    />
    <polygon
      points="816.954 235.309 841.801 200.933 816.614 243.818 813.891 239.053 816.954 235.309"
      fill="#3f3d56"
    />
    <polygon
      points="814.231 270.026 789.385 235.65 814.572 278.535 817.295 273.77 814.231 270.026"
      fill="#3f3d56"
    />
    <circle
      cx="459.05561"
      cy="314.39327"
      r="210.50358"
      fill="#3f3d56"
    />
    <circle
      cx="459.05561"
      cy="314.39327"
      r="193.07779"
      fill="#fff"
    />
    <circle
      cx="458.35857"
      cy="315.0903"
      r="25.79017"
      fill="#3f3d56"
    />
    <rect
      x="452.78232"
      y="131.07392"
      width="11.15251"
      height="20.91095"
      fill="#3f3d56"
    />
    <rect
      x="452.78232"
      y="478.19572"
      width="11.15251"
      height="20.91095"
      fill="#3f3d56"
    />
    <rect
      x="432.91447"
      y="484.23381"
      width="11.15251"
      height="20.91095"
      transform="translate(779.48696 -123.40042) rotate(90)"
      fill="#3f3d56"
    />
    <rect
      x="780.03627"
      y="484.23381"
      width="11.15251"
      height="20.91095"
      transform="translate(1126.60876 -470.52223) rotate(90)"
      fill="#3f3d56"
    />
    <polygon
      points="450.924 326.286 460.455 304.493 579.88 370.635 450.924 326.286"
      fill="#3f3d56"
    />
    <rect
      x="431.87137"
      y="53.00636"
      width="54.36848"
      height="62.73286"
      fill="#3f3d56"
    />
    <ellipse
      cx="459.05561"
      cy="53.00636"
      rx="62.73286"
      ry="17.42579"
      fill="#3f3d56"
    />
    <path
      d="M730.1985,531.28345l-3.48516,4.87922s-16.03173,18.81986-16.72876,9.75845,11.84954-15.3347,11.84954-15.3347l6.97032-4.18219Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#ffb8b8"
    />
    <path
      d="M829.41778,547.74522l2.91833,5.238s10.14843,22.54357,1.66044,19.29564-8.78836-17.27221-8.78836-17.27221l-.79685-8.08957Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#ffb8b8"
    />
    <circle
      cx="660.03348"
      cy="254.04245"
      r="21.22425"
      fill="#2f2e41"
    />
    <path
      d="M811.75122,607.95694l2.78812,18.12283V631.656h16.03173V624.6857s.697-12.54657-2.09109-16.03173S811.75122,607.95694,811.75122,607.95694Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#ffb8b8"
    />
    <path
      d="M783.17291,607.95694l2.78813,18.12283V631.656h16.03173V624.6857s.697-12.54657-2.09109-16.03173S783.17291,607.95694,783.17291,607.95694Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#ffb8b8"
    />
    <path
      d="M793.96129,462.38371s-6.16626,3.25-1.9546,10.64285,28.92249,83.8766,28.92249,83.8766l14.25582-4.04685-12.57235-48.37053-7.1988-30.40158Z"
      transform="translate(-153.69305 -179.59899)"
      :fill="color"
    />
    <circle
      cx="657.70965"
      cy="260.02479"
      r="16.03173"
      fill="#ffb8b8"
    />
    <path
      d="M818.72153,442.06339l-2.09109,32.76049L801.99277,464.3684s4.87922-19.51689,3.48516-20.911Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#ffb8b8"
    />
    <path
      d="M818.72153,472.03575l-15.769-11.67706s-7.23305-2.9606-9.32415.52455-12.54657,55.06551-6.97032,64.824c0,0,29.97237,6.27328,36.24565,2.78812l2.78813-18.12282s3.48516-11.15251-2.0911-18.12283Z"
      transform="translate(-153.69305 -179.59899)"
      :fill="color"
    />
    <path
      d="M797.81058,461.58028s-5.57625-4.18219-10.45548,2.78812-63.42988,62.03583-63.42988,62.03583l9.75844,11.15251,38.33675-32.06346,24.39611-19.51689Z"
      transform="translate(-153.69305 -179.59899)"
      :fill="color"
    />
    <path
      d="M823.25224,528.14681s-34.50307-6.6218-37.98823-1.74258l-.697,7.66735s-7.66735,14.63766-3.48516,38.33674,1.39406,38.33675,1.39406,38.33675,18.81986-2.0911,32.76049,0,16.03173,0,16.03173,0Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#2f2e41"
    />
    <path
      d="M831.96514,628.86789l-20.21392.697,6.97031,52.27739s-5.57625,22.305.697,23.69907a70.7125,70.7125,0,0,0,13.24361,1.39407s6.27328,6.27328,13.2436,5.57625a39.034,39.034,0,0,0,13.2436-4.18219s1.39407-4.18219-3.48516-5.57625-18.12282-5.57626-20.911-20.21392Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#2f2e41"
    />
    <path
      d="M803.38683,628.86789l-20.21392.697,6.97032,52.27739s-5.57625,22.305.697,23.69907a70.7125,70.7125,0,0,0,13.24361,1.39407s6.27328,6.27328,13.2436,5.57625a39.034,39.034,0,0,0,13.2436-4.18219s1.39407-4.18219-3.48516-5.57625S808.96309,697.177,806.175,682.53934Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#2f2e41"
    />
    <circle
      cx="667.45803"
      cy="231.7041"
      r="8.78245"
      fill="#2f2e41"
    />
    <path
      d="M832.861,406.54593a8.78336,8.78336,0,0,0-7.86761-8.735,8.881,8.881,0,0,1,.91484-.04748,8.78244,8.78244,0,0,1,0,17.56489,8.881,8.881,0,0,1-.91484-.04748A8.78336,8.78336,0,0,0,832.861,406.54593Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#2f2e41"
    />
    <ellipse
      cx="657.56451"
      cy="249.54235"
      rx="14.63741"
      ry="8.78245"
      fill="#2f2e41"
    />
    <path
      d="M1041.81277,221.75021a44.67678,44.67678,0,0,0-87.65662-9.41942c-.54125-.01942-1.082-.04117-1.628-.04117a44.68754,44.68754,0,0,0-42.86662,32.08892,31.5744,31.5744,0,0,0-37.46546,9.31921h139.36225a30.21954,30.21954,0,0,0,30.28607-31.28863Q1041.831,222.08017,1041.81277,221.75021Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#e6e6e6"
    />
    <circle
      cx="771.6139"
      cy="124.178"
      r="12"
      fill="#e6e6e6"
    />
    <circle
      cx="807.84695"
      cy="95.88566"
      r="9.9918"
      fill="#e6e6e6"
    />
    <circle
      cx="856.6139"
      cy="150.178"
      r="12"
      fill="#e6e6e6"
    />
    <circle
      cx="765.6139"
      cy="205.178"
      r="12"
      fill="#e6e6e6"
    />
    <circle
      cx="856.6139"
      cy="274.178"
      r="12"
      fill="#e6e6e6"
    />
    <path
      d="M991.28736,685.16529c.00415-.13007.01959-.2572.01959-.38831a11.98223,11.98223,0,0,0-16.98706-10.90472,11.96811,11.96811,0,0,0-20.8316,9.91388c-.061-.00092-.12012-.00916-.18134-.00916a12,12,0,1,0,7.33429,21.48322,11.99355,11.99355,0,0,0,20.62177,1.21716,11.99014,11.99014,0,1,0,10.02435-21.31207Z"
      transform="translate(-153.69305 -179.59899)"
      fill="#e6e6e6"
    />
    <circle
      cx="867.6139"
      cy="226.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="835.6139"
      cy="106.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="818.6139"
      cy="230.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="822.6139"
      cy="225.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="825.6139"
      cy="220.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="813.6139"
      cy="262.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="887.6139"
      cy="318.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="780.6139"
      cy="321.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="786.6139"
      cy="167.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="809.6139"
      cy="257.178"
      r="5"
      fill="#e6e6e6"
    />
    <circle
      cx="804.6139"
      cy="249.178"
      r="5"
      fill="#e6e6e6"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
</script>
