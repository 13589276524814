<template>
  <v-navigation-drawer
    :value="isSidebarDataClientActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-client-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.client') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-client-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              v-if="user.nivel <= 0"
              cols="12"
              md="12"
            >
              <v-switch
                v-model="clientCarShop.is_client_corporate"
                :label="$t('lbl.clientCorporate')"
                hide-details
                class="mt-0"
                @click="changeTypeCLients"
              ></v-switch>
            </v-col>

            <fragment v-if="!clientCarShop.is_client_corporate">
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="clientCarShop.name"
                  :label="`${$t('lbl.name')} *`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="clientCarShop.apellidos"
                  :label="`${$t('lbl.apellidos')} *`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="clientCarShop.email"
                  :label="`${$t('lbl.email')} *`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="clientCarShop.telefono"
                  :label="`${$t('lbl.telefono')}`"
                  outlined
                  dense
                  hide-details="auto"
                  prefix="+"
                  type="number"
                  :rules="phoneRules"
                ></v-text-field>
              </v-col>
            </fragment>
            <fragment v-else>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="clientCarShop.id_client_corporate"
                  class="pt-2"
                  :items="clientsCorporate"
                  hide-details
                  hide-selected
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  item-text="name_comercial"
                  item-value="id"
                  @change="setCliensCorporate()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.dataNotDisponse') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
                <!--<v-autocomplete
                  v-model="clientCarShop.id_client_corporate"
                  class="pt-2"
                  :items="itemsClientsCorporate"
                  :search-input.sync="searchClientsCorporate"
                  hide-details
                  hide-selected
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  item-text="name_comercial"
                  item-value="id"
                  @change="setCliensCorporate()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.name') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name_comercial"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>-->
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="clientCarShop.emails"
                  :items="emailsCorporative"
                  :label="$t('lbl.emails')"
                  outlined
                  dense
                  item-text="email"
                  item-value="email"
                  hide-details
                  multiple
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.dataNotDisponse') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.rol }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </fragment>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="clientCarShop.id_cta_bancaria"
                :items="ctasBancariasComodin"
                :label="$t('register.accountBank')"
                outlined
                dense
                hide-details="auto"
                item-text="comodin"
                item-value="id"
                disabled
              ></v-select>
              <!--:disabled="ctasBancariasComodin.length === 1"-->
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-client-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="camposVerify"
                :loading="loadingBtn"
                @click="
                  setClientCarShop()
                  from === 'btn-print'
                    ? $emit('confirmPrintCotization')
                    : from === 'btn-send'
                      ? $emit('confirmSend')
                      : from === 'car-shop'
                        ? $emit('confirmPay')
                        : from === 'car-shop-reserve'
                          ? $emit('confirmSoliciteReserve')
                          : from === 'car-shop-print-cotization'
                            ? $emit('confirmSolicitePrint')
                            : from === 'car-shop-send-cotization'
                              ? $emit('confirmSend')
                              : from === 'car-shop-convert-to-reserve'
                                ? $emit('confirmConvertToReserve')
                                : from === 'car-shop-convert-to-reserve-pays'
                                  ? $emit('methodsPaysConvertToReserve')
                                  : ''
                "
              >
                {{ from === 'btn-print' ? $t('btn.print') : $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line
import { mapState } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarDataClientActive',
    event: 'update:is-sidebar-data-client-active',
  },
  props: {
    isSidebarDataClientActive: {
      type: Boolean,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    ctasBancarias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      clientsCorporate: [],
      itemsClientsCorporate: [],
      searchClientsCorporate: null,
      rols: [],
      user: {},
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
    }),
    camposVerify() {
      let rest = false
      if (!this.clientCarShop.is_client_corporate) {
        if (!this.clientCarShop.name || !this.clientCarShop.apellidos || !this.clientCarShop.email) {
          rest = true
        }
      } else if (!this.clientCarShop.name || this.clientCarShop.emails.length === 0) {
        rest = true
      }

      return rest
    },
    emailsCorporative() {
      const arrEmails = []
      if (this.clientCarShop.id_client_corporate) {
        const clientResult = this.clientsCorporate.filter(e => e.id === this.clientCarShop.id_client_corporate)
        if (clientResult.length > 0) {
          const { contacts } = clientResult[0]
          contacts.forEach(element => {
            let rol = ''
            if (this.rols.filter(e => e.id === element.role_id).length > 0) {
              rol = this.rols.filter(e => e.id === element.role_id)[0].name
            }
            const { emails } = element
            emails.forEach(email => {
              if (email.email) {
                arrEmails.push({
                  rol: `${rol} - ${email.email}`,
                  email: email.email,
                })
              }
            })
          })
        }
      }

      return arrEmails
    },
    ctasBancariasComodin() {
      const arr = []
      this.ctasBancarias.forEach(element => {
        if (element.principal) {
          this.clientCarShop.id_cta_bancaria = element.id
        }
        if (element.moneda) {
          arr.push({
            ...element,
            comodin: `${element.moneda}, ${element.name_banco}`,
          })
        }
      })

      return arr
    },
  },
  watch: {
    searchClientsCorporate(val) {
      if (val !== null && val.length > 0) {
        this.filterClientsCorporate(val.toLowerCase())
      }
    },
  },
  created() {
    this.profile()
    this.getClientsCorporate()
    this.getRols()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data

            // this.nameCompany = this.user.company.name
            // this.nameUser = `${this.user.name} ${this.user.apellidos}`
            // this.emailUser = `${this.user.email}`
          }
        })
    },
    filterClientsCorporate(v) {
      this.itemsClientsCorporate = []
      if (v === '') {
        this.itemsClientsCorporate = []
      } else {
        this.itemsClientsCorporate = this.clientsCorporate.filter(e => e.name_comercial.toLowerCase())
      }
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    getClientsCorporate() {
      this.axios
        .post(
          'affiliate/get-clients-corporate',
          { user_id: 0 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.clientsCorporate = res.data.data.clients
        })
    },
    changeTypeCLients() {
      if (!this.clientCarShop.is_client_corporate) {
        this.clientCarShop.id_client_corporate = null
        this.clientCarShop.name = null
        this.clientCarShop.email = null
      }
    },
    setCliensCorporate() {
      if (this.clientCarShop.id_client_corporate) {
        const clientResult = this.clientsCorporate.filter(e => e.id === this.clientCarShop.id_client_corporate)

        if (clientResult.length > 0) {
          this.clientCarShop.name = clientResult[0].name_comercial
          this.clientCarShop.email = clientResult[0].email_contact
          if (clientResult[0].contacts.length === 1) {
            if (clientResult[0].contacts[0].emails.length === 1) {
              if (clientResult[0].contacts[0].emails[0].email) {
                this.clientCarShop.emails = [clientResult[0].contacts[0].emails[0].email]
              }
            }
          }
        }
      }
    },
    setClientCarShop() {
      localStorage.setItem('clientCarShop', JSON.stringify(this.clientCarShop))
    },
  },
}
</script>
