<template>
  <v-card>
    <v-card-title>{{ $t('lbl.talkins') }}</v-card-title>
    <v-card-text>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >{{ chatBotRead.message | truncate(350) }} </span>
        </template>
        <span>{{ chatBotRead.message }}</span>
      </v-tooltip>
    </v-card-text>
    <!--<v-card-text>
      If you’re in the market for new desktops, notebooks, or PDAs, there are a myriad of choices. Here’s a rundown of some of the best systems available.
    </v-card-text>
    <v-card-text>
      The following non-standard attributes are available on some browsers.
    </v-card-text>-->
    <v-card-actions class="dense">
      <v-btn
        color="primary"
        text
        large
        @click="$router.push({ name: 'chatbot-talkings' })"
      >
        {{ $t('lbl.talkins4') }}
      </v-btn>
      <v-spacer></v-spacer>
      <span>{{ `${$t('lbl.talkins1')} ${chatBotRead.cant_not_read}` }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      chatBotRead: state => state.app.chatBotRead,
    }),
  },
}
</script>
