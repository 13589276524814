<template>
  <v-col
    cols="12"
    md="11"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-if="!isLoadingCategories"
          v-model="tarifarioFiltersHotels.slug_category_tarifa"
          :items="categoriesTarifariosFree"
          :label="$t('lbl.tarifa')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="slug"
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.tarifa') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <!--@change="filterTarifariosContrateHotel"-->
        <v-select
          v-model="tarifarioFiltersHotels.room_id"
          :items="roomsFree"
          :label="$t('lbl.rooms')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.room') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ `${item.name} (Code: ${item.mysql_id})` }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            {{ `${item.name} (Code: ${item.mysql_id})` }}
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="tarifarioFiltersHotels.plan"
          :items="planesFree"
          :label="$t('lbl.plan')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="siglas"
          item-value="siglas"
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.plan') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="tarifarioFiltersHotels.adult"
          :items="adults"
          label="ADL"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                ADL
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="tarifarioFiltersHotels.teen"
          :disabled="!hasTeen"
          :items="teens"
          label="TEEN"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                TEEN
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="tarifarioFiltersHotels.children"
          :items="childrens"
          label="MNR"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          :disabled="!hasMnr"
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                MNR
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="tarifarioFiltersHotels.babies"
          :items="babies"
          label="INF"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          :disabled="!hasInf"
          @change="filterTarifarios"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                INF
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFromDateFormatted"
              :label="$t('lbl.from')"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              class="mr-2"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="tarifarioFiltersHotels.from"
            no-title
            :min="new Date().toISOString().substr(0, 10)"
            locale="es"
            :show-current="false"
            @input="
              menuFrom = false
              menuTo = true
              tarifarioFiltersHotels.to = $moment(tarifarioFiltersHotels.from)
                .add('days', 7)
                .toDate()
                .toISOString()
                .substr(0, 10)
            "
            @change="filterTarifarios"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedToDateFormatted"
              :label="$t('lbl.to')"
              :disabled="tarifarioFiltersHotels.from === null"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              class="mr-2"
              v-on="on"
            ></v-text-field>
          </template>
          <!--.add(1, 'day')-->
          <v-date-picker
            v-model="tarifarioFiltersHotels.to"
            no-title
            locale="es"
            :scrollable="true"
            :show-current="false"
            :show-adjacent-months="false"
            :min="
              tarifarioFiltersHotels.from
                ? $moment(tarifarioFiltersHotels.from)
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : ''
            "
            @input="
              menuTo = false
            "
            @change="filterTarifarios"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesTarifarios: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    codeHotel: {
      type: String,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      ruleAdlMin: [v => !!v || 'Campo requerido', v => this.isOneOrZero(v) || 'Valor permitido 0 o 1'],
      ruleMnrMax: [v => !!v || 'Campo requerido', v => this.isInterger(v) || 'Valor debe ser >= 0'],
      rulePaxMax: [v => !!v || 'Campo requerido', v => this.isNumber(v) || 'Valor debe ser > 0'],
      adults: [],
      teens: [],
      childrens: [],
      babies: [],
      menuFrom: false,
      menuTo: false,
      categoriesTarifariosFree: [],
      isLoadingCategories: true,
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      tarifarioFiltersHotels: state => state.app.tarifarioFiltersHotels,
    }),
    computedFromDateFormatted() {
      return this.tarifarioFiltersHotels.from ? this.$moment(this.tarifarioFiltersHotels.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.tarifarioFiltersHotels.to ? this.$moment(this.tarifarioFiltersHotels.to).format('D-MMM-YY') : ''
    },
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          result.push(element)
        }
      })

      return result
    },

    planesFree() {
      const result = []

      const planesSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        if (rSeason.planes_id.length > 0) {
          rSeason.planes_id.forEach(element => {
            if (!planesSelect.includes(element)) {
              planesSelect.push(element)
            }
          })
        }

        // PARA LOS DIRECTOS
        if (rSeason.plan_id) {
          if (!planesSelect.includes(rSeason.plan_id)) {
            planesSelect.push(rSeason.plan_id)
          }
        }
      })

      const orderPlanes = []
      orderPlanes.push(this.planes.filter(e => e.siglas === 'EP')[0])
      orderPlanes.push(this.planes.filter(e => e.siglas === 'CP')[0])
      orderPlanes.push(this.planes.filter(e => e.siglas === 'MAP')[0])
      orderPlanes.push(this.planes.filter(e => e.siglas === 'AP')[0])
      orderPlanes.push(this.planes.filter(e => e.siglas === 'TI')[0])

      orderPlanes.forEach(elementPlan => {
        planesSelect.forEach(element => {
          if (element === elementPlan.id) {
            result.push(elementPlan)
          }
        })
      })

      return result
    },
    hasTeen() {
      let result = false

      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          result = true
        }
      })

      return result
    },
    hasMnr() {
      let result = false

      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_mnr) {
          result = true
        }
      })

      return result
    },
    hasInf() {
      let result = false

      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_inf) {
          result = true
        }
      })

      return result
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    // INICIALIZAR LOS FILTROS
    let plan = null
    let roomId = null
    if (this.roomsSeasonContrateHotel.length > 0) {
      if (this.roomsSeasonContrateHotel[0].plan_id) {
        plan = this.planes.filter(e => e.id === this.roomsSeasonContrateHotel[0].plan_id)[0].siglas
      } else if (this.roomsSeasonContrateHotel[0].planes_id) {
        if (this.roomsSeasonContrateHotel[0].planes_id.length > 0) {
          plan = this.planes.filter(e => e.id === this.roomsSeasonContrateHotel[0].planes_id[0])[0].siglas
        }
      }
      if (this.roomsSeasonContrateHotel[0].rooms_id) {
        // eslint-disable-next-line prefer-destructuring
        roomId = this.roomsSeasonContrateHotel[0].rooms_id[0]
      }
      const from = new Date().toISOString().substr(0, 10)
      const to = this.$moment(new Date())
        .add('days', 7)
        .toDate()
        .toISOString()
        .substr(0, 10)

      let cantMnr = 'N/A'
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_mnr) {
          cantMnr = 0
        }
      })
      let cantInf = 'N/A'
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_inf) {
          cantInf = 0
        }
      })
      this.updateTarifarioFiltersContrateHotel({
        slug_category_tarifa: 'solo-alojamiento',
        room_id: roomId,
        plan,
        adult: 2,
        teen: 'N/A',
        children: cantMnr,
        babies: cantInf,
        from,
        to,
      })

      // this.filterTarifariosContrateHotel()
      this.filterTarifarios()
    }

    this.adults.push('Todos')
    this.adults.push(0)
    this.adults.push(1)
    this.adults.push(2)
    this.adults.push(3)
    this.adults.push(4)
    this.adults.push(5)
    this.adults.push(6)
    this.adults.push(7)
    this.adults.push(8)
    this.adults.push(9)
    this.adults.push(10)
    this.adults.push('10+')

    // this.teens.push('N/A')
    this.teens.push('Todos')
    this.teens.push(1)
    this.teens.push(2)
    this.teens.push(3)
    this.teens.push(4)
    this.teens.push(5)
    this.teens.push(6)
    this.teens.push(7)
    this.teens.push(8)
    this.teens.push(9)
    this.teens.push(10)
    this.teens.push('10+')

    this.childrens.push('Todos')
    this.childrens.push(0)
    this.childrens.push(1)
    this.childrens.push(2)
    this.childrens.push(3)
    this.childrens.push(4)
    this.childrens.push(5)
    this.childrens.push(6)
    this.childrens.push(7)
    this.childrens.push(8)
    this.childrens.push(9)
    this.childrens.push(10)
    this.childrens.push('10+')

    this.babies.push('Todos')
    this.babies.push(0)
    this.babies.push(1)
    this.babies.push(2)
    this.babies.push(3)
    this.babies.push(4)
    this.babies.push(5)
    this.babies.push(6)
    this.babies.push(7)
    this.babies.push(8)
    this.babies.push(9)
    this.babies.push(10)
    this.babies.push('10+')

    this.verifyTarifas()
  },
  methods: {
    ...mapMutations([
      'updateTarifarioFiltersContrateHotel',
      'filterTarifariosContrateHotel',
      'updateTarifariosContrateHotel',
      'setLoadingTarifarios',
    ]),
    filterTarifarios() {
      this.setLoadingTarifarios(true)
      const json = {
        ...this.tarifarioFiltersHotels,
        code_hotel_externo: this.codeHotel,
      }
      this.axios
        .post('contrate_hotels/list-season-tarifarios', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === true) {
            this.updateTarifariosContrateHotel(response.data.tarifarios)
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.setLoadingTarifarios(false))
    },
    verifyTarifas() {
      const json = {
        code_hotel_externo: this.codeHotel,
      }
      this.axios
        .post('contrate_hotels/verify-tarifas-by-tarifarios', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success) {
            response.data.data.forEach(element => {
              if (this.categoriesTarifarios.filter(e => e.slug === element).length > 0) {
                this.categoriesTarifariosFree.push(this.categoriesTarifarios.filter(e => e.slug === element)[0])
              }
            })
          }
        })
        .finally(() => {
          this.isLoadingCategories = false
        })
    },

    /* filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    setRooms() {
      if (this.room.rooms_id) {
        this.roomsSeasonContrateHotel.forEach(rSeason => {
          if (rSeason.rooms_id.includes(this.room.rooms_id[0])) {
            if (rSeason.childAge.from === 'N/A' && rSeason.infantAge.from === 'N/A') {
              this.room.ocupations.childMax = 0
            }
          }
        })
      }

      // REAJUSTAR LAS POLITICAS DE NIÑOS
      this.reajustPoliticasChildSeasonContrateHotel()
    }, */
  },
}
</script>
