<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="$vuetify.theme.themes.light.primary"
        v-bind="attrs"
        disabled
        x-large
        v-on="on"
      >
        mdi-apple
      </v-icon>
    </template>
    <span>{{ $t('btn.dowload') }}</span>
  </v-tooltip>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      qr: null,
      isLoading: false,
      loadingDownload: false,
    }
  },
  methods: {
    downloadAPK() {
      this.loadingDownload = true
      const json = {
        ext: 'apk',
        id: this.app.id,
      }
      this.axios
        .post('apps/download', json, { responseType: 'arraybuffer' })
        .then(res => {
          const filename = this.app.app_name

          this.downloadFileAPK(res, filename)
        })
        .finally(() => {
          this.loadingDownload = false
        })
    },
    downloadFileAPK(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/apk' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.apk`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>
