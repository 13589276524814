<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
    ></v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="reduction.reduction_id"
        :disabled="$store.state.app.onlyShow"
        :items="reductionFree"
        :label="$t('lbl.reduccion')"
        outlined
        dense
        hide-details
        item-text="name"
        item-value="id"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.reduccion') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="room.reduction.length > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            v-bind="attrs"
            fab
            dark
            x-small
            color="error"
            v-on="on"
            @click="
              deleteReductionRoomByPriceBySuplementHotelOfertEBB({ pos: posHotel, posRoom: posRoom, posSup: pos })
            "
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>

      <v-tooltip
        v-if="pos === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addReductionRoomByPriceBySuplementHotelOfertEBB({ pos: posHotel, posRoom: posRoom })"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-text-field
        v-model="reduction.valueReduction"
        :label="$t('lbl.monto')"
        :disabled="$store.state.app.onlyShow"
        class="my-input"
        outlined
        dense
        hide-details
        type="number"
        :prefix="reduction.in_value_reduction ? '$' : ''"
        :suffix="!reduction.in_value_reduction ? '%' : ''"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-row class="mt-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="reduction.in_value_reduction"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.valor')"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reduction: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posRoom: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posHotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReduction: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    edad: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsRooms: [],
      calculators: [],
      menuFromViaje: false,
      menuToViaje: false,
      menuFromReserva: false,
      menuToReserva: false,
      menuRooming: false,
      menuFromPagar: false,
      menuFecha: false,
    }
  },
  computed: {
    ...mapState({
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      reduccionsSeasonContrateHotel: state => state.app.reduccionsSeasonContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
    }),
    reductionFree() {
      const reductionContrate = []
      this.allReduction.forEach(element => {
        /* let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.room.reduction.length; index++) {
          if (this.room.reduction[index].reduction_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          reductionContrate.push(element)
        } */
        reductionContrate.push(element)
      })
      let result = []

      if (this.onlyAdult) {
        reductionContrate.forEach(element => {
          if (
            element.slug !== 'infantes'
            && element.slug !== '1er-infante'
            && element.slug !== '2do-infante'
            && element.slug !== '1er-adolescente'
            && element.slug !== '2do-adolescente'
            && element.slug !== '1-adulto-con-1-nino-se-cobra-como-2-adultos-en-dbl'
            && element.slug !== 'ninos'
            && element.slug !== '1er-nino'
            && element.slug !== '1er-nino-con-1-adulto'
            && element.slug !== '1er-nino-con-2-adultos'
            && element.slug !== '2do-nino'
            && element.slug !== '2do-nino-con-1-adulto'
            && element.slug !== '2do-nino-con-2-adultos'
            && element.slug !== '2-ninos-solos-en-una-hab-pagan-precio-pax-en-sgl'
            && element.slug !== '2-ninos-solos-en-una-hab-pagan-precio-pax-en-dbl'
            && element.slug !== 'ninos-solos-en-la-habitacion'
            && element.slug !== 'ninos-solos-en-una-habitacion-el-1er-nino'
            && element.slug !== 'ninos-solos-en-una-habitacion-el-2do-nino'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-los-infantes'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-1er-infante'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-2do-infante'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-los-ninos'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-el-1er-nino'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-el-2do-nino'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-los-infantes'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-1er-infante'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-2do-infante'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-los-ninos'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-el-1er-nino'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-el-2do-nino'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
            && element.slug !== 'desayuno-para-ninos-cp'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'cena-para-ninos-map'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'alimentacion-full-board-para-ninos-ap'
          ) {
            result.push(element)
          }

          if ((element.slug === '1er-adolescente' || element.slug === '2do-adolescente') && this.edad <= 11) {
            result.push(element)
          }
        })
      } else {
        result = reductionContrate
      }

      return result
    },
  },
  methods: {
    ...mapMutations([
      'deleteReductionRoomByPriceBySuplementHotelOfertEBB',
      'addReductionRoomByPriceBySuplementHotelOfertEBB',
    ]),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
