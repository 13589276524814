import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
      states: [],
    }
  },
  computed: {
    ...mapState({
      hotelsContrateListCache: state => state.app.hotelsContrateListCache,
      hotelsContrateShowCache: state => state.app.hotelsContrateShowCache,
    }),
  },
  methods: {
    ...mapMutations([
      'setHotelsContrateListCache',
    ]),
    async fetchDataEndpoints() {
      const user = JSON.parse(localStorage.getItem('profile'))
      const jsonHotelsContrate = {
        page: 1,
        per_page: 10,
      }
      try {
        const [
          resHotelsContrate,
        ] = await Promise.all([
          this.axios
            .post('contrate_hotels/show-all', jsonHotelsContrate, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resHotelsContrate.data.success === false) { } else {
          // PARA ORDERNAR BY PROVEEDOR
          const hotelsContrate = resHotelsContrate.data.data.data.sort((a, b) => {
            let OrderA = a.identificador
            if (user.nivel <= 0) {
              if (a.operador === 1111) {
                OrderA = a.proveedor
              } else {
                OrderA = a.operador
              }
            } else {
              OrderA = a.proveedor
            }

            let OrderB = b.identificador
            if (user.nivel <= 0) {
              if (b.operador === 1111) {
                OrderB = b.proveedor
              } else {
                OrderB = b.operador
              }
            } else {
              OrderB = b.proveedor
            }

            const x = OrderA
            const y = OrderB

            if (x > y) {
              return 1
            }
            if (x < y) {
              return -1
            }

            return 0
          })

          this.setHotelsContrateListCache(hotelsContrate)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      // eslint-disable-next-line no-empty
      } finally { }
    },
  },
  mounted() {
    this.fetchDataEndpoints()
  },
}
