<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
      </div>
    </template>

    <!-- Options List @change="updateActiveLocale" -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
      >
        <v-list-item
          v-for="locale in availableLocales"
          :key="locale.locale"
          :value="locale.locale"
          @click="setLocale(locale.locale)"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
// import { loadLanguageAsync } from '@/plugins/i18n'
// eslint-disable-next-line import/no-unresolved
// import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    // const { isRtl } = useAppConfig()

    const locales = [
      {
        title: 'Español',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/es.png'),
        locale: 'es',
      },
      {
        title: 'Português',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/pt.png'),
        locale: 'pt',
      },
      {
        title: 'English',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Deutsch',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/de.png'),
        locale: 'de',
      },
      {
        title: 'Русский',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/ru.png'),
        locale: 'ru',
      },
      {
        title: 'Français',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/fr.png'),
        locale: 'fr',
      },

      /* {
        title: '中國人',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/ch.png'),
        locale: 'ch',
      }, */
    ]

    /* const updateActiveLocale = locale => {
      // Set to RTL mode if locale is arabic
      isRtl.value = locale === 'ar'

      loadLanguageAsync(locale)
    } */

    return {
      locales,

      // updateActiveLocale,
    }
  },
  data() {
    return {
      formLocales: [],
    }
  },
  computed: {
    availableLocales() {
      return this.locales.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale

      localStorage.setItem('lenguaje', locale)

      const json = {
        locale,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        })
    },
  },
}
</script>
