<template>
  <fragment>
    <v-container grid-list-xs>
      <v-row class="mb-1">
        <v-col
          cols="12"
          md="12"
        >
          <h2>{{ $t('lbl.conductorPrincipal') }}</h2>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.nameConductorPrincipal"
            :label="`${$t('lbl.name')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="textRequiered"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.apellidosConductorPrincipal"
            :label="`${$t('lbl.apellidos')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="textRequiered"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.nameSecoundConductorPrincipal"
            :label="$t('lbl.nameSecond')"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="cotizadorPaxCarSelect.nationalityConductorPrincipal"
            :items="itemsNationality"
            :search-input.sync="searchNationality"
            item-text="name"
            item-value="code"
            hide-selected
            :label="`${$t('lbl.nationality')} *`"
            outlined
            dense
            hide-details="auto"
            return-object
            @change="changeNationality"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.nationality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <!--:placeholder="isFromCuba ? $t('lbl.passport') : ''"-->
          <v-text-field
            v-model="cotizadorPaxCarSelect.noDocumentConductorPrincipal"
            :label="`${isFromCuba ? $t('lbl.passport') : $t('lbl.noDocument')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="alphanumericRules"
            @input="
              val =>
                (cotizadorPaxCarSelect.noDocumentConductorPrincipal = cotizadorPaxCarSelect.noDocumentConductorPrincipal.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="`${$t('lbl.birthday')} *`"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="cotizadorPaxCarSelect.birthdayConductorPrincipal"
              no-title
              :max="new Date(dateBirth).toISOString().substr(0, 10)"
              locale="es"
              :show-current="false"
              @input="
                menuFrom = false
                changeBithdayMain()
              "
            ></v-date-picker>
          </v-menu>
          <!--<vc-date-picker
            v-model="cotizadorPaxCarSelect.birthdayConductorPrincipal"
            outlined
            dense
            mode="date"
            :model-config="modelConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="`${$t('lbl.birthday')} *`"
                outlined
                dense
                hide-details="auto"
                :value="inputValue"
                :rules="textRequiered"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>-->
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-file-input
            v-model="documentConductorPrincipalScanner"
            :label="$t('lbl.docIdentidad')"
            accept="image/png, image/jpeg, image/bmp"
            outlined
            dense
            @change="changeDocumentCondutorPrincipal"
          ></v-file-input>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-switch
            v-model="cotizadorPaxCarSelect.conductorAditional"
            :label="$t('lbl.conductorAditional')"
            class="pb-0 mt-0"
            @change="reseatConductorAditional"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row
        v-if="cotizadorPaxCarSelect.conductorAditional"
        class="mb-1"
      >
        <v-col
          cols="12"
          md="12"
        >
          <h2>{{ $t('lbl.conductorAditional') }}</h2>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.nameConductorAditional"
            :label="`${$t('lbl.name')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="textRequiered"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.apellidosConductorAditional"
            :label="`${$t('lbl.apellidos')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="textRequiered"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cotizadorPaxCarSelect.nameSecoundConductorAditional"
            :label="$t('lbl.nameSecond')"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="cotizadorPaxCarSelect.nationalityConductorAditional"
            :items="itemsNationalityAditional"
            :search-input.sync="searchNationalityAditional"
            item-text="name"
            item-value="code"
            hide-selected
            :label="`${$t('lbl.nationality')} *`"
            outlined
            dense
            hide-details="auto"
            return-object
            @change="changeNationalityAditional"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.nationality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <!--
          :label="`${lblNoDocument} *`"
        -->
          <v-text-field
            v-model="cotizadorPaxCarSelect.noDocumentConductorAditional"
            :label="`${isFromCubaAdic ? $t('lbl.passport') : $t('lbl.noDocument')} *`"
            outlined
            dense
            hide-details="auto"
            :rules="alphanumericRules"
            @input="
              val =>
                (cotizadorPaxCarSelect.noDocumentConductorAditional = cotizadorPaxCarSelect.noDocumentConductorAditional.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :label="`${$t('lbl.birthday')} *`"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="cotizadorPaxCarSelect.birthdayConductorAditional"
              no-title
              :max="new Date(dateBirth).toISOString().substr(0, 10)"
              locale="es"
              :show-current="false"
              @input="
                menuTo = false
                changeBithdayMain()
              "
            ></v-date-picker>
          </v-menu>
          <!--<vc-date-picker
            v-model="cotizadorPaxCarSelect.birthdayConductorAditional"
            outlined
            dense
            mode="date"
            :model-config="modelConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="`${$t('lbl.birthday')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>-->
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-file-input
            v-model="documentConductorAdicionalScanner"
            :label="$t('lbl.docIdentidad')"
            accept="image/png, image/jpeg, image/bmp"
            outlined
            dense
            @change="changeDocumentCondutorAdicional"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiTimerOutline,
} from '@mdi/js'

// import FPasajero from './FPasajero.vue'

export default {
  /* components: {
    FPasajero,
  }, */
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      model: {},
      paxs: [],
      countries: [],
      countriesCallingCodes: [],
      itemsFilterCodePhone: [],
      searchFilterCodePhone: null,
      isSearchCountry: false,
      itemsNationality: [],
      searchNationality: null,
      itemsNationalityAditional: [],
      searchNationalityAditional: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiTimerOutline,
      },
      lblNoDocument: this.$t('lbl.noDocument'),
      user: {},
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [
        v => !!v || 'Campo requerido',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail debe ser válido',
      ],
      alphanumericRules: [
        v => !!v || 'Campo requerido',
        v => /^[a-zA-Z0-9]+$/.test(v) || 'Debe ser solo números y letras',
      ],
      phoneRules: [v => !!v || 'Campo requerido', v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      isFromCuba: false,
      isFromCubaAdic: false,
      documentConductorPrincipalScanner: null,
      documentConductorAdicionalScanner: null,
      menuFrom: false,
      menuTo: false,
      dateBirth: Date.now(),
      age: null,
      ageAditional: null,
    }
  },
  computed: {
    ...mapState({
      declineTerms: state => state.app.declineTerms,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
      noDocumentConductorPrincipalScanner: state => state.app.noDocumentConductorPrincipalScanner,
      noDocumentConductorAditionalScanner: state => state.app.noDocumentConductorAditionalScanner,
    }),
    computedFromDateFormatted() {
      return this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        ? this.$moment(this.cotizadorPaxCarSelect.birthdayConductorPrincipal).format('D-MMM-YY')
        : ''
    },

    computedToDateFormatted() {
      return this.cotizadorPaxCarSelect.birthdayConductorAditional
        ? this.$moment(this.cotizadorPaxCarSelect.birthdayConductorAditional).format('D-MMM-YY')
        : ''
    },
  },
  watch: {
    searchNationality(val) {
      this.filterNationality(val.toLowerCase())
    },
    searchNationalityAditional(val) {
      this.filterNationalityAditional(val.toLowerCase())
    },
    searchFilterCodePhone(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodePhone(val.toLowerCase())
      } else {
        this.itemsFilterCodePhone = []
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-constant-condition
    // if (this.cotizadorCarsResultSelect.countryRecogida === 'cuba') {
    //   this.lblNoDocument = this.$t('lbl.passport')
    //   this.isFromCuba = true
    // }
  },
  created() {
    // PARA ABRIR LAS FECHA 10 AÑOS ATRAS
    const d = new Date()
    // eslint-disable-next-line no-unused-vars
    const date = d.setFullYear(d.getFullYear() - 10)

    // this.dateBirth = new Date(date)

    this.profile()
    this.getCountries()
    this.getCountriesCallingCodes()
  },
  methods: {
    ...mapMutations([
      'deleteDestinoCotizadorFligth',
      'setSearchingFlightCotizador',
      'setCotizadorPaxFlightSelect',
      'setNoDocumentConductorPrincipalScanner',
      'setNoDocumentConductorAdicionalScanner',
      'updateDriverAgeCumple',
    ]),
    changeBithdayMain() {
      if (this.cotizadorPaxCarSelect.birthdayConductorPrincipal) {
        this.age = Math.abs(
          this.$moment(this.$moment(this.cotizadorCars.dateRecogida, 'YYYY-MM-DD')).diff(
            this.$moment(this.cotizadorPaxCarSelect.birthdayConductorPrincipal, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        )

        if (this.cotizadorCarsResultSelect.edad) {
          if (!this.cotizadorCarsResultSelect.edad_max) {
            if (this.cotizadorCarsResultSelect.edad > this.age) {
              this.$toast.error(this.$t('msg.edadDriver1', { edad: this.cotizadorCarsResultSelect.edad }))
              this.updateDriverAgeCumple(false)
            } else {
              this.updateDriverAgeCumple(true)
            }
          } else if (
            this.cotizadorCarsResultSelect.edad > this.age
            || this.cotizadorCarsResultSelect.edad_max <= this.age
          ) {
            this.$toast.error(
              this.$t('msg.edadDriver2', {
                edad1: this.cotizadorCarsResultSelect.edad,
                edad2: this.cotizadorCarsResultSelect.edad_max,
              }),
            )
            this.updateDriverAgeCumple(false)
          } else {
            this.updateDriverAgeCumple(true)
          }
        } else {
          this.updateDriverAgeCumple(true)
        }
      }
      if (this.cotizadorPaxCarSelect.birthdayConductorAditional) {
        this.ageAditional = Math.abs(
          this.$moment(this.$moment(this.cotizadorCars.dateRecogida, 'YYYY-MM-DD')).diff(
            this.$moment(this.cotizadorPaxCarSelect.birthdayConductorAditional, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        )

        if (this.cotizadorCarsResultSelect.edad) {
          if (!this.cotizadorCarsResultSelect.edad_max) {
            if (this.cotizadorCarsResultSelect.edad > this.ageAditional) {
              this.$toast.error(this.$t('msg.edadDriver1', { edad: this.cotizadorCarsResultSelect.edad }))
              this.updateDriverAgeCumple(false)
            } else {
              this.updateDriverAgeCumple(true)
            }
          } else if (
            this.cotizadorCarsResultSelect.edad > this.ageAditional
            || this.cotizadorCarsResultSelect.edad_max <= this.ageAditional
          ) {
            this.$toast.error(
              this.$t('msg.edadDriver2', {
                edad1: this.cotizadorCarsResultSelect.edad,
                edad2: this.cotizadorCarsResultSelect.edad_max,
              }),
            )
            this.updateDriverAgeCumple(false)
          } else {
            this.updateDriverAgeCumple(true)
          }
        } else {
          this.updateDriverAgeCumple(true)
        }
      }

      // PARA VALIDAR EL SEGURO DE EDAD DEL CONDUCTOR
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.cotizadorCarsResultSelect.suplementos.length; i++) {
        if (this.cotizadorCarsResultSelect.suplementos[i].suplemento) {
          if (this.cotizadorCarsResultSelect.suplementos[i].suplemento.slug === 'seguro-extra-por-edad-del-conductor') {
            if (this.cotizadorCarsResultSelect.suplementos[i].seguros_edad_conductor) {
              this.cotizadorCarsResultSelect.suplementos[i].seguros_edad_conductor.forEach(seguro => {
                let arrAge = -1
                if (this.cotizadorCarsResultSelect.category_id) {
                  arrAge = this.cotizadorCarsResultSelect.category_id.id
                }
                if (seguro.category_id.includes(-1) || seguro.category_id.includes(arrAge)) {
                  // eslint-disable-next-line camelcase
                  const { edad_min } = seguro
                  // eslint-disable-next-line camelcase
                  const { edad_max } = seguro

                  if (this.age) {
                    // eslint-disable-next-line camelcase
                    if (this.age < edad_min || this.age > edad_max) {
                      this.cotizadorCarsResultSelect.suplementos[
                        i
                      ].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin
                    } else {
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                    }
                  }
                  if (this.ageAditional) {
                    // eslint-disable-next-line camelcase
                    if (this.ageAditional < edad_min || this.ageAditional > edad_max) {
                      let number = 1
                      if (this.age) {
                        // eslint-disable-next-line camelcase
                        if (this.age < edad_min || this.age > edad_max) {
                          number = 2
                        }
                      }
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin * number
                    } else if (this.age) {
                      // eslint-disable-next-line camelcase
                      if (this.age < edad_min || this.age > edad_max) {
                        this.cotizadorCarsResultSelect.suplementos[
                          i
                        ].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin
                      } else {
                        this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                      }
                    } else {
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                    }
                  }
                }
              })
            }
          }
        }
      }
    },
    filterNationality(v) {
      this.itemsNationality = []
      if (v === '') {
        this.itemsNationality = []
      } else {
        this.itemsNationality = this.countries.filter(e => e.name.toLowerCase())
      }
    },
    changeNationality() {
      if (this.cotizadorPaxCarSelect.nationalityConductorPrincipal) {
        if (this.cotizadorPaxCarSelect.nationalityConductorPrincipal.code === 'cu') {
          this.isFromCuba = true
        } else {
          this.isFromCuba = false
        }
      }
    },
    filterNationalityAditional(v) {
      this.itemsNationalityAditional = []
      if (v === '') {
        this.itemsNationalityAditional = []
      } else {
        this.itemsNationalityAditional = this.countries.filter(e => e.name.toLowerCase())
      }
    },
    changeNationalityAditional() {
      if (this.cotizadorPaxCarSelect.nationalityConductorAditional) {
        if (this.cotizadorPaxCarSelect.nationalityConductorAditional.code === 'cu') {
          this.isFromCubaAdic = true
        } else {
          this.isFromCubaAdic = false
        }
      }
    },
    filterCodePhone(v) {
      this.itemsFilterCodePhone = []
      if (v === '') {
        this.itemsFilterCodePhone = []
      } else {
        this.itemsFilterCodePhone = this.countriesCallingCodes.filter(e => e.code_phone)
      }
    },
    getCountries() {
      this.axios
        .get('countries', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.countries = res.data.data.data
        })
    },
    getCountriesCallingCodes() {
      this.axios
        .get('https://restcountries.com/v2/all?fields=name,alpha2Code,callingCodes', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res.data)
          res.data.forEach(element => {
            const codes = element.callingCodes
            codes.forEach(elementCode => {
              if (!this.countriesCallingCodes.includes(elementCode)) {
                // eslint-disable-next-line radix
                this.countriesCallingCodes.push({
                  code_phone: parseInt(elementCode, 10),
                  country_code: element.alpha2Code.toLowerCase(),
                })
              }
            })
          })
        })

      // .finally(() => this.countriesCallingCodes.sort())
    },
    searchPrefijo() {
      if (this.cotizadorPaxCarSelect.nationalityConductorPrincipal) {
        if (this.cotizadorPaxCarSelect.nationalityConductorPrincipal.code !== '') {
          this.isSearchCountry = true

          this.itemsFilterCodePhone = this.countriesCallingCodes.filter(
            e => e.country_code.toLowerCase() === this.cotizadorPaxCarSelect.nationalityConductorPrincipal.code,
          )

          setTimeout(() => {
            this.isSearchCountry = false
          }, 100)
        }
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          this.user = response.data.data
        })
        .catch(error => console.log(error))
    },

    changeDocumentCondutorPrincipal() {
      if (this.documentConductorPrincipalScanner) {
        const formData = new FormData()
        formData.append('noDocumentConductorScanner', this.documentConductorPrincipalScanner)

        this.axios
          .post('reservations/upload-scanner', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res.data.data.dir)
            this.cotizadorPaxCarSelect.noDocumentConductorPrincipalScanner = res.data.data.dir
          })

        // this.setNoDocumentConductorPrincipalScanner(this.documentConductorPrincipalScanner)
      } else {
        this.cotizadorPaxCarSelect.noDocumentConductorPrincipalScanner = null

        // this.setNoDocumentConductorPrincipalScanner(null)
      }
    },
    changeDocumentCondutorAdicional() {
      if (this.documentConductorAdicionalScanner) {
        const formData = new FormData()
        formData.append('noDocumentConductorScanner', this.documentConductorAdicionalScanner)

        this.axios
          .post('reservations/upload-scanner', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res.data.data.dir)
            this.cotizadorPaxCarSelect.noDocumentConductorAditionalScanner = res.data.data.dir
          })

        // this.setNoDocumentConductorAdicionalScanner(this.documentConductorAdicionalScanner)
      } else {
        this.cotizadorPaxCarSelect.noDocumentConductorAditionalScanner = null

        // this.setNoDocumentConductorAdicionalScanner(null)
      }
    },

    reseatConductorAditional() {
      // DESMARCAR EL SUPLEMENTO DE CONDUCTOR ADICIONAL
      if (!this.cotizadorPaxCarSelect.conductorAditional) {
        this.cotizadorPaxCarSelect.nameConductorAditional = null
        this.cotizadorPaxCarSelect.apellidosConductorAditional = null
        this.cotizadorPaxCarSelect.nameSecoundConductorAditional = null
        this.cotizadorPaxCarSelect.birthdayConductorAditional = null
        this.cotizadorPaxCarSelect.nationalityConductorAditional = null
        this.cotizadorPaxCarSelect.sexConductorAditional = null
        this.cotizadorPaxCarSelect.noDocumentConductorAditional = null
        this.ageAditional = null

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.cotizadorCarsResultSelect.suplementos.length; index++) {
          const element = this.cotizadorCarsResultSelect.suplementos[index]

          if (element.suplemento.slug === 'conductor-extra') {
            this.cotizadorCarsResultSelect.tarifaAllDay -= element.priceAll
            this.cotizadorCarsResultSelect.priceTotal -= element.priceAll
            this.cotizadorCarsResultSelect.priceWithMarkup -= element.priceAll
            this.cotizadorCarsResultSelect.priceWithDescuento -= element.priceAll
            // eslint-disable-next-line no-param-reassign
            this.cotizadorCarsResultSelect.suplementos[index].check = false
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.cotizadorCarsResultSelect.suplementos.length; index++) {
          const element = this.cotizadorCarsResultSelect.suplementos[index]

          if (element.suplemento.slug === 'conductor-extra') {
            this.cotizadorCarsResultSelect.tarifaAllDay += element.priceAll
            this.cotizadorCarsResultSelect.priceTotal += element.priceAll
            this.cotizadorCarsResultSelect.priceWithMarkup += element.priceAll
            this.cotizadorCarsResultSelect.priceWithDescuento += element.priceAll
            // eslint-disable-next-line no-param-reassign
            this.cotizadorCarsResultSelect.suplementos[index].check = true
          }
        }
      }

      setTimeout(() => {
        this.changeBithdayMain()
      }, 200)
    },

    reserveNow() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        this.loading = true
        const suplementos = []
        this.cotizadorCarsResultSelect.suplementos.forEach(element => {
          if (element.check) {
            suplementos.push(element)
          }
        })
        const jsonItem = {
          car: this.cotizadorCarsResultSelect.car,
          category_id: this.cotizadorCarsResultSelect.category_id,
          marca_id: this.cotizadorCarsResultSelect.marca_id,
          contrate_id: this.cotizadorCarsResultSelect.contrate_id,
          cuposDisponibles: this.cotizadorCarsResultSelect.cuposDisponibles,
          diasReservar: this.cotizadorCarsResultSelect.diasReservar,
          priceTotal: this.cotizadorCarsResultSelect.priceTotal,
          priceWithDescuento: this.cotizadorCarsResultSelect.priceWithDescuento,
          priceWithMarkup: this.cotizadorCarsResultSelect.priceWithMarkup,
          rentadora: this.cotizadorCarsResultSelect.rentadora,
          tarifaAllDay: this.cotizadorCarsResultSelect.tarifaAllDay,
          tarifaDay: this.cotizadorCarsResultSelect.tarifaDay,
          tarifaDayExtra: this.cotizadorCarsResultSelect.tarifaDayExtra,
          tarifaGarantia: this.cotizadorCarsResultSelect.tarifaGarantia,
          tarifaSeguro: this.cotizadorCarsResultSelect.tarifaSeguro,
          suplementos,
        }

        let recogida
        if (this.cotizadorCars.recogida.type === 'oficina') {
          recogida = this.cotizadorCars.recogida
        } else if (this.cotizadorCars.recogida.type === 'destino') {
          recogida = this.cotizadorCarsResultSelect.recogida
        }

        let entrega
        if (this.cotizadorCars.entrega) {
          if (this.cotizadorCars.entrega.type === 'oficina') {
            entrega = this.cotizadorCars.entrega
          } else if (this.cotizadorCars.entrega.type === 'destino') {
            entrega = this.cotizadorCarsResultSelect.entrega
          }
        } else {
          entrega = recogida
        }

        const jsonReserve = {
          entrega,
          dateEntrega: this.cotizadorCars.dateEntrega,
          recogida,
          dateRecogida: this.cotizadorCars.dateRecogida,
          entregaDiffRecogida: this.cotizadorCars.entregaDiffRecogida,
          items: jsonItem,
        }

        const markup = {
          prices: this.cotizadorCarsResultSelect,
        }
        console.log(markup)

        const json = {
          product: 'cars',
          data_client: this.cotizadorPaxCarSelect,
          data_markups: this.cotizadorCarsResultSelect,

          // data_markups: this.cotizadorCarsResultSelect,
          data_service: jsonReserve,
          data_reserva: {},
          lang: this.$i18n.locale,
          user_id: this.user.id,
        }

        // console.log(json)

        const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }

        formData.append('data', JSON.stringify(json))

        /* this.axios
          .post(
            'reservations/generate',
            formData,
            { responseType: 'arraybuffer' },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            // console.log(res)
            let filename = 'cotization'
            filename = `${this.cotizadorPaxCarSelect.nameConductorPrincipal} ${
              this.cotizadorPaxCarSelect.apellidosConductorPrincipal
            }_Autos_${this.$moment().format('M-D-Y')}`
            filename = filename.replace(/ /g, '-')

            this.downloadFile(res, filename)

            this.$router.push({ name: 'success-cotitation' })
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          }) */

        this.loading = false
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }

      // console.log(this.model)
      /* this.loading = true
      // eslint-disable-next-line camelcase
      let ticket_number = ''
      if (this.model.reserve_confirm_ticket_number) {
        // eslint-disable-next-line camelcase
        ticket_number = this.model.reserve_confirm_ticket_number
      }
      // eslint-disable-next-line camelcase
      let locator = ''
      if (this.model.reserve_confirm_locator) {
        // eslint-disable-next-line camelcase
        locator = this.model.reserve_confirm_locator
      }
      let pnr = ''
      if (this.model.reserve_confirm_pnr) {
        // eslint-disable-next-line camelcase
        pnr = this.model.reserve_confirm_pnr
      }

      const itemsReserve = []
      if (this.cotizadorFlightSelect.onlyIda) {
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_ida.id_contrate,
            flight: this.cotizadorFlightSelect.item_ida.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa],
          },
        })
      } else if (!this.cotizadorFlightSelect.onlyIda && !this.cotizadorFlightSelect.destinosMultiples) {
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_ida.id_contrate,
            flight: this.cotizadorFlightSelect.item_ida.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa],
          },
        })
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_regreso.id_contrate,
            flight: this.cotizadorFlightSelect.item_regreso.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_regreso.tarifas_contrate[this.cotizadorFlightSelect.indRegreso],
          },
        })
      } else if (this.cotizadorFlightSelect.destinosMultiples) {
        this.cotizadorFlightSelect.destinos.forEach(element => {
          itemsReserve.push({
            origen: element.origen,
            destino: element.destino,
            idOrigen: element.idOrigen,
            idDestino: element.idDestino,
            from: element.from,
            to: element.to,
            flights: {
              id_contrate: element.flights[element.indexTarifa].id_contrate,
              from: element.flights[element.indexTarifa].from,
              to: element.flights[element.indexTarifa].to,
              flight: element.flights[element.indexTarifa].flight,
              tarifas_contrate: element.flights[element.indexTarifa].tarifas_contrate[element.indTarifa],
            },
          })
        })
      }
      const jsonReserve = {
        cant_adults: this.cotizadorFlightSelect.cant_adults,
        cant_ninnos: this.cotizadorFlightSelect.cant_ninnos,
        cant_infant: this.cotizadorFlightSelect.cant_infant,
        onlyIda: this.cotizadorFlightSelect.onlyIda,
        destinosMultiples: this.cotizadorFlightSelect.destinosMultiples,
        destinos: this.cotizadorFlightSelect.destinos,
        items: itemsReserve,
      }
      const json = {
        product: 'flights',
        data_client: this.cotizadorPaxFlightSelect,
        data_markups: this.cotizadorFlightSelectMarkups,
        data_service: jsonReserve,
        data_reserva: {
          ticket_number,
          locator,
          pnr,
        },
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }
      let procesar = true
      this.cotizadorPaxFlightSelect.forEach(element => {
        if (element.name === null || element.apellidos === null || element.nationality === null
            || element.sex === null || element.noDocument === null || element.dateCaduque === null || element.contact_email === null) {
          procesar = false
        }
      })

      if (procesar) {
        this.axios
          .post('reservations/generate', json,
            { responseType: 'arraybuffer' }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
          .then(res => {
            // console.log(res)
            let filename = 'cotization'
            if (this.cotizadorPaxFlightSelect.length > 0) {
              filename = `${this.cotizadorPaxFlightSelect[0].name} ${this.cotizadorPaxFlightSelect[0].apellidos}_Vuelos_${this.$moment().format('M-D-Y')}`
              filename = filename.replace(/ /g, '-')
            }

            this.downloadFile(res, filename)

            this.$router.push({ name: 'success-cotitation' })
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      } */
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
