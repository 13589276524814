<template>
  <v-col cols="12">
    <v-row :class="pos > 0 ? 'mt-10' : ''">
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="politica.rooms_id"
              disabled
              :items="allRooms"
              :label="$t('lbl.rooms')"
              class="my-input mr-2"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.room') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="7"
        offset-md="1"
      >
        <v-row>
          <v-col
            cols="4"
            offset-md="4"
          >
            <b v-if="pos === 0">{{ $t('lbl.equipaje_personal.para') }}: </b>
            <v-radio-group
              v-if="pos === 0"
              v-model="politica.applyBy"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                v-for="(ap, ind) in apply"
                :key="ind"
                :label="ap.name"
                :value="ap.slug"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="2"
            class="text-right"
            style="text-align: right"
          >
            <v-switch
              v-if="pos === 0"
              v-model="politica.is_tarifa"
              :label="$t('lbl.tarifa')"
              hide-details
              class="pt-4 mt-0"
              @click="setTarifa"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <v-col
        cols="12"
        md="4"
      >
        <!--COUNT-->
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="politica.adl.count"
              disabled
              label="ADL"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="politica.teen.count"
              disabled
              label="TEEN"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="politica.mnr.count"
              disabled
              label="MNR"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="politica.inf.count"
              disabled
              label="INF"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="7"
        offset-md="1"
      >
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="politica.adl.base"
              :disabled="$store.state.app.onlyShow || politica.na || politica.adl.count === 0"
              :items="basesFree"
              label="ADL paga como"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    ADL paga como
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="politica.teen.count === 0"
            cols="12"
            md="3"
          >
            <v-switch
              v-model="politica.teen_free"
              label="Teen FREE"
              hide-details
              class="pt-0 mt-0"
              :disabled="politica.teen.count === 0 || politica.teen.count === 'N/A'"
              @click="setTeenFree"
            ></v-switch>
          </v-col>

          <v-col
            v-if="politica.mnr.count > 0 || politica.inf.count > 0"
            cols="12"
            md="3"
          >
            <v-switch
              v-model="politica.mnr_free"
              label="Mnr FREE"
              hide-details
              class="pt-0 mt-0"
              :disabled="politica.mnr.count === 0 || politica.mnr.count === 'N/A'"
              @click="setMnrFree"
            ></v-switch>
          </v-col>

          <v-col
            v-if="politica.mnr.count > 0 || politica.inf.count > 0"
            cols="12"
            md="3"
          >
            <v-switch
              v-model="politica.inf_free"
              label="Inf FREE"
              hide-details
              class="pt-0 mt-0"
              :disabled="politica.inf.count === 0 || politica.inf.count === 'N/A'"
              @click="setInfFree"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-switch
              v-model="politica.na"
              label="N/A"
              hide-details
              class="pt-0 mt-0"
              @click="setNA"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <v-col
        cols="12"
        md="1"
        offset-md="3"
        class="text-right"
        style="text-align: right"
      >
        <!--<v-switch
          v-model="politica.is_tarifa"
          :label="$t('lbl.tarifa')"
          hide-details
          class="pt-0 mt-0"
          @click="setTarifa"
        ></v-switch>-->
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-row>
          <v-col
            cols="12"
            md="11"
            offset-md="1"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(season, indR) in seasonsContrateHotel"
                :key="indR"
                style="max-width: 90px; margin-left: 20px; margin-bottom: 10px"
                class="pt-0 mt-0"
              >
                <v-text-field
                  v-model="season.name"
                  class="my-input"
                  hide-details
                  disabled
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--ADL--
    <SeasonPoliticaChildAdlHotel
      v-if="politica.adl.count > 0"
      :politica="politica"
      :bases="bases"
    />-->

    <!--MNRs-->
    <template v-if="!politica.is_tarifa">
      <SeasonPoliticaChildMnrHotel
        v-for="(mnr, indM) in politica.mnrs"
        :key="indM"
        :politica="politica"
        :bases="bases"
        :mnr="mnr"
        :pos-politica="pos"
        :pos="indM"
        class="pt-0 mt-0"
      />
    </template>

    <!--MNRs Tarifas-->
    <template v-if="politica.is_tarifa">
      <SeasonPoliticaChildMnrTarifaHotel
        v-for="(mnr, indM) in politica.mnrs_rooms"
        :key="indM"
        :politica="politica"
        :all-rooms="allRooms"
        :mnr="mnr"
        class="pt-0 mt-0"
      />
    </template>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import SeasonNamesHotelTarifa from './SeasonNamesHotelTarifa.vue'
import SeasonPoliticaChildAdlHotel from './SeasonPoliticaChildAdlHotel.vue'
import SeasonPoliticaChildMnrHotel from './SeasonPoliticaChildMnrHotel.vue'
import SeasonPoliticaChildMnrTarifaHotel from './SeasonPoliticaChildMnrTarifaHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonNamesHotelTarifa,
    // eslint-disable-next-line vue/no-unused-components
    SeasonPoliticaChildAdlHotel,
    SeasonPoliticaChildMnrHotel,
    SeasonPoliticaChildMnrTarifaHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    politica: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),

      // allSuplement: [],
      // allRooms: [],
      itemsRooms: [],

      itemsSuplement: [],
      searchSuplement: null,
      bases: [],
      apply: [],
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      politicasChildSeasonContrateHotel: state => state.app.politicasChildSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),

    basesFree() {
      const arr = []
      this.bases.forEach(element => {
        if (this.politica.na || this.politica.adl.count === 0) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (element.slug !== 'N/A') {
          arr.push(element)
        }
      })

      return arr
    },
    hasTeen() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          has = true
        }
      })

      return has
    },
    hasMnr() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_mnr || element.has_a_inf) {
          has = true
        }
      })

      return has
    },
  },
  watch: {
    searchSuplement(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterSuplement(val.toLowerCase())
      }
    },
  },
  created() {
    this.apply.push({
      name: 'PPPN',
      slug: 'pppn',
    })
    this.apply.push({
      name: 'PPHN',
      slug: 'pphn',
    })

    this.bases.push({
      name: 'N/A',
      slug: 'N/A',
    })
    this.bases.push({
      name: 'ADL en DBL',
      slug: 'adl_in_dbl',
    })
    this.bases.push({
      name: 'ADL en SGL',
      slug: 'adl_in_sgl',
    })
    this.bases.push({
      name: 'Habitación DBL',
      slug: 'room_dbl',
    })
    this.bases.push({
      name: 'Habitación SGL',
      slug: 'room_sgl',
    })

    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }
  },
  methods: {
    ...mapMutations([
      'deleteSuplementsAlimenticiosSeasonContrateHotel',
      'addSuplementsAlimenticiosSeasonContrateHotel',
      'replicaSuplementsAlimenticiosSeasonContrateHotel',
    ]),
    setTarifa() {
      if (this.politica.is_tarifa) {
        // eslint-disable-next-line no-plusplus
        for (let poly = 0; poly < this.politicasChildSeasonContrateHotel.length; poly++) {
          this.politicasChildSeasonContrateHotel[poly].is_tarifa = true
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.politicasChildSeasonContrateHotel[poly].mnrs.length; index++) {
            this.politicasChildSeasonContrateHotel[poly].mnrs[index].in_pc = false

            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_teen) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'N/A'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = 'FREE'
              }
            }
            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_mnr) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'N/A'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = 'FREE'
              }
            }
            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_inf) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'N/A'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = 'FREE'
              }
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let poly = 0; poly < this.politicasChildSeasonContrateHotel.length; poly++) {
          this.politicasChildSeasonContrateHotel[poly].is_tarifa = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.politicasChildSeasonContrateHotel[poly].mnrs.length; index++) {
            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_teen) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'adl_in_dbl'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = null
              }
            }
            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_mnr) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'adl_in_dbl'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = null
              }
            }
            if (this.politicasChildSeasonContrateHotel[poly].mnrs[index].is_inf) {
              this.politicasChildSeasonContrateHotel[poly].mnrs[index].base = 'adl_in_dbl'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices.length; j++) {
                this.politicasChildSeasonContrateHotel[poly].mnrs[index].prices[j].price = null
              }
            }
          }
        }
      }
    },
    checkPricePc(elEvento, model, inPc) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (inPc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!inPc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (elEvento.key === '.' && model.length === 0) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },

    setTeenFree() {
      if (this.politica.teen_free) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_teen) {
            this.politica.mnrs[index].base = 'N/A'
            this.politica.mnrs[index].in_pc = false
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = 'FREE'
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_teen) {
            this.politica.mnrs[index].base = 'adl_in_dbl'
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = null
            }
          }
        }
      }
    },
    setMnrFree() {
      if (this.politica.mnr_free) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_mnr) {
            this.politica.mnrs[index].base = 'N/A'
            this.politica.mnrs[index].in_pc = false
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = 'FREE'
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_mnr) {
            this.politica.mnrs[index].base = 'adl_in_dbl'
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = null
            }
          }
        }
      }
    },
    setInfFree() {
      if (this.politica.inf_free) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_inf) {
            this.politica.mnrs[index].base = 'N/A'
            this.politica.mnrs[index].in_pc = false
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = 'FREE'
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          if (this.politica.mnrs[index].is_inf) {
            this.politica.mnrs[index].base = 'adl_in_dbl'
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
              this.politica.mnrs[index].prices[j].price = null
            }
          }
        }
      }
    },
    setNA() {
      if (this.politica.na) {
        this.politica.adl.base = 'N/A'
        // eslint-disable-next-line no-plusplus
        for (let a = 0; a < this.politica.adl.prices.length; a++) {
          this.politica.adl.prices[a].price = 'N/A'
        }
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          this.politica.mnrs[index].base = 'N/A'
          this.politica.mnrs[index].in_pc = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
            this.politica.mnrs[index].prices[j].price = 'N/A'
          }
        }
      } else {
        this.politica.adl.base = 'adl_in_dbl'
        // eslint-disable-next-line no-plusplus
        for (let a = 0; a < this.politica.adl.prices.length; a++) {
          this.politica.adl.prices[a].price = null
        }
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.politica.mnrs.length; index++) {
          this.politica.mnrs[index].base = 'adl_in_dbl'
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.politica.mnrs[index].prices.length; j++) {
            this.politica.mnrs[index].prices[j].price = null
          }
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
