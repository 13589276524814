<template>
  <fragment></fragment>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
    }
  },
  mounted() {
    // CONFIG
    this.loadDataGeneralSite()

    // DASHBOARD
    this.getSales()
    this.getContratesHotels()
    this.getCheckDashboard()
  },
  methods: {
    ...mapMutations([
      'setConfigDataGeneral',
    ]),

    // CONFIG SITE
    async loadDataGeneralSite() {
      await this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            const jsonConfig = {
              color: res.data.data.data.data.color,
              favicon: res.data.data.data.data.favicon,
              isotipo: res.data.data.data.data.isotipo,
              logo: res.data.data.data.data.logo,
              name: res.data.data.data.data.name,
            }
            if (res.data.data.data.data.favicon) {
              const favicon = document.getElementById('favicon')
              favicon.href = `${this.rutaPublic}${res.data.data.data.data.favicon}`
            }

            if (res.data.data.data.data.name) {
              const ppp = 'Panel de Administración / '
              document.title = this.nameProject === 'wwwww'
                ? `${ppp}${res.data.data.data.data.name}, plataforma de viajes`
                : `${ppp}${res.data.data.data.data.name}`
            }
            this.setConfigDataGeneral(jsonConfig)
            this.$vuetify.theme.themes.light.primary = res.data.data.data.data.color
            localStorage.setItem('configData', JSON.stringify(jsonConfig))
          }
        })
    },

    // DASHBOARD
    async getSales() {
      await this.axios
        .post('sales/sales-mounth')
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            localStorage.setItem('salesMonth', JSON.stringify(res.data))
          }
        })
    },
    async getContratesHotels() {
      await this.axios
        .post('contrate_hotels/pendients', { users: 'all' })
        .then(res => {
          localStorage.setItem('contratesHotelsAll', JSON.stringify(res.data))
        })
    },
    async getCheckDashboard() {
      await this.axios
        .get('dashboard_cards')
        .then(res => {
          localStorage.setItem('modulesCheck', JSON.stringify(res.data.data.notifications))
        })
    },
  },
}
</script>
