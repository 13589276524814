<template>
  <div>
    <template v-if="isLoading">
      <v-tabs
        v-model="pos"
        icons-and-text
      >
        <v-tab
          v-for="i in 3"
          :key="i"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-tab>
      </v-tabs>
      <v-card>
        <v-card-text>
          <v-tabs-items v-model="pos">
            <v-tab-item
              v-for="j in 3"
              :key="j"
            >
              <v-form class="multi-col-validation mt-1">
                <v-container
                  grid-list-xs
                >
                  <v-row
                    no-gutters
                    class="py-0"
                  >
                    <v-col
                      v-for="k in 3"
                      :key="k"
                      cols="4"
                      class="pr-0"
                    >
                      <v-skeleton-loader
                        class="mx-auto"
                        type="heading"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-tabs
        v-model="pos"
        icons-and-text
      >
        <v-tab
          v-if="products.includes('vuelos')"
          @click="setPosCotizador(0)"
        >
          <v-icon>{{ icons.mdiAirplane }}</v-icon>
        </v-tab>
        <v-tab
          v-if="products.includes('cars')"
          @click="setPosCotizador(1)"
        >
          <v-icon>{{ icons.mdiCar }}</v-icon>
        </v-tab>
        <v-tab
          v-if="products.includes('hotels')"
          @click="setPosCotizador(2)"
        >
          <v-icon>{{ icons.mdiBed }}</v-icon>
        </v-tab>
      </v-tabs>
      <v-card>
        <v-card-text>
          <v-tabs-items v-model="pos">
            <v-tab-item v-if="products.includes('vuelos')">
              <Flights />
            </v-tab-item>
            <v-tab-item v-if="products.includes('cars')">
              <Cars />
            </v-tab-item>
            <v-tab-item v-if="products.includes('hotels')">
              <Hotels />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import Flights from '../tabs/Flights.vue'
import Cars from '../tabs/Cars.vue'
import Hotels from '../tabs/Hotels.vue'

// eslint-disable-next-line import/no-unresolved

export default {
  components: {
    Flights,
    Cars,
    Hotels,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      tab: 0,
      hora: null,
      countryList: [],
      languageList: [],
      transmision: [],
      adicionales: [],
      radioGroup: 1,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,
      ubicacionDesde: null,
      ubicacionHasta: null,
      fechaIda: null,
      fechaVuelta: null,
      claseVuelo: null,
      adultos: 0,
      ninnos: 0,
      bebes: 0,
      aerolin: null,
      value: null,
      ubicacion: null,
      correo: null,
      nombre: null,
      auto: null,
      telef: null,
      checkbox: null,
      age: 6,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      user: {},
      products: [],
      pos: 0,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      productsList: state => state.app.productsList,
    }),
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
    this.pos = this.posCotizador
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.company_id) {
        if (this.user.company.products.length > 0) {
          this.productsList.forEach(element => {
            if (this.user.company.products.includes(element.slug)) {
              this.products.push(element.slug)
            }
          })
        }
      } else {
        this.productsList.forEach(element => {
          this.products.push(element.slug)
        })
      }

      this.isLoading = false

      /* this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.getProducts())
        */
    },

    /**
     * getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.products.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.products.push(element.slug)
            })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
     */
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

.vc-pane-layout {
  z-index: 99999;
}
</style>
