import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
    }
  },
  methods: {
    ...mapMutations([
      'setHotelsProductCache',
      'setHotelsProductFiltersCache',
    ]),
    async fetchDataHotelsDez() {
      const jsonHotelsProduct = {
        page: 1,
        per_page: 10,
      }
      try {
        const [
          resHotelsProduct,
          resHotelsFiltersProduct,
        ] = await Promise.all([
          this.axios
            .post('hotels/list', jsonHotelsProduct, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('hotels/all-filter', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resHotelsProduct.data.success === false) { } else {
          this.setHotelsProductCache(resHotelsProduct.data.data)
        }
        // eslint-disable-next-line no-empty
        if (resHotelsFiltersProduct.data.success === false) { } else {
          const jsonHotelsFileter = {
            totalNotPublic: resHotelsFiltersProduct.data.notPublic,
            totalInfoPendiente: resHotelsFiltersProduct.data.totalInfoPendiente,
            totalSinOperador: resHotelsFiltersProduct.data.totalSinOperador,
            totalSinMarca: resHotelsFiltersProduct.data.totalSinMarca,
            totalSinFotos: resHotelsFiltersProduct.data.totalSinFotos,
            totalSinTagFotos: resHotelsFiltersProduct.data.totalSinTagFotos,
            totalSinTagPrincipal: resHotelsFiltersProduct.data.totalSinTagPrincipal,
            totalSinDireccion: resHotelsFiltersProduct.data.totalSinDireccion,
            totalSinLocalidad: resHotelsFiltersProduct.data.totalSinLocalidad,
            totalSinCrop: resHotelsFiltersProduct.data.totalSinCrop,
            totalSinContact: resHotelsFiltersProduct.data.totalSinContact,
          }
          this.setHotelsProductFiltersCache(jsonHotelsFileter)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
  },
  mounted() {
    this.fetchDataHotelsDez()
  },
}
