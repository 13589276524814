<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="mt-8">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="25%">
              {{ item.code }}
            </th>
            <th width="5px">
              <v-btn
                icon
                @click="setShowItem"
              >
                <v-icon color="primary">
                  {{ showItem ? icons.mdiArrowDownCircle : icons.mdiArrowRightCircle }}
                </v-icon>
              </v-btn>
            </th>
            <th width="15%">
              Check in
            </th>
            <th width="15%">
              Check out
            </th>
            <th
              width="5px"
              class="text-capitalize"
            >
              {{ $t('lbl.nights') }}
            </th>
            <th
              width="5px"
              class="text-capitalize"
            >
              {{ $t('lbl.plan') }}
            </th>
            <th>
              <span v-if="!showItem">{{ $t('lbl.details') }}</span>
              <span v-else>{{ $t('lbl.rooms') }}</span>
            </th>
            <th
              style="max-width: 5px; text-align: center"
              class="pl-0 ml-0"
            >
              <v-icon
                v-if="showItem"
                large
              >
                mdi-human-male
              </v-icon>
            </th>
            <th
              style="max-width: 5px; text-align: center"
              class="pl-0 ml-0"
            >
              <v-icon
                v-if="showItem"
                class="mt-2 ml-2"
              >
                mdi-human-male
              </v-icon>
            </th>
            <th
              style="max-width: 5px; text-align: center"
              class="pl-0 ml-0"
            >
              <v-icon
                v-if="showItem"
                class="mt-2 ml-2"
              >
                mdi-baby-bottle-outline
              </v-icon>
            </th>
            <th width="5%">
              <template v-if="states.filter(e => e.id === item.state_item).length > 0">
                <v-chip
                  style="min-width: 110px;align-self: center !important;"
                  pill
                  class="ma-2"
                  :color="
                    states.filter(e => e.id === item.state_item)[0].slug === 'en-proceso'
                      ? 'secondary'
                      : states.filter(e => e.id === item.state_item)[0].slug === 'confirmada'
                        ? 'accent'
                        : states.filter(e => e.id === item.state_item)[0].slug === 'pagada'
                          ? 'warning'
                          : states.filter(e => e.id === item.state_item)[0].slug === 'cobrada'
                            ? 'success'
                            : states.filter(e => e.id === item.state_item)[0].slug === 'cancelada'
                              ? 'error'
                              : states.filter(e => e.id === item.state_item)[0].slug === 'reclamada'
                                ? 'warning'
                                : states.filter(e => e.id === item.state_item)[0].slug === 'reembolsada'
                                  ? 'error'
                                  : states.filter(e => e.id === item.state_item)[0].slug === 'completada'
                                    ? 'success'
                                    : ''
                  "
                >
                  <span style="align-self: center;">
                    {{
                      states.filter(e => e.id === item.state_item)[0].slug === 'en-proceso'
                        ? 'En proceso'
                        : states.filter(e => e.id === item.state_item)[0].slug === 'confirmada'
                          ? 'Confirmado'
                          : states.filter(e => e.id === item.state_item)[0].slug === 'pagada'
                            ? 'Pagado'
                            : states.filter(e => e.id === item.state_item)[0].slug === 'cobrada'
                              ? 'Cobrado'
                              : states.filter(e => e.id === item.state_item)[0].slug === 'cancelada'
                                ? 'Cancelado'
                                : states.filter(e => e.id === item.state_item)[0].slug === 'reclamada'
                                  ? 'Reclamado'
                                  : states.filter(e => e.id === item.state_item)[0].slug === 'reembolsada'
                                    ? 'Reembolsado'
                                    : states.filter(e => e.id === item.state_item)[0].slug === 'completada'
                                      ? 'Completado'
                                      : ''
                    }}
                  </span>
                </v-chip>
              </template>
            </th>
            <th width="10%">
              {{ item.data_service.items.rooms[0].priceWithDescuento | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}
              <template v-if="item.data_service.items.tasaCambioContrate && !showItem">
                <v-tooltip
                  v-if="item.data_service.items.tasaCambioContrate"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      color="primary"
                      :style="`color: ${$vuetify.theme.themes.light.primary}`"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      <v-icon>mdi-information</v-icon>
                    </span>
                  </template>
                  <span>
                    1 {{ item.data_service.items.currencyBaseCode }} = {{ item.data_service.items.tasaCambioContrate }} {{ item.data_service.items.contrateCurrencyCode }}<br />
                    <span v-if="item.data_service.items.contrateCurrencyCode !== item.data_service.items.currencyCodeFacturar">
                      1 {{ item.data_service.items.currencyBaseCode }} = {{ item.data_service.items.tasaCambioFacturar }} {{ item.data_service.items.currencyCodeFacturar }}
                    </span>

                    <!--{{ $t('lbl.exchangeRate') }}:
                      {{ item.data_service.items.tasaCambioContrate | number('0.0000') }}
                    -->
                  </span>
                </v-tooltip>
              </template>
            </th>
            <th
              v-if="!onlyShow"
              width="5%"
            >
              <v-menu
                v-if="item.state_item !== idStateCancele"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--item.state_item !== idStatePay -->
                  <v-list-item
                    v-if="!reserva.pagada"
                    link
                  >
                    <v-list-item-title @click="editServive">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!reserva.pagada"
                    link
                  >
                    <v-list-item-title @click="showCanceleService(item.data_service.items.rooms[0])">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.cancele') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="reserva.pagada"
                    link
                  >
                    <v-list-item-title @click="showCanceleService(item.data_service.items.rooms[0])">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.cancele') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              :rowspan="5"
              style="vertical-align: top;border: 0"
            >
              <template v-if="showItem">
                <v-img
                  :src="
                    nameB2B === 'ttttt'
                      ? rutaS3 + remplaceUrl(image)
                      : nameB2B === 'wwwww'
                        ? resource !== 'local' ? rutaS3 + image : rutaPublic + image
                        : rutaPublic + image
                  "
                  contain
                  width="300"
                  class="my-5"
                ></v-img>
              </template>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{
                item.data_service.items.hotel.name
              }}</span>
              <br />
              <!--CATEGORIA-->
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  :md="item.data_service.items.hotel.categoria.plus ? '4' : '12'"
                >
                  <v-rating
                    background-color="default"
                    color="primary"
                    :empty-icon="icons.mdiStarOutline"
                    :full-icon="icons.mdiStar"
                    :half-icon="icons.mdiStarHalfFull"
                    length="5"
                    readonly
                    size="20"
                    dense
                    half-increments
                    :value="item.data_service.items.hotel.categoria.cant_estrellas"
                  ></v-rating>
                </v-col>
                <v-col
                  v-if="item.data_service.items.hotel.categoria.plus"
                  cols="12"
                  md="2"
                >
                  PLUS
                </v-col>
              </v-row>
              <template v-if="showItem">
                <v-row class="mt-2">
                  <v-col
                    v-if="item.data_service.items.hotel.cant_departament.cant_rooms"
                    cols="12"
                    md="3"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-bed-outline
                        </v-icon>
                        <span>
                          {{ item.data_service.items.hotel.cant_departament.cant_rooms }}
                        </span>
                      </template>
                      <span>{{ $t('lbl.cantRoom') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="item.data_service.items.hotel.cant_departament.cant_restaurant"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-silverware-fork-knife
                        </v-icon>
                        <span>
                          {{ item.data_service.items.hotel.cant_departament.cant_restaurant }}
                        </span>
                      </template>
                      <span>{{ $t('lbl.cantRest') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="item.data_service.items.hotel.cant_departament.cant_bars"
                    cols="12"
                    md="3"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-glass-cocktail
                        </v-icon>
                        <span>
                          {{ item.data_service.items.hotel.cant_departament.cant_bars }}
                        </span>
                      </template>
                      <span>{{ $t('lbl.cantBars') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="item.data_service.items.hotel.cant_departament.cant_pool"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-pool
                        </v-icon>
                        <span>
                          {{ item.data_service.items.hotel.cant_departament.cant_pool }}
                        </span>
                      </template>
                      <span>{{ $t('lbl.cantPiscinas') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="item.data_service.items.hotel.cant_departament.cant_coffee"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-coffee
                        </v-icon>
                        <span>
                          {{ item.data_service.items.hotel.cant_departament.cant_coffee }}
                        </span>
                      </template>
                      <span>{{ $t('lbl.cantCafeterias') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <br />
                <span v-if="item.data_service.items.hotel.operador">
                  <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ `${$t('lbl.cadena')}:` }}</span>
                  {{ item.data_service.items.hotel.operador.name }}
                  <br />
                </span>
                <span v-if="item.data_service.items.proveedor">
                  <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('lbl.proveedor')}:` }}</span>
                  {{ item.data_service.items.proveedor.name }}<br />
                </span>

                <span>
                  <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ `${$t('lbl.state')}:` }}</span>
                  <span v-if="setStates.filter(e => e.id === item.state_item).length > 0">
                    {{ setStates.filter(e => e.id === item.state_item)[0].name }}
                  </span>
                  <br />
                </span>
                <!--<v-select
                  v-if="!construyendoListStates"
                  v-model="item.state_item"
                  :items="setStates"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.state')"
                  class="mt-5"
                  outlined
                  dense
                  hide-details
                  :disabled="user.nivel > 0"
                  @change="setStateItem"
                ></v-select>-->
                <span
                  v-if="item.history_state_item.length > 0"
                  class="text-caption"
                >
                  {{ $moment(item.history_state_item[item.history_state_item.length - 1].date).format('D-MMM-Y') }},
                  {{ item.history_state_item[item.history_state_item.length - 1].time }} -
                  {{ item.history_state_item[item.history_state_item.length - 1].user }} </span><br />
                <br />

                <template v-if="item.data_service.items.sources.includes('hotetec') || item.data_service.items.sources.includes('dingus')">
                  <span>
                    <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ `${$t('lbl.noConfirm')}:` }}</span>
                    {{ item.number_confirmation }}
                    <br />
                  </span>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="item.number_confirmation"
                    :disabled="
                      idStateConfirm !== item.state_item
                        ||
                        item.data_service.items.sources.includes('hotetec')
                        ||
                        item.data_service.items.sources.includes('dingus')
                        ||
                        user.nivel > 0
                        ||
                        onlyShow
                    "
                    :label="$t('lbl.noConfirm')"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                    @input="val => (item.number_confirmation = item.number_confirmation.toUpperCase())"
                  ></v-text-field>
                </template>
                <v-btn
                  color="primary"
                  outlined
                  class="mt-2"
                  block
                  :disabled="idStateCobrado !== item.state_item || !item.number_confirmation || onlyShow"
                  :loading="printVoucher"
                  @click="printItem"
                >
                  <v-icon class="mr-2">
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  {{ `${$t('btn.print')} Voucher` }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  class="mt-2"
                  block
                  :disabled="idStateCobrado !== item.state_item || !item.number_confirmation || onlyShow"
                  :loading="sendVoucher"
                  @click="sendItem"
                >
                  <v-icon class="mr-2">
                    {{ icons.mdiSendOutline }}
                  </v-icon>
                  {{ `${$t('btn.send')} Voucher` }}
                </v-btn>

                <!--AJUSTES REQUERIDOS
                <v-switch
                  v-model="item.ajuste_requered"
                  :label="$t('lbl.ajusteRequered')"
                  hide-details
                  :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                  @change="setAjusteRequered('ajuste_requered')"
                ></v-switch>
                <template v-if="item.ajuste_requered">
                  <v-select
                    v-model="item.type_ajuste_requered"
                    :items="listTypeAjuste"
                    item-text="name"
                    item-value="slug"
                    :label="$t('lbl.tipo')"
                    class="mt-5"
                    outlined
                    dense
                    hide-details
                    :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                    @change="setAjusteRequered('type_ajuste_requered')"
                  ></v-select>
                  <v-text-field
                    v-model="item.monto_ajuste_requered"
                    :label="$t('lbl.monto')"
                    prefix="$"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                    :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                    @input="setAjusteRequered('monto_ajuste_requered')"
                  ></v-text-field>
                  <span
                    v-if="item.historial_ajuste_requered.length > 0"
                    class="text-caption"
                  >
                    {{
                      $moment(item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].date).format(
                        'D-MMM-Y',
                      )
                    }}, {{ item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].time }} -
                    {{ item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].user }}
                  </span>
                  <span
                    v-else
                    class="text-caption"
                  >
                    {{ historial_ajuste_requered_empty.date }}, {{ historial_ajuste_requered_empty.time }},
                    {{ historial_ajuste_requered_empty.user }}
                  </span>
                  <br />
                </template>-->

                <!--RECLAMACION
                <v-switch
                  v-model="item.reclamado"
                  :label="$t('lbl.reclamated')"
                  hide-details
                  :disabled="
                    (item.state_item !== idStateCobrado && item.state_item !== idStateDisfrutado) || user.nivel > 0 || onlyShow
                  "
                  @change="setAjusteRequered('reclamado')"
                ></v-switch>-->

                <!--CANCELACION--
                @change="setAjusteRequered('cancelado')" -->
                <!--<v-switch
                  v-model="item.cancelado"
                  :label="$t('lbl.cancelated')"
                  hide-details
                  :disabled="item.cancelado"
                  @change="showModalCanceleSwitch = true"
                ></v-switch>-->
                <!--REEMBOLSOS REQUERIDOS-->
                <!--<template v-if="idStateCancele === item.state_item || idStateReclamado === item.state_item">-->

                <template v-if="onlyShow">
                  <v-switch
                    v-model="item.reembolso_requered"
                    :label="$t('lbl.reembolsoRequered')"
                    hide-details
                    :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                    @change="setAjusteRequered('reembolso_requered')"
                  ></v-switch>
                  <template v-if="item.reembolso_requered">
                    <v-switch
                      v-model="item.reembolso_total"
                      :label="$t('lbl.reembolsoTotal')"
                      hide-details
                      :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                      @change="setAjusteRequered('reembolso_total')"
                    ></v-switch>
                    <template v-if="!item.reembolso_total">
                      <v-row>
                        <v-col cols="4">
                          <v-switch
                            v-model="item.monto_reembolso_is_pc"
                            label="%"
                            hide-details
                            :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                            @change="setAjusteRequered('monto_reembolso_is_pc')"
                          ></v-switch>
                        </v-col>
                        <v-col cols="8">
                          <!--VKK-->
                          <v-text-field
                            v-if="!item.monto_reembolso_is_pc"
                            v-model="item.monto_reembolso_requered"
                            :label="$t('lbl.monto')"
                            :prefix="item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$'"
                            outlined
                            dense
                            hide-details="auto"
                            max="100"
                            class="mt-2"
                            type="number"
                            :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                            :rules="refundRules"
                            @keypress="checkMontoRefund($event, item.monto_reembolso_requered)"
                            @input="setAjusteRequered('monto_reembolso_requered')"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="item.monto_reembolso_pc"
                            :label="$t('lbl.monto')"
                            suffix="%"
                            outlined
                            dense
                            hide-details="auto"
                            class="mt-2"
                            type="number"
                            :rules="refundPCRules"
                            :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                            @input="setAjusteRequered('monto_reembolso_pc')"
                          ></v-text-field>
                          <span
                            v-if="item.historial_reembolso_requered.length > 0"
                            class="text-caption"
                          >
                            {{
                              $moment(
                                item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].date,
                              ).format('D-MMM-Y')
                            }}, {{ item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].time }} <!---
                            {{ item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].user }}-->
                          </span>
                          <span
                            v-else
                            class="text-caption"
                          >
                            {{ historial_ajuste_requered_empty.date }}, {{ historial_ajuste_requered_empty.time }},
                            <!--{{ historial_ajuste_requered_empty.user }}-->
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                    <br />
                  </template>
                </template>
              </template>
            </td>
            <td
              :rowspan="5"
              style="vertical-align: top;border: 0"
            ></td>
            <td
              style="vertical-align: top;border: 0"
            >
              {{ $moment(item.data_service.dateIn).format('DD MMM, Y') }}
              <br />
              16:00
            </td>
            <td
              style="vertical-align: top;border: 0"
            >
              {{ $moment(item.data_service.dateOut).format('DD MMM, Y') }}
              <br />
              12:00
            </td>
            <td
              style="vertical-align: top;border: 0; text-align: center"
            >
              {{ item.data_service.items.diasReservar }}
            </td>
            <td style="vertical-align: top;border: 0">
              <span v-if="!showItem && roomsItems.length > 0">{{ roomsItems[0].plan }}</span>
              <span v-else>
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <span>{{ roomI.plan }}</span><br />
                  </span>
                </template>
              </span>
            </td>
            <td
              :colspan="!showItem ? 2 : 1"
              style="vertical-align: top;border: 0;max-height: 20px"
            >
              <span v-if="!showItem">{{ detallesItemRooms }}</span>
              <span v-else>
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          color="primary"
                          :style="`color: ${$vuetify.theme.themes.light.primary}`"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          {{ roomI.room | truncate(8) }}
                        </span>
                      </template>
                      <span>{{ roomI.room }}</span>
                    </v-tooltip>
                    <br />
                  </span>
                </template>
              </span>
            </td>
            <td style="vertical-align: top;border: 0;">
              <span v-if="showItem">
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <span>{{ roomI.adults }}</span><br />
                  </span>
                </template>
              </span>
            </td>
            <td style="vertical-align: top;border: 0;">
              <span v-if="showItem">
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <span>{{ roomI.childs }}</span><br />
                  </span>
                </template>
              </span>
            </td>
            <td style="vertical-align: top;border: 0; text-align: center">
              <span v-if="showItem">
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <span>{{ roomI.inf }}</span><br />
                  </span>
                </template>
              </span>
            </td>
            <td style="vertical-align: top;border: 0"></td>
            <td style="vertical-align: top;border: 0; text-align: center">
              <!--<span v-if="showItem">
                <template v-for="(roomI, indR) in roomsItems">
                  <span
                    :key="indR"
                  >
                    <span>{{ roomI.price | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</span><br />
                  </span>
                </template>
              </span> -->
            </td>
          </tr>
          <!--<template v-for="(roomI, indR) in roomsDetalles">
            <tr
              v-if="showItem && indR > 0"
              :key="indR"
              style="vertical-align: top;border: 0"
            >
              <td style="vertical-align: top;border: 0">
                {{ roomI.plan }}
              </td>
              <td
                style="vertical-align: top;border: 0"
              >
                {{ roomI.room }}
              </td>
              <td style="vertical-align: top;border: 0; text-align: center">
                {{ roomI.adults }}
              </td>
              <td style="vertical-align: top;border: 0; text-align: center">
                {{ roomI.childs }}
              </td>
              <td style="vertical-align: top;border: 0; text-align: center">
                {{ roomI.inf }}
              </td>
              <td style="vertical-align: top;border: 0"></td>
              <td style="vertical-align: top;border: 0; text-align: center">
                {{ roomI.price | currency }}
              </td>
            </tr>
          </template>-->

          <tr>
            <td
              colspan="11"
              style="vertical-align: top;border: 0"
            >
              <!--SUPLEMENTO-->
              <v-simple-table
                v-if="showItem && item.data_markups.suplementos.length > 0"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.cargoSuplement') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.applyTo') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.cantidad') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.importe') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ 0 | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</td>
                    </tr>
                    <!--<template
                      v-for="(suplement, indS) in item.data_markups.suplementos"
                    >
                      <tr
                        v-if="suplement.priceAll > 0"
                        :key="indS"
                      >
                        <td>{{ suplement.suplemento.name }}</td>
                        <td>
                          <span v-if="suplement.suplemento.by_day">Día</span>
                          <span v-else-if="suplement.suplemento.by_servicio">Servicio</span>
                          <span v-else-if="suplement.suplemento.by_km">Km</span>
                        </td>
                        <td>{{ suplement.priceAll | currency }}</td>
                      </tr>
                    </template>-->
                  </tbody>
                </template>
              </v-simple-table>

              <!--HABITACIONES-->
              <!--<v-simple-table
                v-if="showItem && roomsItems.length > 0"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.rooms') }}
                      </th>
                      <th>
                        {{ $t('lbl.travels') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.edad') }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(roomsD, indRD) in roomsItems"
                      :key="indRD"
                    >
                      <td>{{ roomsD.room }}</td>
                      <td>
                        <span
                          v-for="(pax, indP) in roomsD.paxs"
                          :key="indP"
                        >
                          <span v-if="pax.name">
                            {{ pax.name }} {{ pax.apellidos }}
                          </span>
                          <span v-else>Huésped {{ indP+1 }}</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(pax, indP) in roomsD.paxs"
                          :key="indP"
                        >
                          <span v-if="pax.type === 'ADL'">
                            ADL
                          </span>
                          <span v-else-if="pax.edad">
                            {{ pax.edad }} {{ $t('lbl.years') }}
                          </span>
                          <br />
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>-->
              <template v-if="showItem && roomsItems.length > 0">
                <v-row
                  v-for="(rrr, indRRR) in roomsItems"
                  :key="indRRR"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <b>{{ $t('lbl.room') }}</b><br />
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        {{ rrr.room }}
                        ({{ $t('lbl.plan') }}: {{ rrr.plan }})
                        <br />
                        <span v-if="rrr.tarifasSeason.filter(e => e.code === rrr.codeTarifa).length > 0">
                          {{ $t('lbl.tarif') }}:
                          {{ rrr.tarifasSeason.filter(e => e.code === rrr.codeTarifa)[0].name }} ({{ rrr.codeTarifa }})
                          <v-tooltip
                            top
                            color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  style="cursor: pointer;"
                                  class="ml-5"
                                  @click="showModal(rrr)"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </span>
                            </template>
                            <span>
                              {{ $t('lbl.policyCancele') }}
                            </span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <b>{{ $t('lbl.travels') }}:</b>
                    <br />
                    <v-row
                      no-gutters
                    >
                      <template v-if="rrr.adults > 0">
                        <v-col
                          v-for="adl in rrr.adults"
                          :key="adl"
                          cols="2"
                        >
                          <v-icon>
                            mdi-human-male
                          </v-icon>
                        </v-col>
                      </template>
                      <template v-if="rrr.childs > 0">
                        <v-col
                          v-for="mnr in rrr.childs"
                          :key="mnr"
                          cols="2"
                          style="vertical-align: bottom;"
                        >
                          <v-icon
                            size="20px"
                            class="mt-1"
                          >
                            mdi-human-child
                          </v-icon>
                        </v-col>
                      </template>
                      <template v-if="rrr.inf > 0">
                        <v-col
                          v-for="inf in rrr.inf"
                          :key="inf"
                          cols="2"
                          style="vertical-align: bottom;"
                        >
                          <v-icon
                            size="15px"
                            class="mt-1"
                          >
                            mdi-baby-bottle-outline
                          </v-icon>
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>
                              {{ $t('lbl.name') }}
                            </th>
                            <th>
                              {{ $t('lbl.edad') }}
                            </th>
                            <th>ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(pax, indRD) in rrr.paxs"
                            :key="indRD"
                          >
                            <td>
                              <span v-if="pax.name">
                                {{ pax.name }} {{ pax.apellidos }}
                              </span>
                              <span v-else>Huésped {{ indRD+1 }}</span>
                            </td>
                            <td>
                              <span v-if="pax.type === 'ADL'">
                                ADL
                              </span>
                              <span v-else-if="pax.edad">
                                {{ pax.edad }} {{ $t('lbl.years') }}
                              </span>
                            </td>
                            <td>{{ pax.noDocument }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </template>

              <!--TERMINOS-->
              <v-simple-table
                v-if="showItem && listConditions.length > 0"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.termsConditions') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: right;">
                        <ul style="text-align: left;">
                          <fragment v-if="showAllConditions">
                            <li
                              v-for="(condition, indC) in listConditions"
                              :key="indC"
                            >
                              <span v-html="condition.description"></span>
                            </li>
                          </fragment>
                          <li v-else>
                            <span v-html="listConditions[0].description"></span>
                          </li>
                        </ul>
                        <span
                          style="cursor: pointer"
                          @click="showAllConditions = !showAllConditions"
                        >
                          <u>{{ showAllConditions ? $t('lbl.lessSee') : $t('lbl.moreSee') }}</u>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- CANCELAR SERVICIO -->
    <v-dialog
      v-if="policyCancele"
      v-model="showModalCancele"
      scrollable
      max-width="600px"
    >
      <v-card max-height="600px">
        <v-card-title>
          {{ `${$t('btn.cancele')} ${$t('lbl.service')}!!!` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.deleteService1', { item: item.code }) }}

          <br />
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <template v-if="!noReembolsable">
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="montoReembolsar"
                  :label="$t('lbl.monto')"
                  :prefix="item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$'"
                  outlined
                  dense
                  hide-details="auto"
                  max="100"
                  type="number"
                  :rules="refundRules"
                  :disabled="noReembolsable || freeCancelation"
                  @keypress="verifyMontoRefund($event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row class="mx-2">
                  <v-col
                    cols="12"
                  >
                    <v-list-item-title>
                      {{ policyCancele.showName }}
                      <span style="font-size: 12px">
                        {{ `(${policyCancele.name} ${policyCancele.code})` }}
                      </span>
                    </v-list-item-title>
                    <template v-if="policyCancele.slug === 'no-reembolsable'">
                      <!--<span style="font-size: 12px">
                        {{ $t('lbl.titleOfert') }}
                      </span>-->
                    </template>
                    <template v-else>
                      <span
                        class="mt-5"
                        style="font-size: 12px"
                      >
                        <template v-if="policyCancele.dates_hasta.length > 0">
                          <b>{{ $t('lbl.beforeOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                          <br />
                          {{ $t('lbl.conditionsCancelation') }}
                          <br />
                          {{ $t('lbl.cancelaFree') }} {{ policyCancele.dates_hasta[0] }}
                          <br />
                          <br />
                          <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                        </template>
                        <template v-else>
                          <span style="color: red">{{ $t('lbl.withPenalidad') }}</span>
                          <br />
                          <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_penalidad }}
                        </template>
                        <br />
                        {{ $t('lbl.conditionsCancelation') }}
                        <br />
                        {{ $t('lbl.cancelaPena1') }} {{ policyCancele.dates_hasta.length > 0 ? policyCancele.dates_hasta[0] : policyCancele.dates_penalidad }} {{ $t('lbl.cancelaPena2') }}
                        <br />
                        <ul>
                          <li>
                            {{ `${planPolicy.name} (${planPolicy.siglas})` }}
                          </li>
                        </ul>
                        <br />
                        {{ $t('lbl.penalidad') }}:
                        <br />
                        <span
                          v-for="(data, iDataModa) in policyCancele.cancele_free_data"
                          :key="iDataModa"
                        >
                          {{ penalidad(data) }}<br />
                        </span>

                        <br v-if="policyCancele.conditions.length > 0" />
                        <template v-for="(condi, indCModa) in policyCancele.conditions">
                          <span :key="indCModa">
                            <span>
                              {{ condi.name }}
                              <ul
                                v-for="(ccc, indCCC) in condi.conditions"
                                :key="indCCC"
                              >
                                <li v-html="ccc.description"></li>
                              </ul>
                            </span>
                          </span>
                        </template>
                      </span>
                    </template>
                    <v-divider
                      class="mt-2"
                    ></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <v-col
              v-else
              cols="12"
            >
              <span style="color: red">
                {{ $t('lbl.noReembolsable') }}
              </span>
            </v-col>
          </v-row>

          <!--<br /><br />
          <span v-if="!item.reembolso_requered">
            {{ $t('msg.deleteService2') }}
          </span>
          <span v-else>
            {{ $t('msg.deleteService3') }}<br /><br />
            {{ $t('msg.deleteService4') }}:
            <span v-if="item.reembolso_total">
              Total
            </span>
            <span v-else>
              Parcial
            </span><br />
            {{ $t('msg.deleteService5') }}: {{ amountRefund | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}
          </span>
          <br />
          <template>
            <v-switch
              v-model="item.reembolso_requered"
              :label="$t('lbl.reembolsoRequered')"
              hide-details
              class="caption"
              :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
            ></v-switch>
            <template v-if="item.reembolso_requered">
              <v-switch
                v-model="item.reembolso_total"
                :label="$t('lbl.reembolsoTotal')"
                hide-details
                :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
              ></v-switch>
              <template v-if="!item.reembolso_total">
                <v-row>
                  <v-col cols="4">
                    <v-switch
                      v-model="item.monto_reembolso_is_pc"
                      label="%"
                      hide-details
                      class="text-caption"
                      :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                    ></v-switch>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-if="!item.monto_reembolso_is_pc"
                      v-model="item.monto_reembolso_requered"
                      :label="$t('lbl.monto')"
                      :prefix="item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$'"
                      outlined
                      dense
                      hide-details="auto"
                      max="100"
                      class="mt-2 col-4"
                      type="number"
                      :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                      :rules="refundRules"
                      @keypress="checkMontoRefund($event, item.monto_reembolso_requered)"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.monto_reembolso_pc"
                      :label="$t('lbl.monto')"
                      suffix="%"
                      outlined
                      dense
                      hide-details="auto"
                      class="mt-2 col-4"
                      type="number"
                      :rules="refundPCRules"
                      :disabled="user.nivel > 0 || idStateConfirm !== item.state_item || onlyShow"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <br />
            </template>
          </template>-->
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalCancele = !showModalCancele"
          >
            {{ $t('btn.no') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :disabled="Math.floor(montoReembolsar) > Math.floor(item.data_service.items.rooms[0].priceWithDescuento)"
            @click="canceleService()"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CANCELAR SERVICIO EN PROCESO-->
    <v-dialog
      v-model="showModalCanceleProceso"
      scrollable
      max-width="600px"
    >
      <v-card max-height="600px">
        <v-card-title>
          {{ `${$t('btn.cancele')} ${$t('lbl.service')}!!!` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.deleteService1', { item: item.code }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalCanceleProceso = !showModalCanceleProceso"
          >
            {{ $t('btn.no') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="canceleServiceProceso()"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CANCELAR DESDE EL SWITCH -->
    <v-dialog
      v-model="showModalCanceleSwitch"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')} ${$t('lbl.service')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleService', { code: item.code }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="
              showModalCanceleSwitch = !showModalCanceleSwitch
              item.cancelado = false
            "
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingCanceleSwitch"
            @click="setAjusteRequered('cancelado')"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--POLITICAS DE CANCELACION-->
    <v-dialog
      v-if="policyCancele"
      v-model="dialogPolicy"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogPolicy = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('lbl.policyCancele') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template>
          <v-row class="my-2 mx-2">
            <v-col
              cols="12"
            >
              <v-list-item-title>
                {{ policyCancele.showName }}
                <span style="font-size: 12px">
                  {{ `(${policyCancele.name} ${policyCancele.code})` }}
                </span>
              </v-list-item-title>
              <template v-if="policyCancele.slug === 'no-reembolsable'">
                <!--<span style="font-size: 12px">
                  {{ $t('lbl.titleOfert') }}
                </span>-->
              </template>
              <template v-else>
                <span
                  class="mt-5"
                  style="font-size: 12px"
                >
                  <template v-if="policyCancele.dates_hasta.length > 0">
                    <b>{{ $t('lbl.beforeOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                    <br />
                    {{ $t('lbl.conditionsCancelation') }}
                    <br />
                    {{ $t('lbl.cancelaFree') }} {{ policyCancele.dates_hasta[0] }}
                    <br />
                    <br />
                    <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_hasta[0] }}
                  </template>
                  <template v-else>
                    <span style="color: red">{{ $t('lbl.withPenalidad') }}</span>
                    <br />
                    <b>{{ $t('lbl.afterOf') }}</b> {{ policyCancele.dates_penalidad }}
                  </template>
                  <br />
                  {{ $t('lbl.conditionsCancelation') }}
                  <br />
                  {{ $t('lbl.cancelaPena1') }} {{ policyCancele.dates_hasta.length > 0 ? policyCancele.dates_hasta[0] : policyCancele.dates_penalidad }} {{ $t('lbl.cancelaPena2') }}
                  <br />
                  <ul>
                    <li>
                      {{ `${regimenSelect.name} (${regimenSelect.siglas})` }}
                    </li>
                  </ul>
                  <br />
                  {{ $t('lbl.penalidad') }}:
                  <br />
                  <span
                    v-for="(data, iData) in policyCancele.cancele_free_data"
                    :key="iData"
                  >
                    {{ penalidad(data) }}<br />
                  </span>

                  <br v-if="policyCancele.conditions.length > 0" />
                  <template v-for="(condi, indC) in policyCancele.conditions">
                    <span :key="indC">
                      <span>
                        {{ condi.name }}
                        <ul
                          v-for="(ccc, indCCC) in condi.conditions"
                          :key="indCCC"
                        >
                          <li v-html="ccc.description"></li>
                        </ul>
                      </span>
                    </span>
                  </template>
                </span>
              </template>
              <v-divider
                class="mt-2"
              ></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

    <Loading
      v-if="loadingCancel"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiArrowDownCircle,
  mdiArrowRightCircle,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiSendOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

import { extend } from 'vee-validate'

extend('min', {
  validate(value, { length }) {
    return value.length >= length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters',
})

export default {
  components: {
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    conditions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
  },
  data() {
    return {
      onlyShow: localStorage.getItem('reservations-show') !== null,
      refundRules: [v => this.isRefundValid(v) || 'Monto no permitido'],
      refundPCRules: [v => this.isRefundPCValid(v) || '% no permitido'],
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiArrowDownCircle,
        mdiArrowRightCircle,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiSendOutline,
        mdiMapMarkerOutline,
      },
      permisos: localStorage.getItem('permisos_auth'),
      image: null,
      resource: '',
      contrate: null,
      listTypeAjuste: [],

      // listStates: [],
      listConditions: [],
      idStateProces: 0,
      idStateConfirm: 0,
      idStatePay: 0,
      idStateCancele: 0,
      idStateReclamado: 0,
      idStateCobrado: 0,
      idStateDisfrutado: 0,
      showAllConditions: false,
      showModalCancele: false,
      showModalCanceleProceso: false,
      showModalCanceleSwitch: false,
      loadingCanceleSwitch: false,
      printVoucher: false,
      sendVoucher: false,
      historial_ajuste_requered_empty: [],
      construyendoListStates: false,
      detallesRooms: '',
      roomsDetalles: [],
      roomsItems: [],
      detallesItemRooms: '',
      roomsDetallesPaxs: [],
      loadingCancel: false,

      policyCancele: null,
      dialogPolicy: false,
      regimenSelect: {
        name: '',
        siglas: '',
      },
      montoReembolsar: 0,
      noReembolsable: false,
      freeCancelation: false,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      indexCarItem: state => state.app.indexCarItem,
      showCarItem: state => state.app.showCarItem,
    }),
    showItem() {
      if (this.indexCarItem === this.pos && this.showCarItem) {
        return true
      }

      return false
    },

    setStates() {
      const listStates = []

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idStateProces = this.states.filter(e => e.slug === 'en-proceso')[0].id
      this.states.forEach(element => {
        if (element.slug === 'en-proceso') {
          // if (this.item.state_item === element.id) {
          listStates.push({
            id: element.id,
            name: 'En proceso',
            disabled: this.item.state_item !== element.id,
          })

          // }
        } else if (element.slug === 'confirmada') {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              listStates.push({
                id: element.id,
                name: 'Confirmado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            listStates.push({
              id: element.id,
              name: 'Confirmado',
              disabled: this.item.state_item !== element.id && this.idStateProces !== this.item.state_item,
            })
          }
        } else if (element.slug === 'cancelada') {
          if (this.item.cancelado) {
            listStates.push({
              id: element.id,
              name: 'Cancelado',
              disabled: this.item.state_item !== element.id,
            })
          }
          this.idStateCancele = element.id
        } else if (element.slug === 'disfrutada') {
          if (!this.item.cancelado && !this.item.reclamado) {
            listStates.push({
              id: element.id,
              name: 'Disfrutado',
              disabled: this.item.state_item !== element.id,
            })
          }
          this.idStateDisfrutado = element.id
        }

        /* else if (element.slug === 'archivada') {
          // if (this.item.state_item === element.id) {
          listStates.push({
            id: element.id,
            name: 'Archivado',
            disabled: this.item.state_item !== element.id,
          })

          // }
        } */
      })

      return listStates
    },
    amountRefund() {
      let result = 0
      const total = this.item.data_markups.priceTotal
      if (!this.item.reembolso_total) {
        if (this.item.monto_reembolso_is_pc) {
          result = parseFloat(total) * parseFloat(this.item.monto_reembolso_pc)
          result /= 100
        } else {
          result = this.item.monto_reembolso_requered
        }
      } else {
        result = total
      }

      return Math.round((result), 2)
    },
    planPolicy() {
      const plan = {
        name: '',
        siglas: '',
      }
      const room = this.item.data_service.items.rooms[0]
      if (this.planes.filter(e => e.id === room.plan).length > 0) {
        plan.name = this.planes.filter(e => e.id === room.plan)[0].name
        plan.siglas = this.planes.filter(e => e.id === room.plan)[0].siglas
      }

      return plan
    },
  },
  mounted() {
    this.getContrate()

    // this.setStates()
    if (this.item.data_service.items.hotel.galery_random === null || this.item.data_service.items.hotel.galery_random === 'null') {
      this.image = 'config/hotel_placeholder.jpg'
      this.resource = 'local'
    } else {
      this.image = this.item.data_service.items.hotel.galery_random
    }

    this.listTypeAjuste.push({
      name: 'Débito',
      slug: 'debito',
    })
    this.listTypeAjuste.push({
      name: 'Crédito',
      slug: 'credito',
    })

    this.historial_ajuste_requered_empty = {
      date: this.$moment(new Date()).format('DD-MMM-YYYY'),
      time: this.$moment(new Date()).format('hh:mm'),
      user: `${this.user.name} ${this.user.apellidos}`,
    }
  },
  created() {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.item.data_service.items.ocupation.length; index++) {
      const iii = this.item.data_service.items.ocupation[index]
      if (this.item.data_service.items.ocupation[index].cant === 1) {
        this.detallesRooms = `${this.detallesRooms} ${iii.cant} ${iii.uso.toUpperCase()}`
        if (iii.childs > 0) {
          this.detallesRooms = `${this.detallesRooms} + ${iii.childs} ${'MNR'.toUpperCase()}`
        }
        if (index + 1 !== this.item.data_service.items.ocupation.length) {
          this.detallesRooms = `${this.detallesRooms}, `
        }

        if (this.rooms.filter(e => e.id === iii.room_id
          // eslint-disable-next-line no-shadow
          && this.planes.filter(e => e.id === iii.plan).length > 0).length > 0) {
          this.roomsDetalles.push({
            room: this.rooms.filter(e => e.id === iii.room_id)[0].name,
            adults: iii.adults,
            childs: iii.childs,
            inf: 0,
            plan: this.planes.filter(e => e.id === iii.plan)[0].siglas,
            paxs: iii.paxs,
            edades: iii.edades,
            price: iii.priceWithDescuento,
          })
        }
      } else {
        this.detallesRooms = `${this.detallesRooms} ${iii.cant} ${iii.uso.toUpperCase()}`
        if (iii.childs > 0) {
          this.detallesRooms = `${this.detallesRooms} + ${iii.childs} ${'MNR'.toUpperCase()}`
        }
        if (index + 1 !== this.item.data_service.items.ocupation.length) {
          this.detallesRooms = `${this.detallesRooms}, `
        }

        // eslint-disable-next-line no-plusplus
        for (let ocup = 0; ocup < this.item.data_service.items.ocupation[index].cant; ocup++) {
          this.roomsDetalles.push({
            room: this.rooms.filter(e => e.id === iii.room_id)[0].name,
            adults: iii.adults,
            childs: iii.childs,
            inf: 0,
            plan: this.planes.filter(e => e.id === iii.plan)[0].siglas,
            paxs: iii.paxs,
            edades: iii.edades,
            price: iii.priceWithDescuento / this.item.data_service.items.ocupation[index].cant,
          })
        }
      }
    }

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.item.data_service.ocupation.length; index++) {
      const iii = this.item.data_service.ocupation[index]
      if (this.rooms.filter(e => e.id === iii.room_id
        // eslint-disable-next-line no-shadow
        && this.planes.filter(e => e.id === iii.plan).length > 0).length > 0) {
        this.roomsDetallesPaxs.push({
          room: this.rooms.filter(e => e.id === iii.room_id)[0].name,
          adults: iii.adults,
          childs: iii.childs,
          inf: 0,
          plan: this.planes.filter(e => e.id === iii.plan)[0].siglas,
          paxs: iii.paxs,
          edades: iii.edades,
          price: iii.priceWithDescuento,
        })
      }
    }

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.item.data_service.items.rooms.length; index++) {
      const iii = this.item.data_service.items.rooms[index]
      this.detallesItemRooms = `${this.detallesItemRooms} 1 ${iii.uso.toUpperCase()}`
      if (iii.childs > 0) {
        this.detallesItemRooms = `${this.detallesItemRooms} + ${iii.childs} ${'MNR'.toUpperCase()}`
      }

      if (this.rooms.filter(e => e.id === iii.room_id
        // eslint-disable-next-line no-shadow
        && this.planes.filter(e => e.id === iii.plan).length > 0).length > 0) {
        this.roomsItems.push({
          room: this.rooms.filter(e => e.id === iii.room_id)[0].name,
          adults: iii.adults,
          childs: iii.childs,
          inf: 0,
          plan: this.planes.filter(e => e.id === iii.plan)[0].siglas,
          paxs: iii.paxs,
          edades: iii.edades,
          price: iii.priceWithDescuento,
          tarifasSeason: iii.tarifasSeason,
          codeTarifa: iii.codeTarifa,
        })
      }
    }
    this.getIdStatesPayment()
    this.getStatesIds()
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
      'updateIndexCarItem',
      'updateShowCarItem',
      'setSearchingHotel',
    ]),
    verifyMontoRefund(elEvento) {
      const numeros = '0123456789.'
      const numerosCaracteres = numeros

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (this.montoReembolsar.length === 0)
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (this.montoReembolsar.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI EL MONTO EXCEDE AL TOTAL
      else if (Math.floor(this.montoReembolsar) > Math.floor(this.item.data_service.items.rooms[0].priceWithDescuento)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }
    },
    checkMontoRefund(elEvento, model) {
      const numeros = '0123456789.'
      const numerosCaracteres = numeros

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0)
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI EL MONTO EXCEDE AL TOTAL
      else if (model > this.item.data_markups.priceTotal) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }
    },
    isRefundValid(str) {
      try {
        if (str === '' || str === null || str === undefined) {
          return false
        } if (Math.floor(str) > Math.floor(this.item.data_service.items.rooms[0].priceWithDescuento)) {
          return false
        }
      } catch (_) {
        return false
      }

      return true
    },
    isRefundPCValid(str) {
      try {
        if (str === '' || str === null || str === undefined) {
          return false
        } if (str > 100) {
          return false
        }
      } catch (_) {
        return false
      }

      return true
    },
    remplaceUrl(url) {
      let img = url.replace('uploads/car/', '')
      img = img.replace(' ', '%20')

      return img
    },
    getStatesIds() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idStateProces = this.states.filter(e => e.slug === 'en-proceso')[0].id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.idStatePay = this.states.filter(e => e.slug === 'pagada')[0].id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.idStateCobrado = this.states.filter(e => e.slug === 'cobrada')[0].id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idStateCancele = this.states.filter(e => e.slug === 'cancelada')[0].id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.idStateReclamado = this.states.filter(e => e.slug === 'reclamada')[0].id
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idStateDisfrutado = this.states.filter(e => e.slug === 'disfrutada')[0].id
    },
    getAge(birthday) {
      return Math.ceil(
        Math.abs(
          this.$moment(this.$moment(new Date(), 'YYYY-MM-DD')).diff(
            this.$moment(birthday, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        ),
      )
    },
    getIdStatesPayment() {
      // PARA IDENTIFICAR EL ID DE CONFIRMADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada').length > 0) {
        this.idStateConfirm = this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada')[0].id
      }

      // PARA IDENTIFICAR EL ID DE PAGADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'pagada').length > 0) {
        this.idStatePay = this.states.filter(e => e.id === this.item.state_item && e.slug === 'pagada')[0].id
      }
    },
    getContrate() {
      this.axios
        .get(`contrate_hotels/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data

          /* if (this.contrate.conditions) {
            this.contrate.conditions.forEach(element => {
              this.conditions.forEach(elementAll => {
                if (element === elementAll.id) {
                  this.listConditions.push(elementAll)
                }
              })
            })
          } */
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    /* setStates() {
      this.listStates = []

      // const slugItem = this.states.filter(e => e.id === this.item.state_item)[0].slug
      this.states.forEach(element => {
        if (element.slug === 'en-proceso') {
          // if (this.item.state_item === element.id) {
          this.listStates.push({
            id: element.id,
            name: 'En proceso',
            disabled: this.item.state_item !== element.id,
          })

          // }
        } else if (element.slug === 'confirmada') {
          // if (slugItem === 'en-proceso' || slugItem === 'confirmada') {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Confirmado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Confirmado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'pagada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Pagado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Pagado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'cobrada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Cobrado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Cobrado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCobrado = element.id
        } else if (element.slug === 'cancelada') {
          // if (slugItem === 'confirmada' || slugItem === 'cancelada') {
          if (this.item.cancelado) {
            this.listStates.push({
              id: element.id,
              name: 'Cancelado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCancele = element.id
        } else if (element.slug === 'reclamada') {
          if (this.item.state_item === element.id) {
            this.listStates.push({
              id: element.id,
              name: 'Reclamado',
            })
          }
          this.idStateReclamado = element.id
        } else if (element.slug === 'reembolsada') {
          if (this.item.state_item === element.id) {
            this.listStates.push({
              id: element.id,
              name: 'Reembolsado',
            })
          }
        } else if (element.slug === 'disfrutada') {
          // if (this.item.state_item === element.id) {
          if (!this.item.cancelado) {
            this.listStates.push({
              id: element.id,
              name: 'Disfrutado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateDisfrutado = element.id
        } else if (element.slug === 'archivada') {
          // if (this.item.state_item === element.id) {
          this.listStates.push({
            id: element.id,
            name: 'Archivado',
            disabled: this.item.state_item !== element.id,
          })

          // }
        }
      })
    }, */
    setStateItem() {
      this.getIdStatesPayment()

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        state_item: this.item.state_item,
        code_item: this.item.code,
      }
      this.axios
        .post('reservations/update-state-item-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.item.state_item)

              // this.setStates()

              // this.reserva.state_reserva_id = this.item.state_item
            }

            this.construyendoListStates = true
            setTimeout(() => {
              this.construyendoListStates = false
            }, 100)
          }
        })
    },
    setShowItem() {
      if (!this.showCarItem) {
        this.updateIndexCarItem(this.pos)
        this.updateShowCarItem(true)
      } else if (this.showCarItem && this.pos === this.indexCarItem) {
        this.updateIndexCarItem(-1)
        this.updateShowCarItem(false)
      } else if (this.showCarItem && this.pos !== this.indexCarItem) {
        this.updateIndexCarItem(this.pos)
      }
    },

    editServive() {
      const json = {
        pos: this.pos,
        item: this.item,
      }

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('route-redirect', 'reservations-update')

      this.$router.push({ name: 'car-shop-item-update' })
    },
    showCanceleService(room) {
      this.showModalCancele = true
      this.calcularMontoReembolsar()

      room.tarifasSeason.forEach(tar => {
        if (tar.code === room.codeTarifa) {
          this.policyCancele = tar
        }
      })

      /* if (this.planes.filter(e => e.siglas === room.plan).length > 0) {
        this.regimenSelect.name = this.planes.filter(e => e.siglas === room.plan)[0].name
        this.regimenSelect.siglas = this.planes.filter(e => e.siglas === room.plan)[0].siglas
      } */
    },
    calcularMontoReembolsar() {
      const room = this.item.data_service.items.rooms[0]
      // eslint-disable-next-line no-unused-vars
      const montoOrigin = room.priceWithDescuento
      let monto = room.priceWithDescuento
      const { diasReservar } = this.item.data_service.items
      const montoByDay = parseFloat(monto / diasReservar)

      const tarifasRoom = room.tarifasSeason
      const { codeTarifa } = room
      const tarifaUse = tarifasRoom.filter(e => e.code === codeTarifa)
      let penalidad = 0
      if (tarifaUse.length > 0) {
        if (tarifaUse[0].no_reembolsable) {
          this.noReembolsable = true
          monto = 0
        } else if (!tarifaUse[0].cancele_free_all_time) {
          let applyPenalidad = false

          // YA NACIO CON PENALIDAD
          if (tarifaUse[0].dates_penalidad !== null) {
            applyPenalidad = true
          } else if (tarifaUse[0].dates_hasta.length > 0) {
            const today = new Date()
            if (this.$moment(today).isAfter(tarifaUse[0].dates_hasta[0])) {
              applyPenalidad = true
            }
          }

          if (applyPenalidad) {
            if (tarifaUse[0].cancele_free_data.length > 0) {
              let cantNight = tarifaUse[0].cancele_free_data[0].cant_nigths

              // VER SI SON TODAS LAS NOCHES
              if (tarifaUse[0].cancele_free_data[0].all_nights) {
                cantNight = diasReservar
              }

              if (tarifaUse[0].cancele_free_data[0].penalidad_in_pc) {
                penalidad = montoByDay * cantNight
                penalidad *= parseFloat(tarifaUse[0].cancele_free_data[0].penalidad_valor) / 100
              } else {
                penalidad = parseFloat(tarifaUse[0].cancele_free_data[0].penalidad_valor) * cantNight
              }

              monto = montoOrigin - penalidad
            }
          }
        }
      }

      this.montoReembolsar = monto.toFixed(2)
    },

    canceleService() {
      this.item.state_item = this.idStateCancele
      this.showModalCancele = false
      this.loadingCancel = true

      // this.setSearchingHotel(true)

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        state_item: this.idStateCancele,
        item: this.item,
        montoReembolsar: this.montoReembolsar,
      }

      this.axios
        .post('reservations/cancele-service', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.$parent.$emit('getItem')

            /* this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.idStateCancele)
            }

            setTimeout(() => {
              window.location.reload()
            }, 100) */
          } else if (res.data.data.status === 406) {
            this.item.state_item = this.idStateConfirm
            this.$toast.error(this.$t('msg.errorRefundMonto'))
          } else {
            this.item.state_item = this.idStateConfirm
            this.$toast.error(this.$t('msg.errorRefund'))
          }
        })
        .finally(() => {
          this.loadingCancel = false
          this.setSearchingHotel(true)

          setTimeout(() => {
            this.setSearchingHotel(false)
          }, 100)
        })
    },

    canceleServiceProceso() {
      // this.item.state_item = this.idStateCancele
      this.showModalCanceleProceso = false
      this.loadingCancel = true

      // this.setSearchingHotel(true)

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        state_item: this.idStateCancele,
        item: this.item,
      }
      this.axios
        .post('reservations/cancele-service-proceso', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.$parent.$emit('getItem')

            /* this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.idStateCancele)
            }

            setTimeout(() => {
              window.location.reload()
            }, 100) */
          } else if (res.data.data.status === 406) {
            this.item.state_item = this.idStateConfirm
            this.$toast.error(this.$t('msg.errorRefundMonto'))
          } else {
            this.item.state_item = this.idStateConfirm
            this.$toast.error(this.$t('msg.errorRefund'))
          }
        })
        .finally(() => {
          this.loadingCancel = false
          this.setSearchingHotel(true)

          setTimeout(() => {
            this.setSearchingHotel(false)
          }, 100)
        })
    },

    printItem() {
      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        action: 'print',
        codes_items: [this.item.code],
      }

      this.printVoucher = true

      this.axios
        .post('reservations/print-voucher-items-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_document_item = res.data.data.history_document_item
          }
        })
        .finally(() => {
          this.printVoucher = false
        })
    },
    sendItem() {
      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        action: 'send',
        codes_items: [this.item.code],
      }

      this.sendVoucher = true

      this.axios
        .post('reservations/print-voucher-items-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_document_item = res.data.data.history_document_item
          }
        })
        .finally(() => {
          this.sendVoucher = false
        })
    },

    setAjusteRequered(parameter) {
      if (!this.item.ajuste_requered) {
        this.item.type_ajuste_requered = 'debito'
        this.item.monto_ajuste_requered = 0
      }
      if (!this.item.reembolso_requered) {
        this.item.monto_reembolso_requered = 0
      }

      // PERMITIR VOLVER DE CANCELADO A CONFIRMADO
      let volverStatusPreview = false

      this.loadingCanceleSwitch = true
      if (parameter === 'cancelado' && !this.item.cancelado) {
        volverStatusPreview = true
      }

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        item: this.item,
        code_item: this.item.code,
        parameter,
        volverStatusPreview,
      }
      this.axios
        .post('reservations/update-ajuste-item-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success) {
            if (res.data.data.parameter === 'cancelado') {
              // eslint-disable-next-line camelcase
              const { state_service_cancelada_id } = res.data.data
              // eslint-disable-next-line camelcase
              const { state_service_preview_id } = res.data.data
              if (volverStatusPreview) {
                // eslint-disable-next-line camelcase
                this.item.state_item = state_service_preview_id
                this.$emit('updateStateReservaItem', state_service_preview_id)
              } else {
                // eslint-disable-next-line camelcase
                this.item.state_item = state_service_cancelada_id
                this.$emit('updateStateReservaItem', state_service_cancelada_id)
              }
              this.item.last_status_id = res.data.data.last_status_id
            } else if (res.data.data.parameter === 'reclamado') {
              this.item.state_item = res.data.data.state_service_reclamada_id
              this.item.last_status_id = res.data.data.last_status_id
              this.$emit('updateStateReservaItem', res.data.data.state_service_reclamada_id)
            }

            this.construyendoListStates = true
            setTimeout(() => {
              this.construyendoListStates = false
            }, 100)
          }
        })
        .finally(() => {
          this.showModalCanceleSwitch = false
          this.loadingCanceleSwitch = false
        })
    },

    showModal(room) {
      this.dialogPolicy = true
      room.tarifasSeason.forEach(tar => {
        if (tar.code === room.codeTarifa) {
          this.policyCancele = tar
        }
      })

      if (this.planes.filter(e => e.siglas === room.plan).length > 0) {
        this.regimenSelect.name = this.planes.filter(e => e.siglas === room.plan)[0].name
        this.regimenSelect.siglas = this.planes.filter(e => e.siglas === room.plan)[0].siglas
      }
    },
    penalidad(data) {
      let result = ''
      if (data.antes_de) {
        result += `${this.$t('lbl.antesDe')} ${data.from} ${data.param === 'days' ? this.$t('lbl.day') : data.param}, `
      } else {
        result += `${this.$t('lbl.entre')} ${data.from} ${this.$t('lbl.and')} ${data.to} ${data.param}, `
      }
      result += !data.penalidad_in_pc ? this.currencySymbolFacturar + data.penalidad_valor : data.penalidad_valor

      result += data.penalidad_in_pc ? ' %' : ''
      result += ` ${this.$t('lbl.of')}`
      if (data.all_rooms) {
        result += ` ${this.$t('lbl.roomsAll')}`
      } else {
        result += ` ${this.$tc('lbl.roomsCount', data.cant_rooms)}`
      }
      result += ` ${this.$t('lbl.for')}`
      if (data.all_nights) {
        result += ` ${this.$t('lbl.nightAll')}`
      } else {
        result += ` ${this.$tc('lbl.nightCount', data.cant_nigths)}`
      }

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
