<template>
  <v-row
    v-if="!isLoading"
    class="mt-2"
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-select
        v-model="model.tipo_afiliado_id"
        :items="typeAfiliados"
        item-text="name"
        item-value="id"
        :label="$t('menu.typeAfiliado')"
        outlined
        dense
        hide-details
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-btn
        v-if="!editMarkup"
        color="primary"
        class="mb-4 me-3"
        :disabled="!model.tipo_afiliado_id"
        @click="editMarkup = !editMarkup"
      >
        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
        <span>{{ $t('btn.edit') }}</span>
      </v-btn>
      <!--<v-btn
        v-else
        color="error"
        class="mb-4 me-3"
        @click="cancelTodayAction"
      >
        <v-icon>{{ icons.mdiCancel }}</v-icon>
        <span>{{ $t('btn.cancele') }}</span>
      </v-btn>-->
    </v-col>

    <v-col
      cols="12"
      md="12"
    >
      <v-tabs
        v-if="model.tipo_afiliado_id"
        v-model="currentTab"
      >
        <v-tab v-if="productsPermit.includes('vuelos') || productsPermit.includes('flights')">
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-airplane</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('menu.flights') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab v-if="productsPermit.includes('cars')">
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-car</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('menu.cars') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab v-if="productsPermit.includes('hotels')">
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-office-building</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('menu.hotels') }}</span>
          </v-tooltip>
        </v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items
        v-if="model.tipo_afiliado_id"
        v-model="currentTab"
      >
        <v-tab-item v-if="productsPermit.includes('vuelos') || productsPermit.includes('flights')">
          <FlightsTab
            v-if="showTabs"
            :is-comition="false"
            :proveedores="proveedores"
            :contrates="contrates"
            :flights="flights"
            :type-afiliados="user.nivel !== 2 ? typeAfiliados.filter(e => e.id === model.tipo_afiliado_id) : []"
            :edit-markup="editMarkup"
            :user="user"
          />
        </v-tab-item>
        <v-tab-item v-if="productsPermit.includes('cars')">
          <CarsTab
            v-if="showTabs"
            :is-comition="false"
            :proveedores-car="proveedoresCar"
            :contrates-car="contratesCar"
            :categories-car="categoriesCar"
            :marcas-car="marcasCar"
            :type-afiliados="user.nivel !== 2 ? typeAfiliados.filter(e => e.id === model.tipo_afiliado_id) : []"
            :edit-markup="editMarkup"
            :user="user"
          />
        </v-tab-item>
        <v-tab-item v-if="productsPermit.includes('hotels')">
          <HotelsTab
            v-if="showTabs"
            :is-comition="false"
            :proveedores-hotel="proveedoresHotel"
            :contrates-hotel="contratesHotel"
            :hotels="hotels"
            :rooms="rooms"
            :type-afiliados="user.nivel !== 2 ? typeAfiliados.filter(e => e.id === model.tipo_afiliado_id) : []"
            :edit-markup="editMarkup"
            :user="user"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import FlightsTab from './tabs/Flights.vue'
import CarsTab from './tabs/Cars.vue'
import HotelsTab from './tabs/Hotels.vue'

export default {
  components: {
    FlightsTab,
    CarsTab,
    HotelsTab,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    typeAfiliados: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    products: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    proveedoresCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    contratesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    categoriesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    marcasCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    proveedoresHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    contratesHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    hotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    contrates: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    flights: {
      type: Array,
    },
  },
  data() {
    return {
      currentTab: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      showTabs: true,
      productsPermit: [],
      editMarkup: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },

  /* computed: {
    productsPermit() {
      const arr = []
      if (this.model.products.length > 0) {
        this.products.forEach(product => {
          if (this.model.products.includes(product.id)) {
            arr.push(product.entity)
          }
        })
      }

      return arr
    },
  }, */
  created() {
    this.productsPermit = []
    if (this.model.products.length > 0) {
      this.products.forEach(product => {
        if (this.model.products.includes(product.id)) {
          this.productsPermit.push(product.entity)
        }
      })
    }
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
