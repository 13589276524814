<template>
  <fragment v-if="!isLoading">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="ofertEBBDates.identificador"
              :disabled="$store.state.app.onlyShow"
              :label="$t('lbl.identificador')"
              outlined
              dense
              hide-details
            ></v-text-field>
            <strong>{{ $t('lbl.publicada') }}</strong>: {{ computedFechaPublicFormatted }}
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="ofertEBBDates.type_offert"
              :disabled="$store.state.app.onlyShow"
              :items="typeOffert"
              :label="$t('lbl.typeOffert')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.typeOffert') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row class="mt-2">
              <v-col
                cols="12"
                md="4"
                class="mt-3"
              >
                <strong class="text-center">{{ $t('lbl.reserve') }}</strong>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-menu
                  v-model="menuFromReserva"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedFromDateReservaFormatted"
                      :label="$t('lbl.from')"
                      :disabled="$store.state.app.onlyShow"
                      persistent-hint
                      class="my-input"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.fromReserva"
                    no-title
                    locale="es"
                    :show-current="false"
                    :min="new Date(Date.now()).toISOString().substr(0, 10)"
                    @input="
                      menuFromReserva = false
                      activeToDateReserva()
                    "
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-menu
                  v-model="menuToReserva"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedToDateReservaFormatted"
                      :label="$t('lbl.to')"
                      :disabled="ofertEBBDates.fromReserva === null || $store.state.app.onlyShow"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      class="my-input mb-0"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.toReserva"
                    no-title
                    locale="es"
                    :show-current="false"
                    :min="
                      ofertEBBDates.fromReserva
                        ? $moment(ofertEBBDates.fromReserva)
                          .add(1, 'day')
                          .toDate()
                          .toISOString()
                          .substr(0, 10)
                        : ''
                    "
                    @input="menuToReserva = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!--<v-row class="mt-0">
              <v-col
                cols="12"
                md="4"
                class="mt-3"
              >
                <strong class="text-center">{{ $t('lbl.travel') }}</strong>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-menu
                  v-model="menuFromViaje"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedFromDateViajeFormatted"
                      :label="$t('lbl.from')"
                      :disabled="$store.state.app.onlyShow"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      class="my-input mb-0"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.fromViaje"
                    no-title
                    :min="new Date(Date.now()).toISOString().substr(0, 10)"
                    locale="es"
                    :show-current="false"
                    @input="
                      menuFromViaje = false
                      activeToDateViaje()
                    "
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-menu
                  v-model="menuToViaje"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedToDateViajeFormatted"
                      :label="$t('lbl.to')"
                      :disabled="ofertEBBDates.fromViaje === null || $store.state.app.onlyShow"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      class="my-input"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.toViaje"
                    no-title
                    locale="es"
                    :show-current="false"
                    :min="
                      ofertEBBDates.fromViaje
                        ? $moment(ofertEBBDates.fromViaje)
                          .add(1, 'day')
                          .toDate()
                          .toISOString()
                          .substr(0, 10)
                        : ''
                    "
                    @input="menuToViaje = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>-->
            <v-row class="mt-0">
              <v-col
                cols="12"
                md="4"
                class="mt-3"
              >
                <strong class="text-center">Rooming</strong>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="ofertEBBDates.rooming"
                  :label="$t('lbl.daysBefore')"
                  :disabled="$store.state.app.onlyShow"
                  class="my-input"
                  outlined
                  dense
                  hide-details
                  type="number"
                ></v-text-field>
                <!--<v-menu
                  v-model="menuRooming"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedRoomingDateFormatted"
                      :label="$t('lbl.before')"
                      :disabled="$store.state.app.onlyShow"
                      class="my-input"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.rooming"
                    no-title
                    :min="new Date(Date.now()).toISOString().substr(0, 10)"
                    locale="es"
                    :show-current="false"
                    @input="menuRooming = false"
                  ></v-date-picker>
                </v-menu>-->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="5"
        offset-md="1"
      >
        <strong>{{ $t('menu.modelo') }}:</strong><br />
        <v-radio-group
          v-model="ofertEBBDates.modelo"
          :disabled="$store.state.app.onlyShow"
          row
          hide-details
          class="mt-0"
          @change="changeModelo"
        >
          <v-radio
            :label="$t('lbl.descuento')"
            value="descuento"
          ></v-radio>
          <v-radio
            :label="$t('lbl.price')"
            value="precio"
          ></v-radio>
        </v-radio-group>

        <v-switch
          v-if="ofertEBBDates.modelo === 'precio'"
          v-model="ofertEBBDates.by_suplement_reductions"
          :disabled="$store.state.app.onlyShow"
          :label="$t('lbl.suplementReducc')"
          hide-details
          @click="bySuplementReduc"
        ></v-switch>

        <v-switch
          v-if="ofertEBBDates.modelo === 'precio'"
          v-model="ofertEBBDates.price_by_room"
          :disabled="$store.state.app.onlyShow"
          :label="$t('lbl.priceByRoom')"
          hide-details
          @click="updatePriceByRoom"
        ></v-switch>

        <v-switch
          v-model="ofertEBBDates.stopSale"
          :disabled="$store.state.app.onlyShow"
          :label="$t('lbl.cancelada')"
          hide-details
          class="mt-2"
          @click="updateStopSale"
        ></v-switch>
        <fragment v-if="ofertEBBDates.stopSale">
          <strong>{{ $t('lbl.cancelada') }}</strong>: {{ computedFechaCanceledFormatted }}
        </fragment>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col
        cols="12"
        md="8"
      >
        <v-row class="mt-0">
          <v-col
            cols="12"
            md="3"
            class="mt-3"
          >
            <strong class="text-center">{{ $t('lbl.pay') }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <OfertsHotelPays
              v-for="(pay, indexPay) in ofertEBBDates.pays"
              :key="indexPay"
              :pay="pay"
              :pos="indexPay"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--<v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="10"
              >
                <v-menu
                  v-model="menuFecha"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedFechaFormatted"
                      :label="$t('lbl.date')"
                      :disabled="$store.state.app.onlyShow"
                      persistent-hint
                      class="my-input"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ofertEBBDates.dateUpdate"
                    no-title
                    locale="es"
                    :show-current="false"
                    :min="new Date(Date.now()).toISOString().substr(0, 10)"
                    @input="menuFecha = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-cols="12"
                md="2"
              >
                <v-tooltip
                  v-if="!$store.state.app.onlyShow && ofertEBBDates.dateUpdate !== ofertEBBDates.dateUpdateOld"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      v-bind="attrs"
                      fab
                      dark
                      x-small
                      color="primary"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCheckBold }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.confirmDateUpdate') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <strong>{{ $t('lbl.update') }}</strong>: {{ computedFechaUpdateFormatted }}
            <v-tooltip
              v-if="!$store.state.app.onlyShow && ofertEBBDates.dateUpdateSaved !== null"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  v-bind="attrs"
                  fab
                  dark
                  x-small
                  color="error"
                  v-on="on"
                  @click="cancelDateUpdateStopSaleOfertaSeasonContrateHotel(pos)"
                >
                  <v-icon small>
                    {{ icons.mdiCancel }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('lbl.canceleDateUpdate') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="!$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              v-bind="attrs"
              fab
              dark
              x-small
              color="error"
              v-on="on"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-row v-if="ofertEBBDates.modelo === 'descuento'">
          <v-col
            cols="12"
            md="5"
            class="mt-2"
          >
            <strong class="text-center">{{ $t('lbl.calculateBy') }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <v-select
              v-model="ofertEBBDates.calculadoPor"
              :disabled="$store.state.app.onlyShow"
              :items="calculators"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
              class="my-input mb-0"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="ofertEBBDates.modelo === 'descuento'"
        cols="12"
        md="12"
      >
        <OfertsHotelDescuentos
          v-for="(descuento, indexDesc) in ofertEBBDates.descuentos"
          :key="indexDesc"
          :descuento="descuento"
          :pos="indexDesc"
          :all-rooms="allRooms"
        />
      </v-col>
      <v-col
        v-if="ofertEBBDates.modelo === 'precio' && !ofertEBBDates.by_suplement_reductions"
        cols="12"
        md="12"
      >
        <OfertsHotelPrices
          v-for="(price, indexPric) in ofertEBBDates.rooms_by_price"
          :key="indexPric"
          :price="price"
          :pos="indexPric"
          :all-rooms="allRooms"
        />
      </v-col>
      <v-col
        v-if="ofertEBBDates.modelo === 'precio' && ofertEBBDates.by_suplement_reductions"
        cols="12"
        md="12"
      >
        <OfertsHotelPricesBySuplement
          v-for="(price, indexPric) in ofertEBBDates.rooms_by_price_suplement"
          :key="indexPric"
          :price="price"
          :pos="indexPric"
          :all-rooms="allRooms"
          :all-suplement="allSuplement"
          :all-reductions="allReductions"
          :only-adult="onlyAdult"
          :all-include="allInclude"
        />
      </v-col>-->
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
  mdiCheckBold,
} from '@mdi/js'

import OfertsHotelPays from './OfertsHotelPays.vue'

// import OfertsHotelDescuentos from './OfertsHotelDescuentos.vue'
// import OfertsHotelPrices from './OfertsHotelPrices.vue'
// import OfertsHotelPricesBySuplement from './OfertsHotelPricesBySuplement.vue'

export default {
  components: {
    OfertsHotelPays,

    // OfertsHotelDescuentos,
    // OfertsHotelPrices,
    // OfertsHotelPricesBySuplement,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReductions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    idOfertEBB: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      showRowOferta: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
        mdiCheckBold,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsRooms: [],
      calculators: [],

      /* menuFromViaje: false,
      menuToViaje: false, */
      menuFromReserva: false,
      menuToReserva: false,
      menuRooming: false,
      menuFecha: false,
      typeOffert: [],
    }
  },
  computed: {
    ...mapState({
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      ofertEBBDates: state => state.app.ofertEBBDates,
    }),

    /* computedFromDateViajeFormatted() {
      return this.ofertEBBDates.fromViaje ? this.$moment(this.ofertEBBDates.fromViaje).format('D-MMM-YY') : ''
    }, */
    computedFromDateReservaFormatted() {
      return this.ofertEBBDates.fromReserva ? this.$moment(this.ofertEBBDates.fromReserva).format('D-MMM-YY') : ''
    },
    computedFechaFormatted() {
      return this.ofertEBBDates.dateUpdate ? this.$moment(this.ofertEBBDates.dateUpdate).format('D-MMM-YY') : ''
    },
    computedFechaUpdateFormatted() {
      return this.ofertEBBDates.dateUpdateOld ? this.$moment(this.ofertEBBDates.dateUpdateOld).format('D-MMM-YY') : ''
    },
    computedFechaPublicFormatted() {
      return this.ofertEBBDates.datePublic ? this.$moment(this.ofertEBBDates.datePublic).format('D-MMM-YY') : ''
    },
    computedFechaCanceledFormatted() {
      return this.ofertEBBDates.dateCancele ? this.$moment(this.ofertEBBDates.dateCancele).format('D-MMM-YY') : ''
    },
    computedRoomingDateFormatted() {
      return this.ofertEBBDates.rooming ? this.$moment(this.ofertEBBDates.rooming).format('D-MMM-YY') : ''
    },

    /* computedToDateViajeFormatted() {
      return this.ofertEBBDates.toViaje ? this.$moment(this.ofertEBBDates.toViaje).format('D-MMM-YY') : ''
    }, */
    computedToDateReservaFormatted() {
      return this.ofertEBBDates.toReserva ? this.$moment(this.ofertEBBDates.toReserva).format('D-MMM-YY') : ''
    },
    roomsFree() {
      const result = []
      this.allRooms.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].room_id === element.id) {
            esta = true
          }
        }
        if (esta) {
          result.push(element)
        }
      })

      return result
    },
  },
  created() {
    this.typeOffert.push({
      name: this.$t('lbl.offertSpetial'),
      slug: 'offert_special',
    })
    this.typeOffert.push({
      name: 'EBB',
      slug: 'ebb',
    })

    this.calculators.push({
      slug: 'estancia',
      name: this.$t('lbl.estancia'),
    })

    this.updateOnlyAdult(this.onlyAdult)
    this.updateAllInclude(this.allInclude)
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations([
      'deleteOfertaSeasonContrateHotel',
      'updateOfertPay',
      'emptyDescuentosOfertEBB',
      'emptyRoomByPriceHotelOfertEBB',
      'emptyRoomByPriceBySuplementHotelOfertEBB',
      'confirmDateUpdateStopSaleOfertaSeasonContrateHotel',
      'cancelDateUpdateStopSaleOfertaSeasonContrateHotel',
      'updateOnlyAdult',
      'updateAllInclude',
      'initAllRoomByPriceHotelOfertEBB',
      'initAllDescuentosOfertEBB',
      'updateOfertEBBDates',
    ]),
    showItemOferta() {
      this.showRowOferta = !this.showRowOferta
    },
    updateStopSale() {
      let action = 'Actualizada'
      if (this.ofertEBBDates.stopSale) {
        action = 'Cancelada'
        this.updateOfertPay(new Date(Date.now()).toISOString().substr(0, 10))
      } else {
        this.updateOfertPay(null)
      }

      const json = {
        stopSale: this.ofertEBBDates.stopSale,
        date: new Date(Date.now()).toISOString().substr(0, 10),
        action,
        idUser: this.user.id,
        nameUser: `${this.user.name} ${this.user.apellidos}`,
      }

      this.axios
        .put(`oferts-ebb/update-historial-cancele/${this.idOfertEBB}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.updateOfertEBBDates(response.data.data.item)
          }
        })
        .catch(error => console.log(error))
    },
    updatePriceByRoom() {
      const action = 'Actualizada'

      const json = {
        price_by_room: this.ofertEBBDates.price_by_room,
        date: new Date(Date.now()).toISOString().substr(0, 10),
        action,
        idUser: this.user.id,
        nameUser: `${this.user.name} ${this.user.apellidos}`,
      }

      this.axios
        .put(`oferts-ebb/update-historial-price/${this.idOfertEBB}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.updateOfertEBBDates(response.data.data.item)
          }
        })
        .catch(error => console.log(error))
    },
    changeModelo() {
      if (this.ofertEBBDates.modelo === 'precio') {
        // this.ofertEBBDates.calculadoPor = null
        this.emptyDescuentosOfertEBB()
      } else {
        this.emptyRoomByPriceHotelOfertEBB()
        this.emptyRoomByPriceBySuplementHotelOfertEBB()
      }

      // INICIALIZAR LOS HOTELES
      if (this.ofertEBBDates.modelo === 'precio') {
        this.initAllRoomByPriceHotelOfertEBB()
      } else if (this.ofertEBBDates.modelo === 'descuento') {
        this.initAllDescuentosOfertEBB()
      }
    },
    bySuplementReduc() {
      if (this.ofertEBBDates.by_suplement_reductions) {
        this.emptyRoomByPriceHotelOfertEBB()
      } else {
        this.emptyRoomByPriceBySuplementHotelOfertEBB()
      }
    },
    activeToDateViaje() {
      setTimeout(() => {
        // this.$refs.dateToViaje.focus()
        this.menuToViaje = true
      }, 100)
    },
    activeToDateReserva() {
      setTimeout(() => {
        // this.$refs.dateToReserva.focus()
        this.menuToReserva = true
      }, 100)
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
