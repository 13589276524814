<template>
  <div v-if="!isLoadingPagePermisos">
    <v-container
      grid-list-xs
      class="px-0"
    >
      <v-row>
        <v-col cols="12">
          <template>
            <v-expansion-panels accordion>
              <!--BOOKING-->
              <v-expansion-panel
                v-if="permisos.includes('cotizador:list')
                  || permisos.includes('reservas:list')
                  || permisos.includes('refund:list')
                  || permisos.includes('pvp:list')
                  || permisos.includes('tarifarios:list')
                  || permisos.includes('tarifario_neto:list')
                "
                id="booking"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.booking') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--BOKING-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Booking
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--COTIZADOR-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('cotizador:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.cotizador') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Cotizador
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--RESERVAS-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('reservas:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.reservas') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Reservas
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--REFUND-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('refund:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.refund') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Refund
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PVP-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('pvp:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.pvp') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <PVP
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARIFARIOS-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('tarifarios:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tarifarios') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Tarifarios
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARIFARIOS NETOS-->
                    <tr v-if="$store.state.app.showBooking && permisos.includes('tarifario_neto:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tarifariosNetos') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TarifarioNeto
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--PRODUCT-->
              <v-expansion-panel
                v-if="
                  permisos.includes('flight:list') || permisos.includes('car:list') || permisos.includes('hotel:list')
                "
                id="products"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.products') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--PRODUCT-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Products
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--VUELOS-->
                    <tr v-if="$store.state.app.showProduct && permisos.includes('flight:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.flights') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ProductFlights
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AUTOS-->
                    <tr v-if="$store.state.app.showProduct && permisos.includes('car:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.cars') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ProductCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--HOTELES-->
                    <tr v-if="$store.state.app.showProduct && permisos.includes('hotel:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.hotels') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ProductHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--CONTRATOS-->
              <v-expansion-panel
                v-if="
                  permisos.includes('contrate_flights:list') ||
                    permisos.includes('contrate_cars:list') ||
                    permisos.includes('contrate_hotels:list') ||
                    permisos.includes('stopSale:list') ||
                    permisos.includes('oferts:list')
                "
                id="contrates"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.contratos') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--CONTRATOS-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Contrate
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--VUELOS-->
                    <tr v-if="$store.state.app.showContrate && permisos.includes('contrate_flights:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.flights') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ContrateFlights
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AUTOS-->
                    <tr v-if="$store.state.app.showContrate && permisos.includes('contrate_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.cars') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ContrateCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--HOTELES-->
                    <tr v-if="$store.state.app.showContrate && permisos.includes('contrate_hotels:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.hotels') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ContrateHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PAROS DE VENTA-->
                    <tr v-if="$store.state.app.showContrate && permisos.includes('stopSale:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.stopSale') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <StopSale
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--OFERTAS-->
                    <tr v-if="$store.state.app.showContrate && permisos.includes('oferts:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.oferts') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Oferts
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--ADMINISTRACION-->
              <v-expansion-panel
                v-if="
                  permisos.includes('contabilidad:list') ||
                    permisos.includes('markup:list') ||
                    permisos.includes('comisiones:list')
                "
                id="gestion"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.gestion') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--ADMINISTRACION-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Administration
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CONTABILIDAD
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('contabilidad:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.contability') }}
                        </p>
                      </td>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Contability
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>-->
                    <!--CUENTAS X COBRAR-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('ctas_x_cobrar:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 mt-1 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.ctasXC') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CuentasXCobrar
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CUENTAS X PAGAR-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('ctas_x_pagar:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 mt-1 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.ctasXP') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CuentasXPagar
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PAGOS RECIBIDOS-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('pays_recibidos:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 mt-1 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.paysRec') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <PagosRecibidos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PAGOS EMITIDOS-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('pays_emitidos:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 mt-1 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.paysEmit') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <PagosEmitidos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--FINANZAS-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('finanzas:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.finanzas') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Finanzas
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--MARKUPS-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('markup:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.markups') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Markups
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--COMISIONES-->
                    <tr v-if="$store.state.app.showAdministration && permisos.includes('comisiones:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.comitions') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Comitions
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--CONFIGURACION-->
              <v-expansion-panel
                v-if="
                  permisos.includes('configuration_general:list') ||
                    permisos.includes('perfil_afiliado:list') ||
                    permisos.includes('user_register:list') ||
                    permisos.includes('role:list') ||
                    permisos.includes('user:list') ||
                    permisos.includes('agente:list') ||
                    permisos.includes('newletters:list')
                "
                id="configuration"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.configuration') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--CONFIGURACION-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Configuration
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--IDENTIFICADOR-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('configuration_general:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.dataGeneral') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Identificador
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PERFILES-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('perfil_afiliado:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.perfilUser') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Perfiles
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--COMPAÑIAS-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('user_register:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.companies') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Afiliados
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--ROLES-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('role:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.roles') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Roles
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--USERS-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('user:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.users') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Users
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--USERS SON-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('user:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.usersSon') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <UsersSon
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AGENTES-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('agente:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.agentes') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Agentes
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--NEWLETTERS-->
                    <tr v-if="$store.state.app.showConfiguration && permisos.includes('newletters:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Newsletter
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Newletters
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--APIS-->
              <v-expansion-panel
                v-if="
                  permisos.includes('api_tti:list') ||
                    permisos.includes('api_amadeus:list') ||
                    permisos.includes('api_redsys:list') ||
                    permisos.includes('api_keys:list') ||
                    permisos.includes('api_authorize_net:list') ||
                    permisos.includes('api_sibs:list') ||
                    permisos.includes('currencyapi:list') ||
                    permisos.includes('api_hotetec:list') ||
                    permisos.includes('api_copa_connect:list') ||
                    permisos.includes('api_dingus:list') ||
                    permisos.includes('api_juniper:list') ||
                    permisos.includes('api_solways:list') ||
                    permisos.includes('api_deepl:list') ||
                    permisos.includes('api_bigdata:list')
                "
                id="configuration"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >API</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--APIS-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <APIs
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API KEYS-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          API Propias
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <api-keys
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API TTI-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          TTI Zenith
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiTTI
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API Amadeus-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Amadeus
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiAmadeus
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API Redsys-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          RedSys
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiRedsys
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API SIBS-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          SIBS
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiSIBS
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API Authorize.net-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_keys:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Authorize.net
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiAuthorizeNet
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API PayPal-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_paypal:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          PayPal
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiPayPal
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API CURRENCYAPI-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('currencyapi:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Currencyapi
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiCurrency
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API HOTETEC-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_hotetec:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Hotetec
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiHotetec
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API DINGUS-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_dingus:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Dingus
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiDingus
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API SOLWAYS-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_solways:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Solways
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiSolways
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API JUNIPER-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_juniper:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Juniper
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiJuniper
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API Copa Connect-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_copa_connect:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Copa Connect
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiCopaConnect
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API DeepL-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_deepl:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          DeepL
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiDeepL
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--API BIGDATA-->
                    <tr v-if="$store.state.app.showAPIs && permisos.includes('api_bigdata:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          BigData
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ApiBigData
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--APPS-->
              <v-expansion-panel
                v-if="
                  permisos.includes('app_backoffice:list')
                "
                id="apps"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >APPs</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--ALL--
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <APIs
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>-->
                    <!--BACKOFFICE-->
                    <tr v-if="$store.state.app.showAPPs && permisos.includes('app_backoffice:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          BackOffice
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <AppBackoffice
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--NOMENCLADORES-->
              <v-expansion-panel
                v-if="
                  permisos.includes('nom_suplemento_contrate_fligths:list') ||
                    permisos.includes('nomenclators_tti:list') ||
                    permisos.includes('nom_conditions_generals_fligths:list') ||
                    permisos.includes('nom_aerolines:list') ||
                    permisos.includes('airplane:list') ||
                    permisos.includes('airport:list') ||
                    permisos.includes('nom_clase_flights:list') ||
                    permisos.includes('nom_tarifa_flights:list') ||
                    permisos.includes('nom_tarjeta_fidelidad_flights:list') ||
                    permisos.includes('nom_code_ofac_flights:list') ||
                    permisos.includes('nom_dimensiones_flights:list') ||
                    permisos.includes('nom_peso_equipaje_flights:list') ||
                    permisos.includes('nom_politica_tarifaria_flights:list') ||
                    permisos.includes('nom_suplemento_contrate_cars:list') ||
                    permisos.includes('nom_conditions_generals_cars:list') ||
                    permisos.includes('nom_oficina_renta_cars:list') ||
                    permisos.includes('nom_tags_pto_recogida_cars:list') ||
                    permisos.includes('nom_transmision_cars:list') ||
                    permisos.includes('nom_type_auto_cars:list') ||
                    permisos.includes('nom_marca_cars:list') ||
                    permisos.includes('nom_model_cars:list') ||
                    permisos.includes('nom_combustible_cars:list') ||
                    permisos.includes('nom_caracteristica_cars:list') ||
                    permisos.includes('nom_categories_cars:list') ||
                    permisos.includes('nom_rentadora_cars:list') ||
                    permisos.includes('nom_tarifas_cars:list') ||
                    permisos.includes('nom_tarifas_x_km_cars:list') ||
                    permisos.includes('nom_edades_cars:list') ||
                    permisos.includes('nom_tag_imagen_cars:list') ||
                    permisos.includes('nom_suplemento_contrate_hotels:list') ||
                    permisos.includes('nom_reducciones_contrate_hotels:list') ||
                    permisos.includes('nom_conditions_generals_hotels:list') ||
                    permisos.includes('nom_tipo_destino_hotels:list') ||
                    permisos.includes('nom_operador_hotels:list') ||
                    permisos.includes('nom_marca_asociada_hotels:list') ||
                    permisos.includes('nom_categorias_hotels:list') ||
                    permisos.includes('nom_intereses_hotels:list') ||
                    permisos.includes('nom_servicios_hotels:list') ||
                    permisos.includes('nom_habitacion_hotels:list') ||
                    permisos.includes('nom_regimen_alimenticio:list') ||
                    permisos.includes('nom_edad_hotels:list') ||
                    permisos.includes('nom_tag_imagen_hotels:list') ||
                    permisos.includes('nom_categories_conditions:list') ||
                    permisos.includes('tipo_afiliado:list') ||
                    permisos.includes('nomenclators_countries:list') ||
                    permisos.includes('nom_categories_tarifarios_hotels:list')
                "
                id="nomenclators"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.nomenclador') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <!--ALL-->
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Nomencladores
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--VUELOS-->
                  <table
                    v-if="
                      permisos.includes('nom_suplemento_contrate_fligths:list') ||
                        permisos.includes('nomenclators_tti:list') ||
                        permisos.includes('nom_conditions_generals_fligths:list') ||
                        permisos.includes('nom_aerolines:list') ||
                        permisos.includes('airplane:list') ||
                        permisos.includes('airport:list') ||
                        permisos.includes('nom_clase_flights:list') ||
                        permisos.includes('nom_tarifa_flights:list') ||
                        permisos.includes('nom_tarjeta_fidelidad_flights:list') ||
                        permisos.includes('nom_code_ofac_flights:list') ||
                        permisos.includes('nom_dimensiones_flights:list') ||
                        permisos.includes('nom_peso_equipaje_flights:list') ||
                        permisos.includes('nom_politica_tarifaria_flights:list')
                    "
                  >
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header pb-1"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.flights') }}
                          <v-icon
                            class="ml-3"
                            @click="showNomencladoresFlightsItems()"
                          >
                            {{
                              $store.state.app.showNomencladoresFlights ? icons.mdiChevronDown : icons.mdiChevronRight
                            }}
                          </v-icon>
                        </p>
                      </td>
                      <!--ALL CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <NomencladoresVuelos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TTI ZENITH-->
                    <tr v-if="$store.state.app.showNomencladoresFlights && permisos.includes('nomenclators_tti:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          TTI Zenith
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TTI
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--SUPLEMENTOS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights &&
                          permisos.includes('nom_suplemento_contrate_fligths:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.suplementos') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <SuplementoFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CONDITONS GENERAL-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights &&
                          permisos.includes('nom_conditions_generals_fligths:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.conditionGenerals') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ConditionsGeneralsFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AEROLINEAS-->
                    <tr v-if="$store.state.app.showNomencladoresFlights && permisos.includes('nom_aerolines:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.aerolinea') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Aeroline
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AERONAVES-->
                    <tr v-if="$store.state.app.showNomencladoresFlights && permisos.includes('airplane:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.aeronave') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Aeronave
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--AEROPUERTOS-->
                    <tr v-if="$store.state.app.showNomencladoresFlights && permisos.includes('airport:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.airport') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Airport
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CLASES-->
                    <tr v-if="$store.state.app.showNomencladoresFlights && permisos.includes('nom_clase_flights:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.class') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Clases
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARIFAS-->
                    <tr
                      v-if="$store.state.app.showNomencladoresFlights && permisos.includes('nom_tarifa_flights:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tarifas') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TarifasFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--DIMENSIONES-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights && permisos.includes('nom_dimensiones_flights:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.dimensiones') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Dimensiones
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PESOS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights && permisos.includes('nom_peso_equipaje_flights:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.pesos') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Pesos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARJETAS FIDELIDAD-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights &&
                          permisos.includes('nom_tarjeta_fidelidad_flights:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.cardFidelidad') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TarjetasFidelidadFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CODES OFAC-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights && permisos.includes('nom_code_ofac_flights:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.codeOFAC') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CodeOFACFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--POLITICAS TARIFARIAS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresFlights &&
                          permisos.includes('nom_politica_tarifaria_flights:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.politicaTarifa') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <PoliticaTarifariaFlight
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--AUTOS-->
                  <table
                    v-if="
                      permisos.includes('nom_suplemento_contrate_cars:list') ||
                        permisos.includes('nom_conditions_generals_cars:list') ||
                        permisos.includes('nom_oficina_renta_cars:list') ||
                        permisos.includes('nom_tags_pto_recogida_cars:list') ||
                        permisos.includes('nom_transmision_cars:list') ||
                        permisos.includes('nom_type_auto_cars:list') ||
                        permisos.includes('nom_marca_cars:list') ||
                        permisos.includes('nom_model_cars:list') ||
                        permisos.includes('nom_combustible_cars:list') ||
                        permisos.includes('nom_caracteristica_cars:list') ||
                        permisos.includes('nom_categories_cars:list') ||
                        permisos.includes('nom_rentadora_cars:list') ||
                        permisos.includes('nom_tarifas_cars:list') ||
                        permisos.includes('nom_tarifas_x_km_cars:list') ||
                        permisos.includes('nom_edades_cars:list') ||
                        permisos.includes('nom_tag_imagen_cars:list')
                    "
                  >
                    <tr>
                      <td
                        colspan="
                    2"
                        class="leftColMine header pb-1"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.cars') }}
                          <v-icon
                            class="ml-5"
                            @click="showNomencladoresCarsItems()"
                          >
                            {{ $store.state.app.showNomencladoresCars ? icons.mdiChevronDown : icons.mdiChevronRight }}
                          </v-icon>
                        </p>
                      </td>
                      <!--ALL CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <NomencladoresAutos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--SUPLEMENTOS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresCars && permisos.includes('nom_suplemento_contrate_cars:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.suplementos') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <SuplementoCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CONDITIONS GENERAL-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresCars && permisos.includes('nom_conditions_generals_cars:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.conditionGenerals') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ConditionsGeneralsCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TRANSMISIONES-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_transmision_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.trasnmision') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Tranmisiones
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TYPE AUTO-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_type_auto_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.typeCar') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TypeAuto
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--MARCAS-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_marca_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.marca') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Marca
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--MODELOS-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_model_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.modelo') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Modelos
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--COMBUSTIBLES-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_combustible_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.combustible') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Combustibles
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CARACTERISTICAS-->
                    <tr
                      v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_caracteristica_cars:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.caracteristicas') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Caracteristicas
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CATEGORIAS-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_categories_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.category') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Categorias
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--RENTADORAS-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_rentadora_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.rentadora') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Rentadora
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARIFAS-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_tarifas_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tarifas') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TarifasCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TARIFAS POR KM-->
                    <tr
                      v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_tarifas_x_km_cars:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tarifasXKm') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TarifasXKmCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--EDAD CONDUCTOR-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_edades_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('lbl.edadConductor') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <EdadConductor
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--PTO RECOGIDA-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresCars && permisos.includes('nom_tags_pto_recogida_cars:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.ptoRecogida') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <PtoRecogida
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--OFICINAS RENTA-->
                    <tr
                      v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_oficina_renta_cars:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.oficinaRenta') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <OficinasRenta
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TAGS IMG-->
                    <tr v-if="$store.state.app.showNomencladoresCars && permisos.includes('nom_tag_imagen_cars:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tagsImg') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TagImagenCars
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--HOTELES-->
                  <table
                    v-if="
                      permisos.includes('nom_suplemento_contrate_hotels:list') ||
                        permisos.includes('nom_reducciones_contrate_hotels:list') ||
                        permisos.includes('nom_conditions_generals_hotels:list') ||
                        permisos.includes('nom_tipo_destino_hotels:list') ||
                        permisos.includes('nom_operador_hotels:list') ||
                        permisos.includes('nom_marca_asociada_hotels:list') ||
                        permisos.includes('nom_categorias_hotels:list') ||
                        permisos.includes('nom_intereses_hotels:list') ||
                        permisos.includes('nom_servicios_hotels:list') ||
                        permisos.includes('nom_habitacion_hotels:list') ||
                        permisos.includes('nom_regimen_alimenticio:list') ||
                        permisos.includes('nom_edad_hotels:list') ||
                        permisos.includes('nom_tag_imagen_hotels:list') ||
                        permisos.includes('nom_categories_tarifarios_hotels:list')
                    "
                  >
                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header pb-1"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.hotels') }}
                          <v-icon
                            class="ml-2"
                            @click="showNomencladoresHotelsItems()"
                          >
                            {{
                              $store.state.app.showNomencladoresHotels ? icons.mdiChevronDown : icons.mdiChevronRight
                            }}
                          </v-icon>
                        </p>
                      </td>
                      <!--ALL CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <NomencladoresHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--SUPLEMENTOS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels &&
                          permisos.includes('nom_suplemento_contrate_hotels:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.suplementos') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <SuplementoHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--REDUCCIONES-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels &&
                          permisos.includes('nom_reducciones_contrate_hotels:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.reducciones') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ReduccionesHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CONDITIONS GENERAL-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels &&
                          permisos.includes('nom_conditions_generals_hotels:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.conditionGenerals') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ConditionsGeneralsHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--TIPO DESTINO-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels && permisos.includes('nom_tipo_destino_hotels:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.typeDestination') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TipoDestinoHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--OPERADORES-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_operador_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.operador') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <OperadorHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--MARCAS ASOCIADAS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels && permisos.includes('nom_marca_asociada_hotels:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.marcaAsociada') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <MarcaAsociadaHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CATEGORIAS-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_categorias_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.category') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CategoriaHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--INTERESES-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_intereses_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.intereses') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <InteresesHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--SERVICIOS-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_servicios_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.servicios') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ServiciosHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--HABITACIONES-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_habitacion_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.habitaciones') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <HabitacionesHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--CATEGORIAS DE TARIFARIOS-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels && permisos.includes('nom_regimen_alimenticio:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.categoriesTarifarios') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CategoriesTarifariosHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--REGIMEN ALIMENTICIO-->
                    <tr
                      v-if="
                        $store.state.app.showNomencladoresHotels && permisos.includes('nom_regimen_alimenticio:list')
                      "
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.regimenAlimenticio') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <RegimenAlimenticio
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                    <!--EDADES--
                    <tr v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_edad_hotels:list')">
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.edadMin') }}
                        </p>
                      </td>
                      --CHECKBOX--
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <EdadesHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>-->
                    <!--TAGS IMAGEN-->
                    <tr
                      v-if="$store.state.app.showNomencladoresHotels && permisos.includes('nom_tag_imagen_hotels:list')"
                    >
                      <td
                        colspan="2"
                        class="leftColMine header"
                      >
                        <p
                          class="mb-0 pl-5"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.tagsImg') }}
                        </p>
                      </td>
                      <!--CHECKBOX-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TagImagenHotels
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--CATEGORIAS CONDICIONES-->
                  <table v-if="permisos.includes('nom_categories_conditions:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.categoryCondition') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <CategoriesConditions
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--TIPO AFILIADO-->
                  <table v-if="permisos.includes('tipo_afiliado:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('menu.typeAfiliado') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <TipoAfiliado
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--MONEDAS-->
                  <table v-if="permisos.includes('nomenclators_currencies:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('lbl.currencies') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Currencies
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--PAISES-->
                  <table v-if="permisos.includes('nomenclators_countries:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('lbl.countries') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Countries
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--LOGS-->
              <v-expansion-panel
                v-if="permisos.includes('logs:list')"
                id="logs"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >Logs</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Logs
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--MODERACIONES-->
              <v-expansion-panel
                v-if="permisos.includes('moderation:list')"
                id="moderations"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('permiso.moderations') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Moderations
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--NOTIFICACIONES-->
              <v-expansion-panel
                v-if="permisos.includes('dns_emails:list') ||
                  permisos.includes('bot_telegram:list')"
                id="notifications"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('permiso.notifications') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                    </tr>
                  </table>

                  <!--DNS EMAILS-->
                  <table v-if="permisos.includes('dns_emails:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          DNS - Email
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <SMTPEmails
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--BOT TELEGRAM-->
                  <table v-if="permisos.includes('bot_telegram:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Bot Telegram
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <BotTelegram
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>

                  <!--DASHBOARD-->
                  <table v-if="permisos.includes('dashboard_cards:list')">
                    <tr v-if="$store.state.app.showNomencladores">
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          Dashboard
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Dashboards
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--CHATBOT-->
              <v-expansion-panel
                v-if="permisos.includes('chatbot:list')"
                id="chatbot"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('permiso.chatbot') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL BOKING-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <ChatBot
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--TUTORIALES-->
              <v-expansion-panel
                v-if="permisos.includes('tutorials:list')"
                id="tutorials"
              >
                <v-expansion-panel-header style="font-weight: bolder;">
                  <span
                    class="pt-4"
                    style="font-size: 18px;"
                  >{{ $t('menu.tutorials') }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="overflow_Mine">
                  <table>
                    <!--NOMBRES-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2 text-uppercase"
                          style="font-size: 14px;"
                        >
                          {{ $t('permiso.zonas') }}
                        </p>
                      </th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          colspan="4"
                          class=""
                        >
                          <div class="d-flex justify-center">
                            {{ item.name }}
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          style="min-width: 50px;"
                          class="header_top"
                        ></th>
                      </template>
                    </tr>
                    <!--ICONOS-->
                    <tr>
                      <th
                        colspan="2"
                        class="header"
                      ></th>
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.see') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        >
                          <div class="d-flex justify-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  small
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </th>
                        <th
                          :key="Math.random() * (index + 1)"
                          class="header_top_second"
                        ></th>
                      </template>
                    </tr>

                    <tr>
                      <td
                        colspan="2"
                        class="leftColMine header"
                        style="min-width:140px"
                      >
                        <p
                          class="mb-0 pl-2"
                          style="font-size: 14px;"
                        >
                          {{ $t('btn.all') }}
                        </p>
                      </td>
                      <!--ALL-->
                      <template v-for="(item, index) in $store.state.app.sourcePermisos">
                        <Tutorials
                          :key="index"
                          :index="index"
                        />
                      </template>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
  mdiCar,
  mdiBed,
  mdiWalletTravel,
  mdiSeatPassenger,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiAirplane,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiDelete,
  mdiChevronRight,
  mdiChevronDown,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

// import ConditionGeneral from '@/views/nomencladores/flights/ConditionGeneral.vue'
import Booking from '../utils/all/Booking.vue'
import Cotizador from '../utils/tr/Cotizador.vue'
import Reservas from '../utils/tr/Reservas.vue'
import Refund from '../utils/tr/Refund.vue'
import PVP from '../utils/tr/PVP.vue'
import Tarifarios from '../utils/tr/Tarifarios.vue'
import TarifarioNeto from '../utils/tr/TarifarioNeto.vue'

import Products from '../utils/all/Products.vue'
import ProductFlights from '../utils/tr/ProductFlights.vue'
import ProductCars from '../utils/tr/ProductCars.vue'
import ProductHotels from '../utils/tr/ProductHotels.vue'

import Contrate from '../utils/all/Contrate.vue'
import ContrateFlights from '../utils/tr/ContrateFlights.vue'
import ContrateCars from '../utils/tr/ContrateCars.vue'
import ContrateHotels from '../utils/tr/ContrateHotels.vue'
import StopSale from '../utils/tr/StopSale.vue'
import Oferts from '../utils/tr/Oferts.vue'

import Administration from '../utils/all/Administration.vue'

// import Contability from '../utils/tr/Contability.vue'
import Markups from '../utils/tr/Markups.vue'
import Comitions from '../utils/tr/Comitions.vue'
import Finanzas from '../utils/tr/Finanzas.vue'
import CuentasXCobrar from '../utils/tr/CuentasXCobrar.vue'
// eslint-disable-next-line import/no-unresolved
import CuentasXPagar from '../utils/tr/CuentasXPagar.vue'
import PagosRecibidos from '../utils/tr/PagosRecibidos.vue'
import PagosEmitidos from '../utils/tr/PagosEmitidos.vue'

import APIs from '../utils/all/APIs.vue'
import ApiKeys from '../utils/tr/ApiKeys.vue'
import ApiTTI from '../utils/tr/ApiTTI.vue'
import ApiAmadeus from '../utils/tr/ApiAmadeus.vue'
import ApiRedsys from '../utils/tr/ApiRedsys.vue'
import ApiSIBS from '../utils/tr/ApiSIBS.vue'
import ApiCurrency from '../utils/tr/ApiCurrency.vue'
import ApiHotetec from '../utils/tr/ApiHotetec.vue'
import ApiDingus from '../utils/tr/ApiDingus.vue'
import ApiSolways from '../utils/tr/ApiSolways.vue'
import ApiJuniper from '../utils/tr/ApiJuniper.vue'
import ApiCopaConnect from '../utils/tr/ApiCopaConnect.vue'
import ApiDeepL from '../utils/tr/ApiDeepL.vue'
import ApiBigData from '../utils/tr/ApiBigData.vue'
import ApiAuthorizeNet from '../utils/tr/ApiAuthorizeNet.vue'
import ApiPayPal from '../utils/tr/ApiPayPal.vue'
import AppBackoffice from '../utils/tr/AppBackoffice.vue'
import Configuration from '../utils/all/Configuration.vue'
import Identificador from '../utils/tr/Identificador.vue'
import Afiliados from '../utils/tr/Afiliados.vue'
import Perfiles from '../utils/tr/Perfiles.vue'
import Roles from '../utils/tr/Roles.vue'
import Users from '../utils/tr/Users.vue'
import UsersSon from '../utils/tr/UsersSon.vue'
import Agentes from '../utils/tr/Agentes.vue'
import Newletters from '../utils/tr/Newletters.vue'

import Nomencladores from '../utils/all/Nomencladores.vue'
import NomencladoresVuelos from '../utils/all/NomencladoresVuelos.vue'
import TTI from '../utils/tr/TTI.vue'
import SuplementoFlight from '../utils/tr/SuplementoFlight.vue'
import ConditionsGeneralsFlight from '../utils/tr/ConditionsGeneralsFlight.vue'
import Aeroline from '../utils/tr/Aeroline.vue'
import Aeronave from '../utils/tr/Aeronave.vue'
import Clases from '../utils/tr/Clases.vue'
import TarifasFlight from '../utils/tr/TarifasFlight.vue'
import Dimensiones from '../utils/tr/Dimensiones.vue'
import Pesos from '../utils/tr/Pesos.vue'
import Airport from '../utils/tr/Airport.vue'
import TarjetasFidelidadFlight from '../utils/tr/TarjetasFidelidadFlight.vue'
import CodeOFACFlight from '../utils/tr/CodeOFACFlight.vue'
import PoliticaTarifariaFlight from '../utils/tr/PoliticaTarifariaFlight.vue'

import NomencladoresAutos from '../utils/all/NomencladoresAutos.vue'
import SuplementoCars from '../utils/tr/SuplementoCars.vue'
import ConditionsGeneralsCars from '../utils/tr/ConditionsGeneralsCars.vue'
import Tranmisiones from '../utils/tr/Tranmisiones.vue'
import TypeAuto from '../utils/tr/TypeAuto.vue'
import Marca from '../utils/tr/Marca.vue'
import Modelos from '../utils/tr/Modelos.vue'
import Combustibles from '../utils/tr/Combustibles.vue'
import Caracteristicas from '../utils/tr/Caracteristicas.vue'
import Categorias from '../utils/tr/Categorias.vue'
import Rentadora from '../utils/tr/Rentadora.vue'
import TarifasCars from '../utils/tr/TarifasCars.vue'
import TarifasXKmCars from '../utils/tr/TarifasXKmCars.vue'
import EdadConductor from '../utils/tr/EdadConductor.vue'
import PtoRecogida from '../utils/tr/PtoRecogida.vue'
import OficinasRenta from '../utils/tr/OficinasRenta.vue'
import TagImagenCars from '../utils/tr/TagImagenCars.vue'

import NomencladoresHotels from '../utils/all/NomencladoresHotels.vue'
import SuplementoHotels from '../utils/tr/SuplementoHotels.vue'
import ReduccionesHotels from '../utils/tr/ReduccionesHotels.vue'
import ConditionsGeneralsHotels from '../utils/tr/ConditionsGeneralsHotels.vue'
import TipoDestinoHotels from '../utils/tr/TipoDestinoHotels.vue'
import OperadorHotels from '../utils/tr/OperadorHotels.vue'
import MarcaAsociadaHotels from '../utils/tr/MarcaAsociadaHotels.vue'
import CategoriaHotels from '../utils/tr/CategoriaHotels.vue'
import InteresesHotels from '../utils/tr/InteresesHotels.vue'
import ServiciosHotels from '../utils/tr/ServiciosHotels.vue'
import HabitacionesHotels from '../utils/tr/HabitacionesHotels.vue'
import CategoriesTarifariosHotels from '../utils/tr/CategoriesTarifariosHotels.vue'
import RegimenAlimenticio from '../utils/tr/RegimenAlimenticio.vue'
import EdadesHotels from '../utils/tr/EdadesHotels.vue'
import TagImagenHotels from '../utils/tr/TagImagenHotels.vue'

import CategoriesConditions from '../utils/tr/CategoriesConditions.vue'
import TipoAfiliado from '../utils/tr/TipoAfiliado.vue'
import Currencies from '../utils/tr/Currencies.vue'
import Countries from '../utils/tr/Countries.vue'
import Logs from '../utils/tr/Logs.vue'
import Moderations from '../utils/tr/Moderations.vue'
import ChatBot from '../utils/tr/ChatBot.vue'
import BotTelegram from '../utils/tr/BotTelegram.vue'
import Dashboards from '../utils/tr/Dashboard.vue'
import SMTPEmails from '../utils/tr/SMTPEmails.vue'
import Tutorials from '../utils/tr/Tutorials.vue'

export default {
  components: {
    Booking,
    Cotizador,
    Reservas,
    Refund,
    PVP,
    Tarifarios,
    TarifarioNeto,
    Products,
    ProductFlights,
    ProductCars,
    ProductHotels,
    Contrate,
    ContrateFlights,
    ContrateCars,
    ContrateHotels,
    Administration,

    // Contability,
    Markups,
    Comitions,
    Finanzas,
    CuentasXCobrar,
    CuentasXPagar,
    PagosRecibidos,
    PagosEmitidos,
    StopSale,
    Oferts,
    Newletters,
    Configuration,
    APIs,
    ApiKeys,
    ApiTTI,
    ApiAmadeus,
    ApiRedsys,
    ApiSIBS,
    ApiCurrency,
    ApiAuthorizeNet,
    ApiPayPal,
    ApiHotetec,
    ApiDingus,
    ApiSolways,
    ApiJuniper,
    ApiCopaConnect,
    ApiDeepL,
    ApiBigData,
    AppBackoffice,
    Identificador,
    Afiliados,
    Perfiles,
    Roles,
    Users,
    UsersSon,
    Agentes,
    Nomencladores,
    NomencladoresVuelos,
    SuplementoFlight,
    TTI,
    ConditionsGeneralsFlight,
    Aeroline,
    Aeronave,
    Clases,
    TarifasFlight,
    Dimensiones,
    Pesos,
    Airport,
    TarjetasFidelidadFlight,
    CodeOFACFlight,
    PoliticaTarifariaFlight,
    NomencladoresAutos,
    SuplementoCars,
    ConditionsGeneralsCars,
    Tranmisiones,
    TypeAuto,
    Marca,
    Modelos,
    Combustibles,
    Caracteristicas,
    Categorias,
    Rentadora,
    TarifasCars,
    TarifasXKmCars,
    EdadConductor,
    PtoRecogida,
    OficinasRenta,
    TagImagenCars,
    NomencladoresHotels,
    SuplementoHotels,
    ReduccionesHotels,
    ConditionsGeneralsHotels,
    TipoDestinoHotels,
    OperadorHotels,
    MarcaAsociadaHotels,
    CategoriaHotels,
    InteresesHotels,
    ServiciosHotels,
    HabitacionesHotels,
    CategoriesTarifariosHotels,
    RegimenAlimenticio,
    // eslint-disable-next-line vue/no-unused-components
    EdadesHotels,
    TagImagenHotels,
    CategoriesConditions,
    TipoAfiliado,
    Currencies,
    Countries,
    Logs,
    Moderations,
    SMTPEmails,
    BotTelegram,
    Dashboards,
    ChatBot,
    Tutorials,

    // ConditionGeneral,
  },
  data() {
    return {
      isLoading: true,
      checkboxVer: [],
      items: [],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
        mdiCar,
        mdiBed,
        mdiWalletTravel,
        mdiSeatPassenger,
        mdiAirplaneTakeoff,
        mdiAirplaneLanding,
        mdiAirplane,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiDelete,
        mdiChevronRight,
        mdiChevronDown,
        mdiMenuDown,
        mdiMenuRight,
      },
      showBooking: true,
      showProduct: true,
      showContrate: true,
      showAdministration: true,
      showConfiguration: true,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      userUpdate: state => state.app.userUpdate,
      isLoadingPagePermisos: state => state.app.isLoadingPagePermisos,
      viewPermisos: state => state.app.viewPermisos,
    }),
  },
  mounted() {
    this.profile()
    if (this.viewPermisos === 'perfil') {
      this.getPerfiles()
    } else if (this.viewPermisos === 'rols') {
      this.getRoles()
    } else if (this.viewPermisos === 'users') {
      this.getUser()
    }
  },
  methods: {
    ...mapMutations(['setPermisosTabLoading', 'setSourcePermisos', 'setSourcePermisosPos']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getPerfiles() {
      this.axios
        .get('perfil_afiliado/permisos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.$store.state.app.sourcePermisos = this.items
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.setPermisosTabLoading(false))
    },
    getRoles() {
      this.axios
        .get(`role/permisos?perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.setSourcePermisos(this.items)
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.setPermisosTabLoading(false))
    },
    getUser() {
      if (localStorage.getItem('user-id') !== null) {
        if (localStorage.getItem('user-role-new-id') !== null) {
          this.axios
            .post(
              'user/permisos-role-new',
              { role_id: localStorage.getItem('user-role-new-id') },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              // eslint-disable-next-line no-empty
              if (res.data.success === false) {
              } else {
                this.items = res.data.data.data
                this.setSourcePermisos(this.items)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.setPermisosTabLoading(false))
        } else {
          this.axios
            .post(
              'user/permisos',
              { id: localStorage.getItem('user-id') },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              // eslint-disable-next-line no-empty
              if (res.data.success === false) {
              } else {
                this.items = res.data.data.data
                this.setSourcePermisos(this.items)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.setPermisosTabLoading(false))
        }
      } else {
        this.axios
          .post(
            'user/permisos',
            { role_id: this.userUpdate.role_id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              this.items = res.data.data.data
              this.setSourcePermisos(this.items)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => this.setPermisosTabLoading(false))
      }
    },
    showBookingItems() {
      this.$store.state.app.showBooking = !this.$store.state.app.showBooking
    },
    showProductItems() {
      this.$store.state.app.showProduct = !this.$store.state.app.showProduct
    },
    showContrateItems() {
      this.$store.state.app.showContrate = !this.$store.state.app.showContrate
    },
    showAdministrationItems() {
      this.$store.state.app.showAdministration = !this.$store.state.app.showAdministration
    },
    showConfigurationItems() {
      this.$store.state.app.showConfiguration = !this.$store.state.app.showConfiguration
    },
    showNomencladoresItems() {
      this.$store.state.app.showNomencladores = !this.$store.state.app.showNomencladores
    },
    showNomencladoresFlightsItems() {
      this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresHotels = false
    },
    showNomencladoresCarsItems() {
      this.$store.state.app.showNomencladoresCars = !this.$store.state.app.showNomencladoresCars
      this.$store.state.app.showNomencladoresFlights = false
      this.$store.state.app.showNomencladoresHotels = false
    },
    showNomencladoresHotelsItems() {
      this.$store.state.app.showNomencladoresHotels = !this.$store.state.app.showNomencladoresHotels
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresFlights = false
    },

    checkPerm(pos, ability) {
      let { permisos } = this.sourcePermisos[pos]
      if (permisos.includes(ability)) {
        const result = permisos.filter(e => e !== ability)
        this.setSourcePermisosPos({ pos, update: result })
      } else {
        permisos = permisos.push(ability)
      }
    },
  },
}
</script>

<style scoped>
.demo-content[data-v-566be365]:last-child {
  padding-bottom: 0px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-color: white;
  width: auto;
}

td,
th {
  text-align: left;
  padding: 0px 8px;
}

tr:nth-child(even) {
  background-color: #ffffff;
}

td {
  max-width: 40px !important;
}

.font-size-Mine > th {
  font-size: 16px;
  font-weight: lighter;
}

.leftColMine {
  padding: 5px 10px 0px 10px;
}

.header {
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: #ffffff;
}

th {
  padding-top: 0;
}

th {
  padding-top: 0;
}

.overflow_Mine {
  overflow-x: auto !important;
}

.v-expansion-panel-header {
  padding-bottom: 8px !important;
}

#main > .v-expansion-panel-content__wrap {
  padding-left: 0% !important;
  padding-right: 0% !important;
  padding-bottom: 0% !important;
}

#sec > .v-expansion-panel-content__wrap {
  padding-left: 0% !important;
  padding-right: 0% !important;
  margin-bottom: 1rem !important;
}

#sec {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

#sec::before {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>
