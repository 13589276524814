<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="ocupation.adult"
            label="ADL"
            disabled
            outlined
            dense
            hide-details="auto"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="ocupation.mnr"
            label="MNR"
            outlined
            disabled
            dense
            hide-details="auto"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="ocupation.inf"
            label="INF"
            outlined
            disabled
            dense
            hide-details="auto"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <!--<v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="
                deleteRangeAgeRoomsSeasonContrateHotel(pos)
              "
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              :disabled="verifyRoomsFree"
              v-on="on"
              @click="addMaxOcupationRoomsSeasonContrateHotel()"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>-->
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      maxOcupationRoomsSeasonContrateHotel: state => state.app.maxOcupationRoomsSeasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations([
      'deleteRangeAgeRoomsSeasonContrateHotel',
      'addMaxOcupationRoomsSeasonContrateHotel',
      'reajustPoliticasChildSeasonContrateHotel',
    ]),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>
