<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.tutorials')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'tutorials-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="item.name"
                  :label="$t('lbl.title')"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="item.permiso"
                  :items="listPermisos"
                  outlined
                  dense
                  :label="$t('permiso.permiso')"
                  item-text="name"
                  item-value="permiso"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }} ({{ data.item.group }})
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <!--
                          <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                        -->
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="item.is_extern"
                  :label="$t('lbl.urlExtern')"
                  hide-details
                  class="mt-2"
                ></v-switch>
              </v-col>
              <v-col
                v-if="item.is_extern"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="item.url_extern"
                  label="Url"
                  placeholder="https://youtu.be/iurtw5"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="!item.is_extern"
                cols="12"
                md="4"
              >
                <v-file-input
                  v-model="video"
                  accept="video/*"
                  exc
                  show-size
                  outlined
                  dense
                  :label="$t('lbl.uploadVideo')"
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                >
                </v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <tiptap-vuetify
                  v-model="item.description"
                  :extensions="extensions"
                  :placeholder="`${$t('lbl.description')}...`"
                  :disabled="$store.state.app.onlyShow"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    TiptapVuetify,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      showMap: true,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      listPermisos: [],

      video: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('tutorials-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    if (localStorage.getItem('tutorials-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    // BOOKING
    this.listPermisos.push({
      header: this.$t('menu.booking'),
    })
    this.listPermisos.push({
      name: this.$t('menu.cotizador'),
      group: this.$t('menu.booking'),
      permiso: 'cotizador:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.reservas'),
      group: this.$t('menu.booking'),
      permiso: 'reservas:list',
    })

    // PRODUCTS
    this.listPermisos.push({
      header: this.$t('menu.products'),
    })
    this.listPermisos.push({
      name: this.$t('menu.flights'),
      group: this.$t('menu.products'),
      permiso: 'flight:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.cars'),
      group: this.$t('menu.products'),
      permiso: 'car:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.hotels'),
      group: this.$t('menu.products'),
      permiso: 'hotel:list',
    })

    // CONTRATOS
    this.listPermisos.push({
      header: this.$t('menu.contratos'),
    })
    this.listPermisos.push({
      name: this.$t('menu.flights'),
      group: this.$t('menu.contratos'),
      permiso: 'contrate_flights:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.cars'),
      group: this.$t('menu.contratos'),
      permiso: 'contrate_cars:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.hotels'),
      group: this.$t('menu.contratos'),
      permiso: 'contrate_hotels:list',
    })

    // ADMINISTRACION
    this.listPermisos.push({
      header: this.$t('menu.gestion'),
    })
    this.listPermisos.push({
      name: this.$t('menu.contability'),
      group: this.$t('menu.gestion'),
      permiso: 'contabilidad:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.markups'),
      group: this.$t('menu.gestion'),
      permiso: 'markup:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.comitions'),
      group: this.$t('menu.gestion'),
      permiso: 'comisiones:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.stopSale'),
      group: this.$t('menu.gestion'),
      permiso: 'stopSale:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.oferts'),
      group: this.$t('menu.gestion'),
      permiso: 'oferts:list',
    })
    this.listPermisos.push({
      name: 'Newsletter',
      group: this.$t('menu.gestion'),
      permiso: 'newletters:list',
    })

    // CONFIGURACION
    this.listPermisos.push({
      header: this.$t('menu.configuration'),
    })
    this.listPermisos.push({
      name: this.$t('menu.perfilUser'),
      group: this.$t('menu.configuration'),
      permiso: 'perfil_afiliado:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.roles'),
      group: this.$t('menu.configuration'),
      permiso: 'role:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.users'),
      group: this.$t('menu.configuration'),
      permiso: 'user:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.agentes'),
      group: this.$t('menu.configuration'),
      permiso: 'agentes:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.companies'),
      group: this.$t('menu.configuration'),
      permiso: 'user_register:list',
    })
    this.listPermisos.push({
      name: 'API',
      group: this.$t('menu.configuration'),
      permiso: 'api_keys:list',
    })
    this.listPermisos.push({
      name: this.$t('menu.dataGeneral'),
      group: this.$t('menu.configuration'),
      permiso: 'config_general:edit',
    })
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    save() {
      if (this.item.name && this.item.permiso) {
        let proceder = true
        if (this.item.is_extern) {
          if (!this.item.url_extern) {
            proceder = false
          }
        } else if (!this.video) {
          proceder = false
        }

        if (proceder) {
          this.loading = true

          if (localStorage.getItem('tutorials-id') === null) {
            const formData = new FormData()
            if (this.video) {
              formData.append('video', this.video)
            }
            formData.append('data', JSON.stringify(this.item))

            this.axios
              .post('tutorials', formData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  }
                } else {
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => this.reseat())
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reseat() {
      this.loading = false

      this.item = {}
      this.video = null
      this.$router.push({ name: 'tutorials-list' })
    },
  },
}
</script>
