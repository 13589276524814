<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('menu.cotizations') }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltrosCotizations"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--FECHAS DE COMPRA-->
        <div class="customizer-section">
          <v-row no-gutters>
            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.cotizationFrom') }}</span>
              <v-menu
                v-model="menuFromReserve"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFromReserveDateFormatted"
                    :label="$t('lbl.reserveHome')"
                    persistent-hint
                    class="pt-2"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtersCotizations.dateReserveFrom"
                  no-title
                  locale="es"
                  :show-current="false"
                  @change="filtrarCotizations"
                  @input="
                    menuFromReserve = false
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.cotizationTo') }}</span>
              <v-menu
                v-model="menuToReserve"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedToReserveDateFormatted"
                    :label="$t('lbl.reserveEnd')"
                    persistent-hint
                    class="pt-2"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <!--:max="new Date(Date.now()).toISOString().substr(0, 10)"-->
                <v-date-picker
                  v-model="filtersCotizations.dateReserveTo"
                  no-title
                  locale="es"
                  :show-current="false"
                  :min="
                    filtersCotizations.dateReserveFrom
                      ? $moment(filtersCotizations.dateReserveFrom)
                        .add(1, 'day')
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                  @change="filtrarCotizations"
                  @input="
                    menuToReserve = false
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <!--FECHAS DE SERVICIO-->
        <div class="customizer-section">
          <v-row no-gutters>
            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.serviceFrom') }}</span>
              <v-menu
                v-model="menuFromService"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFromServiceDateFormatted"
                    :label="$t('lbl.homeService')"
                    persistent-hint
                    class="pt-2"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtersCotizations.dateServiceFrom"
                  no-title
                  locale="es"
                  :show-current="false"
                  @change="filtrarCotizations"
                  @input="
                    menuFromService = false
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.serviceTo') }}</span>
              <v-menu
                v-model="menuToService"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedToServiceDateFormatted"
                    :label="$t('lbl.endService')"
                    persistent-hint
                    class="pt-2"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <!--:max="new Date(Date.now()).toISOString().substr(0, 10)"-->
                <v-date-picker
                  v-model="filtersCotizations.dateServiceTo"
                  no-title
                  locale="es"
                  :show-current="false"
                  :min="
                    filtersCotizations.dateServiceFrom
                      ? $moment(filtersCotizations.dateServiceFrom)
                        .add(1, 'day')
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                  @change="filtrarCotizations"
                  @input="
                    menuToService = false
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <!--PRODUCTO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.product') }}</span>
          <v-select
            v-model="filtersCotizations.tipo_product"
            :items="tipos_product"
            :label="$t('lbl.product')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="entity"
            @change="filtrarCotizations"
          ></v-select>
        </div>

        <v-divider></v-divider>

        <!--NOMBRE DEL PASAJERO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.nameViajero') }}</span>
          <v-text-field
            v-model="filtersCotizations.fullName"
            :label="$t('lbl.client')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="filtrarCotizations"
          >
          </v-text-field>
        </div>

        <v-divider></v-divider>

        <!--AFILIADO-->
        <div
          v-if="user.nivel <= 0"
          class="customizer-section"
        >
          <span class="text--primary">{{ $t('lbl.afiliate') }}</span>
          <v-autocomplete
            v-model="filtersCotizations.afiliate"
            :items="afiliadosList"
            :search-input.sync="afiliadosSearch"
            hide-details
            hide-selected
            :label="$t('lbl.afiliateAgency')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="filtrarCotizations"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.afiliateAgency') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <v-divider></v-divider>

        <!--VENDEDOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.vendedor') }}</span>
          <v-autocomplete
            v-model="filtersCotizations.seller"
            :items="sellerList"
            :search-input.sync="sellerSearch"
            hide-details
            hide-selected
            :label="$t('lbl.vendedor')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="filtrarCotizations"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.vendedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <v-divider></v-divider>

        <!--CODIGO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.codeCotization') }}</span>
          <v-autocomplete
            v-model="filtersCotizations.codeReserva"
            :items="codesReserva"
            :search-input.sync="searchCodeReserva"
            hide-details
            hide-selected
            :label="$t('lbl.code')"
            outlined
            dense
            @change="filtrarCotizations"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.code') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <v-divider></v-divider>

        <!--ESTADO--
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.stateCotization') }}</span>
          <v-select
            v-model="filtersCotizations.state"
            :items="states"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            @change="filtrarCotizations"
          ></v-select>
        </div>-->

        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tipos_product: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    afiliados: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    seller: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    codes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    states: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFromReserve: false,
      menuToReserve: false,
      menuFromService: false,
      menuToService: false,
      afiliadosList: [],
      afiliadosSearch: null,
      sellerList: [],
      sellerSearch: null,
      codesReserva: [],
      searchCodeReserva: null,
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      filtersCotizations: state => state.app.filtersCotizations,
    }),
    computedFromReserveDateFormatted() {
      return this.filtersCotizations.dateReserveFrom ? this.$moment(this.filtersCotizations.dateReserveFrom).format('D-MMM-YY') : ''
    },
    computedToReserveDateFormatted() {
      return this.filtersCotizations.dateReserveTo ? this.$moment(this.filtersCotizations.dateReserveTo).format('D-MMM-YY') : ''
    },
    computedFromServiceDateFormatted() {
      return this.filtersCotizations.dateServiceFrom ? this.$moment(this.filtersCotizations.dateServiceFrom).format('D-MMM-YY') : ''
    },
    computedToServiceDateFormatted() {
      return this.filtersCotizations.dateServiceTo ? this.$moment(this.filtersCotizations.dateServiceTo).format('D-MMM-YY') : ''
    },
  },
  watch: {
    afiliadosSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAfiliados(val.toLowerCase())
      } else {
        this.afiliadosList = []
      }
    },
    sellerSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterSeller(val.toLowerCase())
      } else {
        this.sellerList = []
      }
    },
    searchCodeReserva(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodeReserva(val.toLowerCase())
      } else {
        this.codesReserva = []
      }
    },
  },
  methods: {
    ...mapMutations([
      'filtrarCotizations',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCotizations',
    ]),
    filterAfiliados(v) {
      this.afiliadosList = []
      if (v === '') {
        this.afiliadosList = []
      } else {
        this.afiliadosList = this.afiliados.filter(e => e.name.toLowerCase())
      }
    },
    filterSeller(v) {
      this.sellerList = []
      if (v === '') {
        this.sellerList = []
      } else {
        this.sellerList = this.seller.filter(e => e.name.toLowerCase())
      }
    },
    filterCodeReserva(v) {
      this.codesReserva = []
      if (v === '') {
        this.codesReserva = []
      } else {
        this.codesReserva = this.codes.filter(e => e.toLowerCase())
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
