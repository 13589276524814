<!-- eslint-disable vue/no-v-html -->
<template>
  <span
    v-html="qr"
  ></span>

  <!--<v-row>
    <v-col cols="8">
      <span v-html="qr"></span>
    </v-col>
    <v-col
      cols="4"
      style="vertical-align: text-bottom;"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            :color="$vuetify.theme.themes.light.primary"
            v-bind="attrs"
            :disabled="loadingDownload"
            @click="downloadAPK"
            v-on="on"
          >
            <v-icon
              color="#fff"
            >
              mdi-android
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.dowload') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2 mt-2"
            fab
            :color="$vuetify.theme.themes.light.primary"
            v-bind="attrs"
            disabled
            v-on="on"
          >
            <v-icon
              color="#fff"
            >
              mdi-apple
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.dowload') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>-->
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      qr: null,
      isLoading: false,
      loadingDownload: false,
    }
  },
  created() {
    this.getQR()
  },
  methods: {
    getQR() {
      this.axios
        .post('apps/qr', { id: this.app.id }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.qr = res.data
        })
    },
  },
}
</script>
