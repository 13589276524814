<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row>
        <!--:md="!seasonContrateHotel.is_tarifa_price_estancia_dbl ? 10 : 12"-->
        <v-col
          cols="12"
          md="10"
        >
          <!--<v-text-field
            v-model="nameRoom"
            class="my-input"
            hide-details
            disabled
          ></v-text-field>-->
          <v-select
            v-model="room.room_id"
            :disabled="$store.state.app.onlyShow"
            :items="roomsFree"
            :label="$t('lbl.room')"
            class="my-input mr-2"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            @change="setRooms()"
            @click.prevent="setRoomsNative()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.room') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <!--v-if="!seasonContrateHotel.is_tarifa_price_estancia_dbl"-->
        <v-col
          cols="12"
          md="2"
        >
          <!--<v-tooltip
            v-if="pricesDBLRoomsSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1"
                fab
                dark
                x-small
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="deletePriceDBLRoomsSeasonContrateHotel(pos)"
              >
                <v-icon small>
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>-->
          <v-switch
            v-model="room.is_main"
            label="Main"
            hide-details
            class="pt-0 mt-4"
            @click="setIsMain"
          ></v-switch>
        </v-col>

        <!--<v-col
          cols="12"
          md="1"
        >
          <v-tooltip
            v-if="!$store.state.app.onlyShow && pos === 0"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 ml-2"
                fab
                dark
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="verifyRoomsFree"
                v-on="on"
                @click="addPriceDBLRoomsNullSeasonContrateHotel()"
              >
                <v-icon small>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>-->
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
      style="text-align: right"
    >
      <span v-if="!room.is_main">
        <span>%</span>
        <br />
        <v-switch
          v-model="room.in_pc"
          hide-details
          class="pt-0 mt-0 ml-15"
        ></v-switch>
      </span>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <div
          v-for="(price, indC) in room.prices"
          :key="indC"
          style="max-width: 90px; margin-left: 20px"
          class="mt-2"
        >
          <v-text-field
            v-model="price.price"
            :disabled="$store.state.app.onlyShow"
            :append-icon="indC === 0 && !room.is_main ? 'mdi-arrow-right' : ''"
            :label="`${!room.is_main ? 'Suplemento' : `${$t('lbl.price')} DBL`}`"
            outlined
            dense
            hide-details
            :prefix="
              room.in_pc
                ? ''
                : price.price
                  ? !price.price.includes('N') && !price.price.includes('F')
                    ? '$'
                    : ''
                  : '$'
            "
            :suffix="
              room.in_pc
                ? price.price
                  ? price.price.includes('N') || price.price.includes('F')
                    ? ''
                    : '%'
                  : '%'
                : ''
            "
            @click:append="
              indC === 0 && !room.is_main
                ? replicaPriceNoMainSeasonContrateHotel({
                  posRoom: pos,
                  price: price.price,
                })
                : ''
            "
            @keypress="checkPrice($event, price.price)"
            @input="val => (price.price = price.price.toUpperCase())"
          ></v-text-field>
        </div>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      room_id: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      seasonContrateHotel: state => state.app.seasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      pricesDBLRoomsSeasonContrateHotel: state => state.app.pricesDBLRoomsSeasonContrateHotel,
    }),
    nameRoom() {
      let name = ''
      if (this.room.room_id) {
        if (this.roomsHotel.filter(e => e.id === this.room.room_id).length > 0) {
          name = this.roomsHotel.filter(e => e.id === this.room.room_id)[0].name
        }
      }

      return name
    },
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          // let esta = false
          // eslint-disable-next-line no-plusplus
          /* for (let index = 0; index < this.pricesDBLRoomsSeasonContrateHotel.length; index++) {
            if (this.pricesDBLRoomsSeasonContrateHotel[index].room_id === element.id && index !== this.pos) {
              esta = true
            }
          } */
          result.push(element)

          /* if (!esta) {
            result.push(element)
          } */
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.pricesDBLRoomsSeasonContrateHotel.length; index++) {
            if (this.pricesDBLRoomsSeasonContrateHotel[index].room_id === element) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })
    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.room.room_id = this.roomsSeasonContrateHotel[0].rooms_id[0]
      }
    }
  },
  methods: {
    ...mapMutations([
      'deletePriceDBLRoomsSeasonContrateHotel',
      'addPriceDBLRoomsNullSeasonContrateHotel',
      'changeRoomDBLSeasonContrateHotel',
      'replicaPriceNoMainSeasonContrateHotel',
    ]),
    checkPrice(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.room.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.room.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0 || model.includes('N') || model.includes('/') || model.includes('A'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if ((model) && (model.includes('.') && elEvento.key === '.')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if ((model) && (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if ((model) && (model.includes('/') && elEvento.key === '/')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if ((model) && (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if ((model) && (model.includes('N') || model.includes('/') || model.includes('A'))) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }

      // VERIFICAR SI ES  CERO DE PRIMERO
      if (elEvento.keyCode === 48) {
        if (model.length === 0) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
    setRoomsNative() {
      this.room_id = this.room.room_id
    },
    setRooms() {
      this.changeRoomDBLSeasonContrateHotel({
        room_id_old: this.room_id,
        room_id_new: this.room.room_id,
        pos: this.pos,
      })
    },
    setIsMain() {
      if (this.room.is_main) {
        this.room.in_pc = false
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
