<template>
  <fragment>
    <div class="d-flex">
      <span class="pt-2">
        {{ hotel.name }}
        <span v-if="hotel.edad_min_id"> ({{ hotel.edad_min.name }}) </span>
      </span>
      <v-chip
        v-if="!hotel.publicSeason"
        class="ma-2"
        color="red"
        text-color="white"
      >
        {{ $t('lbl.sinPublicar') }}
      </v-chip>
      <v-chip
        v-if="hotel.sugerido"
        class="ma-2"
        color="primary"
      >
        {{ $t('lbl.sugerido') }}
      </v-chip>
      <!--<template v-if="hotel.stop_sale">
        <v-chip
          v-if="hotel.stop_sale"
          class="ma-2"
          color="red"
          text-color="white"
        >
          {{ $t('lbl.stopSale') }}
        </v-chip>
      </template>-->
    </div>
    <!--<v-row>
      <v-col
        cols="12"
        md="4"
      >
        {{ hotel.name }}
        <span v-if="hotel.edad_min_id">
          ({{ hotel.edad_min.name }})
        </span>
      </v-col>
      <v-col
        v-if="hotel.categoria_id !== null"
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-rating
              v-model="hotel.categoria.cant_estrellas"
              color="primary"
              size="30"
              readonly
              class="mr-5 mt-1"
            ></v-rating>
          </v-col>
          <v-col
            v-if="hotel.categoria.plus"
            cols="12"
            md="4"
            class="mt-2 ml-7"
          >
            <span
              class="ml-2"
            >PLUS</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-chip
              v-if="!hotel.publicado"
              class="ma-2"
              color="red"
              text-color="white"
            >
              {{ $t('lbl.sinPublicar') }}
            </v-chip>
            <v-chip
              v-else
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{ $t('lbl.publico') }}
            </v-chip>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-chip
              v-if="hotel.sugerido"
              class="ma-2"
              color="primary"
            >
              {{ $t('lbl.sugerido') }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>-->
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteContactHotel', 'adicionarEmailContactHotel', 'adicionarTelefonoContactHotel']),
  },
}
</script>
