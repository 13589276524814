<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">
        {{ $t('lbl.filtros') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        large
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-calendar-event-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
      >
        <v-autocomplete
          v-model="filtrosCotizadorCar.marca"
          :items="itemsMarcas"
          :search-input.sync="searchMarca"
          hide-details
          hide-selected
          :label="$t('menu.marca')"
          outlined
          dense
          item-text="name"
          item-value="id"
          @change="changeMarca"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.marca') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar v-if="item.icon !== null">
              <v-img
                size="20"
                :src="rutaPublic + item.icon"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-avatar
                color="primary"
                size="30"
              >
                <span class="white--text text-h5">{{ item.name.substr(0, 1) }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-select
          v-model="filtrosCotizadorCar.modelo"
          :items="modelos"
          item-text="name"
          item-value="id"
          :label="$t('menu.modelo')"
          outlined
          dense
          hide-details
          class="pt-8"
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.modelo') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>

        <v-select
          v-model="filtrosCotizadorCar.transmision"
          :items="transmisiones"
          item-text="name"
          item-value="id"
          :label="$t('menu.trasnmision')"
          outlined
          dense
          hide-details
          class="pt-8"
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.trasnmision') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>

        <v-autocomplete
          v-model="filtrosCotizadorCar.tipo_auto"
          :items="itemsType"
          :search-input.sync="searchType"
          hide-details
          hide-selected
          :label="$t('menu.typeCar')"
          outlined
          dense
          item-text="name"
          item-value="id"
          class="pt-8"
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.typeCar') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-btn
          width="100%"
          color="primary"
          class="mt-8"
          text
          :loading="loading"
          outlined
          @click="aplicarFiltros"
        >
          {{ $t('btn.apply') }}
        </v-btn>
      </v-form>

      <!--<v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
      >
        <v-select
          v-model="filtrosCotizadorFlight.aerolinea"
          :items="aerolineas"
          :label="$t('permiso.aerolinea')"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          cols="12"
        ></v-select>

        <h4
          class="pl-0 mb-0 pr-6 pt-5"
          subtitle-2
        >
          {{ $t('lbl.escalas') }}:
        </h4>
        <v-radio-group
          v-model="filtrosCotizadorFlight.escala"
          row
        >
          <v-radio
            dense
            :label="$t('lbl.escala1')"
          ></v-radio>
          <v-radio
            dense
            :label="$t('lbl.escala2')"
          ></v-radio>
          <v-radio
            dense
            :label="$t('lbl.escala3')"
          ></v-radio>
        </v-radio-group>

        <fragment cols="6">
          <h4
            class="pl-0 mb-0 pr-6"
            subtitle-2
          >
            {{ $t('lbl.durationEscala') }}:
            <strong color="primary">{{
              $t('lbl.durationEscalaRango', { time: filtrosCotizadorFlight.duracionEscala })
            }}</strong>
          </h4>
          <v-slider
            v-model="filtrosCotizadorFlight.duracionEscala"
            max="24"
            min="0"
            hide-details
          ></v-slider>
        </fragment>

        <fragment cols="6">
          <h4
            class="pl-0 mb-0 pr-6"
            subtitle-2
          >
            {{ $t('lbl.durationViaje') }}:
            <strong>{{ $t('lbl.durationEscalaRango', { time: filtrosCotizadorFlight.duracionViaje }) }}</strong>
          </h4>
          <v-slider
            v-model="filtrosCotizadorFlight.duracionViaje"
            max="24"
            min="0"
            hide-details
          ></v-slider>
        </fragment>

        <fragment>
          <v-autocomplete
            v-model="filtrosCotizadorFlight.airport"
            :items="itemsOrigen"
            :search-input.sync="searchOrigen"
            :label="$t('lbl.airport')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            single-line
            hide-details
            clearable
            class="pt-8"
            cols="6"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.airport') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </fragment>

        <fragment>
          <v-select
            v-model="filtrosCotizadorFlight.clase"
            :items="clases"
            :label="$t('lbl.clase')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-5"
          ></v-select>
        </fragment>

        <fragment>
          <h4
            class="pl-0 mb-0 pr-6 pt-5"
            subtitle-2
          >
            {{ $t('lbl.bag') }}:
          </h4>
          <v-radio-group
            v-model="filtrosCotizadorFlight.equipaje"
            row
          >
            <v-radio
              dense
              label="Indiferente"
            ></v-radio>
            <v-radio
              dense
              label="Sin equipaje"
            ></v-radio>
            <v-radio
              dense
              label="Con equipaje"
            ></v-radio>
          </v-radio-group>
        </fragment>

        <v-btn
          width="100%"
          color="primary"
          text
          :loading="loading"
          outlined
          @click="aplicarFiltros"
        >
          {{ $t('btn.apply') }}
        </v-btn>
      </v-form>-->
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiClose,
} from '@mdi/js'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingCar: true,
      loading: false,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiClose,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },

      transmisiones: [],
      itemsMarcas: [],
      searchMarca: null,
      modelos: [],
      itemsModelos: [],
      searchModelo: null,
      itemsType: [],
      searchType: null,
      typeAuto: [],
      itemsTypeAuto: [],
      searchTypeAuto: null,
    }
  },
  computed: {
    ...mapState({
      cotizadorCars: state => state.app.cotizadorCars,
      filtrosCotizadorCar: state => state.app.filtrosCotizadorCar,
    }),
  },
  watch: {
    searchMarca(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.itemsMarcas = []
      }
    },
    searchModelo(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterModelo(val.toLowerCase())
      } else {
        this.itemsModelos = []
      }
    },
    searchType(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsType = []
      }
    },
  },
  created() {
    this.getMarcas()
    this.getTransmisiones()
    this.getTypeAuto()
  },
  methods: {
    ...mapMutations(['setSearchingCar', 'setCotizadorCarsResult', 'setAvalibleFiltrosCotizadorCar']),
    filterMarca(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.marcas.filter(e => e.name.toLowerCase())
      }
    },
    filterModelo(v) {
      this.itemsModelos = []
      if (v === '') {
        this.itemsModelos = []
      } else {
        this.itemsModelos = this.modelos.filter(e => e.name.toLowerCase())
      }
    },
    filterTypeAuto(v) {
      this.itemsTypeAuto = []
      if (v === '') {
        this.itemsType = []
      } else {
        this.itemsType = this.typeAuto.filter(e => e.name.toLowerCase())
      }
    },
    getMarcas() {
      this.axios
        .get(`nom_marca_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcas = res.data.data
        })
    },
    changeMarca() {
      if (this.filtrosCotizadorCar.marca) {
        const marc = this.marcas.filter(a => a.id === this.filtrosCotizadorCar.marca)[0]
        this.modelos = marc.modelos
      } else {
        this.modelos = []
      }
    },
    getTransmisiones() {
      this.axios
        .get(`nom_transmision_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.transmisiones = res.data.data
        })
    },
    getTypeAuto() {
      this.axios
        .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeAuto = res.data.data
        })
    },
    aplicarFiltros() {
      // this.dialog = false
      // console.log(this.filtrosCotizadorCar)

      /* this.setFiltrosCotizadorFlight({
        filtrosCotizadorFlight: this.filtrosCotizadorFlight,
      })
      this.setSearchCotizadorIdaFlight(true)
      this.setSearchCotizadorFlights(true) */

      // console.log(this.filtrosCotizadorFlight)

      this.loading = true
      this.setAvalibleFiltrosCotizadorCar(true)

      const json = {
        recogida: this.cotizadorCars.recogida,
        entrega: this.cotizadorCars.entrega,
        entregaDiffRecogida: this.cotizadorCars.entregaDiffRecogida,
        dateRecogida: this.cotizadorCars.dateRecogida,
        dateEntrega: this.cotizadorCars.dateEntrega,
        transmision_id: this.cotizadorCars.transmision_id,
        rentadora_id: this.cotizadorCars.rentadora_id,

        marca: this.filtrosCotizadorCar.marca,
        modelo: this.filtrosCotizadorCar.modelo,
        transmision: this.filtrosCotizadorCar.transmision,
        carroseria: this.filtrosCotizadorCar.carroseria,

        // byMejorOpcion: this.filtrosCotizadorCar.byMejorOpcion,
        // byMasEconomico: this.filtrosCotizadorCar.byMasEconomico,
        // byMasRapido: this.filtrosCotizadorCar.byMasRapido,
      }

      // console.log(json)

      this.axios
        .post('cotizador-search-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.setSearchingCar(true)

          if (res.data.success) {
            this.setCotizadorCarsResult(res.data.data.items)
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false

          // this.setSearchCotizadorIdaFlight(false)
          this.$emit('close-drawer')
        })

      /* if (this.cotizadorFlight.onlyIda) {
        console.log(this.aerolineCotizadorIdaFlight)
      } else if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        console.log(this.aerolineCotizadorRegresoFlight)
      } else {
        console.log('multidestinos')
      } */
    },
  },
}
</script>

<style scoped>
.ps {
  height: 550px;
}
</style>
