<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="!isLoading"
    flat
    min-height="800px"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            v-if="saved"
            cols="12"
            md="2"
          >
            <v-btn
              color="error"
              class="mb-4 me-3"
              @click="saved = false"
            >
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
              <span>{{ $t('btn.back') }}</span>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              v-if="!$store.state.app.onlyShow && !saved"
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click="setSave()"
            >
              <span>{{ $t('btn.save') }}</span>
              <v-icon>{{ icons.mdiContentSaveMoveOutline }}</v-icon>
            </v-btn>

            <v-btn
              v-if="saved"
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click="save()"
            >
              <span>{{ $t('btn.send') }}</span>
              <v-icon>{{ icons.mdiUpdate }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="!saved">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <h3 class="mt-2">
                {{ $t('lbl.recipients') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-btn
                icon
                @click="showRecipients = !showRecipients"
              >
                <v-icon>
                  {{ showRecipients ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </v-col>
            <fragment v-if="showRecipients">
              <v-col
                cols="12"
                md="3"
              >
                <v-form ref="step1Form">
                  <v-text-field
                    v-model="newEmail"
                    label="Email"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="emailRules"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  v-if="!$store.state.app.onlyShow"
                  color="primary"
                  class="me-3"
                  @click="addEmail()"
                >
                  <span>{{ $t('btn.insert') }}</span>
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="me-3"
                  @click="activeFileUpload"
                >
                  <span class="d-none d-sm-block">{{ $t('btn.import') }}</span>
                  <v-icon>
                    mdi-email-multiple
                  </v-icon>
                </v-btn>

                <v-file-input
                  id="refInputAdj"
                  ref="refInputAdj"
                  v-model="file"
                  accept=".ods, .csv"
                  outlined
                  dense
                  label="Avatar"
                  hide-details
                  :hide-spin-buttons="true"
                  :hide-input="true"
                  prepend-icon=""
                  @change="importFile"
                ></v-file-input>
              </v-col>
            </fragment>
          </v-row>
          <v-row v-if="showRecipients">
            <v-col
              cols="12"
              md="12"
            >
              <v-chip
                v-for="(recipient, index) in recipients"
                :key="index"
                class="ma-2"
                close
                color="indigo darken-3"
                outlined
                close-icon="mdi-delete"
                @click:close="deleteEmail(index)"
              >
                <v-avatar left>
                  <v-icon>mdi-email-check</v-icon>
                </v-avatar>
                {{ recipient }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <h3 class="mt-2">
                {{ $t('lbl.programeSend') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-btn
                icon
                @click="showPrograme = !showPrograme"
              >
                <v-icon>
                  {{ showPrograme ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </v-col>
            <fragment v-if="showPrograme">
              <v-col
                cols="12"
                md="3"
              >
                <vc-date-picker
                  v-model="dateTime"
                  outlined
                  dense
                  hide-details
                  :min-date="
                    $moment(new Date())
                      .add(1, 'days')
                      .toDate()
                  "
                  mode="dateTime"
                  :model-config="modelConfig"
                  class="d-flex justify-center;z-index: 1 !important"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.dateTime')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-form>
                  <v-select
                    v-model="zone_time"
                    :items="zoneTimes"
                    :label="$t('lbl.zoneHour')"
                    outlined
                    dense
                    hide-details="auto"
                    item-text="name"
                    item-value="time_zone"
                  ></v-select>
                </v-form>
              </v-col>
            </fragment>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="subject"
                outlined
                dense
                label="Subject"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="adjunt"
                show-size
                outlined
                dense
                label="Adjunto"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <h3 class="text-center">
                {{ `${$t('lbl.template')} 1` }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <!--<v-textarea
                v-model="section1"
                :label="$t('lbl.content')"
                auto-grow
                outlined
                rows="3"
                row-height="25"
                shaped
              ></v-textarea>-->
              <tiptap-vuetify
                v-model="section1"
                :extensions="extensions"
                :placeholder="`${$t('lbl.content')}...`"
                :disabled="$store.state.app.onlyShow"
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <v-img
                v-if="img1 === null"
                src="@/assets/images/pages/activity-timeline.png"
                class="rounded"
                alt="img"
                style="min-height: 100px; width: 100%;cursor: pointer;"
                @click="activeImg1"
              />
              <v-img
                v-else
                :src="previewImage1 !== null ? previewImage1 : '@/assets/images/pages/activity-timeline.png'"
                class="rounded"
                alt="img"
                style="min-height: 100px; width: 100%;cursor: pointer;"
                @click="activeImg1"
              />

              <v-file-input
                id="refInputImg1"
                ref="refInputImg1"
                v-model="img1"
                accept="image/*"
                outlined
                dense
                label="Avatar"
                hide-details
                :hide-spin-buttons="true"
                :hide-input="true"
                prepend-icon=""
                @change="changeImg1"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <!--<v-textarea
                v-model="section2"
                :label="$t('lbl.content')"
                auto-grow
                outlined
                rows="3"
                row-height="25"
                shaped
              ></v-textarea>-->
              <tiptap-vuetify
                v-model="section2"
                :extensions="extensions"
                :placeholder="`${$t('lbl.content')}...`"
                :disabled="$store.state.app.onlyShow"
              />
            </v-col>
          </v-row>
        </template>

        <template v-if="saved">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="subject"
                disabled
                outlined
                dense
                label="Subject"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <v-card
                class="mx-auto"
                max-width="100%"
              >
                <v-card-text class="text--primary">
                  <p class="text-justify">
                    <span v-html="section1"></span>
                  </p>
                  <v-img
                    v-if="previewImage1 !== null"
                    :src="previewImage1"
                    class="rounded"
                    alt="img"
                    style="min-height: 100px; width: 100%;cursor: pointer;"
                  />
                  <p class="text-justify">
                    <span v-html="section2"></span>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiCamera,
  mdiContentSaveMoveOutline,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      subject: null,
      content: null,
      adjunt: null,
      saved: false,
      section1: null,
      img1: null,
      previewImage1: null,
      section2: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCamera,
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiContentSaveMoveOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      showRecipients: false,
      showPrograme: false,
      zoneTimes: [],
      dateTime: null,
      zone_time: null,
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      emailRules: [v => this.isEmail(v) || 'E-mail debe ser válido'],
      show: false,
      status: [],
      emailOrigin: null,
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      languajes: [],
      countries: [],
      user: {},
      totalRecipients: 0,
      recipients: [],
      newEmail: null,
      step1Form: null,
      file: null,
      parsed: false,
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },
  },
  mounted() {
    this.profile()
  },
  created() {
    // this.getCountries()
    this.zoneTimes.push({
      name: 'Ciudad de Guatemala',
      time_zone: 'America/Guatemala',
    })
    this.zoneTimes.push({
      name: 'La Habana',
      time_zone: 'America/Havana',
    })
    this.zoneTimes.push({
      name: 'Los Ángeles',
      time_zone: 'America/Los_Angeles',
    })
    this.zoneTimes.push({
      name: 'Madrid',
      time_zone: 'Europe/Madrid',
    })
    this.zoneTimes.push({
      name: 'Nueva York',
      time_zone: 'America/New_York',
    })
    this.zoneTimes.push({
      name: 'Toronto',
      time_zone: 'America/Toronto',
    })
  },
  methods: {
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
          this.totalRecipients = this.user.totalRecipients
          this.recipients = this.user.recipients
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    // eslint-disable-next-line no-unused-vars
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      this.content = html
    },
    addEmail() {
      if (this.newEmail) {
        const isStepValid = this.$refs.step1Form.validate()
        if (isStepValid) {
          this.recipients.push(this.newEmail)
          this.newEmail = null
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteEmail(index) {
      this.recipients.splice(index, 1)
    },
    activeFileUpload() {
      const refInputAdj = document.getElementById('refInputAdj')
      if (refInputAdj != null) {
        refInputAdj.click()
      }
    },
    importFile() {
      if (this.file) {
        const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.$papa.parse(this.file, {
          header: false,
          skipEmptyLines: true,
          // eslint-disable-next-line func-names
          complete: function (results) {
            // console.log(results.data)

            results.data.forEach(element => {
              if (!this.recipients.includes(element[0]) && res.test(element[0])) {
                this.recipients.push(element[0])
              }
            })
            this.parsed = true
          }.bind(this),
        })

        this.file = null
        this.parsed = false
      }
    },

    setSave() {
      if (this.subject && this.recipients.length > 0) {
        this.saved = true
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    activeImg1() {
      const refInputImg1 = document.getElementById('refInputImg1')
      if (refInputImg1 != null) {
        refInputImg1.click()
      }
    },
    changeImg1() {
      this.previewImage1 = URL.createObjectURL(this.img1)
    },

    save() {
      if (this.subject && this.recipients.length > 0) {
        const json = {
          emails: this.recipients,
          subject: this.subject,
          template: 'template_1',
          section1: this.section1,
          section2: this.section2,
          dateTime: this.dateTime,
          zone_time: this.zone_time,
        }

        const formData = new FormData()
        if (this.adjunt) {
          formData.append('adjunt', this.adjunt)
        }
        if (this.img1) {
          formData.append('img1', this.img1)
        }

        // console.log(json)

        formData.append('data', JSON.stringify(json))

        this.loading = true

        this.axios
          .post('newletter', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            console.log(response)
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              this.$toast.success(this.$t('msg.sendNewletter'))
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false

            this.adjunt = null
            this.content = null
            this.section1 = null
            this.section2 = null
            this.img1 = null
            this.previewImage1 = null
            this.subject = null
            this.saved = false
            this.dateTime = null
            this.zone_time = null
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    cancele() {
      this.$router.go(-1)
    },
  },
}
</script>

<style>
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
img {
  max-width: 100%;
}
.ql-editor {
  min-height: 300px;
}
</style>
