<template>
  <v-row justify="center">
    <v-dialog
      v-model="loading"
      persistent
      max-width="350"
    >
      <v-card>
        <!--<v-card-title class="text-h5">
          Use Google's location service?
        </v-card-title>-->
        <v-card-text>
          <span style="color: $vuetify.theme.themes.light.primary">
            <b>{{ $t('lbl.inactividad1') }}</b>
          </span>
          <br />
          {{ $t('lbl.inactividad2') }}: <span style="color: red">{{ `00:${timerCount >= 10 ? timerCount : `0${timerCount}`}` }}</span>
          <br />
          <br />
        </v-card-text>
        <v-card-text class="text-center">
          <Reload
            width="250"
            height="180"
            :color="$vuetify.theme.themes.light.primary"
          />
        </v-card-text>
        <!--<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="loading = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="loading = false"
          >
            Agree
          </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import Reload from '@/views/utils/images/reload.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loading,
    Reload,
  },
  data() {
    return {
      loading: false,
      timerCount: 59,
      timerEnabled: false,
    }
  },
  computed: {
    ...mapState({
      // carItems: state => state.app.carItems,
    }),
  },

  // EXISTE INACTIVIDAD
  onIdle() {
    if (localStorage.getItem('sanctum_auth') !== null) {
      this.loading = true
      this.timerEnabled = true
    }
  },

  // ESTA ACTIVO
  onActive() {
    this.loading = false
    this.timerCount = 59
    this.timerEnabled = false
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount -= 1
        }, 1000)
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount -= 1
          }, 1000)
        } else if (value === 0 && this.timerEnabled) {
          this.loading = false
          localStorage.clear()
          localStorage.clear()

          this.$router.push({ name: 'inactivity' })

          /* setTimeout(() => {
            window.location.reload()
          }, 100) */
        }
      },
      immediate: true,
    },

  },
  methods: {
    ...mapMutations(['updateItemToCar']),
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
