<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.booking')} / ${$t('menu.reservas')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'reservations' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="!isLoading">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="1"
                >
                  <fragment v-if="item.entity === 'flights'">
                    <v-icon class="mt-1">
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                  </fragment>
                  <fragment v-else-if="item.entity === 'cars'">
                    <v-icon class="mt-1">
                      {{ icons.mdiCar }}
                    </v-icon>
                  </fragment>
                </v-col>
                <v-col
                  cols="12"
                  md="11"
                >
                  <h4 class="mt-1">
                    {{ $t('lbl.code') }}: <i>{{ item.code }}</i>
                  </h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-chip
                :color="item.color_state"
                text-color="white"
                hide-details
              >
                {{ item.state_reserva.name }}
              </v-chip>
            </v-col>
          </v-row>

          <fragment v-if="item.entity === 'cars'">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <CarItem
                      :product="product"
                      :rentadora="rentadora"
                      :image="image"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <CarDetail
                      :recogida="recogida"
                      :date-recogida="dateRecogida"
                      :entrega="entrega"
                      :date-entrega="dateEntrega"
                      :dias-reservar="diasReservar"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <CarPvP :data_markups="data_markups" />
            </v-row>
            <CarConductors :clients="clients" />
          </fragment>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
} from '@mdi/js'

import CarItem from '../utils/CarItem.vue'
import CarDetail from '../utils/CarDetail.vue'
import CarConductors from '../utils/CarConductors.vue'
import CarPvP from '../utils/CarPvP.vue'

export default {
  components: {
    AppCardCode,
    CarItem,
    CarDetail,
    CarConductors,
    CarPvP,
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      product: {},
      clients: {},
      diasReservar: 0,
      rentadora: {},
      recogida: {},
      entrega: {},
      dateRecogida: new Date(),
      dateEntrega: new Date(),
      image: null,
      data_markups: {},
      precioSuplementPayOnline: 0,
      precioSuplementPayRentator: 0,
      precioTarifaRetorno: 0,

      loading: false,

      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    if (localStorage.getItem('reservations-id') !== null) {
      this.axios
        .get(`reservations/${localStorage.getItem('reservations-id')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.item = res.data.data.data
          if (this.item.entity === 'cars') {
            this.product = this.item.data_service.items.car
            this.clients = this.item.data_client
            this.diasReservar = this.item.data_service.items.diasReservar
            this.recogida = this.item.data_service.recogida
            this.dateRecogida = this.item.data_service.dateRecogida
            this.entrega = this.item.data_service.entrega
            this.dateEntrega = this.item.data_service.dateEntrega
            this.rentadora = this.item.data_service.items.rentadora
            this.data_markups = this.item.data_markups

            // PAY ONLINE
            let priceTotal = parseFloat(this.data_markups.tarifaSeguro)
            this.data_markups.suplementos.forEach(suplement => {
              if (!!suplement.check && !!suplement.online) {
                priceTotal += suplement.priceAll
              }
            })
            this.precioSuplementPayOnline = priceTotal

            // PAY RENTADORA
            let priceTotalRent = parseFloat(this.data_markups.tarifaGarantia)
            this.data_markups.suplementos.forEach(suplement => {
              if (!!suplement.check && !suplement.online) {
                priceTotalRent += suplement.priceAll
              }
            })
            this.precioSuplementPayRentator = priceTotalRent

            // PAY RETORNO
            let priceTotalRetorno = 0
            this.data_markups.suplementos.forEach(suplement => {
              if (suplement.suplemento.slug === 'tarifa-de-retorno' && !suplement.online) {
                priceTotalRetorno += suplement.priceAll
              }
            })
            this.precioTarifaRetorno = priceTotalRetorno

            this.getCar()
          }
        })
    } else {
      this.$router.push({ name: 'reservations' })
    }
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    getCar() {
      if (this.product.galery === null) {
        this.image = 'config/car_placeholder.png'
      } else {
        this.image = this.product.galery_random
      }
      setTimeout(() => {
        this.isLoading = false
      }, 200)
    },

    getItem() {
      if (localStorage.getItem('conditions-generals-id') !== null) {
        const id = localStorage.getItem('conditions-generals-id')
        this.axios
          .get(`nom_conditions_generals/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data
            if (this.item.proveedor_id) {
              this.itemsProveedor = this.proveedores.filter(e => e.id === this.item.proveedor_id)
            } else {
              this.itemsProveedor = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
    reseat() {
      this.item = {}
      this.loading = false
      this.$router.push({ name: 'conditions-generals-cars-list' })
    },
  },
}
</script>
