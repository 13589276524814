<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              rowspan="2"
            >
              Desde
            </th>
            <th
              class="text-center"
              rowspan="2"
            >
              Hasta
            </th>
            <th
              class="text-center"
              rowspan="2"
            >
              Cupo
            </th>
            <th
              class="text-center"
              rowspan="2"
            >
              Release
            </th>
            <th
              class="text-center"
              rowspan="2"
            >
              Min. noches
            </th>
            <th
              class="text-center"
              rowspan="2"
            >
              Max. noches
            </th>
            <th
              colspan="6"
              class="text-center"
            >
              Ocupación
            </th>
            <th
              class="text-center"
            >
            </th>
          </tr>
          <tr>
            <th class="text-center">
              Adul.
            </th>
            <th class="text-center">
              Teen
            </th>
            <th class="text-center">
              Mnr.
            </th>
            <th class="text-center">
              Inf.
            </th>
            <th class="text-center">
              Estado
            </th>
            <th class="text-center">
              Precio
            </th>
            <th class="text-center">
              Tarifa
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(season, indS) in tarifariosHotels"
            :key="indS"
            class="text-center"
          >
            <!--<td>{{ $moment(season.from).format('DD MMM Y') }}</td>
            <td>{{ $moment(season.to).format('DD MMM Y') }}</td>-->
            <td>{{ $moment(season.dateFrom).format('DD-MMM-Y') }}</td>
            <td>{{ $moment(season.dateTo).format('DD-MMM-Y') }}</td>
            <td>
              <template v-if="!source.includes('hotetec') && !source.includes('dingus')">
                <span
                  v-if="season.on_request"
                  style="color: red"
                >
                  OR
                </span>
                <span v-else>
                  {{ season.availableQuota }}
                </span>
              </template>
              <template v-if="source.includes('hotetec') || source.includes('dingus')">
                {{ season.availableQuota }}
              </template>
            </td>
            <td>
              <template v-if="!source.includes('hotetec') && !source.includes('dingus')">
                <span
                  v-if="season.release_na"
                  style="color: red"
                >
                  N/A
                </span>
                <span v-else>
                  {{ season.release }}
                </span>
              </template>
              <template v-if="source.includes('hotetec') || source.includes('dingus')">
                {{ season.release }}
              </template>
            </td>
            <td>{{ season.minimumStay }}</td>
            <td>{{ season.maximumStay }}</td>
            <!--<td>{{ season.checkinRestriction }}</td>
            <td>{{ season.checkoutRestriction }}</td>-->
            <td>
              <span v-if="tarifarioFiltersHotels.adult === 'N/A'">
                N/A
              </span>
              <span v-else>{{ season.adult }}</span>
            </td>
            <td>
              <span v-if="tarifarioFiltersHotels.teen === 'N/A'">
                N/A
              </span>
              <span v-else>{{ season.teen }}</span>
            </td>
            <td>
              <span v-if="tarifarioFiltersHotels.children === 'N/A'">
                N/A
              </span>
              <span v-else>{{ season.children }}</span>
            </td>
            <td>
              <span v-if="tarifarioFiltersHotels.babies === 'N/A'">
                N/A
              </span>
              <span v-else>{{ season.babies }}</span>
            </td>
            <td :style="season.status === 'Closed' ? 'color: red !important;' : ''">
              <b v-if="season.status === 'Closed'">{{ season.status }}</b>
              <span v-else>{{ season.status }}</span>
            </td>
            <td>
              <span v-if="season.mnrAmount > 0 && season.mnrAmount !== season.amount">
                <span style="color: red; text-decoration: line-through">
                  {{ season.mnrAmount | currency }}
                </span>
              </span>
              <!--<span v-if="season.myrAmount > 0 && season.myrAmount > season.amount">
                <span style="color: red; text-decoration: line-through">
                  {{ season.myrAmount | currency }}
                </span>
              </span>-->
              {{ season.amount | currency }}
            </td>
            <td>
              <template v-if="tarifasHotels.length > 0">
                <span v-if="tarifasHotels.filter(e => e.code === season.code_tarifa).length > 0">
                  {{ tarifasHotels.filter(e => e.code === season.code_tarifa)[0].name }}
                </span>
              </template>
              <template v-if="tarifasDirectoHotels.length > 0">
                <span v-if="tarifasDirectoHotels.filter(e => e.code === season.code_tarifa).length > 0">
                  {{ tarifasDirectoHotels.filter(e => e.code === season.code_tarifa)[0].name }}
                </span>
              </template>
            </td>
          </tr>
          <!--<tr
            class="text-center"
          >
            <td colspan="11"></td>
            <td>{{ seasonAll | currency }}</td>
          </tr>-->
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    source: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      cuposRoomsSeasonContrateHotel: state => state.app.cuposRoomsSeasonContrateHotel,
      tarifarioFiltersHotels: state => state.app.tarifarioFiltersHotels,
      tarifariosHotels: state => state.app.tarifariosHotels,
      tarifasHotels: state => state.app.tarifasHotels,
      tarifasDirectoHotels: state => state.app.tarifasDirectoHotels,
    }),
    seasonAll() {
      let result = 0
      this.tarifariosHotels.forEach(element => {
        result += element.amount
      })

      return result
    },
  },
  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
  },
}
</script>

<!--<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>-->
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
