<template>
  <div
    class="auth-wrapper auth-v1"
  >
    <template v-if="!isLoading">
      <div class="auth-inner">
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="`${url_public}${config.isotipo ? config.isotipo : config.logo}`"
                max-height="100px"
                max-width="100px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ config.name }}
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <p class="font-weight-semibold text--primary mb-2">
              Gracias por descargar nuestra aplicación <br />
              {{ app.app_name }}
            </p>
          </v-card-text>

          <!-- login form --
          <v-card-text>
            <v-form>
              <v-btn
                :loading="loading"
                block
                color="primary"
                @click="$router.push({ name: 'auth-login' })"
              >
                {{ $t('lbl.iniciar') }}
              </v-btn>
            </v-form>
          </v-card-text>-->
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="190"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      />

      <WstIzq
        v-if="name_project === 'wwwww'"
        class="auth-tree"
        width="230"
        height="200"
        :color="$vuetify.theme.themes.light.primary"
      />
      <WstDerecha
        v-if="name_project === 'wwwww'"
        class="auth-tree-3"
        width="250"
        height="150"
        :color="$vuetify.theme.themes.light.primary"
      />
    </template>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loading,
    WstIzq,
    WstDerecha,
  },
  data() {
    return {
      config: {
        logo: null,
        name: '',
      },
      app: null,
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      icons: {
        mdiClose,
      },
    }
  },
  created() {
    this.getConfig()
    this.load()
  },
  methods: {
    getConfig() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
    },
    load() {
      const json = {
        id: this.$route.params.id,
      }
      this.axios
        .post('apps/show', json)
        .then(res => {
          this.app = res.data.data.data
        })
        .finally(() => {
          this.getUlrAPK()
        })
    },
    getUlrAPK() {
      this.loadingDownload = true
      const json = {
        ext: 'apk',
        id: this.$route.params.id,
      }
      this.axios
        .post('apps/download', json)
        .then(res => {
          const fileUrl = res.data
          this.downloadAPK(fileUrl)
        })
        .finally(() => {
          this.loadingDownload = false
        })
    },
    async downloadAPK(fileUrl) {
      try {
        const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', `${this.app.slug}.apk`)
        document.body.appendChild(link)

        await link.click()
        link.remove()

        this.isLoading = false
      } catch (error) {
        console.error('Error al descargar el archivo:', error)
      }
    },

    /* downloadAPK() {
      this.loadingDownload = true
      const json = {
        ext: 'apk',
        id: this.$route.params.id,
      }

      this.axios
        .post('apps/download', json)
        .then(async res => {
          const fileUrl = res.data
          try {
            const response = await this.axios.get(fileUrl, {
              responseType: 'blob',
            })

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.app.slug}.apk`)
            document.body.appendChild(link)
            link.click()
            link.remove()

            // Liberar objeto URL después de la descarga
            window.URL.revokeObjectURL(url)
          } catch (error) {
            console.error('Error al descargar el archivo:', error)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }, */

    /* downloadAPK() {
      const json = {
        ext: 'apk',
        id: this.$route.params.id,
      }
      this.axios
        .post('apps/download', json, { responseType: 'arraybuffer' })
        .then(res => {
          const filename = this.app.app_name

          this.downloadFileAPK(res, filename)
        })
        .finally(() => {
          this.isLoading = false
        })
    }, */
    downloadFileAPK(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/apk' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.apk`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)

        // window.close()
      }, 100)
    },
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
