var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"400px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(((_vm.$t('lbl.cancelated')) + "!!!")))]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('msg.deleteBookingItem', { item: _vm.item.code }))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('msg.deleteUndo'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.no'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.yes'))+" ")])],1)],1)],1),(_vm.reservasPaginate.length > 0)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase",attrs:{"width":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.code'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.client'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.product'))+" ")]),_c('th',{staticClass:"text-uppercase",attrs:{"width":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.service'))+" ")]),_c('th',{staticClass:"text-uppercase",attrs:{"width":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.monto'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.vendedor'))+" ")]),_c('th',{staticClass:"text-uppercase",attrs:{"width":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.state'))+" ")]),(
                    _vm.permisos.includes('reservas:list') ||
                      _vm.permisos.includes('reservas:edit') ||
                      _vm.permisos.includes('reservas:destroy') ||
                      _vm.permisos.includes('*')
                  )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.actions'))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.reservasShowPaginate),function(reserva,index){return _c('tr',{key:index},[_c('td',[_c('span',{staticClass:"d-inline-block text-caption",style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(" "+_vm._s(reserva.code)+" ")])]),_c('td',[(reserva.cliente)?_c('span',[(reserva.cliente.name)?[_vm._v(" "+_vm._s(reserva.cliente.name)+" "+_vm._s(reserva.cliente.apellidos)),_c('br'),(reserva.cliente.email)?_c('a',{attrs:{"href":("mailito:" + (reserva.cliente.email))}},[_vm._v(" "+_vm._s(reserva.cliente.email)+" ")]):(reserva.cliente.emails.length > 0)?_c('a',{attrs:{"href":("mailito:" + (reserva.cliente.emails[0]))}},[_vm._v(" "+_vm._s(reserva.cliente.emails[0])+" ")]):_vm._e(),(reserva.cliente.telefono)?_c('span',[_c('br'),_vm._v(_vm._s(reserva.cliente.telefono)+" ")]):_vm._e()]:_vm._e()],2):_vm._e()]),_c('td',[(reserva.entities)?_c('fragment',[(reserva.entities.length > 1)?_c('span',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPackage))]),_vm._v(" "+_vm._s(_vm.$t('lbl.paquet'))+" ")],1)]}}],null,true)},[_c('span',_vm._l((reserva.entities),function(entity,ind){return _c('span',{key:ind},[(entity === 'flights')?_c('fragment',[_c('v-icon',{staticStyle:{"color":"#FFF"}},[_vm._v(_vm._s(_vm.icons.mdiAirplaneTakeoff))]),_vm._v(" "+_vm._s(_vm.$t('lbl.flight'))),_c('br')],1):_vm._e(),(entity === 'cars')?_c('fragment',[_c('v-icon',{staticStyle:{"color":"#FFF"}},[_vm._v(_vm._s(_vm.icons.mdiCar))]),_vm._v(" "+_vm._s(_vm.$t('lbl.car'))+" "),_c('br')],1):_vm._e(),(entity === 'hotels')?_c('fragment',[_c('v-icon',{staticStyle:{"color":"#FFF"}},[_vm._v(_vm._s(_vm.icons.mdiBed))]),_vm._v(" "+_vm._s(_vm.$t('lbl.hotel'))+" "),_c('br')],1):_vm._e()],1)}),0)])],1):_c('span',[(reserva.entities.includes('flights'))?_c('fragment',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAirplaneTakeoff))]),_vm._v(" "+_vm._s(_vm.$t('lbl.flight'))+" ")],1):(reserva.entities.includes('cars'))?_c('fragment',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCar))]),_vm._v(" "+_vm._s(_vm.$t('lbl.car'))+" ")],1):(reserva.entities.includes('hotels'))?_c('fragment',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiBed))]),_vm._v(" "+_vm._s(_vm.$t('lbl.hotel'))+" ")],1):_vm._e()],1)]):_vm._e()],1),_c('td',[(reserva.date_servicio.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(reserva.date_servicio[0],'D/MM/Y'))+" ")]):_vm._e()]),_c('td',[_c('span',{style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("currency")(reserva.pricePayOnline,reserva.symbolPayOnline))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('lbl.venta'))+": "+_vm._s(_vm._f("currency")(reserva.pricePayOnline,reserva.symbolPayOnline))+" ("+_vm._s(reserva.codePayOnline)+") "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('lbl.neto'))+": "+_vm._s(_vm._f("currency")(reserva.priceNeto,reserva.symbolNeto))+" ("+_vm._s(reserva.codeNeto)+") "),_c('br'),_vm._v(" Utilidad: "+_vm._s(_vm._f("currency")(reserva.priceUtilidad,'$'))+" "),_c('br'),_vm._v(" Comisión: "+_vm._s(_vm._f("currency")(reserva.priceComision,'$'))+" ")])])],1)]),_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"d-inline-block text-caption"},[_vm._v(_vm._s(reserva.afiliado.name))]),_c('br'),_vm._v(" "+_vm._s(reserva.afiliado.company !== null ? reserva.afiliado.company : reserva.afiliado.nivel === 0 ? 'Propietario' : '')+" ")]),_c('td',[(reserva.state_reserva)?_c('v-chip',{attrs:{"color":reserva.color_state,"text-color":"white"}},[_vm._v(" "+_vm._s(reserva.state_reserva.name)+" ")]):_vm._e()],1),(_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('reservas:list') || _vm.permisos.includes('*'))?_c('td',{attrs:{"width":"10%"}},[(
                      (_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('reservas:list') || _vm.permisos.includes('*'))
                        && reserva.code
                    )?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(_vm.permisos.includes('reservas:list'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.showItem(reserva)}}},[_c('v-list-item-title',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))])],1),_c('span',[_vm._v(_vm._s(_vm.$t('btn.see')))])],1)],1):_vm._e(),(
                          (_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('*')) &&
                            reserva.state_reserva.slug !== 'cancelada' &&
                            (reserva.afiliado.nivel === _vm.user.nivel || _vm.user.nivel <= 0)
                        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editItem(reserva)}}},[_c('v-list-item-title',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])],1)],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()])}),0)]},proxy:true}],null,false,1715261046)})],1)],1),(_vm.reservasShowPaginate.length > 0)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.totalPagination,"total-visible":"5"},model:{value:(_vm.currentPagination),callback:function ($$v) {_vm.currentPagination=$$v},expression:"currentPagination"}})],1)],1):_vm._e()]:[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"4","offset-md":"4"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('msg.noReservas'))+" "+_vm._s(_vm.$t('msg.changeFilters'))+" ")])],1)],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }