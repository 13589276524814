<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${pos === 0 ? $t('lbl.addOcupation') : $t('lbl.deleteOcupation')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-update' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="pos === 0"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyItems"
                @click="save()"
              >
                <span>{{ $t('btn.add') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="pos === 1"
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyDelete"
                @click="isDialogVisibleDelete = true"
              >
                <span>{{ $t('btn.delete') }}</span>
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row v-if="isLoading">
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>

            <template v-else>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs
                    v-model="pos"
                    icons-and-text
                    @change="changeTab"
                  >
                    <v-tab>
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-tab>
                    <v-tab>
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="pos">
                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationAdd.adult"
                            :items="adults"
                            label="ADL"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationAdd"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  ADL
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <!--<v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationAdd.teen"
                            disabled
                            :items="teens"
                            label="TEEN"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  TEEN
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>-->

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationAdd.mnr"
                            :items="childrens"
                            label="MNR"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationAdd"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  MNR
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationAdd.inf"
                            :items="babies"
                            label="INF"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationAdd"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  INF
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationDel.adult"
                            :items="adults"
                            label="ADL"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationDel"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  ADL
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationDel.mnr"
                            :items="childrens"
                            label="MNR"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationDel"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  MNR
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-model="ocupationDel.inf"
                            :items="babies"
                            label="INF"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            :disabled="isLoadingHotels"
                            @change="changeOcupationDel"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  INF
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </template>
          </v-form>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <template v-if="!isLoadingHotels">
                <v-simple-table v-if="hotels.length > 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase"
                          width="5%"
                        >
                          <v-checkbox
                            v-model="checkAll"
                            hide-details
                            class="my-0"
                            @change="changeCheckAll"
                          ></v-checkbox>
                        </th>
                        <th
                          class="text-uppercase"
                          width="40%"
                        >
                          {{ $t('lbl.hotel') }}
                        </th>
                        <th
                          class="text-uppercase"
                        >
                          {{ $t('lbl.destino') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <fragment
                        v-for="(iten, indP) in hotels"
                        :key="indP"
                      >
                        <tr v-if="iten.id === iten.id">
                          <td>
                            <v-checkbox
                              v-model="iten.check"
                              hide-details
                              class="my-0"
                              @change="changeCheckItem"
                            ></v-checkbox>
                          </td>
                          <td>
                            <NameHotelStar :hotel="iten" />
                          </td>
                          <td>
                            {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                            <span v-if="iten.country">{{ iten.country }}</span>
                          </td>
                        </tr>
                      </fragment>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>

    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          ¿Está seguro que desea eliminar la ocupación definida?
          <br />
          <br />
          Esta acción es irreversible y dejaría a los hoteles sin precios para esta ocupación.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline, mdiUpdate,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      pos: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
      },
      ocupationAdd: {
        adult: 0,
        mnr: 0,
        inf: 0,
      },
      ocupationDel: {
        adult: 0,
        mnr: 0,
        inf: 0,
      },
      adults: [],
      teens: [],
      childrens: [],
      babies: [],
      loading: false,
      loadingCategories: true,
      categoriesTarifarios: [],
      hotels: [],
      hotelsNomAll: [],
      isLoadingHotels: true,
      hotels_pactados: [],
      checkAll: true,
      model: null,
      ocupations: [],
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
    verifyItems() {
      let result = false

      /* if (!this.tarifa.name || !this.tarifa.category_id) {
        result = true
      } */
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
    verifyDelete() {
      let result = false

      /* if (!this.tarifa.tarifa_code) {
        result = true
      } */
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
  },
  created() {
    this.getItem()

    this.adults.push(0)
    this.adults.push(1)
    this.adults.push(2)
    this.adults.push(3)
    this.adults.push(4)
    this.adults.push(5)
    this.adults.push(6)
    this.adults.push(7)
    this.adults.push(8)
    this.adults.push(9)
    this.adults.push(10)

    this.teens.push(1)
    this.teens.push(2)
    this.teens.push(3)
    this.teens.push(4)
    this.teens.push(5)
    this.teens.push(6)
    this.teens.push(7)
    this.teens.push(8)
    this.teens.push(9)
    this.teens.push(10)

    this.childrens.push(0)
    this.childrens.push(1)
    this.childrens.push(2)
    this.childrens.push(3)
    this.childrens.push(4)
    this.childrens.push(5)
    this.childrens.push(6)
    this.childrens.push(7)
    this.childrens.push(8)
    this.childrens.push(9)
    this.childrens.push(10)

    this.babies.push(0)
    this.babies.push(1)
    this.babies.push(2)
    this.babies.push(3)
    this.babies.push(4)
    this.babies.push(5)
    this.babies.push(6)
    this.babies.push(7)
    this.babies.push(8)
    this.babies.push(9)
    this.babies.push(10)
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    getItem() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.hotels_pactados = this.model.hotels_pactados

            // LLENAR LAS OCUPACIONES
            this.model.seasons.forEach(elementSea => {
              const hot = {
                name: null,
                city: null,
                country: null,
                hotel_id: null,
                ocupations: [],
                slugs: [],
              }
              if (elementSea.hotels) {
                if (elementSea.hotels.length > 0) {
                  // eslint-disable-next-line prefer-destructuring
                  hot.hotel_id = elementSea.hotels[0]

                  if (elementSea.seasons) {
                    if (elementSea.seasons.ocupations) {
                      elementSea.seasons.ocupations.forEach(elementOcup => {
                        elementOcup.ocupations.forEach(elementOO => {
                          const slug = `adl-${elementOO.adult},mnr-${elementOO.mnr},inf-${elementOO.inf}`
                          hot.ocupations.push({
                            adult: elementOO.adult,
                            teen: 0,
                            mnr: elementOO.mnr,
                            inf: elementOO.inf,
                            slug,
                          })
                          hot.slugs.push(slug)
                        })
                      })
                    }
                  }

                  this.ocupations.push(hot)
                }
              }
            })
          })
          .finally(() => {
            this.isLoading = false

            this.getHoteles()
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'marca_asociada_id',
          'categoria_id',
          'tipo_destino_id',
          'sugerido',
          'number_sugerido',
          'checkIn',
          'checkOut',
          'direccion',
          'publicado',
          'cant_departament',
        ],
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
            this.hotelsNomAll.forEach(element => {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < this.ocupations.length; index++) {
                if (this.ocupations[index].hotel_id === element.id) {
                  this.ocupations[index].name = element.name
                  this.ocupations[index].city = element.city
                  this.ocupations[index].country = element.country
                }
              }

              /* if (this.hotels_pactados.includes(element.id)) {
                this.hotels.push({
                  ...element,
                  check: true,
                })
              } */
            })
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
        })
    },
    changeTab() {
      this.checkAll = true
      this.hotels = []
      this.ocupationAdd = {
        adult: 0,
        mnr: 0,
        inf: 0,
      }
      this.ocupationDel = {
        adult: 0,
        mnr: 0,
        inf: 0,
      }
    },
    changeOcupationAdd() {
      this.checkAll = true
      const slug = `adl-${this.ocupationAdd.adult},mnr-${this.ocupationAdd.mnr},inf-${this.ocupationAdd.inf}`
      this.hotels = []
      if (this.ocupationAdd.adult) {
        this.ocupations.forEach(element => {
          if (!element.slugs.includes(slug)) {
            this.hotels.push({
              ...element,
              check: true,
            })
          }
        })
      }

      // this.hotels = this.hotels.sort((a, b) => a.name - b.name)
    },
    changeOcupationDel() {
      this.checkAll = true
      const slug = `adl-${this.ocupationDel.adult},mnr-${this.ocupationDel.mnr},inf-${this.ocupationDel.inf}`
      this.hotels = []
      if (this.ocupationDel.adult) {
        this.ocupations.forEach(element => {
          if (element.slugs.includes(slug)) {
            this.hotels.push({
              ...element,
              check: true,
            })
          }
        })
      }
    },
    changeCheckAll() {
      if (this.checkAll) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = false
        }
      }
    },
    changeCheckItem() {
      const cantH = this.hotels.length
      let cont = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cont += 1
        }
      }
      if (cont === cantH) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
    save() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.hotel_id)
        }
      })
      this.ocupationAdd.hotels = hot
      this.ocupationAdd.contrate_id = localStorage.getItem('hotels-contrate-id')

      this.loading = true
      this.axios
        .post('contrate_hotels/agregar-ocupation-global', this.ocupationAdd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    confirmDelete() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.hotel_id)
        }
      })
      this.ocupationDel.hotels = hot
      this.ocupationDel.contrate_id = localStorage.getItem('hotels-contrate-id')

      this.loading = true
      this.axios
        .post('contrate_hotels/delete-ocupation-global', this.ocupationDel, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isDialogVisibleDelete = false
          this.loading = false
        })
    },
  },
}
</script>
