<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <Loading
      v-if="searching"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <v-col cols="12">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              class="me-3"
              :disabled="!isTable || campos.length === 0"
              @click="search()"
            >
              <span>{{ $t('btn.search') }}</span>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              class="me-3"
              :disabled="!isTable || campos.length === 0"
              @click="activeFilter()"
            >
              <span>{{ $t('lbl.filtros') }}</span>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="tabla"
              :items="entitiesList"
              :label="$t('lbl.tableData')"
              outlined
              dense
              item-text="name"
              item-value="tabla"
              class="pt-2"
              return-object
              clearable
              @click:clear="clearTable"
              @change="setTabla"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.table') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <!--<v-autocomplete
              v-model="tabla"
              :items="entities"
              :search-input.sync="entitySearch"
              hide-details
              hide-selected
              :label="$t('lbl.tableData')"
              outlined
              dense
              item-text="name"
              item-value="tabla"
              class="pt-2"
              return-object
              clearable
              @click:clear="clearTable"
              @change="setTabla"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.table') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>-->
          </v-col>
          <v-col
            v-if="isTable"
            cols="12"
            md="8"
          >
            <v-select
              v-model="campos"
              :items="camposEntityList"
              :label="$t('lbl.inputsExport')"
              multiple
              outlined
              dense
              item-text="string"
              item-value="name"
              class="pt-2"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="campos.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('lbl.selectAll') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <!--<v-select
              v-model="campos"
              :items="camposEntityList"
              :label="$t('lbl.inputsExport')"
              multiple
              outlined
              dense
              item-text="string"
              item-value="name"
              class="pt-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.inputs') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>-->
            <!--<v-autocomplete
              v-model="campos"
              :items="camposList"
              :search-input.sync="camposSearch"
              hide-details
              hide-selected
              :label="$t('lbl.inputsExport')"
              outlined
              dense
              item-text="name"
              item-value="tabla"
              class="pt-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.inputs') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>-->
          </v-col>
        </v-row>

        <v-row v-if="isTable">
          <v-col
            cols="12"
            md="12"
          >
            <Table
              :tabla="tabla"
              :items="items"
              :headers="headers"
              :total-f="totalF"
              :total-items="totalItems"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!--<v-card-text v-if="!isLoading">
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-row>
              <v-col
                cols="12"
                md="1"
              >
                <fragment v-if="item.entity === 'flights'">
                  <v-icon class="mt-1">
                    {{ icons.mdiAirplaneTakeoff }}
                  </v-icon>
                </fragment>
                <fragment v-else-if="item.entity === 'cars'">
                  <v-icon class="mt-1">
                    {{ icons.mdiCar }}
                  </v-icon>
                </fragment>
              </v-col>
              <v-col
                cols="12"
                md="11"
              >
                <h4 class="mt-1">
                  {{ $t('lbl.code') }}: <i>{{ item.code }}</i>
                  <fragment v-if="item.number_vouchers">
                    <br />
                    <span v-if="item.number_vouchers.length > 0">
                      Voucher: <i>{{ item.number_vouchers[0].voucher }}</i>
                    </span>
                  </fragment>
                  <fragment v-if="item.number_confirmed">
                    <br />
                    {{ $t('lbl.claveConfirm') }}: <i>{{ item.number_confirmed }}</i><br />
                  </fragment>
                </h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="!editStatus"
            cols="12"
            md="2"
          >
            <v-chip
              :color="item.color_state"
              text-color="white"
              hide-details
            >
              {{ item.state_reserva.name }}
            </v-chip>
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editStatus = !editStatus"
                >
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.edit') }}</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-else
            cols="12"
            md="4"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="item.state_reserva_id"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.state')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                v-if="item.number_confirmed || idConfirmState === item.state_reserva_id"
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="item.number_confirmed"
                  :label="$t('lbl.numerConfirmed')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <fragment v-if="item.entity === 'cars'">
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <CarItem
                    :product="product"
                    :rentadora="rentadora"
                    :image="image"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <CarDetail
                    :recogida="recogida"
                    :date-recogida="dateRecogida"
                    :entrega="entrega"
                    :date-entrega="dateEntrega"
                    :dias-reservar="diasReservar"
                  />
                </v-col>
              </v-row>
            </v-col>

            <CarPvPUpdate
              v-if="!loadingPvP"
              :data_markups="data_markups"
              :slug-state="slugState"
              @reset-pvp="resetPvP()"
            />
          </v-row>

          <CarConductorsUpdate :clients="clients" />
      </v-card-text>-->
    </v-col>

    <fragment v-if="isTable">
      <SidebarDataFilter
        v-if="tabla"
        v-model="isSidebarDataFilterActive"
        :tabla="tabla"
        :marcas-cars="marcasCars"
        :transmisiones="transmisiones"
        :type-auto="typeAuto"
        :combustibles="combustibles"
        :caracteristicas="caracteristicas"
        :operadores-hotel="operadoresHotel"
        :marcas-hotel="marcasHotel"
        :categories="categories"
        :tipos-destinos-hotel="tiposDestinosHotel"
        :intereses-hotel="interesesHotel"
        :servicios-hotel="serviciosHotel"
        :edades-hotel="edadesHotel"
        :rooms-hotel="roomsHotel"
        :countries="countries"
        :states="states"
        :states-list="statesList"
        :cities="cities"
        @search="search"
      />
    </fragment>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

import SidebarDataFilter from '../utils/SidebarDataFilter.vue'
import Table from '../utils/Table.vue'

export default {
  components: {
    Table,
    SidebarDataFilter,
    Loading,
  },
  data() {
    return {
      searching: false,
      isLoading: true,
      isTable: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      isSidebarDataFilterActive: false,
      loading: false,
      tabla: {
        name: null,
        tabla: null,
        campos: [],
      },
      campos: [],
      entities: [],
      entitySearch: null,
      entitiesList: [],
      camposEntityList: [],
      camposList: [],
      camposSearch: null,
      items: [],
      headers: [],
      totalF: 0,
      totalItems: 0,

      marcasCars: [],
      transmisiones: [],
      typeAuto: [],
      combustibles: [],
      caracteristicas: [],

      operadoresHotel: [],
      marcasHotel: [],
      categories: [],
      tiposDestinosHotel: [],
      interesesHotel: [],
      serviciosHotel: [],
      edadesHotel: [],
      roomsHotel: [],
      countries: [],
      states: [],
      statesList: [],
      cities: [],
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
      filtrosExport: state => state.app.filtrosExport,
    }),

    selectAllInputs() {
      return this.campos.length === this.camposEntityList.length
    },
    selectSomeInputs() {
      return this.campos.length > 0 && !this.selectAllInputs
    },
    icon() {
      if (this.selectAllInputs) return 'mdi-close-box'
      if (this.selectSomeInputs) return 'mdi-minus-box'

      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    entitySearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterEntities(val.toLowerCase())
      } else {
        this.entities = []
      }
    },
    camposSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCampos(val.toLowerCase())
      } else {
        this.camposList = []
      }
    },
  },
  created() {
    this.getEntities()

    // NOM CARS
    this.getMarcas()
    this.getTransmisiones()
    this.getTypeAuto()
    this.getCombustibles()
    this.getCaracteristicas()

    // NOM HOTELS
    this.getOperadores()
    this.getMarcasHotels()
    this.getCategorias()
    this.getTipoDestino()
    this.getIntereses()
    this.getServicios()
    this.getEdadesHotel()
    this.getRooms()
    this.getDestinosHotel()
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'setFiltrosExport']),
    filterEntities(v) {
      this.entities = []
      if (v === '') {
        this.entities = []
      } else {
        this.entities = this.entitiesList.filter(e => e.name.toLowerCase())
      }
    },
    filterCampos(v) {
      this.camposList = []
      if (v === '') {
        this.camposList = []
      } else {
        this.camposList = this.camposEntityList.filter(e => e.name.toLowerCase())
      }
    },
    getEntities() {
      this.axios
        .get('export-csv/list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.entitiesList = res.data.data.list
            this.entities = this.entitiesList
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    toggle() {
      if (this.selectAllInputs) {
        this.campos = []
      } else {
        this.campos = []
        this.camposEntityList.forEach(element => {
          this.campos.push(element.name)
        })
      }
    },

    setTabla() {
      this.isTable = false
      if (this.tabla) {
        if (this.tabla.tabla) {
          this.resetValues()

          setTimeout(() => {
            this.isTable = true
            const result = this.entitiesList.filter(e => e.tabla === this.tabla.tabla)[0]
            this.camposEntityList = result.campos
          }, 100)
        } else {
          this.clearTable()
        }
      } else {
        this.clearTable()
      }
    },
    clearTable() {
      this.tabla = {
        name: null,
        tabla: null,
        campos: [],
      }
      this.isTable = false
      setTimeout(() => {
        this.resetValues()
      }, 50)
    },
    resetValues() {
      this.isSidebarDataFilterActive = false
      this.setFiltrosExport({})
      this.headers = []
      this.items = []
      this.totalF = 0
      this.totalItems = 0
      this.campos = []
      this.camposEntityList = []
    },

    search() {
      this.isSidebarDataFilterActive = false
      this.searching = true
      const json = {
        table: this.tabla.tabla,
        inputs: this.campos,
        search: this.filtrosExport,
      }

      this.axios
        .post('export-csv/search', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.headers = res.data.data.headers
            this.items = res.data.data.list
            this.totalF = this.items.length
            this.totalItems = res.data.data.totalItems
          }
        })
        .finally(() => {
          this.searching = false
        })
    },
    activeFilter() {
      this.isSidebarDataFilterActive = true
    },

    // NOMENCLADORES AUTOS
    getMarcas() {
      this.axios
        .get(`nom_marca_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcasCars = res.data.data
        })
    },
    getTransmisiones() {
      this.axios
        .get(`nom_transmision_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.transmisiones = res.data.data
        })
    },
    getTypeAuto() {
      this.axios
        .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeAuto = res.data.data
        })
    },
    getCombustibles() {
      this.axios
        .get(`nom_combustible_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.combustibles = res.data.data
        })
    },
    getCaracteristicas() {
      this.axios
        .get(`nom_caracteristica_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.caracteristicas = res.data.data
        })
    },

    // NOMENCLADORES HOTELES
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadoresHotel = res.data.data
        })
    },
    getMarcasHotels() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcasHotel = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tiposDestinosHotel = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.interesesHotel = res.data.data
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.serviciosHotel = res.data.data
        })
    },
    getEdadesHotel() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edadesHotel = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsHotel = res.data.data
        })
    },
    getDestinosHotel() {
      this.axios
        .post(
          'hotels/destinos',
          {
            countries: 'all',
            states: 'all',
            cities: 'all',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.countries = res.data.data.countries
          this.states = res.data.data.states
          this.statesList = res.data.data.statesList
          this.cities = res.data.data.cities
        })
    },
  },
}
</script>
