<template>
  <fragment>
    <template>
      <v-col
        v-if="cardsDashboard.includes('download-apps')"
        cols="12"
        order="1"
        class="align-self-top"
      >
        <DNS
          :user="user"
        />
      </v-col>
    </template>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import DNS from './notificaciones/DNS.vue'

export default {
  components: {
    DNS,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
    }),
  },
}
</script>
