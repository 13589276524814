<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
    ></v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="suplement.suplement_id"
        :disabled="$store.state.app.onlyShow"
        :items="suplementFree"
        :label="$t('lbl.suplemento')"
        outlined
        dense
        hide-details
        item-text="name"
        item-value="id"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.suplemento') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="room.suplement.length > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            v-bind="attrs"
            fab
            dark
            x-small
            color="error"
            v-on="on"
            @click="
              deleteSuplementRoomByPriceBySuplementHotelOfertEBB({ pos: posHotel, posRoom: posRoom, posSup: pos })
            "
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>

      <v-tooltip
        v-if="pos === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addSuplementRoomByPriceBySuplementHotelOfertEBB({ pos: posHotel, posRoom: posRoom })"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-text-field
        v-model="suplement.valueSuplement"
        :label="$t('lbl.monto')"
        :disabled="$store.state.app.onlyShow"
        class="my-input"
        outlined
        dense
        hide-details
        type="number"
        :prefix="suplement.in_value_suplement ? '$' : ''"
        :suffix="!suplement.in_value_suplement ? '%' : ''"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-row class="mt-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="suplement.in_value_suplement"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.valor')"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posRoom: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posHotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsRooms: [],
      calculators: [],
      menuFromViaje: false,
      menuToViaje: false,
      menuFromReserva: false,
      menuToReserva: false,
      menuRooming: false,
      menuFromPagar: false,
      menuFecha: false,
    }
  },
  computed: {
    ...mapState({
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
    }),
    suplementFree() {
      const suplementContrate = []
      this.allSuplement.forEach(element => {
        /* let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.room.suplement.length; index++) {
          if (this.room.suplement[index].suplement_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          suplementContrate.push(element)
        } */
        suplementContrate.push(element)
      })

      let result = []
      if (this.allInclude) {
        suplementContrate.forEach(element => {
          if (
            element.slug !== 'suplemento-de-alimentacion-full-board-para-adultos-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'suplemento-de-cena-map'
            && element.slug !== 'suplemento-de-cena-para-adultos-map'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'suplemento-de-desayuno-para-adultos-cp'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
            && element.slug !== 'suplemento-de-desayuno'
            && element.slug !== 'suplemento-de-desayuno-cp'
          ) {
            result.push(element)
          }
        })
      } else if (this.onlyAdult) {
        suplementContrate.forEach(element => {
          if (
            element.slug !== 'suplemento-de-alimentacion-full-board-para-adultos-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'suplemento-de-cena-para-adultos-map'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'suplemento-de-desayuno-para-adultos-cp'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
          ) {
            result.push(element)
          }
        })
      } else {
        result = suplementContrate
      }

      return result
    },
  },
  methods: {
    ...mapMutations([
      'deleteSuplementRoomByPriceBySuplementHotelOfertEBB',
      'addSuplementRoomByPriceBySuplementHotelOfertEBB',
    ]),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
