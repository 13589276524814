<template>
  <v-navigation-drawer
    :value="isSidebarPPPPPSendActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-ppppp-send-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('btn.gestionarCobros') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-ppppp-send-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.email"
                outlined
                :label="$t('lbl.para')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.subject"
                outlined
                :label="$t('lbl.subject')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <tiptap-vuetify
                v-model="clientCarShop.message"
                :extensions="extensions"
                :placeholder="`${$t('lbl.message')}...`"
              />
            </v-col>
          </v-row>

          <!--<v-chip
            color="primary"
            small
            label
            class="v-chip-light-bg primary--text mb-6"
          >
            {{ $t('lbl.adjuntCotization') }}
          </v-chip>-->

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-ppppp-send-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="!clientCarShop.email || !clientCarShop.subject || !clientCarShop.message"
                :loading="loading"
                @click="sendEmail"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  // ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  model: {
    prop: 'isSidebarPPPPPSendActive',
    event: 'update:is-sidebar-ppppp-send-active',
  },
  props: {
    isSidebarPPPPPSendActive: {
      type: Boolean,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    nameb2b: {
      type: String,
      required: true,
    },
    nameafiliado: {
      type: String,
      required: true,
    },
    codes: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    noConciliacion: {
      type: String,
      required: true,
    },
    emailConciliacion: {
      type: String,
      required: true,
    },
    montoAll: {
      type: Number,
      required: true,
    },
    moneda: {
      type: String,
      required: true,
    },
    ctasCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      extensions: [
        // BulletList,
        // OrderedList,
        // ListItem,
        Italic,
        Bold,
        HardBreak,
      ],
      icons: {
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      itemsCheckCobros: state => state.app.itemsCheckCobros,
    }),
    message: {
      get() {
        const msg = `<p>Hola <b>${this.itemsCheckCobros[0].company.user_name}</b>, le enviamos nuestra conciliación
          <b>${this.noConciliacion}</b> por un monto de <b>${this.$options.filters.currency(this.montoAll)} ${
  this.moneda
}</b>
          <br /><br />
          Las órdenes de servicio (<b>${this.ctasCount}</b>) van en adjunto para su revisión.
          <br /><br />
          Quedamos al pendiente de sus comentarios.
          <br /><br />
          <span style="margin-left: 10px">Saludos Cordiales</span>
      </p>`

        return msg
      },
    },
  },

  /**
   * const msg = `<p>Buenos días, en espera se encuentren bien, le enviamos nuestra conciliación <b>${
          this.noConciliacion
        }</b> por un monto de
          <b>${this.$options.filters.currency(this.montoAll)} ${this.moneda}</b> el cual se encuentra amparado por <b>${
  this.ctasCount
}</b> órdenes de servicio, las cuales adjunto.
          <br /><br />
          Quedamos al pendiente de sus comentarios.
          <br /><br />
          <span style="margin-left: 10px">Saludos Cordiales</span>
          <br /><br />
          Ivón Guerra Hernández<br />
          Director Administrativo<br />
          Proturs, S.A. de C.V. <br />
          Tel: (55) 5264-4497<br />
          ivon@proturs.com<br />
          Av. Insurgentes Sur 295-B<br />
          Col. Hipodromo Condesa<br />
          06140 Ciudad de México<br />
      </p>`
   *
   */

  created() {
    this.clientCarShop.subject = 'Gestión de cobros, cuentas por pagar'
    this.clientCarShop.message = this.message
    this.clientCarShop.email = this.emailConciliacion
  },
  methods: {
    ...mapMutations(['setCheckAllCobros', 'updateItemCheckCobros']),
    sendEmail() {
      const json = {
        subject: this.clientCarShop.subject,
        message: this.clientCarShop.message,
        email: this.clientCarShop.email,
        ctas: this.itemsCheckCobros,
        email_conciliacion: this.itemsCheckCobros[0].company.email,
        user_name_conciliacion: this.itemsCheckCobros[0].company.user_name,
      }
      this.loading = true
      this.axios
        .post('ctas-x-cobrar/send-email-ppppp', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.$emit('update:is-sidebar-ppppp-send-active', false)
            this.$toast.success(this.$t('msg.infoSuccess'))

            // VACIAR EL ARREGLO
            this.setCheckAllCobros(false)
            this.updateItemCheckCobros([])
            this.$emit('update:is-sidebar-data-pays-active', false)
            this.$emit('initAll')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
