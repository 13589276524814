<template>
  <tr>
    <td>
      <v-checkbox
        v-model="oficina.check"
        hide-details
        class="my-0"
        @change="changeCheck()"
      ></v-checkbox>
    </td>
    <td>
      <span class="pb-0 mb-0">
        {{ oficina.name }}
      </span>
      <br />
      <span class="text-caption pt-0 mt-0">
        {{ `${oficina.country}, ${oficina.state}, ${oficina.city}` }}
      </span>
    </td>
    <td>
      <v-tooltip
        v-if="oficina.direccion !== null && oficina.direccion !== 'null'"
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-map-marker-outline</v-icon>
          </v-btn>
        </template>
        <span>
          <span>{{ oficina.direccion }}</span>
        </span>
      </v-tooltip>
    </td>
    <fragment v-if="oficina.check">
      <fragment
        v-for="(oficceR, indOficce) in oficceRent"
        :key="indOficce"
      >
        <fragment v-if="oficceR.oficina_id === oficina.id">
          <td>
            <Telefonos
              v-for="(telefono, i) in oficceR.telefonos"
              :key="i"
              :telefono="telefono"
              :pos="i"
              :pos-oficce="indOficce"
              :total="oficceR.telefonos.length"
            />
          </td>
          <td>
            <Horarios
              v-for="(horario, i) in oficceR.horarios"
              :key="i"
              :pos="i"
              :horario="horario"
              :pos-oficce="indOficce"
              :total="oficceR.horarios.length"
            />
          </td>
        </fragment>
      </fragment>
    </fragment>
    <fragment v-else>
      <td></td>
      <td></td>
    </fragment>

    <!---->
  </tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline } from '@mdi/js'

import Horarios from './Horarios.vue'
import Telefonos from './Telefonos.vue'

export default {
  components: {
    Telefonos,
    Horarios,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    oficina: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
    }
  },
  computed: {
    ...mapState({
      oficceRent: state => state.app.oficceRent,
      paiseCode: state => state.app.paiseCode,
    }),
  },

  mounted() {
    this.oficceRent.forEach(element => {
      if (element.oficina_id === this.oficina.id) {
        this.oficina.check = true
      }
    })
  },
  methods: {
    ...mapMutations(['adicionarOficceRent', 'deleteOficceRent', 'setPaisCode']),
    changeCheck() {
      if (this.oficina.check) {
        if (this.paiseCode.name !== this.oficina.country) {
          this.axios
            .post(
              'get/code-phone',
              { id: this.oficina.id, latitude: this.oficina.latitud, longitude: this.oficina.longitud },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              this.setPaisCode({
                name: this.oficina.country,
                countrySlug: res.data.country_code,
                code: res.data.countryCode,
              })
            })
            .finally(() => {
              let code = 53
              if (this.paiseCode.code !== null && this.paiseCode.code !== 'null') {
                code = this.paiseCode.code
              }
              this.adicionarOficceRent({
                oficina_id: this.oficina.id,
                telefonos: [{ code, number: null, ext: null }],
                horarios: [
                  {
                    modelSemana: [0, 1, 2, 3, 4, 5, 6],
                    is_time_completo: true,
                    horarios: [],
                  },
                ],
              })
            })
        } else {
          this.adicionarOficceRent({
            oficina_id: this.oficina.id,
            telefonos: [{ code: this.paiseCode.code, number: null, ext: null }],
            horarios: [
              {
                modelSemana: [0, 1, 2, 3, 4, 5, 6],
                is_time_completo: true,
                horarios: [],
              },
            ],
          })
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.oficceRent.length; index++) {
          const element = this.oficceRent[index]

          if (element.oficina_id === this.oficina.id) {
            this.deleteOficceRent(index)
            break
          }
        }
      }
    },
  },
}
</script>
