<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <tbody>
          <!--PRODUCTS-->
          <tr>
            <th>
              {{ $t('lbl.product') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProduct()"
              >
                <v-icon>
                  {{ showRowProduct ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProduct">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <!--<th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
                style="text-align: center;"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>-->
            </tr>

            <tr
              v-for="(mark, indM) in itemsProduct"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <!--<td
                v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                style="text-align: center;"
              >
                <v-menu
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                      link
                      @click="editItem()"
                    >
                      <v-list-item-title>
                        <v-btn icon>
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>-->
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--PROVEEDOR-->
          <tr>
            <th>
              {{ $t('lbl.proveedor') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProveedor()"
              >
                <v-icon>
                  {{ showRowProveedor ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProveedor">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProveedor"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--CONTRATE-->
          <tr>
            <th>
              {{ $t('lbl.contrato') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemContrate()"
              >
                <v-icon>
                  {{ showRowContrate ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowContrate">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsContrate"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--CATEGORY-->
          <tr>
            <th>
              {{ $t('lbl.cat') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemCategory()"
              >
                <v-icon>
                  {{ showRowCategory ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowCategory">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsCategory"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--MARCA MODELO-->
          <tr>
            <th>
              {{ $t('lbl.brandModel') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemMarca()"
              >
                <v-icon>
                  {{ showRowMarca ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowMarca">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsMarcas"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--TEMPORADAS-->
          <tr>
            <th>
              {{ $t('lbl.temporadas') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemTemporada()"
              >
                <v-icon>
                  {{ showRowTemporada ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowTemporada">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsTemporada"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: {
    isComition: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    proveedoresCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    marcasCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    typeAfiliados: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    editMarkup: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowCategory: false,
      showRowMarca: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      model: {},
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      proveedores: [],
      aerolineas: [],

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      markupsList: state => state.app.markupsList,
    }),
    itemsProduct() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            let identificador = ''
            if (element.products.includes('all')) {
              identificador = 'Autos'

              // identificador = 'Todos'
            } else {
              identificador = 'Autos'

              /* if (element.products.includes('hotels')) {
                identificador += ', Hoteles'
              }
              if (element.products.includes('flights')) {
                identificador += ', Vuelos'
              } */
            }

            if (
              (element.proveedor_id.length === 0
                || (element.proveedor_id.length === 1 && element.proveedor_id.includes(-1)))
              && element.contrate_id.length === 0
              && element.category_id.length === 0
              && element.marca_modelo_id.length === 0
              && element.temporada_id.length === 0
            ) {
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsProveedor() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length > 0
              && !element.proveedor_id.includes(-1)
              && ((element.contrate_id.length === 1 && element.contrate_id.includes(-1))
                || element.contrate_id.length === 0)
              && element.category_id.length === 0
              && element.marca_modelo_id.length === 0
              && element.temporada_id.length === 0
            ) {
              element.proveedor_id.forEach(provee => {
                let identificador = ''
                const ppp = this.proveedoresCar.filter(e => e.id === provee)
                if (ppp.length > 0) {
                  identificador = ppp[0].name_comercial
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsContrate() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && element.contrate_id.length > 0
              && !element.contrate_id.includes(-1)
              && ((element.category_id.length === 1 && element.category_id.includes(-1))
                || (element.marca_modelo_id.length === 1 && element.marca_modelo_id.includes(-1)))

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              element.contrate_id.forEach(ite => {
                let identificador = ''
                const ppp = this.contratesCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador = ppp[0].identificador
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsCategory() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.category_id.length > 0
              && !element.category_id.includes(-1)
              && element.marca_modelo_id.length === 0

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              element.category_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                }

                const ppp = this.categoriesCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador += `, ${ppp[0].name}`
                } else {
                  identificador += ', Todas'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsMarcas() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.category_id.length === 0
              && element.marca_modelo_id.length > 0

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              element.marca_modelo_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                }

                const ppp = this.marcasCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador += `, ${ppp[0].name}`
                } else {
                  identificador += ', Todas'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsTemporada() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && (element.category_id.length > 0 || element.marca_modelo_id.length > 0)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
            ) {
              // eslint-disable-next-line no-unused-vars
              element.temporada_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                  // eslint-disable-next-line no-empty
                  if (cont[0].tipo_contrato === 'categoria') {
                    if (element.category_id.length > 0) {
                      let nameSeason = 'Todas'
                      if (ite !== 'all') {
                        const sss = cont[0].temporadas
                        sss.forEach(seasss => {
                          if (seasss.temporada_id === ite) {
                            nameSeason = seasss.name
                          }
                        })
                      }
                      element.category_id.forEach(cate => {
                        const ppp = this.categoriesCar.filter(e => e.id === cate)
                        let nameCat = 'Todas'
                        if (ppp.length > 0) {
                          nameCat = ppp[0].name
                        }

                        newArr.push({
                          identificador: `${identificador}, ${nameCat}, ${nameSeason}`,
                          ...element,
                        })
                      })
                    }
                  } else if (element.marca_modelo_id.length > 0) {
                    let nameSeason = 'Todas'
                    if (ite !== 'all') {
                      const sss = cont[0].temporadas
                      sss.forEach(seasss => {
                        if (seasss.temporada_id === ite) {
                          nameSeason = seasss.name
                        }
                      })
                    }
                    element.marca_modelo_id.forEach(marc => {
                      const ppp = this.marcasCar.filter(e => e.id === marc)
                      let nameMarc = 'Todas'
                      if (ppp.length > 0) {
                        nameMarc = ppp[0].name
                      }

                      newArr.push({
                        identificador: `${identificador}, ${nameMarc}, ${nameSeason}`,
                        ...element,
                      })
                    })
                  }
                }
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsDates() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && (element.category_id.length > 0 || element.marca_modelo_id.length > 0)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
              && element.from
              && element.to
            ) {
              // eslint-disable-next-line no-unused-vars
              let identificador = ''
              const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
              if (cont.length > 0) {
                identificador = cont[0].identificador
                // eslint-disable-next-line no-empty
                if (cont[0].tipo_contrato === 'categoria') {
                  if (element.category_id.length > 0) {
                    element.category_id.forEach(cate => {
                      const ppp = this.categoriesCar.filter(e => e.id === cate)
                      let nameCat = 'Todas'
                      if (ppp.length > 0) {
                        nameCat = ppp[0].name
                      }

                      newArr.push({
                        identificador: `${identificador}, ${nameCat}, ${this.$moment(element.from).format(
                          'D/MMM/YY',
                        )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                        ...element,
                      })
                    })
                  }
                } else if (element.marca_modelo_id.length > 0) {
                  element.marca_modelo_id.forEach(marc => {
                    const ppp = this.marcasCar.filter(e => e.id === marc)
                    let nameMarc = 'Todas'
                    if (ppp.length > 0) {
                      nameMarc = ppp[0].name
                    }

                    newArr.push({
                      identificador: `${identificador}, ${nameMarc}, ${this.$moment(element.from).format(
                        'D/MMM/YY',
                      )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                      ...element,
                    })
                  })
                }
              }
            }
          }
        })

        return newArr
      }

      return []
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    showItemContrate() {
      this.showRowContrate = !this.showRowContrate
    },
    showItemCategory() {
      this.showRowCategory = !this.showRowCategory
    },
    showItemMarca() {
      this.showRowMarca = !this.showRowMarca
    },
    showItemTemporada() {
      this.showRowTemporada = !this.showRowTemporada
    },
    showItemDate() {
      this.showRowDate = !this.showRowDate
    },
    editItem() {
      this.editMarkup = true

      // window.scrollTo(0, 0)
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
