<template>
  <fragment>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-row>
        <v-expansion-panels
          accordion
          multiple
        >
          <v-expansion-panel
            expand
            focusable
          >
            <div style="border: none;">
              <v-expansion-panel-header
                ripple
                class="d-flex justify-center align-center"
              >
                <div class="d-flex justify-space-between align-end pr-12">
                  <div class="d-block justify-center">
                    <div class="d-flex justify-start align-center">
                      <h2>{{ iataOrigen }}</h2>
                      <v-icon
                        class="px-2"
                        color="primary"
                      >
                        {{ icons.mdiArrowRightBold }}
                      </v-icon>
                      <h2>{{ iataDestino }}</h2>
                    </div>
                    <div class="d-flex justify-start align-center mt-2">
                      <p class="mb-0 pr-2">
                        <fragment>
                          {{ $t('lbl.departure') }}, {{ destino.from | moment('DD MMM') }} {{ $t('lbl.aLa') }}
                          {{ flight.hour_departure }}
                        </fragment>
                        <fragment v-if="flight.escalas !== null">
                          · {{ $tc('lbl.esca', flight.escalas.length) }}
                        </fragment>
                        <fragment>
                          · {{ $t('lbl.durationViaje') }}:
                          {{ flight.tiempo_total_viaje_h > 0 ? `${flight.tiempo_total_viaje_h} h` : '' }}
                          {{ flight.tiempo_total_viaje_m > 0 ? `${flight.tiempo_total_viaje_m} min.` : '' }}
                        </fragment>
                      </p>
                    </div>
                  </div>
                  <div>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          :src="rutaPublic + aeroline.isotipo"
                          :alt="aeroline.slug"
                          height="25"
                          color="white"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>{{ aeroline.name }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-expansion-panel-header>
            </div>
            <v-expansion-panel-content style="background-color: aliceblue;">
              <v-container
                fluid
                class="ma-2"
                style="background-color: white;"
              >
                <v-row
                  dense
                  class="pt-0"
                >
                  <v-col cols="12">
                    <v-timeline
                      align-top
                      dense
                      class="pt-0"
                    >
                      <v-timeline-item
                        color="primary"
                        small
                      >
                        <v-row class="pt-1">
                          <v-col cols="2">
                            <strong>{{ flight.hour_departure }}</strong><br />
                            <p>{{ destino.from | moment('DD MMM, YYYY') }}</p>
                          </v-col>
                          <v-col>
                            <p>
                              <strong class="pr-2">{{ iataOrig }}</strong> {{ iataName }}
                              {{ flight.origen.terminal !== null ? flight.origen.terminal : '' }}
                              <br />
                              <span class="text-caption">
                                {{ iataOrigen }}
                              </span>
                            </p>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="primary"
                        small
                      >
                        <v-row class="pt-1">
                          <v-col cols="2">
                            <strong>{{ flight.hour_arrival }}</strong><br />
                            <p>{{ destino.from | moment('DD MMM, YYYY') }}</p>
                          </v-col>
                          <v-col>
                            <p>
                              <strong class="pr-2">{{ iataDest }}</strong>
                              {{ iataNameD }} {{ flight.destino.terminal !== null ? flight.destino.terminal : '' }}
                              <br />
                              <span class="text-caption">
                                {{ iataDestino }}
                              </span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="pt-4 pl-4 pb-0"
                          >
                            <p class="mb-0 pr-2">
                              {{ $t('lbl.flight') }} {{ flight.numero_de_vuelo }}・{{ flight.aeronave.modelo }}
                            </p>
                            <p class="mb-0 pr-2">
                              {{ tarifa.tarifa }}・{{ tarifa.clase }}
                            </p>
                          </v-col>
                          <v-col
                            cols="12"
                            class="pt-0"
                          >
                            <a href="#">
                              <p class="mb-0 pr-2">{{ $t('lbl.rulesTarifa') }}</p>
                            </a>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
    <!-- movil
            <div class="d-md-none">
              <v-row>
                <v-expansion-panels
                  accordion
                  multiple
                >
                  <v-expansion-panel
                    expand
                    focusable
                  >
                    <div style="border: none;">
                      <v-expansion-panel-header
                        ripple
                        class="d-flex justify-center align-center"
                      >
                        <div class="d-flex justify-space-between align-end">
                          <div class="d-block justify-center">
                            <div class="d-flex justify-start align-center">
                              <h4>Nueva York</h4>
                              <v-icon
                                class="px-1"
                                color="primary"
                              >
                                {{ icons.mdiSwapHorizontal }}
                              </v-icon>
                              <h4>Madrid</h4>
                            </div>
                            <div class="d-flex justify-start align-center mt-1">
                              <p class="mb-0 pr-2 p12Mine">
                                Salida el 6 jul. a las 13:15 · 1 escala · Tiempo de viaje: 13 h 08 min.
                              </p>
                            </div>
                          </div>
                          <div class="pr-4">
                            <v-icon
                              x-large
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiPlus }}
                            </v-icon>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                    </div>
                    <v-expansion-panel-content>
                      <v-container
                        fluid
                        class="ma-0 px-0"
                      >
                        <v-row
                          dense
                          class="pt-0"
                        >
                          <v-col cols="12">
                            <v-timeline
                              id="timeLineMovilMine"
                              align-top
                              dense
                              class="pt-0"
                            >
                              <v-timeline-item
                                color="primary"
                                small
                              >
                                <v-row class="pt-1">
                                  <v-col cols="12">
                                    <strong>23:40</strong><br />
                                    <p>10 jun. 2022</p>
                                  </v-col>
                                  <v-col cols="12S">
                                    <strong class="pr-2">MAD</strong>
                                    <p>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</p>
                                    <br />
                                    <div class="text-caption">
                                      Madrid
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-timeline-item>

                              <v-timeline-item
                                color="primary"
                                small
                              >
                                <v-row class="pt-1">
                                  <v-col cols="12">
                                    <strong>04:00</strong><br />
                                    <p>11 jun. 2022</p>
                                  </v-col>
                                  <v-col cols="12">
                                    <strong class="pr-2">MEX</strong>
                                    <p>Benito Juarez International Airport, Terminal 2</p>
                                    <br />
                                    <div class="text-caption">
                                      Ciudad de México
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="pt-4 pl-4 pb-0"
                                  >
                                    <p class="mb-0 pr-2">
                                      Vuelo AM-22・Boeing 787-900
                                    </p>
                                    <p class="mb-0 pr-2">
                                      Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                    </p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    class="pa-2"
                                  >
                                    <a href="#">
                                      <p class="mb-0">Reglas de la tarifa</p>
                                    </a>
                                  </v-col>
                                </v-row>
                              </v-timeline-item>
                            </v-timeline>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </div>-->

    <!-- desktop -->
    <div class="d-none d-md-block mt-3 mb-1">
      <v-row>
        <v-card
          class="my-2"
          width="100%"
        >
          <div class="d-flex justify-space-between align-center">
            <v-card-title primary-title>
              {{ $t('lbl.tarifaComplete') }}
            </v-card-title>
            <!--<a
              href="#"
              class="pr-6"
            >
              <p class="mb-0 caption">
                Cambiar tarifa
              </p>
            </a>-->
          </div>

          <v-container
            grid-list-xs
            class="pa-6 mb-4 pt-2"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="9">
                <div class="d-block">
                  <p class="mb-0 d-flex justify-start">
                    <b>{{ tarifa.tarifa }}</b>
                  </p>
                  <p class="mb-0 d-flex justify-start">
                    {{ $t('lbl.clase') }}: <b class="pl-2">{{ tarifa.clase }}</b>
                  </p>
                </div>
                <v-row class="pt-4">
                  <v-col cols="1">
                    <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                  </v-col>
                  <v-col
                    cols="4"
                    class="px-0"
                  >
                    <p
                      caption
                      class="p14Mine mb-0 d-flex justify-center align-baseline"
                    >
                      {{ $t('msg.verifyTarifa') }}
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <div
                      v-if="tarifa.cant_maletas_bodega !== null"
                      class="d-flex justify-start align-center"
                    >
                      <v-icon style="color: #53af24;">
                        {{ icons.mdiBagSuitcase }}
                      </v-icon>
                      <p
                        style="color: #53af24;"
                        class="p14Mine mb-0 d-flex justify-center align-baseline"
                      >
                        {{ $t('lbl.bag') }}:
                        {{ $tc('lbl.pieza', tarifa.cant_maletas_bodega) }}
                      </p>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-start align-center"
                    >
                      <v-icon color="default">
                        {{ icons.mdiBagSuitcaseOffOutline }}
                      </v-icon>
                      <p
                        color="primeary"
                        class="p14Mine mb-0 d-flex justify-center align-baseline"
                      >
                        {{ $t('lbl.bagNoInclude') }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col
                    cols="1"
                    class="pt-0"
                  >
                    <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="px-0 pt-0"
                  >
                    <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                      {{ $t('msg.verifyReembolso') }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="7"
                    class="pt-0"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-icon style="color: #53af24;">
                        {{ icons.mdiBagPersonal }}
                      </v-icon>
                      <p
                        style="color: #53af24;"
                        class="p14Mine mb-0 d-flex justify-center align-baseline"
                      >
                        {{ $t('lbl.bagHand') }}:
                        {{ `${tarifa.cant_maletas_cabina > 0 ? 'incluido' : ''}` }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <div class="d-block">
                  <p class="mb-0 pr-6 d-flex justify-end align-center">
                    {{ tarifa.price_total | currency }}
                  </p>
                  <div class="d-flex justify-end">
                    <p class="mb-0 pr-5 d-flex justify-end align-center">
                      <b>{{ tarifa.price_total | currency }}</b>
                    </p>
                    <!--<v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiInformation }}
                        </v-icon>
                      </template>
                      <span>
                        <div class="d-block">
                          <div class="d-flex justify-start align-center">
                            <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                          </div>
                          <div class="d-flex justify-space-between align-center">
                            <p class="mb-0 pr-6">Tarifa</p>
                            <p class="mb-0">776,87 $</p>
                          </div>
                          <div class="d-flex justify-space-between align-center">
                            <p
                              class="mb-0 pr-6"
                              subtitle-2
                            >Impuestos y tasas</p>
                            <p
                              class="mb-0"
                              subtitle-2
                            >776,87 $</p>
                          </div>
                          <v-divider
                            color="white"
                            class="mt-2"
                          ></v-divider>
                          <div class="d-flex justify-space-between align-center">
                            <p class="my-2"><b>Total</b></p>
                            <p
                              class="my-2"
                              subtitle-2
                            >776,87 $</p>
                          </div>
                          <div
                            style="background-color: #ffffff6b"
                            class="mb-4 pa-2"
                          >
                            <div class="d-flex justify-space-between align-center">
                              <p class="mb-0">Tu comisión</p>
                              <p
                                class="mb-0"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                            <div class="d-flex justify-space-between align-center">
                              <p class="mb-0"><b>PVP</b></p>
                              <p
                                class="mb-0"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                          </div>
                        </div>
                      </span>
                    </v-tooltip>-->
                  </div>
                  <a href="#">
                    <p
                      style="position: absolute; bottom:45px"
                      class="p14Mine mb-0 d-flex justify-center align-center"
                    >
                      {{ $t('lbl.rulesTarifa') }}
                    </p>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
    </div>
    <!-- movil
            <div class="d-md-none">
              <v-row class="pt-2">
                <v-card
                  width="100%"
                  class="my-4"
                >
                  <v-card-title
                    primary-title
                    class="pb-2"
                  >
                    Tarifa de la ruta completa
                  </v-card-title>
                  <a href="#">
                    <p class="mb-0 subtitle-2 px-6 mb-4">
                      Cambiar tarifa
                    </p>
                  </a>

                  <v-container
                    grid-list-xs
                    class="pa-6 mb-4 pt-2"
                  >
                    <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
                      <v-col cols="12">
                        <div class="d-block">
                          <p class="mb-0 d-flex justify-start">
                            <b>Nombre de la Tarifa</b>
                          </p>
                          <p class="mb-0 d-flex justify-start">
                            Clase: <b class="pl-2">Nombre de la Clase</b>
                          </p>
                        </div>
                        <v-row class="pt-4">
                          <v-col cols="2">
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                          </v-col>
                          <v-col
                            cols="10"
                            class="px-0"
                          >
                            <p
                              caption
                              class="p14Mine mb-0 d-flex justify-center align-baseline"
                            >
                              Consulta las reglas de la tarifa para saber si se admiten cambios.
                            </p>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex justify-start align-center">
                              <v-icon style="color: #53af24;">
                                {{ icons.mdiBagSuitcase }}
                              </v-icon>
                              <p
                                style="color: #53af24;"
                                class="p14Mine mb-0 d-flex justify-center align-baseline"
                              >
                                Equipaje: 1 pieza
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="pt-0">
                          <v-col cols="2">
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                          </v-col>
                          <v-col
                            cols="10"
                            class="px-0"
                          >
                            <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                              Consulta las reglas de la tarifa para conocer la política de reembolso.
                            </p>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex justify-start align-center">
                              <v-icon style="color: #53af24;">
                                {{ icons.mdiBagPersonal }}
                              </v-icon>
                              <p
                                style="color: #53af24;"
                                class="p14Mine mb-0 d-flex justify-center align-baseline"
                              >
                                Equipaje de mano: incluido
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <div class="d-block">
                          <p class="mb-0 pr-6 d-flex justify-end align-center">
                            817,76 $
                          </p>
                          <div class="d-flex justify-end">
                            <p class="mb-0 d-flex justify-end align-center">
                              <b>776,87 $</b>
                            </p>
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiInformation }}
                                </v-icon>
                              </template>
                              <span>
                                <div class="d-block">
                                  <div class="d-flex justify-start align-center">
                                    <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                  </div>
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="mb-0 pr-6">Tarifa</p>
                                    <p class="mb-0">776,87 $</p>
                                  </div>
                                  <div class="d-flex justify-space-between align-center">
                                    <p
                                      class="mb-0 pr-6"
                                      subtitle-2
                                    >Impuestos y tasas</p>
                                    <p
                                      class="mb-0"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                  <v-divider
                                    color="white"
                                    class="mt-2"
                                  ></v-divider>
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="my-2"><b>Total</b></p>
                                    <p
                                      class="my-2"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                  <div
                                    style="background-color: #ffffff6b"
                                    class="mb-4 pa-2"
                                  >
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0">Tu comisión</p>
                                      <p
                                        class="mb-0"
                                        subtitle-2
                                      >776,87 $</p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0"><b>PVP</b></p>
                                      <p
                                        class="mb-0"
                                        subtitle-2
                                      >776,87 $</p>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </v-tooltip>
                          </div>
                          <a
                            href="#"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <p
                              style="position: absolute; bottom:45px"
                              class="p14Mine mb-0 d-flex justify-center align-center"
                            >
                              Reglas de la tarifa
                            </p>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-row>
            </div> -->

    <!-- movil
      <div class="d-md-none">
        <v-row class="mt-2">
          <v-card
            width="100%"
            class="mt-3"
          >
            <v-row>
              <v-col cols="12">
                <v-col cols="12">
                  <v-card-title
                    primary-title
                    class="px-0 py-2"
                  >
                    PVP
                  </v-card-title>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Mark-up"
                    dense
                    hide-details
                    outlined
                    width="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="value"
                    dense
                    hide-details
                    row
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="value"
                    label="Con descuento?"
                    dense
                    hide-details
                    outlined
                    class="my-0 py-0"
                    value="value"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Descuento"
                    dense
                    hide-details
                    outlined
                    width="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="value1"
                    dense
                    hide-details
                    row
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      value="4"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-row class="mt-2 d-flex justify-center align-center">
                  <div class="d-block">
                    <h4 class="d-flex justify-center align-center">
                      Precio inicial
                    </h4>
                    <v-divider></v-divider>
                    <p class="p12Mine mb-0 d-flex justify-center align-center">
                      100.00
                    </p>
                  </div>
                </v-row>
                <v-row class="pt-2 d-flex justify-center align-center">
                  <div class="d-block">
                    <h4 class="d-flex justify-center align-center">
                      con Mark-up
                    </h4>
                    <v-divider></v-divider>
                    <p class="mb-0 d-flex justify-center align-center">
                      100.00
                    </p>
                  </div>
                </v-row>
                <v-row class="pt-2 d-flex justify-center align-center">
                  <div class="d-block">
                    <h4 class="d-flex justify-center align-center">
                      con Descuento
                    </h4>
                    <v-divider></v-divider>
                    <p class="mb-0 d-flex justify-center align-center">
                      100.00
                    </p>
                  </div>
                </v-row>
                <v-col
                  cols="12"
                  class="pa-2 mt-3"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                  >
                    Aplicar
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-row>

        <v-row class="mt-4">
          <v-card
            width="100%"
            class="my-2 pa-4"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-2">
                Billetes aéreos
              </h4>
              <h4>
                <h4>
                  1470,27 $
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h3>
                Total<v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block mb-2">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p class="mb-0 pr-6">Tarifa</p>
                        <p class="mb-0">776,87 $</p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p
                          class="mb-0 pr-6"
                          subtitle-2
                        >Impuestos y tasas</p>
                        <p
                          class="mb-0"
                          subtitle-2
                        >776,87 $</p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
              </h3>

              <h3>
                <h4>
                  1470,27 $
                </h4>
              </h3>
            </div>
          </v-card>
        </v-row>
      </div> -->

    <!--FORM FINALIZAR-->
    <FormFinalizar />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiArrowRightBold,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
} from '@mdi/js'

import FormFinalizar from './FormFinalizar.vue'

// import Ida from './Ida.vue'
// import IdaBack from './IdaBack.vue'
// import IdaDestinos from './IdaDestinos.vue'

export default {
  components: {
    FormFinalizar,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      iataOrigen: null,
      iataOrig: null,
      iataName: null,
      iataDestino: null,
      iataDest: null,
      iataNameD: null,
      aeroline: null,
      flight: null,
      tarifa: null,
      destino: null,
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiArrowRightBold,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      cotizadorFlightSelect: state => state.app.cotizadorFlightSelect,
    }),
  },
  created() {
    this.getOriden()
    this.getDestino()
    this.getAeroline()
    this.getFlight()
    this.getTarifa()
    this.getDestinos()
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth', 'setSearchingFlightCotizador']),
    getOriden() {
      this.axios
        .get(`airport/${this.cotizadorFlightSelect.item_ida.flight.origen.destino.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iataOrigen = res.data.data.data.city
          this.iataOrig = res.data.data.data.iata
          this.iataName = res.data.data.data.name
        })
    },
    getDestino() {
      this.axios
        .get(`airport/${this.cotizadorFlightSelect.item_ida.flight.destino.destino.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iataDestino = res.data.data.data.city
          this.iataDest = res.data.data.data.iata
          this.iataNameD = res.data.data.data.name
        })
    },
    getAeroline() {
      this.aeroline = this.cotizadorFlightSelect.item_ida.flight.aerolinea
    },
    getFlight() {
      this.flight = this.cotizadorFlightSelect.item_ida.flight
    },
    getTarifa() {
      this.tarifa = this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa]
    },
    getDestinos() {
      if (this.cotizadorFlightSelect.destinos.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.destino = this.cotizadorFlightSelect.destinos[0]
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
