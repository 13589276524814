<template>
  <v-row class="match-height">
    <!-- Multiple Column -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.configuration')} / ${$t('menu.users')} / ${title}` }}
          <span
            v-if="currentTab === 1"
            class="pl-2"
          > / {{ $t('permiso.permiso') }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'users-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                :disabled="onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-tabs v-model="currentTab">
            <v-tab
              v-if="permisos.includes('user:list') || permisos.includes('*')"
              @click="setViewTabOther"
            >
              {{ $t('lbl.info') }}
            </v-tab>
            <v-tab
              v-if="(permisos.includes('user:edit') || permisos.includes('*')) && userUpdate.role_id && !onlyShow"
              @click="setViewTab"
            >
              {{ $t('menu.permisos') }}
            </v-tab>
          </v-tabs>

          <v-divider></v-divider>

          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <UserTab v-if="!isLoading" />
            </v-tab-item>
            <v-tab-item>
              <PermisosTab v-if="$store.state.app.viewPermisos === 'users'" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import UserTab from './UserTab.vue'
import PermisosTab from '../tabs/Permisos.vue'

export default {
  components: {
    UserTab,
    PermisosTab,
  },
  data() {
    return {
      currentTab: 0,
      isLoading: true,
      onlyShow: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      roles: [],
      model: {
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisosItem: [],
      loading: false,
      avatar: null,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      show: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
      userUpdate: state => state.app.userUpdate,
      userUpdateAvatar: state => state.app.userUpdateAvatar,
    }),
    btnTitle() {
      return localStorage.getItem('user-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    title() {
      return localStorage.getItem('user-id') === null ? this.$t('btn.create') : this.$t('btn.edit')
    },
  },
  created() {
    this.profile()
    this.getRoles()
    this.getItem()
    if (localStorage.getItem('onlyShow') !== null) {
      this.onlyShow = true
    }
    this.setViewTab()
  },
  methods: {
    ...mapMutations(['setPermisosTab']),
    setViewTab() {
      this.$store.state.app.isLoadingPagePermisos = true
      this.setPermisosTab('users')
    },
    setViewTabOther() {
      this.$store.state.app.viewPermisos = 'other'
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getItem() {
      if (localStorage.getItem('user-id') !== null) {
        const id = localStorage.getItem('user-id')
        this.axios
          .get(`user/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
          })
      }
    },

    getRoles() {
      this.axios
        .get(`role?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roles = res.data.data
        })
    },

    save() {
      if (
        this.userUpdate.name
        && this.userUpdate.apellidos
        && this.userUpdate.email
        && this.userUpdate.role_id
        && this.sourcePermisos.length > 0
      ) {
        this.loading = true
        localStorage.removeItem('user-role-new-id')
        const json = {
          name: this.userUpdate.name,
          apellidos: this.userUpdate.apellidos,
          perfil_slug: localStorage.getItem('perfil'),
          email: this.userUpdate.email,
          role_id: this.userUpdate.role_id,
          active: this.userUpdate.active,

          permisos: this.sourcePermisos[0],

          // password: this.password,
          // password_confirmation: this.confirmation_password,
        }

        const formData = new FormData()
        formData.append('data', JSON.stringify(json))
        if (this.userUpdateAvatar) {
          formData.append('avatar', this.userUpdateAvatar)
          json.avatar = this.userUpdateAvatar
        }

        if (localStorage.getItem('user-id') === null) {
          this.axios
            .post('user', formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)

              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.userExist', { email: this.userUpdate.email }))
                }
              } else {
                localStorage.removeItem('user-id')
                this.$router.push({ name: 'users-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .post(`user/actualice/${this.model.id}`, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('user-id')
                this.$router.push({ name: 'users-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    savePermisos() {
      console.log('permisos')
    },
  },
}
</script>
