<template>
  <div
    class="auth-wrapper auth-v1"
  >
    <div
      v-if="tokenValid"
      class="auth-inner"
    >
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="`${url_public}${configDataGeneral.isotipo ? configDataGeneral.isotipo : configDataGeneral.logo}`"
              max-height="100px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ configDataGeneral.name }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('activeAcount.title') }}
          </p>
          <p class="mb-2">
            <b>{{ model.name }}</b>, {{ $t('activeAcount.subTitle') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('activeAcount.password')"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <!--<fragment>
              <v-row
                v-if="!has_minimum_lenth || !has_lowercase || !has_uppercase || !has_number || !has_special"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <span>{{ $t('password.title') }}</span>
                </v-col>
                <v-col
                  v-if="!has_minimum_lenth"
                  cols="12"
                  md="12"
                >
                  <small
                    style="color: red"
                  ><span>{{ $t('password.has_minimum_lenth') }}</span></small>
                </v-col>
                <v-col
                  v-if="!has_lowercase"
                  cols="12"
                  md="12"
                >
                  <small
                    style="color: red"
                  ><span>{{ $t('password.has_lowercase') }}</span></small>
                </v-col>
                <v-col
                  v-if="!has_uppercase"
                  cols="12"
                  md="12"
                >
                  <small
                    style="color: red"
                  ><span>{{ $t('password.has_uppercase') }}</span></small>
                </v-col>
                <v-col
                  v-if="!has_number"
                  cols="12"
                  md="12"
                >
                  <small
                    style="color: red"
                  ><span>{{ $t('password.has_number') }}</span></small>
                </v-col>
                <v-col
                  v-if="!has_special"
                  cols="12"
                  md="12"
                >
                  <small
                    style="color: red"
                  ><span>{{ $t('password.has_special') }}</span></small>
                </v-col>
              </v-row>
            </fragment>-->

            <v-text-field
              v-model="confirmPassword"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('activeAcount.confirmPassword')"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :disabled="!password || !has_minimum_lenth || !has_lowercase || !has_number || !has_special"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <v-row v-if="!password">
              <v-col
                cols="12"
                md="12"
                class="ml-3"
              >
                <small><span class="text-caption">{{ $t('password.min_rules') }}</span></small>
              </v-col>
            </v-row>

            <v-row
              v-else-if="(!has_minimum_lenth || !has_lowercase || !has_number || !has_special) && !confirmPassword"
            >
              <v-col
                cols="12"
                md="1"
              >
                <v-icon
                  class="mt-2"
                  color="error"
                >
                  mdi-information
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                md="11"
              >
                <small><span
                  class="text-caption"
                  style="color: red"
                >{{ $t('password.security') }}</span></small>
              </v-col>
            </v-row>

            <v-row v-else-if="confirmPassword && password !== confirmPassword">
              <v-col
                cols="12"
                md="12"
              >
                <v-icon
                  class="mt-1 mr-2"
                  color="error"
                >
                  mdi-information
                </v-icon>
                <span
                  class="text-caption mt-3"
                  style="color: red"
                >{{ $t('password.not_math') }}</span>
              </v-col>
            </v-row>

            <v-btn
              :loading="loading"
              :disabled="
                !has_minimum_lenth || !has_lowercase || !has_number || !has_special || password !== confirmPassword
              "
              block
              color="primary"
              class="mt-4"
              @click="active"
            >
              {{ $t('activeAcount.btn') }}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{ name: 'auth-login' }"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>{{ $t('register.btnIn') }}</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <div
      v-if="!tokenValid"
      class="auth-inner"
    >
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="`${url_public}${configDataGeneral.isotipo ? configDataGeneral.isotipo : configDataGeneral.logo}`"
              max-height="100px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ configDataGeneral.name }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="h5 text--primary mb-4">
            {{ $t('activeAcount.msgCaduquedToken', { item: configDataGeneral.name }) }}
            <br />
            <br />
            {{ $t('activeAcount.msgCaduquedToken1') }}
          </p>

          <v-btn
            v-if="name_project === 'wwwww'"
            color="primary"
            block
            :to="{ name: 'home' }"
            class="mb-4"
          >
            {{ $t('landing.menu.home') }}
          </v-btn>
          <v-btn
            v-else
            block
            color="primary"
            :to="{ name: 'auth-login' }"
            class="mb-4"
          >
            INICIAR SESIÓN
          </v-btn>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <WstIzq
      v-if="name_project === 'wwwww'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <WstDerecha
      v-if="name_project === 'wwwww'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <ProIzq
      v-if="name_project === 'ppppp'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <ProDerecha
      v-if="name_project === 'ppppp'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <KroIzq
      v-if="name_project === 'kkkkk'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <KroDerecha
      v-if="name_project === 'kkkkk'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import ProDerecha from '@/views/utils/images/pro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroDerecha from '@/views/utils/images/kro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'

export default {
  components: {
    ProDerecha,
    ProIzq,
    KroDerecha,
    KroIzq,
    WstIzq,
    WstDerecha,
  },
  data() {
    return {
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      isPasswordVisible: false,
      password: '',
      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      confirmPassword: '',
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      model: {},
      loading: false,
      tokenValid: false,
    }
  },
  computed: {
    ...mapState({
      configDataGeneral: state => state.app.configDataGeneral,
    }),
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length > 8
      this.has_number = /\d/.test(this.password)
      this.has_lowercase = /[a-z]/.test(this.password)
      this.has_uppercase = /[A-Z]/.test(this.password)
      // eslint-disable-next-line no-useless-escape
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    },
  },
  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.axios.post('verify', { api_token: this.$route.params.token }).then(res => {
        if (res.data.success) {
          this.model = res.data.data.data
          this.tokenValid = true
        } else {
          this.tokenValid = false

          // this.$toast.error(this.$t('activeAcount.msgInvalidToken'))
          // this.$router.push({ name: 'auth-register' })
        }
      })
    },
    active() {
      if (this.password && this.confirmPassword) {
        if (this.password === this.confirmPassword) {
          this.loading = true
          this.axios
            .post('active', { api_token: this.$route.params.token, email: this.model.email, password: this.password })
            .then(res => {
              if (res.data.success) {
                // this.$toast.success(this.$t('activeAcount.msgSuccess'))
                // this.$router.push({ name: 'auth-login' })
                this.$router.push({ name: 'user-active-success' })
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.$toast.error(this.$t('msg.notMatch'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
