<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="12"
      md="3"
    >
      <v-img
        lazy-src="@/assets/images/placeholder/hotel_placeholder.jpg"
        :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
        contain
        height="100%"
        max-width="300px"
        width="280px"
        style="text-align: right !important;border-radius: 1rem 0 0 1rem;"
      >
        <!--<template>
          <v-chip
            class="mr-2 mt-2"
            color="primary"
          >
            <span v-if="hotel.tipo_reserva === 'free-sale'">Free Sale</span>
            <span v-else>On Request</span>
          </v-chip>
        </template>-->
      </v-img>
    </v-col>
    <v-col
      cols="12"
      md="9"
      class="ml-0 pl-0"
      style="border-top:0.25px solid #e2e2e2; border-bottom:1px solid #e2e2e2;border-right:1px solid #e2e2e2;border-radius: 0 1rem 1rem 0;"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
          class="ml-10"
        >
          <span color="dark">
            HOTEL en
            <span class="text-caption">{{ `${hotel.city}, ${hotel.country}` }}</span><br />
            <!--<span class="text-caption">{{ `${hotel.hotel.tipo_destino.name}` }}</span><br />-->
          </span>
          <span
            :style="`color: ${$vuetify.theme.themes.light.primary};`"
          >
            <strong> {{ hotel.name }} </strong><br />
          </span>
          <v-row no-gutters>
            <v-col
              cols="12"
              :md="hotel.categoria.plus ? '4' : '12'"
            >
              <v-rating
                background-color="default"
                color="primary"
                :empty-icon="icons.mdiStarOutline"
                :full-icon="icons.mdiStar"
                :half-icon="icons.mdiStarHalfFull"
                length="5"
                readonly
                size="20"
                dense
                half-increments
                :value="hotel.categoria.cant_estrellas"
              ></v-rating>
            </v-col>
            <v-col
              v-if="hotel.categoria.plus"
              cols="12"
              md="2"
            >
              PLUS
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mt-0 mb-2"
            >
              <b>
                <v-icon
                  color="#000"
                  small
                >
                  mdi-weather-night
                </v-icon>
                {{ daysSearch }} {{ $t('lbl.nights') }}
                {{ $moment(cotizadorHotels.dateIn).format('D-MMM') }} -
                {{ $moment(cotizadorHotels.dateOut).format('D-MMM') }}
                {{ $t('lbl.in') }}
                <v-progress-circular
                  :size="10"
                  :width="1"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </b>
            </v-col>
          </v-row>
          <span>
            <b>{{ $t('lbl.cadena') }}</b>:
            {{ hotel.operador ? hotel.operador.name : '' }}
          </span><br />
          <span>
            <b>{{ $t('lbl.proveedor') }}</b>:
            {{ hotel.proveedor ? hotel.proveedor.name : '' }}
          </span><br />

          <v-row
            v-if="hotel.cant_departament"
            no-gutters
          >
            <v-col
              v-if="hotel.cant_departament.cant_rooms"
              cols="12"
              md="2 "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-bed-outline
                  </v-icon>
                  <span>
                    {{ hotel.cant_departament.cant_rooms }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantRoom') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.cant_departament.cant_restaurant"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-silverware-fork-knife
                  </v-icon>
                  <span>
                    {{ hotel.cant_departament.cant_restaurant }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantRest') }}</span>
              </v-tooltip>
            </v-col>

            <v-col
              v-if="hotel.cant_departament.cant_bars"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-glass-cocktail
                  </v-icon>
                  <span>
                    {{ hotel.cant_departament.cant_bars }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantBars') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.cant_departament.cant_pool"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-pool
                  </v-icon>
                  <span>
                    {{ hotel.cant_departament.cant_pool }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantPiscinas') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.cant_departament.cant_coffee"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-coffee
                  </v-icon>
                  <span>
                    {{ hotel.cant_departament.cant_coffee }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantCafeterias') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="3"
          style="border-left-style: dotted; margin: 15px 0 15px 0;text-align:right"
        >
          <br />
          <h1>
            <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
              <v-progress-circular
                :size="20"
                :width="1"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </span>
          </h1>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ $t('lbl.priceByNight') }}</span>
          <br />
          <b :style="`color: ${$vuetify.theme.themes.light.primary}`">
            <v-progress-circular
              :size="10"
              :width="1"
              color="primary"
              indeterminate
            ></v-progress-circular>
            {{ $t('lbl.inTotal') }}
          </b>
          <br />
          <br />
          <v-btn
            color="primary"
            class="mb-4"
            loading
          >
            <span>{{ $t('btn.select') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    daysSearch: {
      type: Number,
    },
  },
  data() {
    return {
      tab: 0,
      isLoading: true,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      aerolineas: [],
      radioGroup: 0,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      image: null,
      resource: '',
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
    }),
  },
  created() {
    // this.setStates()
    if (this.hotel.galery_random === null || this.hotel.galery_random === 'null') {
      this.image = 'config/hotel_placeholder.jpg'
      this.resource = 'local'
      this.isLoading = false
    } else {
      this.image = this.hotel.galery_random
      this.resource = 's3'
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
    ]),
  },
}
</script>

<style scoped>
  /* Caja Intermedia */
  #cajaIntMine {
    margin-top: 2em;
  }

  /* Caja intermedia no resultado */
  #cajaIntNoResultMine {
    margin-top: 2em;
  }
</style>
