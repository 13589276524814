<template>
  <div>
    <!-- MOSTRAR ACCEPT -->
    <v-dialog
      v-model="isDialogVisibleAcceptChange"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.acceptChange') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleAcceptChange = !isDialogVisibleAcceptChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="confirmAcceptChanges"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DENEGAR -->
    <v-dialog
      v-model="isDialogVisibleRejectChange"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.canceleChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.rejectChange') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleRejectChange = !isDialogVisibleRejectChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmRejectChanges"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-simple-table v-if="items.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="30%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.date') }}
            </th>
            <th
              width="20%"
              class="text-uppercase text-center"
            >
              {{ $t('menu.users') }}
            </th>
            <th
              width="10%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.action') }}
            </th>
            <th class="text-uppercase text-center">
              {{ $t('lbl.change') }}
            </th>
            <th
              v-if="permisos.includes('moderation:edit') ||
                permisos.includes('moderation:destroy') ||
                permisos.includes('*')"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.action') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td class="text-center">
              {{ $moment(iten.date_time).format('MMMM Do YYYY, h:mm a') }}
            </td>
            <td class="text-center">
              {{ iten.user_email }}
            </td>
            <td class="text-center text-uppercase">
              <v-alert
                class="mt-2"
                outlined
                :type="iten.status"
              >
                {{ iten.action }}
              </v-alert>
            </td>
            <td class="text-center">
              <v-tooltip
                v-if="iten.action !== 'destroy'"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon primary>
                      {{ icons.mdiEye }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card class="mx-auto">
                    <v-card-text>
                      <v-row>
                        <v-col
                          lg="12"
                          cols="6"
                        >
                          <strong>{{ $t('lbl.propuesta') }}</strong><br><br>
                          <span
                            v-for="(detail, indexN) in iten.data_new"
                            :key="Math.random() * (indexN + 1)"
                          >
                            <template v-if="detail.name !== 'nominatim' && detail.name !== 'usuario'">
                              <span class="text-uppercase">{{ detail.name }}</span>:
                              <b>{{ detail.value }}</b>
                              <br>
                            </template>
                            <template v-if="detail.name === 'nominatim'">
                              <span
                                v-for="(detailN, keyN, indexM) in detail.value"
                                :key="indexM"
                              >
                                <span v-if="keyN !== 'address' && keyN !== 'lat' && keyN !== 'lon'">
                                  <span class="text-uppercase">{{ keyN }}</span>:
                                  <b>{{ detailN }}</b>
                                  <br>
                                </span>
                                <span v-if="keyN === 'address'">
                                  <span
                                    v-for="(det, kD, idet) in detailN"
                                    :key="idet"
                                  >
                                    <span class="text-uppercase">{{ kD }}</span>:
                                    <b>{{ det }}</b>
                                    <br>
                                  </span>
                                </span>
                              </span>
                            </template>
                          </span>
                        </v-col>
                        <v-col
                          lg="12"
                          cols="6"
                        >
                          <strong>{{ $t('lbl.dataOld') }}</strong><br><br>
                          <span
                            v-for="(detail, indexN) in iten.data_old"
                            :key="Math.random() * (indexN + 1)"
                          >
                            <template v-if="detail.name !== 'nominatim' && detail.name !== 'usuario'">
                              <span class="text-uppercase">{{ detail.name }}</span>:
                              <b>{{ detail.value }}</b>
                              <br>
                            </template>
                            <template v-if="detail.name === 'nominatim'">
                              <span
                                v-for="(detailN, keyN, indexM) in detail.value"
                                :key="indexM"
                              >
                                <span v-if="keyN !== 'address' && keyN !== 'lat' && keyN !== 'lon'">
                                  <span class="text-uppercase">{{ keyN }}</span>:
                                  <b>{{ detailN }}</b>
                                  <br>
                                </span>
                                <span v-if="keyN === 'address'">
                                  <span
                                    v-for="(det, kD, idet) in detailN"
                                    :key="idet"
                                  >
                                    <span class="text-uppercase">{{ kD }}</span>:
                                    <b>{{ det }}</b>
                                    <br>
                                  </span>
                                </span>
                              </span>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>
            </td>
            <td
              v-if="permisos.includes('moderation:edit') ||
                permisos.includes('moderation:destroy') ||
                permisos.includes('*')"
            >
              <v-tooltip
                v-if="(permisos.includes('moderation:edit') ||
                  permisos.includes('*'))"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="chowAcceptChange(iten)"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.acceptChange') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="(permisos.includes('moderation:destroy') ||
                  permisos.includes('*'))"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="chowRejectChange(iten)"
                  >
                    <v-icon>{{ icons.mdiCancel }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.canceleChange') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text
      v-else
      class="pt-2"
    >
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-center"
        >
          <v-alert
            class="mt-2"
            outlined
            type="info"
          >
            {{ $t('msg.noElementShow') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text
      v-if="items.length > 0"
      class="pt-2"
    >
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
  mdiEye,
  mdiCancel,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
        mdiEye,
        mdiCancel,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleAcceptChange: false,
      isDialogVisibleRejectChange: false,
      loading: false,
      email: null,
      admin: {},
      is_nivel_propietario: false,
      is_creator: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
    this.load()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(
          `moderations?entity=nom_airports&page=${this.pagination.current}&per_page=${this.itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.items = res.data.data
          this.pagination.current = res.data.meta.current_page
          this.pagination.total = res.data.meta.last_page
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.isLoading = false)
    },
    onPageChange() {
      this.load()
    },
    chowAcceptChange(item) {
      this.item = item
      this.isDialogVisibleAcceptChange = true
    },
    chowRejectChange(item) {
      this.item = item
      this.isDialogVisibleRejectChange = true
    },
    confirmAcceptChanges() {
      this.loading = true
      this.axios
        .get(`moderations/change-accept/${this.item.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reset())
    },

    confirmRejectChanges() {
      this.loading = true
      this.axios
        .delete(`moderations/${this.item.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reset())
    },
    reset() {
      this.item = {}
      this.loading = false
      this.isDialogVisibleAcceptChange = false
      this.isDialogVisibleRejectChange = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
