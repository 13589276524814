<template>
  <v-select
    v-if="!isLoading"
    v-model="clase.equipaje_personal.peso"
    :items="pesos"
    item-text="name"
    item-value="id"
    outlined
    dense
    hide-details
    :suffix="sufijo"
    @change="changeSelect()"
  ></v-select>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      sufijo: null,
      pesos: [],
    }
  },

  created() {
    this.load()
  },
  methods: {

    load() {
      this.axios.get(`nom_peso_equipaje_flights?perfil_slug=${localStorage.getItem('perfil')}&tipo=cabina`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (element.tipo === 'Artículo personal') {
              this.pesos.push({
                id: element.id,
                name: `${element.name}`,
              })
            }
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    changeSelect() {
      this.sufijo = null
      if (this.clase.equipaje_personal.peso) {
        this.sufijo = this.pesos.filter(e => e.id === this.clase.equipaje_personal.peso)[0].um
      }
    },
  },
}
</script>
