<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.cars')} / ${$t('lbl.frecuency')}`">
        <template v-if="!isLoading">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="mb-4 me-3"
                  @click="$router.push({ name: 'cars-contrate-list' })"
                >
                  <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                  <span>{{ $t('btn.back') }}</span>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  v-if="!$store.state.app.onlyShow"
                  color="primary"
                  class="mb-4 me-3"
                  :loading="loading"
                  @click="save()"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <strong>{{ $t('lbl.infoContrate') }}</strong>
                      <v-autocomplete
                        v-model="model.rentadora_id"
                        class="pt-2"
                        :items="itemsRentadora"
                        :search-input.sync="searchRentadora"
                        hide-details
                        hide-selected
                        :label="$t('lbl.rental')"
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :disabled="$store.state.app.onlyShow"
                        @change="changeRentadora"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              {{ $t('lbl.rental') }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span v-text="item.name"></span>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <strong style="opacity:0;">.</strong>
                      <v-autocomplete
                        v-model="model.proveedor_id"
                        class="pt-2"
                        :items="itemsProveedor"
                        :search-input.sync="searchProveedor"
                        hide-details
                        hide-selected
                        :label="$t('lbl.proveedor')"
                        outlined
                        dense
                        item-text="name_comercial"
                        item-value="id"
                        :disabled="$store.state.app.onlyShow"
                        @change="setProveedor()"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              {{ $t('lbl.proveedor') }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span v-text="item.name_comercial"></span>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <strong style="opacity:0;">.</strong>
                      <v-text-field
                        v-model="model.identificador"
                        :label="$t('lbl.identificador')"
                        class="pt-2"
                        outlined
                        dense
                        hide-details
                        :disabled="$store.state.app.onlyShow"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <strong>{{ $t('lbl.typeContrato') }}</strong>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="primary">
                                  mdi-information-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('lbl.titleTipoContrate') }}</span>
                          </v-tooltip>
                          <v-radio-group
                            v-model="model.tipo_contrato"
                            row
                            hide-details
                            class="mt-0"
                            :disabled="$store.state.app.onlyShow"
                          >
                            <v-radio
                              :label="$t('lbl.cat')"
                              value="categoria"
                              @click="changeTipoContrato"
                            ></v-radio>
                            <v-radio
                              :label="$t('lbl.brandModel')"
                              value="marca_modelo"
                              @click="changeTipoContrato"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <!--<v-col
                          cols="12"
                          md="6"
                        >
                          <v-switch
                            v-model="model.stopSale"
                            :label="$t('lbl.stopSale')"
                            hide-details
                            class="mt-2"
                            :disabled="$store.state.app.onlyShow"
                          ></v-switch>
                        </v-col>-->
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-switch
                            v-model="model.cancelado"
                            :label="$t('lbl.canceled')"
                            hide-details
                            class="mt-2"
                            :disabled="$store.state.app.onlyShow"
                            @click="setCanceled"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--<v-col
                      cols="12"
                      md="3"
                    >
                      <strong style="opacity:0;">.</strong>
                      <v-text-field
                        ref="contrato_comisionable"
                        v-model="model.comisionable"
                        class="pt-2"
                        :label="$t('lbl.comision')"
                        outlined
                        dense
                        hide-details
                        suffix="%"
                        type="number"
                      >
                        <v-tooltip
                          slot="append-outer"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              class="pb-3"
                              v-on="on"
                            >
                              <v-icon color="primary">
                                mdi-information-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('lbl.titleComitionContrate') }}</span>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>-->
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <strong>{{ $t('lbl.modelPrice') }}</strong>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary">
                              mdi-information-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('lbl.titleModeloPriceContrate') }}</span>
                      </v-tooltip>
                      <v-radio-group
                        v-model="model.calcule_tarif_all_day_by_first"
                        row
                        hide-details
                        class="mt-0"
                        :disabled="$store.state.app.onlyShow"
                      >
                        <v-radio
                          :label="$t('lbl.firstDay')"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          :label="$t('lbl.dayByDay')"
                          :value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <strong>{{ $t('msg.payDayGrace', { days: 1 }) }}</strong>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary">
                              mdi-information-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('msg.payDayGrace', { days: 1 }) }}</span>
                      </v-tooltip>
                      <vc-date-picker
                        v-model="model.time_grace"
                        outlined
                        dense
                        mode="time"
                        :model-config="modelTimeConfig"
                        is24hr
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <v-text-field
                            :label="$t('lbl.timeGrace')"
                            outlined
                            dense
                            :value="inputValue"
                            :disabled="$store.state.app.onlyShow"
                            v-on="inputEvents"
                          >
                          </v-text-field>
                        </template>
                      </vc-date-picker>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-select
                        v-model="model.currency_id"
                        :items="currencies"
                        :label="$t('register.moneda')"
                        outlined
                        dense
                        item-text="code"
                        item-value="id"
                        class="mt-9"
                      ></v-select>
                    </v-col>

                    <!--<v-col
                      cols="12"
                      md="2"
                    >
                      <strong style="opacity:0;">.</strong>
                      <v-switch
                        v-model="model.stopSale"
                        :label="$t('lbl.stopSale')"
                        hide-details
                        class="mt-2"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      md="1"
                    >
                      <strong style="opacity:0;">.</strong>
                      <v-switch
                        v-model="model.cancelado"
                        :label="$t('lbl.canceled')"
                        hide-details
                        class="mt-2"
                        @click="setCanceled"
                      ></v-switch>
                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
              <!--<v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong>{{ $t('lbl.typeReserve') }}</strong>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary">
                          mdi-information
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>.............</span>
                  </v-tooltip>
                  <v-radio-group
                    v-model="model.on_request"
                    row
                    @change="changeFreeSale"
                  >
                    <v-radio
                      :label="$t('lbl.freeSale')"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      :label="$t('lbl.onRequest')"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>-->

              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col v-if="model.tipo_contrato === 'categoria'">
                      <strong>{{ $t('menu.category') }}</strong>
                      <v-tooltip
                        v-if="!$store.state.app.onlyShow"
                        top
                        class="text-right"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="addCategoryContrateCar({ edad_id: edadMin, edad_max_id: null })"
                          >
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiPlus }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.insert') }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-if="model.tipo_contrato === 'marca_modelo'">
                      <strong>{{ $t('lbl.brandModel') }}</strong>
                      <v-tooltip
                        v-if="!$store.state.app.onlyShow"
                        top
                        class="text-right"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="addMarcaModeloContrateCar({ edad_id: edadMin, edad_max_id: null })"
                          >
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiPlus }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.insert') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row v-if="categoryContrateCar.length > 0">
                    <v-col>
                      <CarsCategory
                        v-for="(item, index) in categoryContrateCar"
                        :key="index"
                        :cat="item"
                        :index="index"
                      ></CarsCategory>
                    </v-col>
                  </v-row>
                  <v-row v-if="marcasModeloContrateCar.length > 0">
                    <v-col>
                      <CarsBrandsModel
                        v-for="(item, index) in marcasModeloContrateCar"
                        :key="index"
                        :marca="item"
                        :index="index"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-divider
                  inset
                  vertical
                ></v-divider>

                <v-col
                  v-if="categoryContrateCar.length > 0 || marcasModeloContrateCar.length > 0"
                  cols="6"
                >
                  <v-row>
                    <v-col>
                      <strong>{{ $t('lbl.temporadas') }}</strong>
                      <!--<v-tooltip
                        top
                        class="text-right"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="addTemporadaContrateCar()"
                          >
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiPlus }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.insert') }}</span>
                      </v-tooltip>-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <CarsSeason
                        v-for="(item, index) in temporadasContrateCar"
                        :key="index"
                        :season="item"
                        :index="index"
                        :tipo-contrato="model.tipo_contrato"
                      ></CarsSeason>
                    </v-col>
                  </v-row>
                </v-col>
                <!--DETALLES DEL CONTRATO-->
              </v-row>
              <v-row
                v-if="
                  temporadasContrateCar.length > 0 || categoryContrateCar.length > 0 || marcasModeloContrateCar.length > 0
                "
              >
                <PricesTable
                  v-if="!isLoading"
                  :tipo-contrato="model.tipo_contrato"
                  :oficinas="oficinas"
                />
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col
                v-if="categoryContrateCar.length > 0 || marcasModeloContrateCar.length > 0"
                cols="12"
              >
                <v-simple-table v-if="!isLoadingSuplementos">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase"
                          width="10%"
                        >
                          {{ $t('lbl.active') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.suplemento') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.modalidad') }}
                        </th>
                        <th
                          class="text-uppercase"
                          width="10%"
                        >
                          {{ $t('lbl.price') }}
                        </th>
                        <th
                          class="text-uppercase"
                          colspan="2"
                          width="25%"
                        >
                          {{ $t('lbl.periodoValidez') }}
                        </th>
                        <th
                          class="text-uppercase"
                          width="10%"
                        >
                          ONLINE
                        </th>
                        <th
                          class="text-uppercase"
                          width="10%"
                        >
                          {{ $t('lbl.obligatorio') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(suplemento, indSuplemento) in suplementos">
                        <Suplementos
                          :key="indSuplemento"
                          :suplemento="suplemento"
                          :ind-suplemento="indSuplemento"
                          :suplementos-all="suplementos"
                        />
                      </template>
                      <!--<tr
                          v-for="(suplemento, indSuplemento) in suplementosContrateCar"
                          :key="indSuplemento"
                        >
                          <td>
                            {{ suplementos.filter(e => e.id === suplemento.suplemento_id)[0].nameComodin }}
                          </td>
                          <td>{{ suplemento.price | currency }}</td>
                          <td>{{ $moment(suplemento.from).format('D/MMM/Y') }}</td>
                          <td>{{ $moment(suplemento.to).format('D/MMM/Y') }}</td>
                          <td>
                            <v-icon
                              v-if="suplemento.obligatorio"
                              color="primary"
                              class="mr-2"
                            >
                              mdi-check
                            </v-icon>
                            <v-icon
                              v-else
                              color="error"
                              class="mr-2"
                            >
                              mdi-close
                            </v-icon>
                          </td>
                          <td>
                            <v-tooltip
                              top
                              color="primary"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="editSuplemento(suplemento, indSuplemento)"
                                >
                                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-tooltip>

                            <v-tooltip
                              top
                              color="error"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteSuplementoContrateCar(indSuplemento)"
                                >
                                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-tooltip>
                          </td>
                        </tr>-->
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>

          <!--CONDITIONES GENERALES-->
          <v-card-text
            v-if="
              categCondicionesGenerales.length > 0 &&
                (categoryContrateCar.length > 0 || marcasModeloContrateCar.length > 0)
            "
          >
            <v-row v-if="!noTieneCategCondicionesGenerales">
              <v-col
                cols="12"
                md="2"
              >
                <h3 class="mt-2">
                  {{ $t('menu.conditionGenerals') }}
                </h3>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="filtro"
                  :label="$t('lbl.reference')"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @input="filtrarCateCondicionesGenerales()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <br />
            <template v-if="noTieneCategCondicionesGenerales">
              <h3 class="text-left">
                {{ $t('msg.provedorNotConditions') }}
              </h3>
            </template>
            <v-expansion-panels v-if="showCategories">
              <template v-if="categCondicionesGenerales.length > 0">
                <v-expansion-panel
                  v-for="(cate, ind) in categCondicionesGenerales"
                  :key="ind"
                >
                  <v-expansion-panel-header v-if="cate.condicionesGenerales.length > 0">
                    {{ cate.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!--<v-row no-gutters>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="cate.filtro"
                          :label="$t('lbl.reference')"
                          outlined
                          dense
                          hide-details
                          @input="filtrarCateCondicionesGenerales(cate.filtro, cate, ind)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>-->
                    <template v-for="(cond, indCond) in cate.condicionesGenerales">
                      <template v-if="cond.show">
                        <v-row
                          :key="indCond"
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <v-checkbox
                              v-model="condicionesGenerales_id"
                              :label="`${cond.name ? `${cond.name}` : ''}`"
                              :value="cond.id"
                              hide-details
                              class="pt-0"
                              :disabled="$store.state.app.onlyShow"
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-btn
                              icon
                              @click="showItem(indCond)"
                            >
                              <v-icon>
                                {{ showRow && indCond === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                            md="9"
                          >
                            <div
                              class="col-12"
                              :class="showRow && indCond === indexRow ? '' : 'text-truncate'"
                            >
                              <p v-html="cond.description"></p>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-card-text>

          <v-divider class="mt-2"></v-divider>

          <v-card-text>
            <v-row v-if="contacts.length > 0">
              <v-col
                cols="12"
                md="12"
              >
                <v-row class="mt-0">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <b>{{ $t('lbl.contacts') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-btn
                      icon
                      @click="showItemContacts()"
                    >
                      <v-icon>
                        {{ showRowContacts ? icons.mdiMenuDown : icons.mdiMenuRight }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="showRowContacts">
                  <template v-if="contacts_proveedor.length > 0">
                    <template v-if="contactFree.length > 0">
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="contactAdd"
                          :disabled="$store.state.app.onlyShow"
                          :items="contactFree"
                          :label="$t('lbl.contact')"
                          outlined
                          dense
                          hide-details
                          item-text="name"
                          item-value="id"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                {{ $t('lbl.contact') }}
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-tooltip
                          v-if="!$store.state.app.onlyShow"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-1 mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="addNewContact()"
                            >
                              <v-icon small>
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('btn.insert') }}</span>
                        </v-tooltip>
                      </v-col>
                    </template>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-uppercase">
                                {{ $t('lbl.name') }}
                              </th>
                              <th class="text-uppercase">
                                {{ $t('menu.roles') }}
                              </th>
                              <th class="text-uppercase">
                                {{ $t('lbl.email') }}
                              </th>
                              <th class="text-uppercase">
                                {{ $t('lbl.telefono') }}
                              </th>
                              <th
                                v-if="!$store.state.app.onlyShow"
                                width="150px"
                                class="text-uppercase"
                              >
                                {{ $t('lbl.actions') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(contact, indP) in contactContrateCars">
                              <tr :key="indP">
                                <td>{{ contact.name }}</td>
                                <td>
                                  <span v-if="contact.role_id">
                                    <span v-if="rols.filter(e => e.id === contact.role_id).length > 0">
                                      {{ rols.filter(e => e.id === contact.role_id)[0].name }}
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-for="(email, indEm) in contact.emails"
                                    :key="indEm"
                                  >
                                    {{ email.email }}<br />
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-for="(telefono, indEm) in contact.telefonos"
                                    :key="indEm"
                                  >
                                    {{ telefono.telefono }}
                                    <span v-if="telefono.ext">Ext.: {{ telefono.ext }}</span>
                                    <br />
                                  </span>
                                </td>
                                <td v-if="!$store.state.app.onlyShow">
                                  <v-tooltip
                                    top
                                    color="error"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="deleteContact(contact.id)"
                                      >
                                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.delete') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </template>
                  <!--<fragment
                    v-for="(contact, i) in contacts"
                    :key="i"
                  >
                    <v-col
                      v-if="contact.products.includes(typeProductHotel)"
                      cols="12"
                      md="3"
                    >
                      <v-checkbox
                        v-model="contacts_proveedor"
                        :label="contact.name"
                        :value="contact.id"
                        hide-details
                        class="mt-0 mb-2"
                      ></v-checkbox>
                    </v-col>
                  </fragment>-->
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <v-card-text v-if="isLoading">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="i in 2"
              :key="i"
              cols="12"
              md="6"
            >
              <v-skeleton-loader
                type="article, actions"
              ></v-skeleton-loader>

              <v-skeleton-loader
                type="table-heading, list-item-two-line, image, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR CHANGE TIPO CONTRATO -->
    <v-dialog
      v-model="isDialogDeletedataTipoContrate"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('lbl.infoImportant') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
            dense
            outlined
            type="error"
            class="mt-5"
          >
            <span v-if="initTipoContrate === 'categoria'">
              {{ $t('msg.deleteCategorias') }}
            </span>
            <span v-else>
              {{ $t('msg.deleteModels') }}
            </span>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="cancelChangeTipoContrato()"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmChangeTipoContrato()"
          >
            {{ $t('btn.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline, mdiMenuDown, mdiMenuRight,
} from '@mdi/js'

import Suplementos from '../utils/Suplementos.vue'
import CarsCategory from '../utils/CarsCategory.vue'
import CarsSeason from '../utils/CarsSeason.vue'
import PricesTable from '../utils/PricesTable.vue'
import CarsBrandsModel from '../utils/CarsBrandsModel.vue'

export default {
  components: {
    AppCardCode,
    Suplementos,
    CarsCategory,
    CarsSeason,
    PricesTable,
    CarsBrandsModel,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      model: {
        on_request: 0,
        calcule_tarif_all_day_by_first: true,
      },
      loading: false,
      tarifasAll: [],
      clasesAll: [],
      tarifasEdit: [],
      rentadoras: [],
      itemsRentadora: [],
      searchRentadora: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      suplementos: [],
      currencies: [],
      rules: {
        required: value => !!value || 'Required.',
        // eslint-disable-next-line no-restricted-globals
        numeric: value => !isNaN(value) || 'Invalid value.',
        counter: value => parseInt(value, 10) <= 100 || 'Number between 1 and 100',
      },
      searchMarca: null,
      marca_id: null,
      edadMin: null,
      edades: [],
      itemsMarca: [],
      modelos: [],
      allModelos: [],
      numCategorias: 0,
      catObjects: [],
      brandObjects: [],
      categories: [],
      deletingCat: false,
      seasonObjects: [],
      numSeasons: 0,
      numMarcaModelo: 0,
      modelo_id: null,
      marcas: [],
      oficinas: [],
      editarItemSuplemento: false,
      posEditarItemSuplemento: -1,
      initTipoContrate: null,
      isDialogDeletedataTipoContrate: false,
      categCondicionesGenerales: [],
      allCategCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      showCategories: false,
      showRow: false,
      indexRow: -1,
      filtro: null,
      noTieneCategCondicionesGenerales: true,
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      rols: [],
      showRowContacts: false,
      contacts: [],
      contacts_proveedor: [],
      typeProductCars: null,
      contactAdd: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('cars-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementoCar: state => state.app.suplementoCar,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
      temporadasContrateCar: state => state.app.temporadasContrateCar,
      pricesTemporadasContrateCar: state => state.app.pricesTemporadasContrateCar,
    }),
    contactContrateCars() {
      const arr = []

      this.contacts.forEach(contac => {
        if (this.contacts_proveedor.includes(contac.id)) {
          arr.push(contac)
        }
      })

      return arr
    },
    contactFree() {
      const arr = []
      this.contacts.forEach(contac => {
        if (contac.products.includes(this.typeProductCars)) {
          if (!this.contacts_proveedor.includes(contac.id)) {
            arr.push(contac)
          }
        }
      })

      return arr
    },
  },
  watch: {
    searchRentadora(val) {
      if (val !== null && val.length > 1) {
        this.filterRentadora(val.toLowerCase())
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    if (localStorage.getItem('cars-contrate-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getProducts()
    this.getCurrencies()
    this.getEdades()
    this.getRols()
    this.getRentadoras()
    this.getProveedores()

    // this.getItem()
    this.getSuplementos()
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'addCategoryContrateCar',
      'updateCategoryContrateCar',
      'addMarcaModeloContrateCar',
      'updateMarcaModeloContrateCar',
      'addTemporadaContrateCar',
      'updateTemporadaContrateCar',
      'updatePricesTemporadasContrateCar',
      'addSuplementoContrateCar',
      'setSuplementoContrateCar',
      'deleteSuplementoContrateCar',
      'updateSuplementoContrateCarOne',
      'updateSuplementoContrateCar',
      'setContrateCarFreeSale',
    ]),
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
    },
    getEdades() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
          if (this.edades.length > 0) {
            let edadC = this.edades[0].edad
            this.edadMin = this.edades[0].id
            // eslint-disable-next-line no-plusplus
            for (let index = 1; index < this.edades.length; index++) {
              const element = this.edades[index]
              if (element.edad < edadC) {
                edadC = element.edad
                this.edadMin = element.id
              }
            }
          }
        })
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    showItem(indCond) {
      if (this.indexRow === indCond) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = indCond
    },
    deleteContact(id) {
      const ind = this.contacts_proveedor.indexOf(id)

      if (ind > -1) {
        this.contacts_proveedor.splice(ind, 1)
      }
    },
    addNewContact() {
      if (this.contactAdd) {
        this.contacts_proveedor.push(this.contactAdd)
        this.contactAdd = null
      }
    },
    showItemContacts() {
      this.showRowContacts = !this.showRowContacts
    },
    filtrarCateCondicionesGenerales() {
      if (this.filtro) {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            if (this.categCondicionesGenerales[pos].condicionesGenerales[index].name) {
              if (
                this.categCondicionesGenerales[pos].condicionesGenerales[index].name.toLowerCase().includes(this.filtro)
              ) {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
              } else {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
              }
            } else {
              this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
          }
        }
      }

      /* if (filtro) {
        const filtrado = cate.condicionesGenerales.filter(e => e.name.toLowerCase().includes(filtro))
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
          const condit = this.categCondicionesGenerales[pos].condicionesGenerales[index]
          let esta = false
          filtrado.forEach(element => {
            if (element.id === condit.id) {
              esta = true
            }
          })
          this.categCondicionesGenerales[pos].condicionesGenerales[index].show = esta
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
          this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
        }
      } */
    },
    filterRentadora(v) {
      this.itemsRentadora = []
      if (v === '') {
        this.itemsRentadora = []
      } else {
        this.itemsRentadora = this.rentadoras.filter(e => e.name.toLowerCase())
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    checkConditionsGeneral() {
      this.noTieneCategCondicionesGenerales = true
      this.categCondicionesGenerales.forEach(cate => {
        if (cate.condicionesGenerales.length > 0) {
          this.noTieneCategCondicionesGenerales = false
        }
      })
    },
    getOficinasRenta() {
      this.axios
        .get('nom_oficina_renta_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.oficinasAll = res.data.data
          }
        })
        .finally(() => this.getItem())
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = []

            // this.allCategCondicionesGenerales = res.data.data
            res.data.data.forEach(element => {
              const conditions = []
              this.condicionesGenerales.forEach(cond => {
                if (cond.category_id === element.id && this.model.proveedor_id === cond.proveedor_id) {
                  conditions.push({
                    ...cond,
                    show: true,
                  })
                }
              })
              this.categCondicionesGenerales.push({
                ...element,
                filtro: null,
                condicionesGenerales: conditions,
              })

              setTimeout(() => {
                this.checkConditionsGeneral()
              }, 100)

              /* this.allCategCondicionesGenerales.push({
                ...element,
                filtro: null,
                condicionesGenerales: conditions,
              }) */
            })

            // this.categCondicionesGenerales = res.data.data
          }
        })
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_car=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => this.getCategCondiciones())
    },
    addSuplemento() {
      if (this.suplementoCar.from && this.suplementoCar.to && this.suplementoCar.suplemento_id) {
        this.isLoadingSuplementos = true
        if (!this.editarItemSuplemento) {
          this.addSuplementoContrateCar({
            from: this.suplementoCar.from,
            to: this.suplementoCar.to,
            suplemento_id: this.suplementoCar.suplemento_id,
            price: this.suplementoCar.price,
            obligatorio: this.suplementoCar.obligatorio,
            tarifas_id: this.suplementoCar.tarifas_id,
            tarifas_retorno: this.suplementoCar.tarifas_retorno,
            tarifas_combustible: this.suplementoCar.tarifas_combustible,
            seguros_edad_conductor: this.suplementoCar.seguros_edad_conductor,
          })
        } else {
          this.updateSuplementoContrateCarOne({
            pos: this.posEditarItemSuplemento,
            from: this.suplementoCar.from,
            to: this.suplementoCar.to,
            suplemento_id: this.suplementoCar.suplemento_id,
            price: this.suplementoCar.price,
            obligatorio: this.suplementoCar.obligatorio,
            tarifas_id: this.suplementoCar.tarifas_id,
            tarifas_retorno: this.suplementoCar.tarifas_retorno,
            tarifas_combustible: this.suplementoCar.tarifas_combustible,
            seguros_edad_conductor: this.suplementoCar.seguros_edad_conductor,
          })
        }

        this.isDialogSuplemento = false
        this.editarItemSuplemento = false
        this.posEditarItemSuplemento = -1
        this.setSuplementoContrateCar({
          from: null,
          to: null,
          price: 0,
          suplemento_id: null,
          obligatorio: false,
          tarifas_id: [],
          tarifas_retorno: [],
          tarifas_combustible: [],
        })
        setTimeout(() => {
          this.isLoadingSuplementos = false
        }, 100)
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    editSuplemento(iten, pos) {
      this.editarItemSuplemento = true
      this.posEditarItemSuplemento = pos
      this.isDialogSuplemento = true
      this.setSuplementoContrateCar(iten)
    },
    getItem() {
      if (localStorage.getItem('cars-contrate-id') !== null) {
        const id = localStorage.getItem('cars-contrate-id')
        this.axios
          .get(`contrate_cars/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            this.itemsRentadora.push(this.model.rentadora)

            if (this.model.on_request) {
              this.model.on_request = 1
            } else {
              this.model.on_request = 0
            }

            if (this.model.proveedor_id) {
              this.itemsProveedor.push(this.model.proveedor)
            }

            this.changeRentadora()

            /* this.oficinas = []
            this.axios
              .get(`nom_rentadora_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(result => {
                const rentadoras = result.data.data

                const { oficinas } = rentadoras.filter(e => e.id === this.model.rentadora_id)[0]
                this.oficinasAll.forEach(element => {
                  oficinas.forEach(elementOfi => {
                    if (elementOfi.oficina_id === element.id) {
                      this.oficinas.push(element)
                    }
                  })
                })
              }) */

            if (this.model.categories) {
              this.updateCategoryContrateCar(this.model.categories)
            } else {
              this.updateCategoryContrateCar([])
            }

            if (this.model.marcas) {
              this.updateMarcaModeloContrateCar(this.model.marcas)
            } else {
              this.updateMarcaModeloContrateCar([])
            }

            if (this.model.temporadas) {
              this.updateTemporadaContrateCar(this.model.temporadas)
            } else {
              this.updateTemporadaContrateCar([])
            }

            if (this.model.suplementos) {
              this.updateSuplementoContrateCar(this.model.suplementos)
            } else {
              this.updateSuplementoContrateCar([])
            }

            if (this.model.conditions) {
              this.condicionesGenerales_id = this.model.conditions
            } else {
              this.condicionesGenerales_id = []
            }

            if (this.model.proveedor_id !== null && this.model.proveedor_id !== 'null') {
              if (this.model.proveedor.contacts !== null && this.model.proveedor.contacts !== 'null') {
                this.contacts = this.model.proveedor.contacts
              }
            }
            if (this.model.contacts_proveedor) {
              if (this.model.contacts_proveedor.length > 0) {
                this.contacts_proveedor = this.model.contacts_proveedor
              }
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.axios
              .get(`contrate_cars/${id}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(resPrices => {
                this.updatePricesTemporadasContrateCar(resPrices.data.data.data.prices)
              })
              .finally(() => {
                setTimeout(() => {
                  this.getCondiciones()

                  this.isLoading = false
                  this.isLoadingSuplementos = false
                  this.showCategories = true
                }, 100)
              })
          })
      } else {
        this.updateCategoryContrateCar([])
        this.updateMarcaModeloContrateCar([])
        this.updateTemporadaContrateCar([])
        this.updatePricesTemporadasContrateCar([])

        setTimeout(() => {
          this.getCondiciones()

          this.isLoading = false
          this.isLoadingSuplementos = false
          this.showCategories = true
        }, 100)
      }
    },
    getRentadoras() {
      this.axios
        .get(`nom_rentadora_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.rentadoras = res.data.data
        })
        .finally(() => this.getOficinasRenta())
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          res.data.data.forEach(element => {
            if (element.slug === 'cars') {
              this.typeProductCars = element.id
            }
          })
        })
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    setProveedor() {
      if (this.model.proveedor_id) {
        if (this.proveedores.filter(e => e.id === this.model.proveedor_id).length > 0) {
          if (this.proveedores.filter(e => e.id === this.model.proveedor_id)[0].contacts.length > 0) {
            this.proveedores
              .filter(e => e.id === this.model.proveedor_id)[0]
              .contacts.forEach(element => {
                if (element.products) {
                  if (element.products.length > 0) {
                    if (element.products.includes(this.typeProductCars)) {
                      this.contacts.push(element)
                    }
                  }
                }
              })
          } else {
            this.contacts = []
          }
        } else {
          this.contacts = []
        }

        this.getCategCondiciones()

        const mod = this.proveedores.filter(a => a.id === this.model.proveedor_id)[0]
        const year = this.$moment(new Date()).format('Y')
        this.model.identificador = `${mod.name_comercial}-${year}-${Math.random()
          .toString(36)
          .slice(-3)
          .toUpperCase()}`
      } else {
        this.categCondicionesGenerales = []
        this.model.identificador = null
      }
    },
    getSuplementos() {
      this.axios
        .get('nom_suplemento_contrate/cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.suplementos = res.data.data
          }
        })
    },
    changeTipoContrato() {
      // this.updateTemporadaContrateCar([])

      if (!this.initTipoContrate) {
        // this.updatePricesTemporadasContrateCar([])
        if (this.model.tipo_contrato === 'categoria') {
          this.initTipoContrate = 'categoria'
          this.updateMarcaModeloContrateCar([])
          this.addTemporadaContrateCar()
        } else {
          this.initTipoContrate = 'marca_modelo'
          this.updateCategoryContrateCar([])
          this.addTemporadaContrateCar()
        }
      } else {
        this.isDialogDeletedataTipoContrate = true
      }
    },
    changeFreeSale() {
      if (this.model.on_request) {
        this.setContrateCarFreeSale(false)
      } else {
        this.setContrateCarFreeSale(true)
      }
    },
    changeRentadora() {
      if (this.model.rentadora_id) {
        this.oficinas = []
        this.oficinas.push({
          id: -1,
          name: 'Todas',
        })
        const { oficinas } = this.rentadoras.filter(e => e.id === this.model.rentadora_id)[0]
        this.oficinasAll.forEach(element => {
          if (oficinas.length > 0) {
            oficinas.forEach(elementOfi => {
              if (elementOfi.oficina_id === element.id) {
                this.oficinas.push(element)
              }
            })
          }
        })
      }
    },
    save() {
      const json = {
        rentadora_id: this.model.rentadora_id,
        proveedor_id: this.model.proveedor_id,
        contacts_proveedor: this.contacts_proveedor,
        comisionable: this.model.comisionable,
        calcule_tarif_all_day_by_first: this.model.calcule_tarif_all_day_by_first,
        tipo_contrato: this.model.tipo_contrato,
        on_request: this.model.on_request,
        identificador: this.model.identificador,

        // publico: !this.model.stopSale,
        cancelado: this.model.cancelado,
        time_grace: this.model.time_grace,
        currency_id: this.model.currency_id,

        // publico: this.model.publico,
        categories: this.categoryContrateCar,
        marcas: this.marcasModeloContrateCar,
        temporadas: this.temporadasContrateCar,
        prices: this.pricesTemporadasContrateCar,
        suplementos: this.suplementosContrateCar,
        conditions: this.condicionesGenerales_id,
      }

      if (
        this.model.rentadora_id
        && this.model.identificador
        && this.model.tipo_contrato
        && this.model.currency_id
        && (this.categoryContrateCar.length > 0 || this.marcasModeloContrateCar.length > 0)
        && this.temporadasContrateCar.length > 0
        && this.pricesTemporadasContrateCar.length > 0
      ) {
        let proceder = true

        // RECORRER LAS CATEGORIAS PARA VALIDAR Q ESTEN LLENAS
        if (this.categoryContrateCar.length > 0) {
          this.categoryContrateCar.forEach(cate => {
            if (!cate.category_id || cate.cars_id.length === 0) {
              proceder = false
            }
          })
        }

        // RECORRER LAS MARCAS PARA VALIDAR Q ESTEN LLENAS
        if (this.marcasModeloContrateCar.length > 0) {
          this.marcasModeloContrateCar.forEach(marc => {
            if (marc.cars_id.length === 0) {
              proceder = false
            }
          })
        }

        // RECORRER LAS TEMPORADAS PARA VALIDAR Q ESTEN LLENAS
        if (this.temporadasContrateCar.length > 0) {
          this.temporadasContrateCar.forEach(temp => {
            if (temp.tarifas_id.length === 0) {
              proceder = false
            }
            temp.dates.forEach(date => {
              if (!date.from || !date.to) {
                proceder = false
              }
            })
          })
        }

        // RECORRER LAS PRECIOS PARA VALIDAR Q ESTEN LLENAS
        /* if (this.pricesTemporadasContrateCar.length > 0) {
          this.pricesTemporadasContrateCar.forEach(prices => {
            const price = prices.prices
            price.forEach(ppp => {
              // eslint-disable-next-line no-empty
              if (ppp.tarifa_id === 'seguro' || ppp.tarifa_id === 'deposito' || ppp.tarifa_id === 'day_extra') {
              } else if (ppp.price === 0 || ppp.price === null || ppp.price === undefined) {
                proceder = false
              }
            })
          })
        } */

        if (proceder) {
          this.loading = true
          if (localStorage.getItem('cars-contrate-id') === null) {
            this.axios
              .post('contrate_cars', json, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  } else if (response.data.data.status === 402) {
                    this.$toast.error(this.$t('msg.existContrate', { identificador: this.model.identificador }))
                  }
                } else {
                  localStorage.removeItem('cars-contrate-id')
                  this.$router.push({ name: 'cars-contrate-list' })
                }
              })
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          } else {
            this.axios
              .put(`contrate_cars/${this.model.id}`, json, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  localStorage.removeItem('cars-contrate-id')
                  this.$router.push({ name: 'cars-contrate-list' })
                }
              })
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    setCanceled() {
      if (this.model.cancelado) {
        this.model.publico = false
        this.model.stopSale = true
      }
    },

    confirmChangeTipoContrato() {
      if (this.model.tipo_contrato === 'categoria') {
        this.initTipoContrate = 'categoria'
        this.updateMarcaModeloContrateCar([])
      } else {
        this.initTipoContrate = 'marca_modelo'
        this.updateCategoryContrateCar([])
      }
      this.isDialogDeletedataTipoContrate = false
    },
    cancelChangeTipoContrato() {
      if (this.initTipoContrate === 'categoria') {
        // eslint-disable-next-line no-unused-expressions
        this.model.tipo_contrato = 'categoria'
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.model.tipo_contrato = 'marca_modelo'
      }
      this.isDialogDeletedataTipoContrate = false
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>

<!--<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: center;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>-->
