<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="10"
      sm="10"
    >
      <v-select
        v-if="!isLoading"
        v-model="clase.tarifa_id"
        :items="tarifas"
        item-text="name"
        item-value="id"
        :label="$t('lbl.tarifa')"
        outlined
        dense
        hide-details
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import { mdiTrashCan } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    airline: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      tarifas: [],
      icons: {
        mdiTrashCan,
      },
    }
  },

  created() {
    this.load()
  },
  methods: {
    ...mapMutations(['adicionarClaseVuelo', 'deleteClaseVuelo', 'updateClaseVuelo', 'adicionarPrecioAdicionalVuelo']),

    load() {
      this.axios
        .get(`nom_tarifa_flights?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tarifas = res.data.data.filter(e => e.clase_id === this.clase.class_id && e.aerolinea_id === this.airline.id)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
