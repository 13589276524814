<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table
      class="purchased-items-table"
      dense
    >
      <template v-slot:default>
        <tbody>
          <fragment
            v-for="(item, indItem) in itemsTarifarios"
            :key="indItem"
          >
            <tr>
              <th>
                <span class="d-flex flex-row">
                  <v-checkbox
                    v-model="item.check"
                    hide-details
                    class="my-0"
                  ></v-checkbox>
                  <span class="mt-2">
                    {{ `${item.hotel.name} ${item.categoria.stars ? `(${item.categoria.stars}*)` : ''}` }}
                    {{ item.hotel.operador.name }}
                  </span>
                </span>
              </th>
              <th width="200px">
                {{ $t('lbl.offertsWith', { cant: item.proveedores.length }) }}
              </th>
              <th
                class="text-right"
                width="10px"
              >
                <v-btn
                  icon
                  @click="showItem(indItem)"
                >
                  <v-icon>
                    {{ showRow && indItem === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <tr v-if="showRow && indItem === indexRow">
              <td colspan="3">
                <HotelsProveedorList :hotel="item" />
              </td>
            </tr>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

import HotelsProveedorList from './HotelsProveedorList.vue'

export default {
  components: {
    HotelsProveedorList,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    operadores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      menuFrom: false,
      menuTo: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsList: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
  },

  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss">
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
