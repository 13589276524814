<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('menu.politicaTarifa')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :disabled="loading"
                @click="$router.push({ name: 'politica-tarifaria-flights-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <v-text-field
                  v-model="model.name"
                  :label="$t('lbl.name')"
                  hide-details
                  dense
                  outlined
                  class="pt-2"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <v-autocomplete
                  v-model="model.aerolinea_id"
                  class="pt-2"
                  :items="itemsAerolinea"
                  :search-input.sync="searchAerolinea"
                  hide-details
                  hide-selected
                  :label="$t('lbl.aerolinea')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @change="changeAerolinea"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.aerolinea') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="model.aerolinea_id"
              no-gutters
            >
              <v-col
                style="overflow-x:auto"
                cols="12"
                :sm="
                  $store.state.app.clasesVuelos.length === 1 ? 6 : $store.state.app.clasesVuelos.length === 2 ? 9 : 12
                "
                :md="
                  $store.state.app.clasesVuelos.length === 1 ? 6 : $store.state.app.clasesVuelos.length === 2 ? 9 : 12
                "
              >
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <fragment>
                        <tr>
                          <td
                            class="text-left"
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <!--<v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <h3>{{ $t('lbl.detailContrate') }}</h3>
                              </v-col>
                            </v-row>-->
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            width="200px"
                          >
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="10"
                                md="10"
                              >
                                {{ $t('lbl.clase') }}
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                              >
                                <v-tooltip
                                  top
                                  class="text-right"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      x-small
                                      color="primary"
                                      @click="adicionarClaseVuelo()"
                                    >
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ icons.mdiPlus }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.insert') }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                            class="text-left"
                          >
                            <SelectClaseVuelo
                              :clase="clase"
                              :pos="i"
                              :airline="airline"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ $t('lbl.tarifa') }}
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <SelectTarifaVuelo
                              v-if="!$store.state.app.loadTarifasVuelos"
                              :clase="clase"
                              :airline="airline"
                            />
                          </td>
                        </tr>

                        <!--<tr>
                          <td
                            style="height: 80px"
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <h3>{{ $t('lbl.conditionContrate') }}</h3>
                          </td>
                        </tr>-->
                      </fragment>

                      <!--EQUIPAJE PERSONAL-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_personal.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hidePersonal = !hidePersonal"
                              >
                                <v-icon>
                                  {{ hidePersonal ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionPersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoPersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.precio') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <PricePersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaPersonalVuelo :clase="clase" />
                        </td>
                      </tr>

                      <!--EQUIPAJE DE MANO-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_cabina.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hideCabina = !hideCabina"
                              >
                                <v-icon>
                                  {{ hideCabina ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.precio') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <PriceCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaCabinaVuelo :clase="clase" />
                        </td>
                      </tr>

                      <!--EQUIPAJE BODEGA-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_bodega.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hideBodega = !hideBodega"
                              >
                                <v-icon>
                                  {{ hideBodega ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <!--<tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.max_exceso_equipaje') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <MaxExcesoEquipajeBodegaVuelo :clase="clase" />
                        </td>
                      </tr>-->
                      <template v-if="hideBodega">
                        <tr
                          v-for="(maleta, j) in $store.state.app.clasesVuelos[0].equipaje_bodega.maletas"
                          :key="j"
                        >
                          <td>
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                md="10"
                                sm="10"
                              >
                                {{ `${$t('lbl.equipaje_bodega.precioMaleta')} ${j + 1}` }}
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                              >
                                <v-tooltip
                                  v-if="j === 0"
                                  top
                                  class="text-right"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      x-small
                                      color="primary"
                                      @click="adicionarMaletaCabina()"
                                    >
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ icons.mdiPlus }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('lbl.equipaje_bodega.addMaleta') }}</span>
                                </v-tooltip>

                                <v-tooltip
                                  v-else
                                  top
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      x-small
                                      color="error"
                                      @click="deleteMaletaBodegaVuelo(j)"
                                    >
                                      <v-icon
                                        v-bind="attrs"
                                        small
                                        v-on="on"
                                      >
                                        {{ icons.mdiTrashCan }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <PriceMaletaBodegaVuelo
                              :clase="clase"
                              :pos="j"
                            />
                          </td>
                        </tr>
                      </template>
                      <tr
                        v-if="hideBodega && $store.state.app.clasesVuelos.length > 0"
                      >
                        <fragment>
                          <td
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <v-row
                              no-gutters
                            >
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="pt-2"
                              >
                                <b>{{ $t('lbl.equipaje_bodega.exceso_equipaje') }}</b>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2">{{ $t('lbl.sobrepeso') }}</b>
                                  <v-switch
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_peso"
                                    :label="`$/${$store.state.app.clasesVuelos[0].equipaje_bodega.um_peso}`"
                                    dense
                                    hide-details
                                    class="mb-0 mt-2 ml-2"
                                    :disabled="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_equipaje <= 0"
                                    @change="initExcesoEquipajeBodega"
                                  ></v-switch>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2 mr-1">{{ $t('lbl.equipaje_bodega.max_exceso_equipaje') }}</b>
                                  <v-tooltip
                                    top
                                    color="white"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        :color="$vuetify.theme.themes.light.primary"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span max-width="250px">
                                      <div class="d-block rounded-lg rounded-xl">
                                        <div
                                          class="justify-start align-center rounded-lg rounded-xl"
                                        >
                                          <p
                                            class="text-justify"
                                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                                          >
                                            {{ $t('msg.agreguePesoMax1') }}<br />
                                            {{ $t('msg.agreguePesoMax2') }}
                                          </p>
                                        </div>
                                      </div>
                                    </span>
                                  </v-tooltip>
                                  <v-text-field
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_equipaje"
                                    type="number"
                                    min="0"
                                    hide-details
                                    dense
                                    outlined
                                    :suffix="$store.state.app.clasesVuelos[0].equipaje_bodega.um_peso"
                                    class="col-4"
                                  ></v-text-field>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                              >
                                <div class="d-flex">
                                  <v-icon
                                    color="error"
                                  >
                                    {{ icons.mdiAlertOutline }}
                                  </v-icon>
                                  <span class="ml-1 pt-2">{{ $t('msg.equipajeSupere') }}</span>
                                </div>
                              </v-col>
                            </v-row>
                          <!--<v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <b>{{ $t('lbl.equipaje_bodega.exceso_equipaje') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="6"
                                >
                                  <b>{{ $t('lbl.sobrepeso') }}</b>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="6"
                                >
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>-->
                          </td>
                        </fragment>
                        <!--<td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <ExcesoEquipajeBodegaVuelo :clase="clase" />
                        </td>-->
                      </tr>
                      <template v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment v-if="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_peso">
                          <tr
                            v-for="(exceso_equip, j) in $store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje"
                            :key="j"
                          >
                            <td>
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  md="10"
                                  sm="10"
                                >
                                  <RangeExcesoEquipajeBodegaVuelo
                                    :key="Math.random() * (j + 1)"
                                    :pos="j"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="2"
                                  md="2"
                                >
                                  <v-tooltip
                                    v-if="j === 0"
                                    top
                                    class="text-right"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        @click="adicionarExcesoEquipajeBodega()"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ icons.mdiPlus }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('lbl.equipaje_bodega.addPeso') }}</span>
                                  </v-tooltip>

                                  <v-tooltip
                                    v-else
                                    top
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        color="error"
                                        @click="deleteExcesoEquipajeBodega(j)"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          small
                                          v-on="on"
                                        >
                                          {{ icons.mdiTrashCan }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.delete') }}</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                            <td
                              v-for="(clase, i) in $store.state.app.clasesVuelos"
                              :key="i"
                            >
                              <PriceExcesoEquipajeBodegaVuelo
                                :clase="clase"
                                :pos="j"
                              />
                            </td>
                          </tr>
                        </fragment>
                      </template>

                      <!--DIMENSION-->
                      <tr
                        v-if="hideBodega && $store.state.app.clasesVuelos.length > 0"
                      >
                        <fragment>
                          <td
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <v-row
                              no-gutters
                            >
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="pt-2"
                              >
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2">{{ $t('lbl.sobreDimension') }}</b>
                                  <v-switch
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_dimension"
                                    :label="`$/${$store.state.app.clasesVuelos[0].equipaje_bodega.um_dimension}`"
                                    dense
                                    hide-details
                                    class="mb-0 mt-2 ml-2"
                                    :disabled="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_dimension <= 0"
                                    @change="initExcesoDimensionBodega"
                                  ></v-switch>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2 mr-1">{{ $t('lbl.dimensionMax') }}</b>
                                  <v-tooltip
                                    top
                                    color="white"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        :color="$vuetify.theme.themes.light.primary"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span>
                                      <div class="d-block rounded-lg rounded-xl">
                                        <div
                                          class="justify-start align-center rounded-lg rounded-xl"
                                        >
                                          <p
                                            class="text-justify"
                                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                                          >
                                            {{ $t('msg.agreguePesoMax1') }}<br />
                                            {{ $t('msg.agreguePesoMax2') }}
                                          </p>
                                        </div>
                                      </div>
                                    </span>
                                  </v-tooltip>
                                  <v-text-field
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_dimension"
                                    type="number"
                                    min="0"
                                    hide-details
                                    dense
                                    outlined
                                    :suffix="$store.state.app.clasesVuelos[0].equipaje_bodega.um_dimension"
                                    class="col-4"
                                  ></v-text-field>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                              >
                                <div class="d-flex">
                                  <v-icon
                                    color="error"
                                  >
                                    {{ icons.mdiAlertOutline }}
                                  </v-icon>
                                  <span class="ml-1 pt-2">{{ $t('msg.equipajeSupere') }}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </td>
                        </fragment>
                      </tr>
                      <template v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment v-if="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_dimension">
                          <tr
                            v-for="(exceso_dimen, j) in $store.state.app.clasesVuelos[0].equipaje_bodega.exceso_dimension"
                            :key="j"
                          >
                            <td>
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  md="10"
                                  sm="10"
                                >
                                  <RangeExcesoDimensionBodegaVuelo
                                    :key="Math.random() * (j + 1)"
                                    :pos="j"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="2"
                                  md="2"
                                >
                                  <v-tooltip
                                    v-if="j === 0"
                                    top
                                    class="text-right"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        color="primary"
                                        @click="adicionarDimensionEquipajeBodega()"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ icons.mdiPlus }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('lbl.equipaje_bodega.addPeso') }}</span>
                                  </v-tooltip>

                                  <v-tooltip
                                    v-else
                                    top
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        color="error"
                                        @click="deleteDimensionEquipajeBodega(j)"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          small
                                          v-on="on"
                                        >
                                          {{ icons.mdiTrashCan }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.delete') }}</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                            <td
                              v-for="(clase, i) in $store.state.app.clasesVuelos"
                              :key="i"
                            >
                              <PriceExcesoDimensionBodegaVuelo
                                :clase="clase"
                                :pos="j"
                              />
                            </td>
                          </tr>
                        </fragment>
                      </template>

                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.tasa_despacho') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <TasaDespachoBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiCity,
  mdiChevronUpCircle,
  mdiMenuUp,
  mdiChevronDownCircle,
  mdiMenuDown,
  mdiAirplane,
  mdiInformation,
  mdiChevronLeft,
  mdiChevronRight,
  mdiInformationOutline,
  mdiAlertOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import SelectClaseVuelo from '../utils/tarifas/SelectClaseVuelo.vue'
import SelectTarifaVuelo from '../utils/tarifas/SelectTarifaVuelo.vue'
import SelectDimensionPersonalVuelo from '../utils/tarifas/SelectDimensionPersonalVuelo.vue'
import SelectDimensionCabinaVuelo from '../utils/tarifas/SelectDimensionCabinaVuelo.vue'
import SelectPesoCabinaVuelo from '../utils/tarifas/SelectPesoCabinaVuelo.vue'
import SelectPesoPersonalVuelo from '../utils/tarifas/SelectPesoPersonalVuelo.vue'
import PricePersonalVuelo from '../utils/tarifas/PricePersonalVuelo.vue'
import PriceCabinaVuelo from '../utils/tarifas/PriceCabinaVuelo.vue'
import AplicaPersonalVuelo from '../utils/tarifas/AplicaPersonalVuelo.vue'
import AplicaCabinaVuelo from '../utils/tarifas/AplicaCabinaVuelo.vue'
import SelectDimensionBodegaVuelo from '../utils/tarifas/SelectDimensionBodegaVuelo.vue'
import SelectPesoBodegaVuelo from '../utils/tarifas/SelectPesoBodegaVuelo.vue'
import PriceMaletaBodegaVuelo from '../utils/tarifas/PriceMaletaBodegaVuelo.vue'
import RangeExcesoEquipajeBodegaVuelo from '../utils/tarifas/RangeExcesoEquipajeBodegaVuelo.vue'
import PriceExcesoEquipajeBodegaVuelo from '../utils/tarifas/PriceExcesoEquipajeBodegaVuelo.vue'
import RangeExcesoDimensionBodegaVuelo from '../utils/tarifas/RangeExcesoDimensionBodegaVuelo.vue'
import PriceExcesoDimensionBodegaVuelo from '../utils/tarifas/PriceExcesoDimensionBodegaVuelo.vue'

// import MaxExcesoEquipajeBodegaVuelo from '../utils/tarifas/MaxExcesoEquipajeBodegaVuelo.vue'
// import ExcesoEquipajeBodegaVuelo from '../utils/tarifas/ExcesoEquipajeBodegaVuelo.vue'
import TasaDespachoBodegaVuelo from '../utils/tarifas/TasaDespachoBodegaVuelo.vue'
import AplicaBodegaVuelo from '../utils/tarifas/AplicaBodegaVuelo.vue'

export default {
  components: {
    AppCardCode,
    SelectClaseVuelo,
    SelectTarifaVuelo,
    SelectDimensionPersonalVuelo,
    SelectDimensionCabinaVuelo,
    SelectPesoCabinaVuelo,
    SelectPesoPersonalVuelo,
    PriceCabinaVuelo,
    PricePersonalVuelo,
    AplicaPersonalVuelo,
    AplicaCabinaVuelo,
    SelectDimensionBodegaVuelo,
    SelectPesoBodegaVuelo,
    PriceMaletaBodegaVuelo,
    RangeExcesoEquipajeBodegaVuelo,
    PriceExcesoEquipajeBodegaVuelo,
    RangeExcesoDimensionBodegaVuelo,
    PriceExcesoDimensionBodegaVuelo,

    // MaxExcesoEquipajeBodegaVuelo,
    // ExcesoEquipajeBodegaVuelo,
    TasaDespachoBodegaVuelo,
    AplicaBodegaVuelo,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiAirplaneLanding,
        mdiCity,
        mdiChevronUpCircle,
        mdiMenuUp,
        mdiChevronDownCircle,
        mdiMenuDown,
        mdiAirplane,
        mdiInformation,
        mdiChevronLeft,
        mdiChevronRight,
        mdiInformationOutline,
        mdiAlertOutline,
      },
      hidePersonal: false,
      hideCabina: false,
      hideBodega: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      timezone: '',
      aeronaves: [],
      airline: null,
      semanas: [],
      modelSemanas: [],
      model: {},
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      showMap: true,
      loading: false,

      ptosRecogida: [],
      modelPtosRecogida: [],

      items: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('politica-tarifaria-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
  },
  watch: {
    searchAerolinea(val) {
      this.filterAerolinea(val.toLowerCase())
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getAerolineas()

    this.getItem()
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'updateAirport', 'editAirport', 'setOnlyShow', 'adicionarClaseVuelo',
      'deleteClaseVuelo',
      'updateClaseVuelo',
      'adicionarMaletaCabina',
      'deleteMaletaBodegaVuelo',
      'adicionarExcesoEquipajeBodega',
      'deleteExcesoEquipajeBodega',
      'adicionarDimensionEquipajeBodega',
      'deleteDimensionEquipajeBodega',
    ]),
    filterAerolinea() {
      this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data

          /* const result = res.data.data
          result.forEach(element => {
            if (element.numero_vuelos_validos.length > 0) {
              this.aerolineas.push(element)
            }
          }) */
        })
    },
    changeAerolinea() {
      if (this.model.aerolinea_id) {
        this.flightDetail = null
        this.airline = null

        // eslint-disable-next-line prefer-destructuring
        this.airline = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
      } else {
        this.airline = null
      }
    },
    initExcesoEquipajeBodega() {
      if (this.$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_peso) {
        this.adicionarExcesoEquipajeBodega()
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje.length; index++) {
          // const element = this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje[index]
          this.deleteExcesoEquipajeBodega(index)
        }
      }
    },
    initExcesoDimensionBodega() {
      if (this.$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_dimension) {
        this.adicionarDimensionEquipajeBodega()
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_dimension.length; index++) {
          // const element = this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje[index]
          this.deleteDimensionEquipajeBodega(index)
        }
      }
    },

    getItem() {
      if (localStorage.getItem('politica-tarifaria-id') !== null) {
        const id = localStorage.getItem('politica-tarifaria-id')
        this.axios
          .get(`nom_politica_tarifaria_flights/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.aerolineas.push(this.model.aerolinea)
            this.airline = this.model.aerolinea
            this.searchAerolinea = this.model.aerolinea.name

            this.updateClaseVuelo(this.model.data_clases)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.updateClaseVuelo([])
        this.isLoading = false
      }
    },

    save() {
      if (
        this.model.name
        && this.model.aerolinea_id

      // && this.$store.state.app.geotag.latitud
      ) {
        this.loading = true
        const json = {
          name: this.model.name,
          aerolinea_id: this.model.aerolinea_id,
          data_clases: this.$store.state.app.clasesVuelos,
        }

        if (localStorage.getItem('politica-tarifaria-id') === null) {
          this.axios
            .post('nom_politica_tarifaria_flights', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.airportExist', { numero: this.airport.iata }))
                }
              } else {
                localStorage.removeItem('politica-tarifaria-id')
                this.$router.push({ name: 'politica-tarifaria-flights-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`nom_politica_tarifaria_flights/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('politica-tarifaria-id')
                this.$router.push({ name: 'politica-tarifaria-flights-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
