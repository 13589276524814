import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
      states: [],
    }
  },
  computed: {
    ...mapState({
      reservasAll: state => state.app.reservasAll,
      reservasPaginate: state => state.app.reservasPaginate,
      cotizationsAll: state => state.app.cotizationsAll,
      cotizationsPaginate: state => state.app.cotizationsPaginate,
      refundAll: state => state.app.refundAll,
      refundPaginate: state => state.app.refundPaginate,
      itemsPerPage: state => state.app.itemsPerPage,
    }),
  },
  methods: {
    ...mapMutations([
      'setItemsCont',
      'updateReservasAll',
      'setReservasPaginate',
      'setIdUserLevel0',
      'setIdUserAfiliates',
      'initFiltrosReservas',
      'setCarsProductCache',
      'setCarsProductFiltersCache',
      'setCarsContrateCache',
      'setHotelsContrateCache',
    ]),
    async fetchDataFromEndpoints() {
      const jsonReservas = {
        page: 1,
        per_page: this.itemsPerPage,
        type: 'reservas',
        search: {
          proximas: false,
          activas: false,
          state: null,
        },
      }
      const jsonCarsProduct = {
        page: 1,
        per_page: 10,
      }
      const jsonCarsContrate = {
        page: 1,
        per_page: 10,
      }
      const jsonHotelsContrate = {
        page: 1,
        per_page: 10,
      }
      try {
        const [
          resStates,
          resReservas,
          resCarsProduct,
          resCarsFiltersProduct,
          resCarsContrate,
          resHotelsContrate,
        ] = await Promise.all([
          this.axios
            .get('state_reservations?per_page=10000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('reservations/list', jsonReservas, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('cars/list', jsonCarsProduct, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('cars/all-filter', { not_public: false }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('contrate_cars/index', jsonCarsContrate, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('contrate_hotels/index', jsonHotelsContrate, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        this.states = resStates.data.data

        // eslint-disable-next-line no-empty
        if (resReservas.data.success === false) { } else {
          // this.reservas = res.data.data
          const reservasList = resReservas.data.data
          reservasList.sort((a, b) => new Date(a.date_order) - new Date(b.date_order))
          this.setItemsCont(resReservas.data.total)
          this.updateReservasAll(reservasList)
          this.setIdUserLevel0(resReservas.data.idUserLevel0)
          this.setIdUserAfiliates(resReservas.data.idUserAfiliates)
          const reserv = []
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < reservasList.length; index++) {
            const element = reservasList[index]

            if (index < this.itemsPerPage) {
              reserv.push(element)
            }
          }
          this.setReservasPaginate(reserv)

          const ids = []
          this.states.forEach(element => {
            if (element.slug === 'en-proceso' || element.slug === 'confirmada') {
              ids.push(element.id)
            }
          })
          this.initFiltrosReservas(ids)
        }

        // eslint-disable-next-line no-empty
        if (resCarsProduct.data.success === false) { } else {
          this.setCarsProductCache(resCarsProduct.data.data)
        }
        // eslint-disable-next-line no-empty
        if (resCarsFiltersProduct.data.success === false) { } else {
          const jsonResponseFilterCarsProduct = {
            notPublic: resCarsFiltersProduct.data.notPublic,
            totalInfoPendiente: resCarsFiltersProduct.data.totalInfoPendiente,
            totalSinMarca: resCarsFiltersProduct.data.totalSinMarca,
            totalSinModelo: resCarsFiltersProduct.data.totalSinModelo,
            totalSinTransmision: resCarsFiltersProduct.data.totalSinTransmision,
            totalSinCarroseria: resCarsFiltersProduct.data.totalSinCarroseria,
            totalSinCombustible: resCarsFiltersProduct.data.totalSinCombustible,
            totalSinCantPuertas: resCarsFiltersProduct.data.totalSinCantPuertas,
            totalSinCantPaxs: resCarsFiltersProduct.data.totalSinCantPaxs,
            totalSinBagSmall: resCarsFiltersProduct.data.totalSinBagSmall,
            totalSinBagBig: resCarsFiltersProduct.data.totalSinBagBig,
            totalSinUm: resCarsFiltersProduct.data.totalSinUm,
            totalSinCapacidadComb: resCarsFiltersProduct.data.totalSinCapacidadComb,
            totalSinConsumoComb: resCarsFiltersProduct.data.totalSinConsumoComb,
            totalSinMotor: resCarsFiltersProduct.data.totalSinMotor,
            totalSinCaracteristicas: resCarsFiltersProduct.data.totalSinCaracteristicas,
            totalSinPrioridad: resCarsFiltersProduct.data.totalSinPrioridad,
            totalSinFotos: resCarsFiltersProduct.data.totalSinFotos,
            totalSinTagFotos: resCarsFiltersProduct.data.totalSinTagFotos,
            totalSinTagPrincipal: resCarsFiltersProduct.data.totalSinTagPrincipal,
          }
          this.setCarsProductFiltersCache(jsonResponseFilterCarsProduct)
        }

        // eslint-disable-next-line no-empty
        if (resCarsContrate.data.success === false) { } else {
          // PARA ORDERNAR BY PROVEEDOR
          const carsContrates = resCarsContrate.data.data.sort((a, b) => {
            let OrderA = a.identificador
            if (this.user.nivel <= 0) {
              if (a.operador === 1111) {
                OrderA = a.proveedor.name_comercial
              } else {
                OrderA = a.operador
              }
            } else {
              OrderA = a.proveedor.name_comercial
            }

            let OrderB = b.identificador
            if (this.user.nivel <= 0) {
              if (b.operador === 1111) {
                OrderB = b.proveedor.name_comercial
              } else {
                OrderB = b.operador
              }
            } else {
              OrderB = b.proveedor.name_comercial
            }

            const x = OrderA
            const y = OrderB

            if (x > y) {
              return 1
            }
            if (x < y) {
              return -1
            }

            return 0
          })
          this.setCarsContrateCache(carsContrates)
        }

        // eslint-disable-next-line no-empty
        if (resHotelsContrate.data.success === false) { } else {
          // PARA ORDERNAR BY PROVEEDOR
          const hotelsContrate = resHotelsContrate.data.data.sort((a, b) => {
            let OrderA = a.identificador
            if (this.user.nivel <= 0) {
              if (a.operador === 1111) {
                OrderA = a.proveedor
              } else {
                OrderA = a.operador
              }
            } else {
              OrderA = a.proveedor
            }

            let OrderB = b.identificador
            if (this.user.nivel <= 0) {
              if (b.operador === 1111) {
                OrderB = b.proveedor
              } else {
                OrderB = b.operador
              }
            } else {
              OrderB = b.proveedor
            }

            const x = OrderA
            const y = OrderB

            if (x > y) {
              return 1
            }
            if (x < y) {
              return -1
            }

            return 0
          })

          this.setHotelsContrateCache(hotelsContrate)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      // eslint-disable-next-line no-empty
      } finally { }
    },
  },
  mounted() {
    this.fetchDataFromEndpoints()
  },
}
