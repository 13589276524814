<template>
  <v-navigation-drawer
    :value="isSidebarDataAPIActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-api-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          API
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-api-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="api.api_name"
                :label="`${$t('lbl.name')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>

            <v-col
              v-if="api.slug !== 'hotetecapi' &&
                api.slug !== 'dingusapi' &&
                api.slug !== 'solwaysapi' &&
                api.slug !== 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="api.key"
                :label="api.slug === 'sibs' ? 'Client-ID' : 'KEY'"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="api.token"
                label="Access Token"
                outlined
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col
              v-if="api.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="api.terminal"
                :label="`${$t('lbl.merchantTerminal')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="api.mercantil_code"
                :label="`${$t('lbl.merchantTransactionId')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientsApis[0].client_id"
                label="Client-ID"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientsApis[0].client_secret"
                label="Client-Secret"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>

            <fragment v-if="api.slug === 'redsys'">
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="api.mercantil_code"
                  :label="`${$t('lbl.merchantCode')}`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="api.terminal"
                  :label="`${$t('lbl.merchantTerminal')}`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="api.currency"
                  :items="currencies"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.currency')"
                  outlined
                  clearable
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </fragment>

            <v-col
              v-if="api.slug !== 'hotetecapi' &&
                api.slug !== 'dingusapi' &&
                api.slug !== 'solwaysapi' &&
                api.slug !== 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="api.url_prod"
                label="URL"
                outlined
                dense
                hide-details="auto"
                :disabled="disabledUrl"
              ></v-text-field>
            </v-col>

            <v-col
              v-if="api.slug === 'hotetecapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-hotetec`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-hotetec`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-hotetec` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'dingusapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-dingus`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-dingus`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-dingus` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
            <v-col
              v-if="api.slug === 'solwaysapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-solways`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-solways`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-solways` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
                @change="setLogo"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="api.description"
                :label="$t('lbl.description')"
                outlined
                rows="3"
                row-height="3"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row v-if="api.slug === 'hotetecapi' || api.slug === 'dingusapi' || api.slug === 'solwaysapi'">
            <v-col
              cols="12"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="gestionarProv"
              >
                {{ $t('btn.gestionarProveedores') }}
              </v-btn>
            </v-col>
            <!--<v-col
              cols="12"
              md="9"
            >
              <b>Proveedores</b>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="addProveedorApi()"
                  >
                    <v-icon small>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <DataProveedor
                v-for="(proveedor, indP) in proveedorApis"
                :key="indP"
                :proveedor="proveedor"
                :proveedores="proveedores"
                :pos="indP"
              />
            </v-col>-->
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-api-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="camposVerify"
                :loading="loadingBtn"
                @click="$emit('confirmEdit')"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import DataProveedor from './DataProveedor.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DataProveedor,
  },
  model: {
    prop: 'isSidebarDataAPIActive',
    event: 'update:is-sidebar-data-api-active',
  },
  props: {
    isSidebarDataAPIActive: {
      type: Boolean,
      required: true,
    },
    proveedores: {
      type: Array,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    type_api: {
      type: String,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      currencies: [],
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    camposVerify() {
      let rest = false
      if (!this.api.api_name || !this.api.key || !this.api.url_prod) {
        rest = true
      }

      return rest
    },
    disabledUrl() {
      let rest = true
      if (this.api.slug === 'deeplapi') {
        rest = false
      }

      return rest
    },
  },
  created() {
    this.currencies.push({
      id: '840',
      name: 'USD',
    })
    this.currencies.push({
      id: '978',
      name: 'EUR',
    })

    if (this.api.proveedores) {
      this.updateProveedorApi(this.api.proveedores)
    } else {
      this.updateProveedorApi([])
    }

    if (this.api.clients) {
      this.updateClientApi(this.api.clients)
    } else {
      this.addClientApi()
    }
  },
  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    setLogo() {
      if (this.logo) {
        const formData = new FormData()
        formData.append('logo', this.logo)
        this.axios
          .post('api_extern/upload-logo', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.api.logo = res.data.data.dir
            }
          })
          .catch(error => console.log(error))
      }
    },
    gestionarProv() {
      localStorage.setItem('api-id', this.api.id)
      this.$router.push({ name: 'api-keys-proveedors' })
    },
    copyToClipboard(copyText) {
      // const copyText = document.getElementById(id).textContent
      navigator.clipboard.writeText(copyText)

      this.$toast.info(this.$t('msg.copyText', { text: copyText }))
    },
  },
}
</script>
