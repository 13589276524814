<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="changeCantItems()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="getCuentasXCobrar()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.from') }}</strong>
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.date')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.from"
              no-title
              locale="es"
              :show-current="false"
              @input="menuFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.to') }}</strong>
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :label="$t('lbl.date')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.to"
              :min="
                search.from
                  ? $moment(search.from)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              no-title
              locale="es"
              :show-current="false"
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.modality') }}</strong>
          <v-select
            v-model="search.method_pay_id"
            :items="payments"
            :label="$t('lbl.wayMethod')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-2"
          ></v-select>
        </v-col>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.afiliate') }}</strong>
          <v-autocomplete
            v-model="search.deudor_id"
            :items="deudores"
            :search-input.sync="deudorSearch"
            hide-details
            hide-selected
            :label="$t('lbl.deudor')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.deudor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="4"
        >
          <strong>{{ $t('menu.ctaXC') }}</strong>
          <v-autocomplete
            v-model="search.code"
            :items="codesCtas"
            :search-input.sync="searchCodeCtas"
            hide-details
            hide-selected
            :label="$t('lbl.code')"
            outlined
            dense
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.code') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.stateAccount') }}</strong>
          <v-select
            v-model="search.status_id"
            :items="statesCtas"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-2"
          ></v-select>
        </v-col>

        <!--PAYS-->
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.code') }}</strong>
          <v-autocomplete
            v-model="search.code"
            :items="codesPays"
            :search-input.sync="searchCodePays"
            hide-details
            hide-selected
            :label="$t('lbl.code')"
            outlined
            dense
            single-line
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.code') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.recibidoFrom') }}</strong>
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.dateRecibido')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.from"
              no-title
              locale="es"
              :show-current="false"
              @input="menuFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.recibidoTo') }}</strong>
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :label="$t('lbl.dateRecibido')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.to"
              :min="
                search.from
                  ? $moment(search.from)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              no-title
              locale="es"
              :show-current="false"
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.deudor') }}</strong>
          <v-autocomplete
            v-model="search.deudor"
            :items="acredores"
            :search-input.sync="acredorSearch"
            hide-details
            hide-selected
            :label="$t('lbl.deudorAfiliate')"
            outlined
            dense
            item-text="user_name"
            item-value="company_name"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.deudorAfiliate') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.user_name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.user_name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.state') }}</strong>
          <v-select
            v-model="search.status_pay_id"
            :items="statusPay"
            :label="$t('lbl.statusPay')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-2"
          ></v-select>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.cobradoFrom') }}</strong>
          <v-menu
            v-model="menuFromCobro"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromCobroDateFormatted"
                :label="$t('lbl.dateCobrado')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.fromCobro"
              no-title
              locale="es"
              :show-current="false"
              @input="menuFromCobro = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.cobradoTo') }}</strong>
          <v-menu
            v-model="menuToCobro"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToCobroDateFormatted"
                :label="$t('lbl.dateCobrado')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.toCobro"
              :min="
                search.fromCobro
                  ? $moment(search.fromCobro)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              no-title
              locale="es"
              :show-current="false"
              @input="menuToCobro = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.cobradoBy') }}</strong>
          <v-autocomplete
            v-model="search.gestorAcreditoCobro"
            :items="gestoresAcreditan"
            :search-input.sync="gestoresAcreditanSearch"
            hide-details
            hide-selected
            :label="$t('lbl.gestorAcreditoPay')"
            outlined
            dense
            item-text="user_name"
            item-value="user_id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.gestorAcreditoPay') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.user_name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.user_name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.wayMethod') }}</strong>
          <v-select
            v-model="search.slug_pay"
            :items="wayPays"
            :label="$t('lbl.wayMethod')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="slug"
            class="pt-2"
          ></v-select>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('register.moneda') }}</strong>
          <v-select
            v-model="search.currency"
            :items="currencies"
            :label="$t('register.moneda')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="slug"
            class="pt-2"
          ></v-select>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.importe') }}</strong>
          <v-autocomplete
            v-model="search.importe"
            :items="importes"
            :search-input.sync="importesSearch"
            hide-details
            hide-selected
            :label="$t('lbl.importePay')"
            outlined
            dense
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.importePay') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="pos === 1"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.emitidoBy') }}</strong>
          <v-autocomplete
            v-model="search.emitido_by"
            :items="usersEmitidos"
            :search-input.sync="usersEmitidosSearch"
            hide-details
            hide-selected
            :label="$t('lbl.emitidoBy')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.emitidoBy') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              search.from ||
                search.to ||
                search.method_pay_id ||
                search.deudor_id ||
                search.code ||
                search.status_id ||
                search.deudor ||
                search.status_pay_id ||
                search.fromCobro ||
                search.toCobro ||
                search.gestorAcreditoCobro ||
                search.slug_pay ||
                search.currency ||
                search.importe ||
                search.emitido_by
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="2"
              offset-md="2"
            >
              <v-btn
                :loading="loading"
                color="primary"
                @click="searchAll()"
              >
                <v-icon
                  right
                  dark
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
                <span class="pl-5">{{ $t('btn.search') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="pos === 0"
              cols="12"
              md="2"
              offset-md="1"
            >
              <v-btn
                :loading="loadingPay"
                color="primary"
                outlined
                :disabled="itemsCheckCobros.length === 0"
                @click="gestionarCobros()"
              >
                <v-icon
                  right
                  dark
                >
                  {{ icons.mdiSendOutline }}
                </v-icon>
                <span class="pl-5">{{ $t('btn.gestionarCobros') }}</span>
              </v-btn>
            </v-col>

            <fragment v-if="pos === 1">
              <v-col
                cols="12"
                md="2"
                offset-md="1"
              >
                <v-btn
                  :loading="loadingPay"
                  color="primary"
                  outlined
                  :disabled="itemsCheckPaysRec.length === 0"
                >
                  <v-icon
                    right
                    dark
                  >
                    {{ icons.mdiSendOutline }}
                  </v-icon>
                  <span class="pl-5">{{ $t('btn.sendPays') }}</span>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="2"
                offset-md="1"
              >
                <v-btn
                  :loading="loadingPay"
                  color="primary"
                  outlined
                  :disabled="itemsCheckPaysRec.length === 0"
                >
                  <v-icon
                    right
                    dark
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <span class="pl-5">{{ $t('btn.printPays') }}</span>
                </v-btn>
              </v-col>
            </fragment>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-lowercase"
        >
          <span v-if="pos === 0">
            {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} <span class="text-lower">{{ $t('menu.ctasXC') }}</span>
          </span>
          <span v-if="pos === 1">
            {{ totalFPays }} {{ $t('lbl.de') }} {{ totalItemsPays }}
            <span class="text-lower">{{ $t('menu.paysRec') }}</span>
          </span>
        </v-col>
      </v-row>
    </v-form>

    <v-tabs v-model="pos">
      <v-tab>
        <v-badge
          v-if="cantCtasPending > 0"
          color="warning"
          :content="cantCtasPending"
        >
          {{ $t('menu.cuentasXCobrar') }}
        </v-badge>
        <fragment v-else>
          {{ $t('menu.cuentasXCobrar') }}
        </fragment>
      </v-tab>
      <v-tab>
        <v-badge
          v-if="cantPaysPending > 0"
          color="warning"
          :content="cantPaysPending"
        >
          {{ $t('menu.paysRec') }}
        </v-badge>
        <fragment v-else>
          {{ $t('menu.paysRec') }}
        </fragment>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="pos">
      <v-tab-item @click="setPosCotizador(0)">
        <CuentasXCobrar
          v-if="ctas.length > 0"
          :user="user"
          :ctas="ctas"
          :states="states"
          :states-ctas="statesCtas"
          :id-status-cobrado="idStatusCobrado"
          :payments="payments"
          @loadAll="initAll()"
        />
      </v-tab-item>
      <v-tab-item @click="setPosCotizador(1)">
        <PaysRecibidos
          v-if="pays.length > 0"
          :user="user"
          :pays="pays"
          :states="states"
          :states-ctas="statesCtas"
          :payments="payments"
          @loadAll="loadAll()"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider></v-divider>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-if="pos === 0"
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
          <v-pagination
            v-else-if="pos === 1"
            v-model="paginationPays.current"
            :length="paginationPays.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <SidebarSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      from="pays"
      :user="user"
      :nameb2b="nameb2b"
      :nameafiliado="nameafiliado"
      :codes="codes"
      @initAll="initAll"
    />
    <SidebarPPPPPSend
      v-if="isSidebarPPPPPSendActive"
      v-model="isSidebarPPPPPSendActive"
      from="pays"
      :user="user"
      :nameb2b="nameb2b"
      :nameafiliado="nameafiliado"
      :codes="codes"
      :no-conciliacion="noConciliacion"
      :email-conciliacion="emailConciliacion"
      :monto-all="montoAll"
      :moneda="'USD'"
      :ctas-count="ctasCount"
      @initAll="initAll"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiSendOutline,
  mdiPrinter,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import useAppConfig from '@core/@app-config/useAppConfig'

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import PaysRecibidos from '@/views/administration/paysRecibidos/utils/PaysRecibidos.vue'
import CuentasXCobrar from '../utils/CuentasXCobrar.vue'
import SidebarSend from '../utils/SidebarSend.vue'
// eslint-disable-next-line import/no-unresolved
import SidebarPPPPPSend from '../utils/SidebarPPPPPSend.vue'

export default {
  /* setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  }, */

  components: {
    CuentasXCobrar,
    SidebarSend,
    SidebarPPPPPSend,
    PaysRecibidos,
  },

  data() {
    return {
      isSidebarSendActive: false,
      isSidebarPPPPPSendActive: false,
      nameb2b: null,
      name_project: process.env.VUE_APP_PROJ_NAME,
      nameafiliado: null,
      codes: '',
      currentTab: 0,
      isLoading: true,
      search: {
        proximas: false,
        activas: false,
        state: null,
        afiliate: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
      ctas: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiSendOutline,
        mdiPrinter,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      states: [],
      statesCtas: [],
      idStatusCobrado: null,
      payments: [],
      checkPermiso: 1,
      loading: false,
      loadingPay: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      codesList: [],
      codesCtas: [],
      searchCodeCtas: null,
      deudorList: [],
      deudores: [],
      deudorSearch: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFrom: false,
      menuTo: false,
      menuFromCobro: false,
      menuToCobro: false,
      pos: 0,
      pays: [],
      paginationPays: {
        current: 1,
        total: 0,
      },
      codesPaysList: [],
      codesPays: [],
      searchCodePays: null,
      acredoresList: [],
      acredores: [],
      acredorSearch: null,
      statusPay: [],
      wayPays: [],
      currencies: [],
      gestoresAcreditanList: [],
      gestoresAcreditan: [],
      gestoresAcreditanSearch: null,
      importes: [],
      importesList: [],
      importesSearch: null,
      usersEmitidos: [],
      usersEmitidosList: [],
      usersEmitidosSearch: null,

      emailConciliacion: null,
      noConciliacion: null,
      montoAll: null,
      ctasCount: null,
    }
  },
  computed: {
    ...mapState({
      itemsCheckCobros: state => state.app.itemsCheckCobros,
      itemsCheckPaysRec: state => state.app.itemsCheckPaysRec,
      posCotizador: state => state.app.posCotizador,
    }),
    computedFromDateFormatted() {
      return this.search.from ? this.$moment(this.search.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.search.to ? this.$moment(this.search.to).format('D-MMM-YY') : ''
    },
    computedFromCobroDateFormatted() {
      return this.search.fromCobro ? this.$moment(this.search.fromCobro).format('D-MMM-YY') : ''
    },
    computedToCobroDateFormatted() {
      return this.search.toCobro ? this.$moment(this.search.toCobro).format('D-MMM-YY') : ''
    },

    cantCtasPending() {
      let cant = 0
      this.ctas.forEach(element => {
        if (element.status.slug === 'pendiente') {
          cant += 1
        }
      })

      return cant
    },
    cantPaysPending() {
      let cant = 0
      this.pays.forEach(element => {
        if (element.status.slug === 'pendiente') {
          cant += 1
        }
      })

      return cant
    },
  },
  watch: {
    searchCodeCtas(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodesCtas(val.toLowerCase())
      } else {
        this.codesCtas = []
      }
    },
    deudorSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDeudor(val.toLowerCase())
      } else {
        this.deudores = []
      }
    },

    searchCodePays(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodesPays(val.toLowerCase())
      } else {
        this.codesPays = []
      }
    },
    acredorSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAcredor(val.toLowerCase())
      } else {
        this.acredores = []
      }
    },
    gestoresAcreditanSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterGestoresAcreditan(val.toLowerCase())
      } else {
        this.gestoresAcreditan = []
      }
    },
    importesSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterImportes(val.toLowerCase())
      } else {
        this.importes = []
      }
    },

    usersEmitidosSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterUsersEmitidos(val.toLowerCase())
      } else {
        this.usersEmitidos = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
    // this.pos = this.posCotizador
  },
  created() {
    this.profile()
    this.initAll()

    this.getStates()
    this.getStatesCtas()
    this.getPayments()
    this.getDeudores()
    this.getCodes()

    this.getCodesPays()
    this.getAcredorPays()
    this.getStatesPays()
    this.getGestoresAcreditan()
    this.getUserEmitidoPays()
    this.getImportesPays()

    this.wayPays.push({
      name: this.$t('lbl.transferBank'),
      slug: 'transfer',
    })
    this.wayPays.push({
      name: this.$t('lbl.credit'),
      slug: 'credit',
    })
    this.wayPays.push({
      name: this.$t('lbl.payCard'),
      slug: 'payCard',
    })
    this.wayPays.push({
      name: this.$t('lbl.linkPay'),
      slug: 'linkPay',
    })
    this.wayPays.push({
      name: this.$t('lbl.checkBank'),
      slug: 'checkBank',
    })
    this.wayPays.push({
      name: this.$t('lbl.cash'),
      slug: 'cash',
    })

    this.currencies.push({
      name: 'USD',
      slug: 'USD',
    })
    this.currencies.push({
      name: 'EUR',
      slug: 'EUR',
    })
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    filterDeudor(v) {
      this.deudores = []
      if (v === '') {
        this.deudores = []
      } else {
        this.deudores = this.deudorList.filter(e => e.name.toLowerCase())
      }
    },
    filterCodesCtas(v) {
      this.codesCtas = []
      if (v === '') {
        this.codesCtas = []
      } else {
        this.codesCtas = this.codesList.filter(e => e.toLowerCase())
      }
    },

    filterCodesPays(v) {
      this.codesPays = []
      if (v === '') {
        this.codesPays = []
      } else {
        this.codesPays = this.codesPaysList.filter(e => e.toLowerCase())
      }
    },
    filterAcredor(v) {
      this.acredores = []
      if (v === '') {
        this.acredores = []
      } else {
        this.acredores = this.acredoresList.filter(e => e.user_name.toLowerCase())
      }
    },
    filterGestoresAcreditan(v) {
      this.gestoresAcreditan = []
      if (v === '') {
        this.gestoresAcreditan = []
      } else {
        this.gestoresAcreditan = this.gestoresAcreditanList.filter(e => e.user_name.toLowerCase())
      }
    },
    filterImportes(v) {
      this.importes = []
      if (v === '') {
        this.importes = []
      } else {
        this.importes = this.importesList.filter(e => e)
      }
    },
    filterUsersEmitidos(v) {
      this.usersEmitidos = []
      if (v === '') {
        this.usersEmitidos = []
      } else {
        this.usersEmitidos = this.usersEmitidosList.filter(e => e.name.toLowerCase())
      }
    },

    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
          if (this.user.company) {
            if (this.user.company.name) {
              this.nameafiliado = this.user.company.name
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.getConfig())
    },
    getConfig() {
      this.axios
        .get('configuration-view/data-general', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res.data.data.data.data)
          if (res.data.success) {
            this.nameb2b = res.data.data.data.data.name
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
    },
    getStatesCtas() {
      this.axios
        .get('state_ctas?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.statesCtas = res.data.data
          this.statesCtas.forEach(element => {
            if (element.slug === 'cobrado') {
              this.idStatusCobrado = element.id
            }
          })
        })
    },
    getPayments() {
      this.axios
        .get('nom_methods_pay?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.payments = res.data.data
        })
    },
    getDeudores() {
      this.axios
        .post(
          'ctas-x-cobrar/search-list',
          {
            parameter: 'deudor',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.deudorList = res.data.data.list
          }
        })
    },
    getCodes() {
      this.axios
        .post(
          'ctas-x-cobrar/search-list',
          {
            parameter: 'code',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codesList = res.data.data.list
          }
        })
    },

    getCodesPays() {
      this.axios
        .post(
          'pays-recibidos/search-list',
          {
            parameter: 'code',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codesPaysList = res.data.data.list
          }
        })
    },
    getAcredorPays() {
      this.axios
        .post(
          'pays-recibidos/search-list',
          {
            parameter: 'deudor',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.acredoresList = res.data.data.list
          }
        })
    },
    getStatesPays() {
      this.axios
        .get('state_pays?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.statusPay = res.data.data
        })
    },
    getGestoresAcreditan() {
      this.axios
        .post(
          'pays-recibidos/search-list',
          {
            parameter: 'gestor-acredita',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.gestoresAcreditanList = res.data.data.list
          }
        })
    },
    getUserEmitidoPays() {
      this.axios
        .post(
          'pays-recibidos/search-list',
          {
            parameter: 'user_emitido',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.usersEmitidosList = res.data.data.list
          }
        })
    },
    getImportesPays() {
      this.axios
        .post(
          'pays-recibidos/search-list',
          {
            parameter: 'importe',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.importesList = res.data.data.list
          }
        })
    },

    getCuentas() {
      this.ctas = []
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('ctas-x-cobrar/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // console.log(res)

            this.ctas = res.data.data

            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    changeCantItems() {
      if (this.pos === 0) {
        this.getCuentas()
      } else if (this.pos === 1) {
        this.getPays()
      }
    },
    searchAll() {
      this.pagination.current = 1
      this.paginationPays.current = 1
      if (this.pos === 0) {
        this.getCuentas()
      } else if (this.pos === 1) {
        this.getPays()
      }
    },
    getCuentasAll() {
      const json = {
        page: this.pagination.current,
        per_page: 1000,
        search: this.search,
      }
      this.axios
        .post('ctas-x-cobrar/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      if (this.pos === 0) {
        this.getCuentas()
      } else if (this.pos === 1) {
        this.getPays()
      }
    },

    getPays() {
      const json = {
        page: this.paginationPays.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('pays-recibidos/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // console.log(res)

            this.pays = res.data.data

            this.paginationPays.current = res.data.meta.current_page
            this.paginationPays.total = res.data.meta.last_page

            this.totalFPays = res.data.meta.total
          }
        })
    },
    getPaysAll() {
      const json = {
        page: this.paginationPays.current,
        per_page: 1000,
        search: this.search,
      }
      this.axios
        .post('pays-recibidos/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItemsPays = res.data.meta.total
          }
        })
    },

    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.loadAll()
    },
    loadAll() {
      this.getCuentas()
      this.getPays()
    },
    initAll() {
      this.getCuentas()
      this.getCuentasAll()
      this.getPays()
      this.getPaysAll()
    },

    clearSearch() {
      this.search = {}
      this.loadAll()
    },

    gestionarCobros() {
      this.codes = ''
      let monto = 0
      let ema = ''

      // console.log(this.itemsCheckCobros)
      this.itemsCheckCobros.forEach(element => {
        this.codes = `${this.codes}<br />${element.code}`
        monto += element.monto
        ema = element.company.email
      })
      if (this.name_project === 'ppppp' || this.name_project === 'wwwww') {
        this.montoAll = monto
        this.emailConciliacion = ema
        this.ctasCount = this.itemsCheckCobros.length

        // OBTENER EL NO CONCILIACION
        const json = {
          email: this.itemsCheckCobros[0].company.email,
          user_name: this.itemsCheckCobros[0].company.user_name,
        }
        this.axios
          .post('afiliados/no_conciliation', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.noConciliacion = res.data.data.conciliation.code
            }
          })
          .finally(() => {
            this.isSidebarPPPPPSendActive = true
          })
      } else {
        this.isSidebarSendActive = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
