<template>
  <fragment>
    <v-col
      cols="12"
      md="12"
    >
      <v-row v-if="pos === 0">
        <v-col
          cols="12"
          md="1"
          offset-md="7"
          class="my-input-title"
        >
          <v-text-field
            label="ADL"
            hide-details
            disabled
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="my-input-title"
        >
          <!--<v-text-field
            label="TEEN"
            hide-details
            disabled
          ></v-text-field>-->
          <v-switch
            v-model="room.has_a_teen"
            label="TEEN"
            hide-details
            :disabled="room.has_a_mnr || room.has_a_inf"
            @change="changeHasTeen"
          ></v-switch>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="my-input-title"
        >
          <!--<v-text-field
            label="MNR"
            hide-details
            disabled
          ></v-text-field>-->
          <v-switch
            v-model="room.has_a_mnr"
            label="MNR"
            hide-details
            :disabled="room.has_a_teen"
            @change="changeHasMnr"
          ></v-switch>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="my-input-title"
        >
          <!--<v-text-field
            label="INF"
            hide-details
            disabled
          ></v-text-field>-->
          <v-switch
            v-model="room.has_a_inf"
            label="INF"
            hide-details
            :disabled="room.has_a_teen"
            @change="changeHasInf"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <!--@change="verifyRoomsPlanAge"-->
          <v-select
            v-model="room.rooms_id"
            :disabled="$store.state.app.onlyShow"
            :items="roomsFree"
            :label="$t('lbl.rooms')"
            class="my-input mr-2"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            multiple
            @mouseover.native="focus()"
            @change="
              verifyRoomsPriceDBL();
              verifyRoomsPriceDBLAllTarifas();
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.rooms') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <!--<template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ `${item.name} (Code: ${item.id})` }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ `${item.name} (Code: ${item.id})` }}
            </template>-->
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-row
            no-gutters
          >
            <v-col cols="10">
              <v-select
                v-model="room.planes_id"
                :items="planes"
                :label="`${$t('lbl.plan')}es`"
                class="my-input"
                outlined
                dense
                hide-details
                item-text="siglas"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.regimen') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.adultAge.from"
            :label="$t('lbl.from')"
            outlined
            dense
            hide-details
            type="number"
            @keyup="verifyNA($event, room.adultAge.from, 'adultAgeFrom')"
            @input="val => (room.adultAge.from = room.adultAge.from.toUpperCase())"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.teenAge.from"
            :label="$t('lbl.from')"
            outlined
            dense
            hide-details
            :disabled="!room.has_a_teen"
            @keydown="checkContaint($event, 'teenAgeFrom')"
            @keypress="checkAge($event, room.teenAge.from, 'teenAgeFrom')"
            @keyup="verifyNA($event, room.teenAge.from, 'teenAgeFrom')"
            @input="val => (room.teenAge.from = room.teenAge.from.toUpperCase())"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.childAge.from"
            :label="$t('lbl.from')"
            outlined
            dense
            hide-details
            :disabled="seasonContrateHotel.only_adult || !room.has_a_mnr"
            @keydown="checkContaint($event, 'childAgeFrom')"
            @keypress="checkAge($event, room.childAge.from, 'childAgeFrom')"
            @keyup="verifyNA($event, room.childAge.from, 'childAgeFrom')"
            @input="room.childAge.from = room.childAge.from.toUpperCase()"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.infantAge.from"
            :label="$t('lbl.from')"
            outlined
            dense
            hide-details
            :disabled="seasonContrateHotel.only_adult || !room.has_a_inf"
            @keydown="checkContaint($event, 'infantAgeFrom')"
            @keypress="checkAge($event, room.infantAge.from, 'infantAgeFrom')"
            @input="
              room.infantAge.from = room.infantAge.from.toUpperCase()
              verifyNA($event, room.infantAge.from, 'infantAgeFrom')
            "
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="6"
        >
          <SeasonCuposOcupationHotel
            v-for="(ocupa, indC) in room.ocupations"
            :key="indC"
            :pos="indC"
            :pos-room="pos"
            :ocupation="ocupa"
          />
        </v-col>-->
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="1"
          offset-md="8"
        >
          <v-text-field
            v-model="room.teenAge.to"
            :label="$t('lbl.to')"
            outlined
            dense
            hide-details
            :disabled="!room.has_a_teen"
            @keypress="checkAge($event, room.teenAge.to, 'teenAgeTo')"
            @input="val => (room.teenAge.to = room.teenAge.to.toUpperCase())"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.childAge.to"
            :label="$t('lbl.to')"
            outlined
            dense
            hide-details
            :disabled="!room.has_a_mnr"
            @keypress="checkAge($event, room.childAge.to, 'childAgeTo')"
            @input="val => (room.childAge.to = room.childAge.to.toUpperCase())"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="room.infantAge.to"
            :label="$t('lbl.to')"
            outlined
            dense
            hide-details
            :disabled="!room.has_a_inf"
            @keypress="checkAge($event, room.infantAge.to, 'infantAgeTo')"
            @input="val => (room.infantAge.to = room.infantAge.to.toUpperCase())"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    planHotel: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotetec: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    dingus: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      seasonContrateHotel: state => state.app.seasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      this.roomsHotel.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].rooms_id.includes(element.id) && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      this.roomsHotel.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].rooms_id.includes(element.id)) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }
  },
  methods: {
    ...mapMutations([
      'deleteRoomSeasonContrateHotel',
      'replicaPlanRoomSeasonContrateHotel',
      'addRoomSeasonContrateHotel',
      'verifyRoomsPlanAge',
      'verifyRoomsPriceDBL',
      'verifyRoomsPriceDBLAllTarifas',
    ]),
    changeHasTeen() {
      if (!this.room.has_a_teen) {
        this.room.teenAge.from = 'N/A'
        this.room.teenAge.to = 'N/A'
      } else {
        this.room.has_a_mnr = false
        this.room.childAge.from = 'N/A'
        this.room.childAge.to = 'N/A'
        this.room.has_a_inf = false
        this.room.infantAge.from = 'N/A'
        this.room.infantAge.to = 'N/A'
      }
    },
    changeHasMnr() {
      if (!this.room.has_a_mnr) {
        this.room.childAge.from = 'N/A'
        this.room.childAge.to = 'N/A'
      }
    },
    changeHasInf() {
      if (!this.room.has_a_inf) {
        this.room.infantAge.from = 'N/A'
        this.room.infantAge.to = 'N/A'
      }
    },
    focus() {
      if (this.room.room_id) {
        this.nameRoom = this.allRooms.filter(e => e.id === this.room.room_id)[0].name
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 1000)
      }
    },
    filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    setRoom() {
      this.room.name = this.roomsHotel.filter(e => e.id === this.room.room_id)[0].name
    },
    iconSet() {
      if (this.room.plan_id) {
        this.replicaPlanRoomSeasonContrateHotel({ pos: this.pos, plan_id: this.room.plan_id })
      }
    },
    checkAge(elEvento, model, typeAge) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0 || model.includes('N') || model.includes('/') || model.includes('A'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (model.includes('N') || model.includes('/') || model.includes('A')) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }

      // VERIFICAR SI ES UN VALOR NUMERICO
      if (typeAge === 'teenAgeFrom') {
        if ((elEvento.keyCode >= 48 && elEvento.keyCode <= 57) || (elEvento.keyCode >= 96 && elEvento.keyCode <= 105)) {
          this.room.childAge.from = 'N/A'
          this.room.childAge.to = 'N/A'
          this.room.infantAge.from = 'N/A'
          this.room.infantAge.to = 'N/A'
        }
      }
    },
    verifyNA(elEvento, model, typeAge) {
      if (typeAge === 'adultAgeFrom') {
        if (this.room.adultAge.from && this.room.adultAge.from !== 'N/A') {
          if (this.room.teenAge.from !== 'N/A') {
            this.room.teenAge.to = (parseFloat(this.room.adultAge.from) - 0.01).toFixed(2)
          } else if (this.room.childAge.from !== 'N/A') {
            this.room.childAge.to = (parseFloat(this.room.adultAge.from) - 0.01).toFixed(2)
          }
        }

        // PARA CONCATENAR EL .00
        /* if (elEvento.keyCode >= 48 || elEvento.keyCode <= 57) {
          if (
            this.room.adultAge.from
            && parseFloat(this.room.adultAge.from) > 0
            && parseFloat(this.room.adultAge.from) <= 100
          ) {
            this.room.adultAge.from = `${parseInt(this.room.adultAge.from, 10)}.00`
          }
        } */
      } else if (typeAge === 'childAgeFrom') {
        if (this.room.childAge.from === 'N/A') {
          this.room.childAge.to = 'N/A'
          this.room.has_a_mnr = false
        } else if (this.room.childAge.from && this.room.childAge.from !== 'N/A') {
          if (this.room.infantAge.from !== 'N/A') {
            this.room.infantAge.to = parseFloat(this.room.childAge.from) - 0.01
          }
          if (this.room.teenAge.from !== 'N/A') {
            this.room.childAge.to = parseFloat(this.room.teenAge.from) - 0.01
          } else if (this.room.adultAge.from) {
            this.room.childAge.to = parseFloat(this.room.adultAge.from) - 0.01
          }
        }

        // PARA CONCATENAR EL .00
        if ((elEvento.keyCode >= 48 && elEvento.keyCode <= 57) || (elEvento.keyCode >= 96 && elEvento.keyCode <= 105)) {
          this.room.has_a_mnr = true
          if (this.room.infantAge.from !== 'N/A') {
            this.room.has_a_inf = true
          }

          /* if (
            this.room.childAge.from
            && parseFloat(this.room.childAge.from) > 0
            && parseFloat(this.room.childAge.from) <= 100
          ) {
            this.room.childAge.from = `${parseInt(this.room.childAge.from, 10)}.00`
          } */
        }
      } else if (typeAge === 'infantAgeFrom') {
        if (this.room.infantAge.from === 'N/A') {
          this.room.infantAge.to = 'N/A'
          this.room.has_a_inf = false
        } else {
          this.room.has_a_inf = true
          this.room.infantAge.to = parseFloat(this.room.childAge.from) - 0.01
        }
      } else if (typeAge === 'teenAgeFrom') {
        if (this.room.teenAge.from === 'N/A') {
          this.room.teenAge.to = 'N/A'
        }
        if (this.room.childAge.from === 'N/A') {
          this.room.childAge.to = 'N/A'
        }
        if (this.room.teenAge.from && this.room.teenAge.from !== 'N/A' && this.room.adultAge.from) {
          this.room.teenAge.to = parseFloat(this.room.adultAge.from) - 0.01
        }

        // PARA CONCATENAR EL .00
        if ((elEvento.keyCode >= 48 && elEvento.keyCode <= 57) || (elEvento.keyCode >= 96 && elEvento.keyCode <= 105)) {
          this.room.has_a_teen = true
          this.room.has_a_mnr = false
          this.room.has_a_inf = false

          /* if (
            this.room.teenAge.from
            && parseFloat(this.room.teenAge.from) > 0
            && parseFloat(this.room.teenAge.from) <= 100
          ) {
            this.room.teenAge.from = `${parseInt(this.room.teenAge.from, 10)}.00`
          } */
        }
      }
    },
    checkContaint(elEvento, typeAge) {
      setTimeout(() => {
        if (elEvento.key === 'A' || elEvento.key === 'a') {
          if (typeAge === 'teenAgeFrom') {
            if (this.room.teenAge.from === 'N/A') {
              this.room.has_a_teen = false
            }
          } else if (typeAge === 'childAgeFrom') {
            if (this.room.childAge.from === 'N/A') {
              this.room.has_a_mnr = false
            } else if (Number.isInteger(elEvento.key)) {
              this.room.has_a_teen = false
            }
          } else if (typeAge === 'infantAgeFrom') {
            if (this.room.infantAge.from === 'N/A') {
              this.room.has_a_inf = false
            } else if (Number.isInteger(elEvento.key)) {
              this.room.has_a_teen = false
            }
          }
        }
      }, 300)
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}

::v-deep .my-input-title .v-label {
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 18px !important;
  padding-left: 12px !important;
  max-width: 100%;
}
</style>
