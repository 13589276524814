<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <PagesWeb />
    </v-col>
  </v-row>
</template>

<script>
import PagesWeb from './tablas/PagesWeb.vue'

export default {
  components: {
    PagesWeb,
  },
}
</script>
