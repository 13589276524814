<template>
  <div>
    <!-- Desktop -->
    <div class="d-none d-md-block">
      <v-app-bar
        elevation="0"
        class="px-16"
        style="border-top: 1px solid #d9d9d9; border-bottom: 1px solid #d9d9d9; z-index: 999;"
        fixed
      >
        <div style="width: 155px; height:64px">
          <v-img
            :lazy-src="`${urlS3}wamasolplus-03.svg`"
            :src="`${urlS3}wamasolplus-03.png`"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <a
          class="pr-5"
          @click="scrollInto('home')"
        >
          <p class="mb-0 menuSupLinkMine OpenSansRegular">{{ $t('landing.menu.home') }}</p>
        </a>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <p
              class="mb-0 menuSupLinkMine standarMine OpenSansRegular pr-5"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('landing.menu.about') }}<v-icon>{{ icons.mdiChevronDown }} </v-icon>
            </p>
          </template>
          <v-list>
            <v-list-item>
              <a @click="scrollInto('conecta')">
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.plataform') }}</p>
              </a>
            </v-list-item>
            <v-list-item>
              <a @click="scrollInto('orientada')">
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.afiliate') }}</p>
              </a>
            </v-list-item>
            <v-list-item>
              <a @click="scrollInto('div')">
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.marcas') }}</p>
              </a>
            </v-list-item>
            <!--<v-list-item>
              <a @click="scrollInto('equipo')">
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.team') }}</p>
              </a>
            </v-list-item>-->
            <v-list-item>
              <a @click="scrollInto('historia')">
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.history') }}</p>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
        <a
          class="pr-5"
          @click="scrollInto('productos')"
        >
          <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.products') }}</p>
        </a>
        <a
          class="pr-5"
          @click="scrollInto('socios')"
        >
          <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.parnert') }}</p>
        </a>
        <a
          class="pr-5"
          @click="scrollInto('testimonios')"
        >
          <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.testimonios') }}</p>
        </a>
        <v-divider
          vertical
          class="mx-4 py-6"
        ></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="mb-0 d-flex justify-space-around align-center"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                class="teamPhotoMine ml-2"
                :lazy-src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                :alt="$i18n.locale"
                style="background-position: inherit;max-width: 25px; max-height:15px"
              >
              </v-img>
              <v-icon class="ml-2">
                {{ icons.mdiChevronDown }}
              </v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item-group :value="$i18n.locale">
              <v-list-item
                v-for="locale in availableLocales"
                :key="locale.locale"
                :value="locale.locale"
                @click="setLocale(locale.locale)"
              >
                <a class="d-flex justify-center align-center">
                  <v-img
                    class="teamPhotoMine"
                    :lazy-src="locale.img"
                    :src="locale.img"
                    style="background-position: inherit;max-width: 25px; max-height:15px"
                  >
                  </v-img>
                  <p class="px-2 mb-0 menuSupLinkMine">{{ locale.title }}</p>
                </a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-divider
          vertical
          class="mx-4 py-6"
        ></v-divider>
        <v-btn
          color="#f08319"
          class="OpenSansSemibold"
          style="box-shadow: none !important; color:white"
          rounded
          @click="redirectPage()"
        >
          Login
        </v-btn>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown, mdiMenu } from '@mdi/js'

export default {
  data() {
    return {
      urlLogin: `${process.env.VUE_APP_URL}login`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiMenu,
      },
      locales: [
        {
          title: 'Español',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/es.png'),
          locale: 'es',
        },
        {
          title: 'Português',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/pt.png'),
          locale: 'pt',
        },
        {
          title: 'English',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/en.png'),
          locale: 'en',
        },
        {
          title: 'Deutsch',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/de.png'),
          locale: 'de',
        },
        {
          title: 'Русский',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/ru.png'),
          locale: 'ru',
        },
        {
          title: 'Français',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/fr.png'),
          locale: 'fr',
        },

        /* {
          title: '中國人',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/ch.png'),
          locale: 'ch',
        }, */
      ],
    }
  },
  computed: {
    availableLocales() {
      return this.locales.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    },
    scrollInto(elementId) {
      const section = document.querySelector(`#${elementId}`)
      section.scrollIntoView({
        behavior: 'smooth',
      })
    },
    redirectPage() {
      // window.open(this.urlLogin, '_blank')
      const route = this.$router.resolve({
        name: 'auth-login',
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

.menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
