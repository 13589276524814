<template>
  <v-row class="match-height">
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.typeAfiliado')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'tipo-afiliado-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="model.description"
                  :label="$t('lbl.description')"
                  outlined
                  rows="3"
                  row-height="25"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      model: {},
      typeProducts: [],
      loading: false,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('tipo-afiliado-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
  },
  created() {
    this.getItem()
  },
  methods: {
    getItem() {
      if (localStorage.getItem('tipo-afiliado-id') !== null) {
        const id = localStorage.getItem('tipo-afiliado-id')
        this.axios
          .get(`tipo_afiliado/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
    save() {
      if (this.model.name) {
        this.loading = true
        const json = {
          perfil_slug: localStorage.getItem('perfil'),
          name: this.model.name,
          description: this.model.description,
        }
        if (localStorage.getItem('tipo-afiliado-id') === null) {
          this.axios
            .post('tipo_afiliado', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('tipo-afiliado-id')
                this.$router.push({ name: 'tipo-afiliado-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`tipo_afiliado/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('tipo-afiliado-id')
                this.$router.push({ name: 'tipo-afiliado-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
