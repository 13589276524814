<template>
  <v-col cols="12">
    <v-row :class="pos > 0 ? 'mt-10' : ''">
      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="suplement.suplement_id"
              :disabled="$store.state.app.onlyShow"
              :items="suplementsReductionsFree"
              :label="$t('lbl.suplemento')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              @change="setSuplement"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.suplemento') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="suplementsOtrosSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              @click="deleteSuplementsOtrosSeasonContrateHotel(pos)"
            >
              <v-icon
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              :disabled="verifySuplementsFree"
              v-on="on"
              @click="addSuplementsOtrosSeasonContrateHotel"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>

      <v-col
        v-if="suplement.suplement_id"
        cols="12"
        md="7"
        offset-md="1"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <!--CHECK-IN/OUT-->
            <v-row v-if="suplement.suplement_id && suplement.suplement_type === 'check-in-out'">
              <v-col
                cols="12"
                md="11"
                offset-md="1"
              >
                <!--early-check-in-->
                <v-select
                  v-if="suplement.suplement_slug === 'early-check-in'"
                  v-model="suplement.check_in"
                  :disabled="$store.state.app.onlyShow"
                  :items="checkInList"
                  label="Check-In"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Check-In
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>

                <!--late-check-out-->
                <v-select
                  v-if="suplement.suplement_slug === 'late-check-out'"
                  v-model="suplement.check_out"
                  :disabled="$store.state.app.onlyShow"
                  :items="checkOutList"
                  label="Check-Out"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Check-Out
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <!--TEMPORADA PICO-->
            <v-row v-if="suplement.suplement_id && suplement.suplement_type === 'temporada-pico'">
              <v-col
                cols="12"
                md="11"
                offset-md="1"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      v-model="menuFrom"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedFromDateFormatted"
                          :label="$t('lbl.from')"
                          :disabled="$store.state.app.onlyShow"
                          persistent-hint
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="suplement.season_from"
                        no-title
                        :min="minDate"
                        locale="es"
                        :show-current="false"
                        @input="
                          menuFrom = false
                          activeToDate()
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      v-model="menuTo"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedToDateFormatted"
                          :label="$t('lbl.to')"
                          :disabled="suplement.season_from === null || $store.state.app.onlyShow"
                          persistent-hint
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="suplement.season_to"
                        no-title
                        locale="es"
                        :show-current="false"
                        :min="
                          suplement.season_from
                            ? $moment(suplement.season_from)
                              .add(1, 'day')
                              .toDate()
                              .toISOString()
                              .substr(0, 10)
                            : ''
                        "
                        :max="maxDate ? maxDate : null"
                        @input="menuTo = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!--CENA-->
            <v-row v-if="suplement.suplement_id && suplement.suplement_type === 'cena'">
              <v-col
                cols="12"
                md="11"
                offset-md="1"
              >
                <!--dates-->
                <v-menu
                  ref="menuDates"
                  v-model="menuDates"
                  :close-on-content-click="false"
                  :return-value.sync="suplement.cena_dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      v-model="suplement.cena_dates"
                      multiple
                      chips
                      small-chips
                      outlined
                      hide-details
                      :label="$t('lbl.dates')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-combobox>
                  </template>
                  <v-date-picker
                    v-model="suplement.cena_dates"
                    multiple
                    no-title
                    scrollable
                    locale="es-es"
                    :min="minDate"
                    :max="maxDate ? maxDate : null"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="error"
                      @click="menuDates = false"
                    >
                      {{ $t('btn.cancele') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDates.save(suplement.cena_dates)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <b>{{ $t('lbl.equipaje_personal.para') }}: </b>
            <v-radio-group
              v-model="suplement.applyBy"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                v-for="(ap, ind) in apply"
                :key="ind"
                :label="ap.name"
                :value="ap.slug"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--SWITCHES-->
    <v-row
      v-if="suplement.suplement_id"
      class="pt-0 mt-0"
    >
      <v-col
        cols="12"
        md="2"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.optional"
          :label="$t('lbl.optional')"
          hide-details
          :disabled="suplement.suplement_type === 'check-in-out'"
          class="pt-0 mt-0"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.is_tarifa"
          :label="$t('lbl.tarifa')"
          hide-details
          class="pt-0 mt-0"
          :disabled="suplement.suplement_type === 'check-in-out'"
          @click="setTarifa"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-row v-if="suplement.suplement_type !== 'temporada-pico' && suplement.suplement_type !== 'cena'">
          <v-col
            cols="12"
            md="1"
          ></v-col>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(season, indR) in seasonsContrateHotel"
                :key="indR"
                style="max-width: 85px; margin-left: 10px;margin-bottom: 20px"
                class="pt-0 mt-0"
              >
                <v-text-field
                  v-model="season.name"
                  class="my-input"
                  hide-details
                  disabled
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--TRASLADO-->
    <v-row
      v-if="suplement.suplement_id && suplement.suplement_type === 'traslado'"
      class="pt-0 mt-0"
    >
      <v-col
        cols="12"
        md="9"
        offset-md="3"
      >
        <!--ADL-->
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="ADL"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceAld, indC) in suplement.adl.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px;margin-top:20px"
              >
                <v-text-field
                  v-model="priceAld.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.price')"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    priceAld.price ? (!priceAld.price.includes('N') && !priceAld.price.includes('F') ? '$' : '') : '$'
                  "
                  :append-icon="suplement.is_tarifa && indC === 0 ? 'mdi-arrow-right' : ''"
                  @keypress="checkPricePc($event, priceAld.price, suplement.adl.in_pc)"
                  @input="val => (priceAld.price = priceAld.price.toUpperCase())"
                  @click:append="
                    suplement.is_tarifa && indC === 0
                      ? replicaSuplementsOtrosSeasonContrateHotel({
                        pos: pos,
                        person: 'adl',
                        price: priceAld.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--TEEN-->
        <v-row v-if="hasTeen">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="Teen"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.mnr.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceTeen, indC) in suplement.teen.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px;margin-top:20px"
              >
                <v-text-field
                  v-model="priceTeen.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.price')"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.teen.in_pc
                      ? ''
                      : priceTeen.price
                        ? !priceTeen.price.includes('N') && !priceTeen.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.teen.in_pc
                      ? priceTeen.price
                        ? !priceTeen.price.includes('N') && !priceTeen.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  :append-icon="suplement.is_tarifa && indC === 0 ? 'mdi-arrow-right' : ''"
                  @keypress="checkPricePc($event, priceTeen.price, suplement.teen.in_pc)"
                  @input="val => (priceTeen.price = priceTeen.price.toUpperCase())"
                  @click:append="
                    suplement.is_tarifa && indC === 0
                      ? replicaSuplementsOtrosSeasonContrateHotel({
                        pos: pos,
                        person: 'teen',
                        price: priceTeen.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--MNR-->
        <v-row v-if="hasMnr">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="MNR"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.mnr.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceMnr, indC) in suplement.mnr.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px;margin-top:20px"
              >
                <v-text-field
                  v-model="priceMnr.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.price')"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.mnr.in_pc
                      ? ''
                      : priceMnr.price
                        ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.mnr.in_pc
                      ? priceMnr.price
                        ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  :append-icon="suplement.is_tarifa && indC === 0 ? 'mdi-arrow-right' : ''"
                  @keypress="checkPricePc($event, priceMnr.price, suplement.mnr.in_pc)"
                  @input="val => (priceMnr.price = priceMnr.price.toUpperCase())"
                  @click:append="
                    suplement.is_tarifa && indC === 0
                      ? replicaSuplementsOtrosSeasonContrateHotel({
                        pos: pos,
                        person: 'mnr',
                        price: priceMnr.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>

        <!--INF-->
        <v-row v-if="hasMnr">
          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              label="INF"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
            offset-md="1"
          >
            <span v-if="!suplement.is_tarifa">%</span>
            <br />
            <v-switch
              v-if="!suplement.is_tarifa"
              v-model="suplement.inf.in_pc"
              hide-details
              class="pt-0 mt-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-0 mt-0"
          >
            <v-row class="pt-0 mt-0">
              <div
                v-for="(priceInf, indC) in suplement.inf.prices"
                :key="indC"
                style="max-width: 85px; margin-left: 10px;margin-top:20px"
              >
                <v-text-field
                  v-model="priceInf.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.price')"
                  outlined
                  dense
                  hide-details
                  :prefix="
                    suplement.inf.in_pc
                      ? ''
                      : priceInf.price
                        ? !priceInf.price.includes('N') && !priceInf.price.includes('F')
                          ? '$'
                          : ''
                        : '$'
                  "
                  :suffix="
                    suplement.inf.in_pc
                      ? priceInf.price
                        ? !priceInf.price.includes('N') && !priceInf.price.includes('F')
                          ? '%'
                          : ''
                        : '%'
                      : ''
                  "
                  :append-icon="suplement.is_tarifa && indC === 0 ? 'mdi-arrow-right' : ''"
                  @keypress="checkPricePc($event, priceInf.price, suplement.mnr.in_pc)"
                  @input="val => (priceInf.price = priceInf.price.toUpperCase())"
                  @click:append="
                    suplement.is_tarifa && indC === 0
                      ? replicaSuplementsOtrosSeasonContrateHotel({
                        pos: pos,
                        person: 'inf',
                        price: priceInf.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--CHECK-IN/OUT-->
    <v-row
      v-if="suplement.suplement_id && suplement.suplement_type === 'check-in-out'"
      class="pt-0 mt-0"
    >
      <SeasonSuplementsOtrosHotelRooms
        v-for="(room, indR) in suplement.rooms"
        :key="indR"
        :pos="indR"
        :pos-suplement="pos"
        :hotel="hotel"
        :room="room"
        :suplement="suplement"
        :all-rooms="allRooms"
      />
    </v-row>

    <!--TEMPORADA PICO-->
    <v-row
      v-if="suplement.suplement_id && suplement.suplement_type === 'temporada-pico'"
      class="pt-0 mt-0"
    >
      <SeasonSuplementsOtrosHotelSeasonPicoRooms
        v-for="(room, indR) in suplement.season_rooms"
        :key="indR"
        :pos="indR"
        :pos-suplement="pos"
        :hotel="hotel"
        :room="room"
        :suplement="suplement"
        :all-rooms="allRooms"
      />
    </v-row>

    <!--CENA-->
    <v-row
      v-if="suplement.suplement_id && suplement.suplement_type === 'cena'"
      class="pt-0 mt-0"
    >
      <SeasonSuplementsOtrosHotelCenaRooms
        v-for="(room, indR) in suplement.cena_rooms"
        :key="indR"
        :pos="indR"
        :pos-suplement="pos"
        :hotel="hotel"
        :room="room"
        :suplement="suplement"
        :all-rooms="allRooms"
        :all-planes="allPlanes"
      />
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import SeasonNamesHotelTarifa from './SeasonNamesHotelTarifa.vue'
import SeasonSuplementsOtrosHotelRooms from './SeasonSuplementsOtrosHotelRooms.vue'
import SeasonSuplementsOtrosHotelSeasonPicoRooms from './SeasonSuplementsOtrosHotelSeasonPicoRooms.vue'
import SeasonSuplementsOtrosHotelCenaRooms from './SeasonSuplementsOtrosHotelCenaRooms.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonNamesHotelTarifa,
    SeasonSuplementsOtrosHotelRooms,
    SeasonSuplementsOtrosHotelSeasonPicoRooms,
    SeasonSuplementsOtrosHotelCenaRooms,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),

      // allSuplement: [],
      // allRooms: [],
      itemsRooms: [],

      itemsSuplement: [],
      searchSuplement: null,
      bases: [],
      apply: [],
      checkInList: [],
      checkOutList: [],
      minDate: null,
      maxDate: null,
      menuFrom: false,
      menuTo: false,
      menuDates: false,
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      suplementsOtrosSeasonContrateHotel: state => state.app.suplementsOtrosSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),
    computedFromDateFormatted() {
      return this.suplement.season_from ? this.$moment(this.suplement.season_from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.suplement.season_to ? this.$moment(this.suplement.season_to).format('D-MMM-YY') : ''
    },
    suplementsReductionsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO PLAN ALIMENTOS
        if (
          (element.model === 'traslado'
            || element.model === 'check-in-out'
            || element.model === 'temporada-pico'
            || element.model === 'cena')
          && !element.is_reduction
        ) {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsOtrosSeasonContrateHotel.length; index++) {
            if (this.suplementsOtrosSeasonContrateHotel[index].suplement_id === element.id && index !== this.pos) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      let arrResult = []

      // suplement.suplement_id
      if (this.suplement.suplement_id) {
        // if (result.filter(e => e.id === this.suplement.sup_red_id[0]).length > 0) {
        const v = result.filter(e => e.id === this.suplement.suplement_id)[0]
        if (v.is_reduction) {
          result.forEach(elementOrder => {
            if (elementOrder.is_reduction) {
              arrResult.push(elementOrder)
            } else {
              arrResult.push({
                ...elementOrder,
                disabled: true,
              })
            }
          })
        } else {
          result.forEach(elementOrder => {
            if (!elementOrder.is_reduction) {
              arrResult.push(elementOrder)
            } else {
              arrResult.push({
                ...elementOrder,
                disabled: true,
              })
            }
          })
        }

        // }
      } else {
        arrResult = result
      }

      return arrResult
    },
    verifySuplementsFree() {
      const result = []
      this.allSuplement.forEach(element => {
        // SOLO PARA LOS DE TIPO ALOJAMIENTO
        if (
          (element.model === 'traslado'
            || element.model === 'check-in-out'
            || element.model === 'temporada-pico'
            || element.model === 'cena')
          && !element.is_reduction
        ) {
          let esta = false

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsOtrosSeasonContrateHotel.length; index++) {
            if (this.suplementsOtrosSeasonContrateHotel[index].suplement_id === element.id) {
              esta = true
            }
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },

    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        let esta = false
        let estaIncluida = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
            const roomSup = this.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j]
            if (roomSup.rooms_id.includes(element.id)) {
              if (index === this.posSuplement && j !== this.pos) {
                esta = true
              } else if (index !== this.posSuplement && j === this.pos) {
                esta = true
              } else if (index !== this.posSuplement && j !== this.pos) {
                esta = true
              }
            }
          }
        }
        if (!esta) {
          if (this.room.rooms_id.length > 0) {
            this.roomsSeasonContrateHotel.forEach(rSeason => {
              if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                estaIncluida = true
              }
            })

            if (!estaIncluida) {
              result.push({
                ...element,
                disabled: true,
              })
            } else {
              result.push(element)
            }
          } else {
            result.push(element)
          }
        }
      })

      return result
    },

    hasTeen() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          has = true
        }
      })

      return has
    },
    hasMnr() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_mnr || element.has_a_inf) {
          has = true
        }
      })

      return has
    },
  },
  watch: {
    searchSuplement(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterSuplement(val.toLowerCase())
      }
    },
  },
  created() {
    if (this.seasonsContrateHotel.length > 0) {
      this.minDate = new Date(this.seasonsContrateHotel[0].from).toISOString().substr(0, 10)
      this.maxDate = new Date(this.seasonsContrateHotel[this.seasonsContrateHotel.length - 1].to)
        .toISOString()
        .substr(0, 10)
    } else {
      this.minDate = new Date(Date.now()).toISOString().substr(0, 10)
    }

    this.apply.push({
      name: 'PPP',
      slug: 'ppp',
    })
    this.apply.push({
      name: 'PPH',
      slug: 'pph',
    })
    this.apply.push({
      name: 'PPN',
      slug: 'ppn',
    })
    this.apply.push({
      name: 'PPPN',
      slug: 'pppn',
    })
    this.apply.push({
      name: 'PPHN',
      slug: 'pphn',
    })

    // const ckIn = this.hotel.checkIn
    // const ckOut = this.hotel.checkOut

    if (this.hotel.checkIn) {
      this.suplement.check_out = this.hotel.checkIn
      let chekIn = '06:00'
      while (this.$moment(chekIn, 'HH:mm') < this.$moment(this.hotel.checkIn, 'HH:mm')) {
        this.checkInList.push(this.$moment(chekIn, 'HH:mm').format('HH:mm'))
        chekIn = this.$moment(chekIn, 'HH:mm').add(30, 'minutes')
      }
      this.checkInList.push(this.$moment(this.hotel.checkIn, 'HH:mm').format('HH:mm'))
    }

    if (this.hotel.checkOut) {
      this.suplement.check_in = this.hotel.checkOut

      let chekOut = this.hotel.checkOut
      this.checkOutList.push(this.hotel.checkOut)
      while (this.$moment(chekOut, 'HH:mm') < this.$moment('23:59', 'HH:mm')) {
        this.checkOutList.push(this.$moment(chekOut, 'HH:mm').format('HH:mm'))
        chekOut = this.$moment(chekOut, 'HH:mm').add(30, 'minutes')
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteSuplementsOtrosSeasonContrateHotel',
      'addSuplementsOtrosSeasonContrateHotel',
      'replicaSuplementsOtrosSeasonContrateHotel',
    ]),
    filterSuplement() {
      const suplem = this.allSuplement.filter(e => e.name.toLowerCase())
      suplem.forEach(element => {
        let esta = false
        this.suplementsSeasonContrateHotel.forEach(rrr => {
          if (rrr.sup_red_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsSuplement.push(element)
        }
      })
    },
    setTarifa() {
      if (this.suplement.is_tarifa) {
        this.suplement.mnr.in_pc = false
        this.suplement.inf.in_pc = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplement.season_rooms.length; index++) {
          this.suplement.season_rooms[index].in_pc = false
        }
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplement.cena_rooms.length; index++) {
          this.suplement.cena_rooms[index].in_pc = false
        }
      }
    },
    checkPricePc(elEvento, model, inPc) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (inPc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!inPc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (elEvento.key === '.' && model.length === 0) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },

    setSuplement() {
      if (this.suplement.suplement_id) {
        const sss = this.allSuplement.filter(e => e.id === this.suplement.suplement_id)
        if (sss.length > 0) {
          this.suplement.suplement_slug = sss[0].slug
          this.suplement.suplement_type = sss[0].model
        }

        if (this.suplement.suplement_type === 'check-in-out') {
          this.suplement.is_tarifa = true
          this.suplement.optional = true
        }
      }
    },

    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
      }, 100)
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
