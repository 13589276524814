<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        :loading="loadingDownload"
      >
        <v-icon
          :color="$vuetify.theme.themes.light.primary"
          v-bind="attrs"
          :disabled="loadingDownload || !app.url_apk"
          x-large
          @click="getUlrAPK"
          v-on="on"
        >
          mdi-android
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t('btn.dowload') }}</span>
  </v-tooltip>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      qr: null,
      isLoading: false,
      loadingDownload: false,
    }
  },
  methods: {
    getUlrAPK() {
      this.loadingDownload = true
      const json = {
        ext: 'apk',
        id: this.app.id,
      }
      this.axios
        .post('apps/download', json)
        .then(res => {
          const fileUrl = res.data
          this.downloadAPK(fileUrl)
        })
        .finally(() => {
          this.loadingDownload = false
        })
    },
    async downloadAPK(fileUrl) {
      try {
        const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', `${this.app.slug}.apk`)
        document.body.appendChild(link)

        await link.click()
        link.remove()

        this.loadingDownload = false
      } catch (error) {
        console.error('Error al descargar el archivo:', error)
      }
    },
    downloadFileAPK(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/apk' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.apk`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>
