<template>
  <v-row class="my-6 mx-1">
    <v-col
      cols="11"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <!--<
          cols="12"
        >
          <strong>{{ $t('menu.marca') }}</strong>
          :items="itemsMarca"
          :search-input.sync="searchMarca"

          <v-autocomplete
            v-model="marca.marca_id"
            :label="$t('menu.marcas')"
            :items="marcas"
            class="pt-3"
            outlined
            dense
            chips
            small-chips
            multiple
            hide-details
            item-text="name"
            item-value="id"
            return-object
            @change="changeMarca"
          ></v-autocomplete>
        </v-col>-->
        <v-col cols="12">
          <v-autocomplete
            v-model="marca.cars_id"
            class="pt-2"
            :items="cars"
            :label="$t('menu.modelos')"
            outlined
            hide-details
            dense
            chips
            small-chips
            multiple
            item-text="name"
            item-value="id"
            return-object
            :disabled="$store.state.app.onlyShow"
            @change="verifyPricesTemporadasContrateCar"
          >
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="mt-0 pt-0"
        >
          <v-select
            v-model="marca.edad_id"
            class="pt-2"
            :label="$t('lbl.edadConductor')"
            :items="edades"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-detailst
            :disabled="$store.state.app.onlyShow"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-tooltip
          v-if="!$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              @click="deleteMarcaModeloContrateCar(index)"
            >
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    marca: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
      },
      marcas: [],
      searchMarca: null,
      itemsMarca: [],
      cars: [],
      edades: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchMarca(val) {
      if (val !== null && val.length > 0) {
        this.filterMarca(val.toLowerCase())
      }
    },
  },
  created() {
    // this.getMarcas()
    this.getEdades()
    this.getCars()
  },
  methods: {
    ...mapMutations(['deleteMarcaModeloContrateCar', 'verifyPricesTemporadasContrateCar']),
    filterMarca(v) {
      this.itemsMarca = []
      if (v === '') {
        this.itemsMarca = []
      } else {
        this.itemsMarca = this.marcas.filter(e => e.name.toLowerCase())
      }
    },

    /* getMarcas() {
      this.axios.get('nom_marca_cars?per_page=100000', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        // eslint-disable-next-line no-empty
        if (res.data.success === false) { } else {
          this.marcas = res.data.data
        }
      })
        .finally(() => {
          if (this.marca.marca_id) {
            const mmm = this.marcas.filter(e => e.id === this.marca.marca_id.id)
            if (mmm.length > 0) {
              this.itemsMarca.push(mmm[0])
              this.getCars()
            }
          }
        })
    }, */
    getEdades() {
      this.axios
        .get(`nom_edades_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
        })
    },
    getCars() {
      const json = {
        per_page: 100000,
        perfil_slug: localStorage.getItem('perfil'),
        search: { marca_id: 0 },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.cars = res.data.data
          }
        })
    },

    /* getCars() {
      this.marca.marca_id.forEach(idMarca => {
        const json = {
          per_page: 100000,
          perfil_slug: localStorage.getItem('perfil'),
          search: { marca_id: idMarca },
        }
        this.axios.post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        }).then(res => {
        // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            const result = res.data.data
            result.forEach(car => {
              this.cars.push(car)
            })
          }
        })
      })
    },
    changeMarca() {
      console.log(this.marca.marca_id)
      this.cars = []

      if (this.marca.marca_id) {
        this.getCars()
      }
    }, */
  },
}
</script>
