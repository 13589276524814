<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.date') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.vuelo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.operador') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.origen') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.destino') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.departure') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.arrival') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.cupo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('btn.reserve') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in scheduleResult"
            :key="index"
          >
            <td>{{ iten.date | moment('DD MMMM') }}</td>
            <td>{{ iten.flight.numero_de_vuelo }}</td>
            <td>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  sm="2"
                >
                  <v-avatar
                    v-if="iten.flight.aerolinea.isotipo === null"
                    color="primary"
                    size="35"
                  >
                    <span
                      class="white--text text-h5"
                    >{{ iten.flight.aerolinea.slug }}</span>
                  </v-avatar>
                  <v-img
                    v-else
                    :src="rutaPublic + iten.flight.aerolinea.isotipo"
                    :alt="iten.flight.aerolinea.slug"
                    width="260"
                    contain
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                >
                  {{ iten.flight.aerolinea.name }}
                </v-col>
              </v-row>
            </td>
            <td>{{ iten.flight.origen.destino.iataComodin }}</td>
            <td>{{ iten.flight.destino.destino.iataComodin }}</td>
            <td>{{ iten.hour_departure }}</td>
            <td>{{ iten.hour_arrival }}</td>
            <td align="center">
              {{ iten.cupos }}
            </td>
            <td align="center">
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="reserve(iten)"
                  >
                    <v-icon>{{ icons.mdiCartOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.reserve') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!--<v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>-->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiCartOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      orderByVuelo: null,
      orderByAerolinea: null,
      orderByOrigen: null,
      orderByDestino: null,
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiCartOutline,
      },
      isDialogVisible: false,
      model: {},
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      scheduleResult: state => state.app.scheduleResult,
    }),
  },
  methods: {
    ...mapMutations([
      'setCotizadorFlightSelect',
      'setCotizadorFlight',
    ]),
    onPageChange() {
      this.load()
    },
    reserve(iten) {
      // console.log(iten)

      const destinos = []
      destinos.push({
        destino: iten.flight.destino,
        origen: iten.flight.origen,
        from: iten.date,
        to: null,
      })

      const json = {
        cant_adults: 1,
        cant_ninnos: 0,
        cant_infant: 0,
        aerolinea: iten.flight.aerolinea_id,
        onlyIda: true,
        destinosMultiples: false,
        destinos,
        item_ida: iten,
        indTarifa: 0,
        indRegreso: null,
        item_regreso: null,
      }

      const jsonCotizador = {
        cant_adults: 1,
        cant_ninnos: 0,
        cant_infant: 0,
        aerolinea: iten.flight.aerolinea_id,
        onlyIda: true,
        destinosMultiples: false,
      }

      // console.log(json)

      this.setCotizadorFlight(jsonCotizador)
      this.setCotizadorFlightSelect(json)

      this.$router.push({ name: 'selected-cotizador-flights' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
