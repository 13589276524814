<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('menu.hotels') }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltros"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--PAIS-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.country') }}</span>
          <v-select
            v-model="filtersHotels.country"
            :items="countries"
            :label="$t('lbl.country')"
            outlined
            dense
            hide-details
            @change="changeCountry()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.country') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>

        <!--STATE-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.provinceEstado') }}</span>
          <v-select
            v-model="filtersHotels.state"
            :items="states"
            :label="$t('lbl.provinceEstado')"
            outlined
            dense
            hide-details
            @change="changeState()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.provinceEstado') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>

        <!--CITY-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.locality') }}</span>
          <v-select
            v-model="filtersHotels.city"
            :items="cities"
            :label="$t('lbl.locality')"
            outlined
            dense
            hide-details
            @change="changeCity()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.locality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>

        <!--OPERADOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.operador') }}</span>
          <v-autocomplete
            v-model="filtersHotels.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="setOperadores();"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--MARCAS-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.marcaAsociada') }}</span>
          <v-autocomplete
            v-model="filtersHotels.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="setMarca();"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--HOTEL-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.hotels') }}</span>
          <v-autocomplete
            v-model="filtersHotels.hotel_id"
            :items="itemsHotel"
            :search-input.sync="searchHotel"
            hide-details
            hide-selected
            :label="$t('menu.hotels')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="setHotel();"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--CODE-->
        <div class="customizer-section">
          <span class="text--primary">Code</span>
          <v-autocomplete
            v-model="filtersHotels.code"
            :items="codesHotel"
            :search-input.sync="searchCodesHotel"
            hide-details
            hide-selected
            label="Code"
            outlined
            dense
            item-text="code"
            @change="setCodesHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Code
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.code"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.code }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--NOMBRE DEL AUTO
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.hotel') }}</span>
          <v-text-field
            v-model="filtersHotels.name"
            :label="$t('lbl.hotel')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="refresh()"
          >
          </v-text-field>
        </div>-->

        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    countries: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    operadores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    allMarcasList: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    hotelesAll: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    allCodes: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFromReserve: false,
      menuToReserve: false,
      menuFromService: false,
      menuToService: false,
      afiliadosList: [],
      afiliadosSearch: null,
      sellerList: [],
      sellerSearch: null,
      codesReserva: [],
      searchCodeReserva: null,
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: localStorage.getItem('permisos_auth'),
      searchDestino: null,
      itemsDestino: [],
      itemsMarcas: [],
      searchMarcas: null,
      itemsOperador: [],
      searchOperador: null,
      allHotel: [],
      allMarcas: [],
      states: [],
      cities: [],
      searchHotel: null,
      itemsHotel: [],
      codesHotel: [],
      searchCodesHotel: null,

      filtersHotels: {},
    }
  },
  computed: {
    ...mapState({
      // filtersHotels: state => state.app.filtersHotels,
    }),
  },
  watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      }
    },
    searchCodesHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterCodesHotel(val.toLowerCase())
      }
    },
  },
  created() {
    this.allHotel = this.hotelesAll
    this.allMarcas = this.allMarcasList
  },
  methods: {
    ...mapMutations([
      'filtrarCars',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCars',
    ]),
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    filterHotel() {
      this.itemsHotel = this.hotelesAll.filter(e => e.name.toLowerCase())
    },
    filterCodesHotel() {
      this.codesHotel = this.allCodes.filter(e => e.code.toLowerCase())
    },
    setOperadores() {
      this.allMarcas = []
      this.allHotel = []
      this.itemsHotel = []
      this.itemsMarcas = []
      if (this.operadores.filter(e => e.id === this.filtersHotels.operador_id).length > 0) {
        if (this.operadores.filter(e => e.id === this.filtersHotels.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.operadores.filter(e => e.id === this.filtersHotels.operador_id)[0].marcas
          this.allHotel = this.hotelesAll.filter(e => e.operador_id === this.filtersHotels.operador_id)
          this.itemsMarcas = this.allMarcas
        }
      }

      this.$emit('emitSetOperadores', this.filtersHotels)
    },
    setMarca() {
      this.itemsHotel = []
      if (this.allMarcas.filter(e => e.id === this.filtersHotels.marca_asociada_id).length > 0) {
        this.allHotel = this.hotelesAll.filter(e => e.marca_asociada_id === this.filtersHotels.marca_asociada_id)
      }
      this.$emit('emitSetMarca', this.filtersHotels)
    },
    setHotel() {
      this.$emit('emitSetMarca', this.filtersHotels)
    },
    setCodesHotel() {
      this.$emit('emitSetMarca', this.filtersHotels)
    },
    changeCountry() {
      this.states = []
      this.filtersHotels.state = null
      this.filtersHotels.city = null
      if (this.filtersHotels.country) {
        const states = this.hotelesAll.filter(e => e.country === this.filtersHotels.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }

      this.$emit('refresh', this.filtersHotels)
    },
    changeState() {
      this.cities = []
      this.filtersHotels.city = null
      if (this.filtersHotels.state) {
        const states = this.hotelesAll.filter(e => e.state === this.filtersHotels.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
      this.$emit('refresh', this.filtersHotels)
    },
    changeCity() {
      this.$emit('refresh', this.filtersHotels)
    },

    clearFiltros() {
      this.filtersHotels = {}
      this.$emit('refresh', { })
      this.isCustomizerOpen = false
    },
    refresh() {
      this.$emit('refresh', this.filtersHotels)
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
