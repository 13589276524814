import { render, staticRenderFns } from "./OfertsHotel.vue?vue&type=template&id=4f2e52e4&scoped=true&"
import script from "./OfertsHotel.vue?vue&type=script&lang=js&"
export * from "./OfertsHotel.vue?vue&type=script&lang=js&"
import style0 from "./OfertsHotel.vue?vue&type=style&index=0&id=4f2e52e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2e52e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDatePicker,VListItem,VListItemTitle,VMenu,VRadio,VRadioGroup,VRow,VSelect,VSwitch,VTextField})
