<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.cars')} / ${$t('menu.conditionGenerals')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'conditions-generals-cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="4"
              >
                <!--<v-autocomplete
                  v-model="item.proveedor_id"
                  :items="itemsProveedor"
                  :search-input.sync="searchProveedor"
                  hide-details
                  hide-selected
                  :label="$t('lbl.proveedor')"
                  outlined
                  dense
                  item-text="name_comercial"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.proveedor') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name_comercial"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>-->
                <v-select
                  v-model="item.proveedors"
                  :items="proveedores"
                  :label="$t('lbl.proveedors')"
                  outlined
                  dense
                  hide-details
                  item-text="name_comercial"
                  item-value="id"
                  multiple
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="item.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.cat')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="item.name"
                  :label="$t('lbl.reference')"
                  outlined
                  dense
                  hide-details
                >
                  <v-tooltip
                    slot="append-outer"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="pb-3"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary">
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('lbl.nameReference') }}</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <!--<v-textarea
                  v-model="item.description"
                  :label="$t('lbl.description')"
                  outlined
                  rows="10"
                  row-height="15"
                ></v-textarea>-->
                <tiptap-vuetify
                  v-model="item.description"
                  :extensions="extensions"
                  :placeholder="`${$t('lbl.description')}...`"
                  :disabled="$store.state.app.onlyShow"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    TiptapVuetify,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      showMap: true,
      loading: false,

      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('conditions-generals-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    if (localStorage.getItem('conditions-generals-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getCategories()
    this.getProveedores()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    getCategories() {
      this.axios
        .get('nom_categories_conditions?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categories = res.data.data
          }
        })
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
        .finally(() => this.getItem())
    },

    getItem() {
      if (localStorage.getItem('conditions-generals-id') !== null) {
        const id = localStorage.getItem('conditions-generals-id')
        this.axios
          .get(`nom_conditions_generals/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data
            if (this.item.proveedor_id) {
              this.itemsProveedor = this.proveedores.filter(e => e.id === this.item.proveedor_id)
            } else {
              this.itemsProveedor = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },

    save() {
      if (this.item.description && this.item.category_id && this.item.proveedors.length > 0) {
        this.loading = true
        this.item.for_car = true
        this.item.perfil_slug = localStorage.getItem('perfil')

        if (localStorage.getItem('conditions-generals-id') === null) {
          this.axios
            .post('nom_conditions_generals', this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(
                    this.$t('msg.nom_suplemento_contrate_fligthsExist', { identificador: this.item.modelo }),
                  )
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .put(`nom_conditions_generals/${this.item.id}`, this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reseat() {
      this.item = {}
      this.loading = false
      this.$router.push({ name: 'conditions-generals-cars-list' })
    },
  },
}
</script>
