<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="mt-8">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="25%">
              {{ item.code }}
            </th>
            <th width="5%">
              <v-btn
                icon
                @click="setShowItem"
              >
                <v-icon color="primary">
                  {{ showItem ? icons.mdiArrowDownCircle : icons.mdiArrowRightCircle }}
                </v-icon>
              </v-btn>
            </th>
            <th width="12%">
              {{ $t('lbl.recogida') }}
            </th>
            <th width="12%">
              {{ $t('lbl.entrega') }}
            </th>
            <th
              width="5%"
              class="text-capitalize"
            >
              {{ $t('lbl.day') }}
            </th>
            <th>
              {{ $t('lbl.details') }}
            </th>
            <th width="5%">
              <fragment v-if="states.filter(e => e.id === item.state_item).length > 0">
                <v-chip
                  :color="
                    states.filter(e => e.id === item.state_item)[0].slug === 'en-proceso'
                      ? 'secondary'
                      : states.filter(e => e.id === item.state_item)[0].slug === 'confirmada'
                        ? 'accent'
                        : states.filter(e => e.id === item.state_item)[0].slug === 'pagada'
                          ? 'warning'
                          : states.filter(e => e.id === item.state_item)[0].slug === 'cobrada'
                            ? 'success'
                            : states.filter(e => e.id === item.state_item)[0].slug === 'cancelada'
                              ? 'error'
                              : states.filter(e => e.id === item.state_item)[0].slug === 'reclamada'
                                ? 'warning'
                                : states.filter(e => e.id === item.state_item)[0].slug === 'reembolsada'
                                  ? 'error'
                                  : states.filter(e => e.id === item.state_item)[0].slug === 'completada'
                                    ? 'success'
                                    : ''
                  "
                >
                  {{
                    states.filter(e => e.id === item.state_item)[0].slug === 'en-proceso'
                      ? 'En proceso'
                      : states.filter(e => e.id === item.state_item)[0].slug === 'confirmada'
                        ? 'Confirmado'
                        : states.filter(e => e.id === item.state_item)[0].slug === 'pagada'
                          ? 'Pagado'
                          : states.filter(e => e.id === item.state_item)[0].slug === 'cobrada'
                            ? 'Cobrado'
                            : states.filter(e => e.id === item.state_item)[0].slug === 'cancelada'
                              ? 'Cancelado'
                              : states.filter(e => e.id === item.state_item)[0].slug === 'reclamada'
                                ? 'Reclamado'
                                : states.filter(e => e.id === item.state_item)[0].slug === 'reembolsada'
                                  ? 'Reembolsado'
                                  : states.filter(e => e.id === item.state_item)[0].slug === 'completada'
                                    ? 'Completado'
                                    : ''
                  }}
                </v-chip>
              </fragment>
            </th>
            <th width="5%">
              <fragment v-if="!showItem">
                <!--priceTotal-->
                {{ item.data_markups.precioSuplementPayOnline | currency }}
              </fragment>
              <fragment v-else>
                {{ $t('lbl.importe') }}
              </fragment>
            </th>
            <th width="5%">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title @click="editServive">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="showModalCancele = true">
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.cancele') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              rowspan="7"
              style="vertical-align: top;border: 0"
            >
              <fragment v-if="showItem">
                <v-img
                  :src="
                    nameB2B === 'ttttt'
                      ? rutaS3 + remplaceUrl(image)
                      : nameB2B === 'wwwww'
                        ? rutaS3 + image
                        : rutaPublic + image
                  "
                  contain
                  width="250"
                  class="mb-5"
                ></v-img>
              </fragment>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{
                item.data_service.items.car.name
              }}</span>
              <fragment v-if="showItem">
                <v-row class="mt-2">
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiCarDoor }}
                        </v-icon>
                      </template>
                      <span>
                        <fragment>
                          {{ item.data_service.items.car.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}
                        </fragment>
                      </span>
                    </v-tooltip>
                    <b class="ml-2">
                      {{ item.data_service.items.car.cant_puertas }}
                    </b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiAccountOutline }}
                        </v-icon>
                      </template>
                      <span>
                        <fragment>
                          {{ item.data_service.items.car.cant_paxs }} {{ $t('lbl.travels').toLowerCase() }}
                        </fragment>
                      </span>
                    </v-tooltip>
                    <b class="ml-2">
                      {{ item.data_service.items.car.cant_paxs }}
                    </b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiBagSuitcaseOutline }}
                        </v-icon>
                      </template>
                      <span>
                        <fragment>
                          {{
                            parseInt(item.data_service.items.car.cant_maletas_small, 10) +
                              parseInt(item.data_service.items.car.cant_maletas_big, 10)
                          }}
                          {{ $t('lbl.cantMaletas').toLowerCase() }}
                        </fragment>
                      </span>
                    </v-tooltip>
                    <b class="ml-2">
                      {{
                        parseInt(item.data_service.items.car.cant_maletas_small, 10) +
                          parseInt(item.data_service.items.car.cant_maletas_big, 10)
                      }}
                    </b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-car-shift-pattern
                        </v-icon>
                      </template>
                      <span>
                        <fragment>
                          {{ `${$t('menu.trasnmision')}:` }}
                          <span v-if="item.data_service.items.car.transmision_id">
                            {{ item.data_service.items.car.transmision.name }}
                          </span>
                        </fragment>
                      </span>
                    </v-tooltip>
                    <b>
                      <span v-if="item.data_service.items.car.transmision_id">
                        {{ item.data_service.items.car.transmision.name.slice(0, 1) }}
                      </span>
                    </b>
                  </v-col>
                </v-row>
                <br />
                <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ `${$t('menu.operador')}:` }}</span>
                {{ contrate.rentadora.name }}
                <br />
                <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('lbl.proveedor')}:` }}</span>
                {{ contrate.proveedor.name_comercial }}

                <v-select
                  v-if="!construyendoListStates"
                  v-model="item.state_item"
                  :items="setStates"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.state')"
                  class="mt-5"
                  outlined
                  dense
                  hide-details
                  :disabled="user.nivel > 0"
                  @change="setStateItem"
                ></v-select>
                <span
                  v-if="item.history_state_item.length > 0"
                  class="text-caption"
                >
                  {{ $moment(item.history_state_item[item.history_state_item.length - 1].date).format('D-MMM-Y') }},
                  {{ item.history_state_item[item.history_state_item.length - 1].time }} -
                  {{ item.history_state_item[item.history_state_item.length - 1].user }} </span><br />
                <v-text-field
                  v-model="item.number_confirmation"
                  :disabled="
                    (idStateConfirm !== item.state_item &&
                      idStatePay !== item.state_item &&
                      idStateCobrado !== item.state_item) ||
                      user.nivel > 0
                  "
                  :label="$t('lbl.noConfirm')"
                  outlined
                  dense
                  hide-details
                  class="mt-2"
                  @input="val => (item.number_confirmation = item.number_confirmation.toUpperCase())"
                ></v-text-field>
                <v-btn
                  color="primary"
                  outlined
                  class="mt-2"
                  block
                  :disabled="idStateCobrado !== item.state_item || !item.number_confirmation"
                  :loading="printVoucher"
                  @click="printItem"
                >
                  <v-icon class="mr-2">
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  {{ `${$t('btn.print')} Voucher` }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  class="mt-2"
                  block
                  :disabled="idStateCobrado !== item.state_item || !item.number_confirmation"
                  :loading="sendVoucher"
                  @click="sendItem"
                >
                  <v-icon class="mr-2">
                    {{ icons.mdiSendOutline }}
                  </v-icon>
                  {{ `${$t('btn.send')} Voucher` }}
                </v-btn>

                <!--AJUSTES REQUERIDOS-->
                <v-switch
                  v-model="item.ajuste_requered"
                  :label="$t('lbl.ajusteRequered')"
                  hide-details
                  :disabled="user.nivel > 0"
                  @change="setAjusteRequered('ajuste_requered')"
                ></v-switch>
                <fragment v-if="item.ajuste_requered">
                  <v-select
                    v-model="item.type_ajuste_requered"
                    :items="listTypeAjuste"
                    item-text="name"
                    item-value="slug"
                    :label="$t('lbl.tipo')"
                    class="mt-5"
                    outlined
                    dense
                    hide-details
                    @change="setAjusteRequered('type_ajuste_requered')"
                  ></v-select>
                  <v-text-field
                    v-model="item.monto_ajuste_requered"
                    :label="$t('lbl.monto')"
                    prefix="$"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                    @input="setAjusteRequered('monto_ajuste_requered')"
                  ></v-text-field>
                  <span
                    v-if="item.historial_ajuste_requered.length > 0"
                    class="text-caption"
                  >
                    {{
                      $moment(item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].date).format(
                        'D-MMM-Y',
                      )
                    }}, {{ item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].time }} -
                    {{ item.historial_ajuste_requered[item.historial_ajuste_requered.length - 1].user }}
                  </span>
                  <span
                    v-else
                    class="text-caption"
                  >
                    {{ historial_ajuste_requered_empty.date }}, {{ historial_ajuste_requered_empty.time }},
                    {{ historial_ajuste_requered_empty.user }}
                  </span>
                  <br />
                </fragment>
                <!--RECLAMACION-->
                <v-switch
                  v-model="item.reclamado"
                  :label="$t('lbl.reclamated')"
                  hide-details
                  :disabled="
                    (item.state_item !== idStateCobrado && item.state_item !== idStateDisfrutado) || user.nivel > 0
                  "
                  @change="setAjusteRequered('reclamado')"
                ></v-switch>
                <!--CANCELACION-->
                <v-switch
                  v-model="item.cancelado"
                  :label="$t('lbl.cancelated')"
                  hide-details
                  @change="setAjusteRequered('cancelado')"
                ></v-switch>
                <!--REEMBOLSOS REQUERIDOS-->
                <fragment v-if="idStateCancele === item.state_item || idStateReclamado === item.state_item">
                  <v-switch
                    v-model="item.reembolso_requered"
                    :label="$t('lbl.reembolsoRequered')"
                    hide-details
                    :disabled="user.nivel > 0"
                    @change="setAjusteRequered('reembolso_requered')"
                  ></v-switch>
                  <fragment v-if="item.reembolso_requered">
                    <v-text-field
                      v-model="item.monto_reembolso_requered"
                      :label="$t('lbl.monto')"
                      prefix="$"
                      outlined
                      dense
                      hide-details
                      class="mt-2"
                      :disabled="user.nivel > 0"
                      @input="setAjusteRequered('monto_reembolso_requered')"
                    ></v-text-field>
                    <span
                      v-if="item.historial_reembolso_requered.length > 0"
                      class="text-caption"
                    >
                      {{
                        $moment(
                          item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].date,
                        ).format('D-MMM-Y')
                      }}, {{ item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].time }} -
                      {{ item.historial_reembolso_requered[item.historial_reembolso_requered.length - 1].user }}
                    </span>
                    <span
                      v-else
                      class="text-caption"
                    >
                      {{ historial_ajuste_requered_empty.date }}, {{ historial_ajuste_requered_empty.time }},
                      {{ historial_ajuste_requered_empty.user }}
                    </span>
                    <br />
                  </fragment>
                </fragment>
              </fragment>
            </td>
            <td
              rowspan="7"
              style="vertical-align: top;border: 0"
            ></td>
            <td style="vertical-align: top;border: 0">
              {{ $moment(item.data_service.dateRecogida).format('DD MMM, Y') }}<br />
              {{ $moment(item.data_service.dateRecogida).format('HH:mm') }}
            </td>
            <td style="vertical-align: top;border: 0">
              {{ $moment(item.data_service.dateEntrega).format('DD MMM, Y') }}<br />
              {{ $moment(item.data_service.dateEntrega).format('HH:mm') }}
            </td>
            <td style="vertical-align: top;border: 0">
              {{ item.data_service.items.diasReservar }}
            </td>
            <td
              colspan="2"
              style="vertical-align: top;border: 0"
            >
              <span v-if="!showItem">Tarifa básica, con suplementos</span>
              <span v-else>
                <span v-if="item.data_service.items.category_id">
                  {{ item.data_service.items.category_id.name }},
                </span>
                tarifa básica
              </span>
            </td>
            <td style="vertical-align: top;border: 0">
              <span v-if="showItem">
                {{ item.data_markups.priceTarifa | currency }}
              </span>
            </td>
            <td style="vertical-align: top;border: 0"></td>
          </tr>
          <!--SUPLEMENTO-->
          <tr v-if="showItem">
            <td
              colspan="7"
              style="vertical-align: top;border: 0"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.cargoSuplement') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.applyTo') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.importe') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ $t('lbl.seguro') }}</td>
                      <td></td>
                      <td>{{ item.data_markups.tarifaSeguro | currency }}</td>
                    </tr>
                    <fragment
                      v-for="(suplement, indS) in item.data_markups.suplementos"
                      :key="indS"
                    >
                      <tr v-if="suplement.priceAll > 0">
                        <td>{{ suplement.suplemento.name }}</td>
                        <td>
                          <span v-if="suplement.suplemento.by_day">Día</span>
                          <span v-else-if="suplement.suplemento.by_servicio">Servicio</span>
                          <span v-else-if="suplement.suplemento.by_km">Km</span>
                        </td>
                        <td>{{ suplement.priceAll | currency }}</td>
                      </tr>
                    </fragment>

                    <tr>
                      <td>{{ $t('lbl.depositoGarantia') }}</td>
                      <td></td>
                      <td>{{ item.data_markups.tarifaGarantia | currency }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <!--TOTALES - ONLINE AND RENT-->
          <tr v-if="showItem">
            <td
              colspan="7"
              style="vertical-align: top;border: 0"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.totalPay') }}
                      </th>
                      <th width="15%">
                        {{ item.data_markups.priceTotal | currency }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ $t('lbl.totalPayOnline') }}</td>
                      <td>{{ item.data_markups.precioSuplementPayOnline | currency }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('lbl.totalPayRent') }}</td>
                      <td>{{ item.data_markups.precioSuplementPayRentator | currency }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <!--OTROS SUPLEMENTO-->
          <tr v-if="showItem && (item.data_markups.tarifaDayExtra > 0 || item.data_markups.precioTarifaRetorno)">
            <td
              colspan="7"
              style="border: 0"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.otherCarge') }}
                        <span class="text-caption">({{ $t('lbl.payToRent') }})</span>
                      </th>
                      <th width="15%">
                        {{ $t('lbl.applyTo') }}
                      </th>
                      <th width="15%">
                        {{ $t('lbl.importe') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="item.data_markups.tarifaDayExtra > 0">
                      <td>{{ $t('lbl.dayExtra') }}</td>
                      <td>
                        Día
                      </td>
                      <td>{{ item.data_markups.tarifaDayExtra | currency }}</td>
                    </tr>
                    <tr v-if="item.data_markups.precioTarifaRetorno > 0">
                      <td>{{ $t('lbl.tarifaRetorno') }}</td>
                      <td>
                        Km
                      </td>
                      <td>{{ item.data_markups.precioTarifaRetorno | currency }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <!--CONDUCTORES-->
          <tr v-if="showItem">
            <td
              colspan="7"
              style="border: 0"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="40%">
                        {{ $t('lbl.drivers') }}
                      </th>
                      <th>
                        {{ $t('lbl.document') }}
                      </th>
                      <th class="text-capitalize">
                        {{ $t('lbl.age') }}
                      </th>
                      <th>
                        {{ $t('lbl.nationality') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          `${item.data_client.nameConductorPrincipal} ${item.data_client.apellidosConductorPrincipal}`
                        }}
                      </td>
                      <td>{{ item.data_client.noDocumentConductorPrincipal }}</td>
                      <td>{{ getAge(item.data_client.birthdayConductorPrincipal) }}</td>
                      <td>{{ item.data_client.nationalityConductorPrincipal.name }}</td>
                    </tr>
                    <tr v-if="item.data_client.conductorAditional">
                      <td>
                        {{
                          `${item.data_client.nameConductorAditional} ${item.data_client.apellidosConductorAditional}`
                        }}
                      </td>
                      <td>{{ item.data_client.noDocumentConductorAditional }}</td>
                      <td>{{ getAge(item.data_client.birthdayConductorAditional) }}</td>
                      <td>{{ item.data_client.nationalityConductorAditional.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <!--RECOGIDA-->
          <tr v-if="showItem">
            <td
              colspan="7"
              style="border: 0"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.recogida') }}
                      </th>
                      <th width="50%">
                        {{ $t('lbl.entrega') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              {{ item.data_service.recogida.icon }}
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ item.data_service.recogida.name }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              {{ icons.mdiMapMarkerOutline }}
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ item.data_service.recogida.nameComodin }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              mdi-calendar-range
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ $moment(item.data_service.dateRecogida).format('DD MMM Y, H:m') }}
                          </v-col>
                        </v-row>
                      </td>
                      <td>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              {{ item.data_service.entrega.icon }}
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ item.data_service.entrega.name }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              {{ icons.mdiMapMarkerOutline }}
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ item.data_service.entrega.nameComodin }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-icon
                              color="primary"
                              class="mr-2"
                              small
                            >
                              mdi-calendar-range
                            </v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            {{ $moment(item.data_service.dateEntrega).format('DD MMM Y, H:m') }}
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <!--TERMINOS-->
          <tr v-if="showItem && listConditions.length > 0">
            <td colspan="7">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lbl.termsConditions') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: right;">
                        <ul style="text-align: left;">
                          <fragment v-if="showAllConditions">
                            <li
                              v-for="(condition, indC) in listConditions"
                              :key="indC"
                            >
                              <span v-html="condition.description"></span>
                            </li>
                          </fragment>
                          <li v-else>
                            <span v-html="listConditions[0].description"></span>
                          </li>
                        </ul>
                        <span
                          style="cursor: pointer"
                          @click="showAllConditions = !showAllConditions"
                        >
                          <u>{{ showAllConditions ? $t('lbl.lessSee') : $t('lbl.moreSee') }}</u>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="showModalCancele"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')} ${$t('lbl.service')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleService', { code: item.code }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalCancele = !showModalCancele"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="canceleService()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiArrowDownCircle,
  mdiArrowRightCircle,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiSendOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    conditions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiArrowDownCircle,
        mdiArrowRightCircle,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiSendOutline,
        mdiMapMarkerOutline,
      },
      permisos: localStorage.getItem('permisos_auth'),
      image: null,
      contrate: null,
      listTypeAjuste: [],

      // listStates: [],
      listConditions: [],
      idStateProces: 0,
      idStateConfirm: 0,
      idStatePay: 0,
      idStateCancele: 0,
      idStateReclamado: 0,
      idStateCobrado: 0,
      idStateDisfrutado: 0,
      showAllConditions: false,
      showModalCancele: false,
      printVoucher: false,
      sendVoucher: false,
      historial_ajuste_requered_empty: [],
      construyendoListStates: false,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      indexCarItem: state => state.app.indexCarItem,
      showCarItem: state => state.app.showCarItem,
    }),
    showItem() {
      if (this.indexCarItem === this.pos && this.showCarItem) {
        return true
      }

      return false
    },

    setStates() {
      const listStates = []

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idStateProces = this.states.filter(e => e.slug === 'en-proceso')[0].id
      this.states.forEach(element => {
        if (element.slug === 'en-proceso') {
          // if (this.item.state_item === element.id) {
          listStates.push({
            id: element.id,
            name: 'En proceso',
            disabled: this.item.state_item !== element.id,
          })

          // }
        } else if (element.slug === 'confirmada') {
          // if (slugItem === 'en-proceso' || slugItem === 'confirmada') {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              listStates.push({
                id: element.id,
                name: 'Confirmado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            listStates.push({
              id: element.id,
              name: 'Confirmado',
              disabled: this.item.state_item !== element.id && this.idStateProces !== this.item.state_item,
            })
          }

          // }
        } else if (element.slug === 'pagada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              listStates.push({
                id: element.id,
                name: 'Pagado',
                disabled: this.item.state_item !== element.id,
              })
            }
            this.idStatePay = element.id
          } else {
            listStates.push({
              id: element.id,
              name: 'Pagado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'cobrada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              listStates.push({
                id: element.id,
                name: 'Cobrado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            listStates.push({
              id: element.id,
              name: 'Cobrado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCobrado = element.id
        } else if (element.slug === 'cancelada') {
          // if (slugItem === 'confirmada' || slugItem === 'cancelada') {
          if (this.item.cancelado) {
            listStates.push({
              id: element.id,
              name: 'Cancelado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCancele = element.id
        } else if (element.slug === 'reclamada') {
          if (this.item.state_item === element.id) {
            listStates.push({
              id: element.id,
              name: 'Reclamado',
            })
          }
          this.idStateReclamado = element.id
        } else if (element.slug === 'reembolsada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado || this.item.reclamado) {
            if (this.item.reembolso_requered) {
              listStates.push({
                id: element.id,
                name: 'Reembolsado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else if (this.item.state_item === element.id) {
            listStates.push({
              id: element.id,
              name: 'Reembolsado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'disfrutada') {
          // if (this.item.state_item === element.id) {
          if (!this.item.cancelado && !this.item.reclamado) {
            listStates.push({
              id: element.id,
              name: 'Disfrutado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateDisfrutado = element.id
        } else if (element.slug === 'archivada') {
          // if (this.item.state_item === element.id) {
          listStates.push({
            id: element.id,
            name: 'Archivado',
            disabled: this.item.state_item !== element.id,
          })

          // }
        }
      })

      return listStates
    },
  },
  mounted() {
    this.getContrate()

    // this.setStates()
    if (this.item.data_service.items.car.galery_random === null) {
      this.image = 'config/car_placeholder.png'
    } else {
      this.image = this.item.data_service.items.car.galery_random
    }

    this.listTypeAjuste.push({
      name: 'Débito',
      slug: 'debito',
    })
    this.listTypeAjuste.push({
      name: 'Crédito',
      slug: 'credito',
    })

    this.historial_ajuste_requered_empty = {
      date: this.$moment(new Date()).format('DD-MMM-YYYY'),
      time: this.$moment(new Date()).format('hh:mm'),
      user: `${this.user.name} ${this.user.apellidos}`,
    }
  },
  created() {
    this.getIdStatesPayment()
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'updateIndexCarItem', 'updateShowCarItem']),
    remplaceUrl(url) {
      let img = url.replace('uploads/car/', '')
      img = img.replace(' ', '%20')

      return img
    },
    getAge(birthday) {
      return Math.ceil(
        Math.abs(
          this.$moment(this.$moment(new Date(), 'YYYY-MM-DD')).diff(
            this.$moment(birthday, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        ),
      )
    },
    getIdStatesPayment() {
      // PARA IDENTIFICAR EL ID DE CONFIRMADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada').length > 0) {
        this.idStateConfirm = this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada')[0].id
      }

      // PARA IDENTIFICAR EL ID DE PAGADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'pagada').length > 0) {
        this.idStatePay = this.states.filter(e => e.id === this.item.state_item && e.slug === 'pagada')[0].id
      }
    },
    getContrate() {
      this.axios
        .get(`contrate_cars/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data
          if (this.contrate.conditions) {
            this.contrate.conditions.forEach(element => {
              this.conditions.forEach(elementAll => {
                if (element === elementAll.id) {
                  this.listConditions.push(elementAll)
                }
              })
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    /* setStates() {
      this.listStates = []

      // const slugItem = this.states.filter(e => e.id === this.item.state_item)[0].slug
      this.states.forEach(element => {
        if (element.slug === 'en-proceso') {
          // if (this.item.state_item === element.id) {
          this.listStates.push({
            id: element.id,
            name: 'En proceso',
            disabled: this.item.state_item !== element.id,
          })

          // }
        } else if (element.slug === 'confirmada') {
          // if (slugItem === 'en-proceso' || slugItem === 'confirmada') {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Confirmado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Confirmado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'pagada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Pagado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Pagado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
        } else if (element.slug === 'cobrada') {
          // if (this.item.state_item === element.id) {
          if (this.item.cancelado) {
            if (this.item.last_status_id >= element.id) {
              this.listStates.push({
                id: element.id,
                name: 'Cobrado',
                disabled: this.item.state_item !== element.id,
              })
            }
          } else {
            this.listStates.push({
              id: element.id,
              name: 'Cobrado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCobrado = element.id
        } else if (element.slug === 'cancelada') {
          // if (slugItem === 'confirmada' || slugItem === 'cancelada') {
          if (this.item.cancelado) {
            this.listStates.push({
              id: element.id,
              name: 'Cancelado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateCancele = element.id
        } else if (element.slug === 'reclamada') {
          if (this.item.state_item === element.id) {
            this.listStates.push({
              id: element.id,
              name: 'Reclamado',
            })
          }
          this.idStateReclamado = element.id
        } else if (element.slug === 'reembolsada') {
          if (this.item.state_item === element.id) {
            this.listStates.push({
              id: element.id,
              name: 'Reembolsado',
            })
          }
        } else if (element.slug === 'disfrutada') {
          // if (this.item.state_item === element.id) {
          if (!this.item.cancelado) {
            this.listStates.push({
              id: element.id,
              name: 'Disfrutado',
              disabled: this.item.state_item !== element.id,
            })
          }

          // }
          this.idStateDisfrutado = element.id
        } else if (element.slug === 'archivada') {
          // if (this.item.state_item === element.id) {
          this.listStates.push({
            id: element.id,
            name: 'Archivado',
            disabled: this.item.state_item !== element.id,
          })

          // }
        }
      })
    }, */
    setStateItem() {
      this.getIdStatesPayment()

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        state_item: this.item.state_item,
        code_item: this.item.code,
      }
      this.axios
        .post('reservations/update-state-item-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.item.state_item)

              // this.setStates()

              // this.reserva.state_reserva_id = this.item.state_item
            }

            this.construyendoListStates = true
            setTimeout(() => {
              this.construyendoListStates = false
            }, 100)
          }
        })
    },
    setShowItem() {
      if (!this.showCarItem) {
        this.updateIndexCarItem(this.pos)
        this.updateShowCarItem(true)
      } else if (this.showCarItem && this.pos === this.indexCarItem) {
        this.updateIndexCarItem(-1)
        this.updateShowCarItem(false)
      } else if (this.showCarItem && this.pos !== this.indexCarItem) {
        this.updateIndexCarItem(this.pos)
      }
    },

    editServive() {
      const json = {
        pos: this.pos,
        item: this.item,
      }

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('route-redirect', 'reservations-update')

      this.$router.push({ name: 'car-shop-item-update' })
    },

    canceleService() {
      this.item.state_item = this.idStateCancele
      this.showModalCancele = false

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        state_item: this.item.state_item,
        code_item: this.item.code,
      }
      this.axios
        .post('reservations/update-state-item-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.item.state_item)
            }
          }
        })
    },

    printItem() {
      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        action: 'print',
        codes_items: [this.item.code],
      }

      this.printVoucher = true

      this.axios
        .post('reservations/print-voucher-items-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_document_item = res.data.data.history_document_item
          }
        })
        .finally(() => {
          this.printVoucher = false
        })
    },
    sendItem() {
      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        action: 'send',
        codes_items: [this.item.code],
      }

      this.sendVoucher = true

      this.axios
        .post('reservations/print-voucher-items-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_document_item = res.data.data.history_document_item
          }
        })
        .finally(() => {
          this.sendVoucher = false
        })
    },

    setAjusteRequered(parameter) {
      if (!this.item.ajuste_requered) {
        this.item.type_ajuste_requered = 'debito'
        this.item.monto_ajuste_requered = 0
      }
      if (!this.item.reembolso_requered) {
        this.item.monto_reembolso_requered = 0
      }

      // PERMITIR VOLVER DE CANCELADO A CONFIRMADO
      let volverStatusPreview = false
      if (parameter === 'cancelado' && !this.item.cancelado) {
        volverStatusPreview = true
      }

      const json = {
        reserve_id: localStorage.getItem('reservations-id'),
        item: this.item,
        code_item: this.item.code,
        parameter,
        volverStatusPreview,
      }
      this.axios
        .post('reservations/update-ajuste-item-car', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success) {
            if (res.data.data.parameter === 'cancelado') {
              // eslint-disable-next-line camelcase
              const { state_service_cancelada_id } = res.data.data
              // eslint-disable-next-line camelcase
              const { state_service_preview_id } = res.data.data
              if (volverStatusPreview) {
                // eslint-disable-next-line camelcase
                this.item.state_item = state_service_preview_id
                this.$emit('updateStateReservaItem', state_service_preview_id)
              } else {
                // eslint-disable-next-line camelcase
                this.item.state_item = state_service_cancelada_id
                this.$emit('updateStateReservaItem', state_service_cancelada_id)
              }
              this.item.last_status_id = res.data.data.last_status_id
            } else if (res.data.data.parameter === 'reclamado') {
              this.item.state_item = res.data.data.state_service_reclamada_id
              this.item.last_status_id = res.data.data.last_status_id
              this.$emit('updateStateReservaItem', res.data.data.state_service_reclamada_id)
            }

            this.construyendoListStates = true
            setTimeout(() => {
              this.construyendoListStates = false
            }, 100)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
