<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/valid-v-model -->
<template>
  <fragment v-if="!isLoading">
    <tr>
      <td
        width="50%"
        style="min-width: 350px !important;
              text-align: right;"
      >
        <v-simple-table>
          <template v-slot:default>
            <tbody style="min-width: 350px !important;">
              <tr style="text-align: left;">
                <td><b>TRAVELZUN</b></td>
              </tr>
              <tr style="text-align: left;">
                <td>Phone: {{ phone_voucher }}</td>
              </tr>
              <tr style="text-align: left;">
                <td>Email: {{ email_voucher }}</td>
              </tr>
              <tr style="text-align: left;">
                <td>Web: <a>b2b.travelzun.com</a></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
      <td
        style="margin-left: 25px;
        margin-top: 0px;
        min-width: 650px !important;
        text-align: right;
        vertical-align: top;"
      >
        <img
          style="max-width: 250px;max-height: 250px;"
          :src="`${url_public}config/logopdf.png`"
        />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div style="padding-top: 150px;">
          <table style="width: 100%;min-width: 100%;">
            <body style="font-size: 25px;">
              <tr style="text-align: center;">
                <td
                  colspan="2"
                  :style="
                    `background-color: ${$vuetify.theme.themes.light.primary} !important;
                          color: #fff !important;
                          padding: 10px;
                          font-size: 40px`
                  "
                >
                  <b>BONO / VOUCHER</b>
                </td>
              </tr>
              <tr style="background-color: #f2f2f2; border-bottom: 1px solid;">
                <td style="padding: 10px;font-size: 20px">
                  <b>No. {{ item.number_vouchers[0].voucher }}</b>
                </td>
                <td style="padding: 10px;font-size: 20px">
                  <b>Emisión / Issued: {{ $moment(item.date_compra).format('D MMM, YYYY') }}</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="padding: 30px;"
                ></td>
              </tr>
              <tr>
                <td style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 10px;font-size: 20px">
                  <b>Titular de la reserva / Authorized person:</b>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style="padding: 10px;font-size: 20px">
                  <fragment v-if="item.entity === 'cars'">
                    {{ item.data_client.nameConductorPrincipal }}
                    {{ item.data_client.nameSecoundConductorPrincipal }}
                    {{ item.data_client.apellidosConductorPrincipal }}
                  </fragment>
                </td>
                <td></td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="padding: 30px;"
                ></td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 10px;font-size: 20px"
                >
                  <b>A / To:</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="padding: 10px;font-size: 20px"
                >
                  <fragment v-if="item.entity === 'cars'">
                    {{ item.data_service.items.rentadora.name }}
                  </fragment>
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="padding: 30px;"
                ></td>
              </tr>
              <tr>
                <td style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 10px;font-size: 20px">
                  <b>Servicios / Services</b>
                </td>
                <td style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 10px;font-size: 20px">
                  <!--<fragment v-if="item.entity === 'cars'">
                    <b>Fecha de Emisión</b>
                  </fragment>-->
                </td>
              </tr>
              <tr>
                <td style="padding: 10px;font-size: 20px">
                  <fragment v-if="item.entity === 'cars'">
                    <table style="width: 100%;min-width: 100%;">
                      <body style="font-size: 25px;">
                        <tr>
                          <td style="text-align: right;">
                            <b>{{ $t('lbl.car') }}:</b>
                          </td>
                          <td style="text-align: left;">
                            {{ item.data_service.items.car.name }}
                            <span> o similar </span>
                            <span
                              v-if="item.data_service.items.category_id"
                            >({{ item.data_service.items.category_id.name }})</span>
                          </td>
                        </tr>
                        <!--<tr>
                          <td style="text-align: right;">
                            <b>{{ $t('menu.operador') }}:</b>
                          </td>
                          <td style="text-align: left;">
                            {{ item.data_service.items.rentadora.name }}
                          </td>
                        </tr>-->
                        <tr>
                          <td style="text-align: right;">
                            <b>{{ $t('lbl.recogida') }}:</b>
                          </td>
                          <td style="text-align: left;">
                            {{ item.data_service.recogida.name }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: right;"></td>
                          <td style="text-align: left;">
                            {{ $moment(item.data_service.dateRecogida).format('D MMM, YYYY, H:mm') }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">
                            <b>{{ $t('lbl.entrega') }}:</b>
                          </td>
                          <td style="text-align: left;">
                            {{ item.data_service.entrega.name }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: right;"></td>
                          <td style="text-align: left;">
                            {{ $moment(item.data_service.dateEntrega).format('D MMM, YYYY, H:mm') }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">
                            <b>{{ $t('lbl.incluye') }}:</b>
                          </td>
                          <td style="text-align: left;"></td>
                        </tr>
                        <tr
                          v-for="(suplement, indS) in item.data_service.items.suplementos"
                          :key="indS"
                        >
                          <td style="text-align: right;"></td>
                          <td style="text-align: left;">
                            {{ suplement.suplemento.name }}
                          </td>
                        </tr>
                      </body>
                    </table>
                  </fragment>
                </td>
                <td style="padding: 10px;font-size: 20px; vertical-align: top;">
                  <!--<fragment v-if="item.entity === 'cars'">
                    {{ $moment(item.date_compra).format('D-MMM-YYYY') }}
                  </fragment>-->
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="padding: 30px;"
                ></td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 10px;font-size: 20px"
                >
                  <b>Observaciones / Remarks</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="padding: 50px;"
                ></td>
              </tr>

              <!--CONDICIONES-->
              <fragment v-if="conditions.length > 0">
                <tr>
                  <td
                    colspan="2"
                    style="background-color: #f2f2f2; border-bottom: 1px solid;padding: 1px;font-size: 20px"
                  >
                    <!--<b>Condiciones / Conditions</b>-->
                  </td>
                </tr>
                <tr
                  v-for="(condition, indC) in conditions"
                  :key="indC"
                >
                  <td
                    style="text-align: left;"
                    colspan="2"
                  >
                    <br v-if="indC === 0" />
                    <b>{{ condition.name }}</b>
                    <span
                      v-for="(cod, indCod) in condition.conditions"
                      :key="indCod"
                    >
                      <span
                        style="font-size: 9px;"
                        v-html="cod.description"
                      ></span>
                    </span>
                  </td>
                </tr>
              </fragment>
            </body>
          </table>
        </div>
      </td>
    </tr>
  </fragment>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      phone_voucher: process.env.VUE_APP_NUMBER_PHONE_VOUCHER,
      email_voucher: process.env.VUE_APP_EMAIL_VOUCHER,
      isLoading: true,
      proveedor: null,
      rentadora: null,
      idConditionsContrate: [],
      conditions: [],
      condicionesGenerales: [],
      categoriesCondiciones: [],
    }
  },
  created() {
    this.getContrate()
  },
  methods: {
    getCategCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/categories', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCondiciones = res.data.data
          }
        })
        .finally(() => {
          this.getCondiciones()
        })
    },
    getCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/conditions', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => {
          this.checkConditions()
        })
    },
    getContrate() {
      if (this.item.entity === 'cars') {
        this.axios
          .get(`contrate_cars/${this.item.data_service.items.contrate_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.proveedor = res.data.data.data.proveedor.name_comercial
            this.rentadora = res.data.data.data.rentadora.name
            if (res.data.data.data.conditions) {
              this.idConditionsContrate = res.data.data.data.conditions
            }
          })
          .finally(() => {
            this.getCategCondiciones()
          })
      } else {
        this.isLoading = false
      }
    },
    checkConditions() {
      // console.log(this.idConditionsContrate)
      // console.log(this.condicionesGenerales)

      const arr = []
      this.condicionesGenerales.forEach(element => {
        if (this.idConditionsContrate.includes(element.id)) {
          arr.push(element)
        }
      })

      this.categoriesCondiciones.forEach(element => {
        let tieneConditions = false

        const cond = []
        arr.forEach(elementArr => {
          if (elementArr.category_id === element.id) {
            tieneConditions = true

            cond.push({
              name: elementArr.name,
              description: elementArr.description,
            })
          }
        })

        if (tieneConditions) {
          this.conditions.push({
            name: element.name,
            conditions: cond,
          })
        }
      })

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
  },
}
</script>
