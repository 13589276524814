<template>
  <div v-if="!isLoading">
    <v-tabs v-model="currentTab">
      <v-tab>
        {{ $t('menu.airport') }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <NomAirports />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'
import NomAirports from '../tabs/NomAirports.vue'

export default {
  components: {
    NomAirports,
  },
  data() {
    return {
      currentTab: 0,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      is_creator: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
