<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <b>Aplica para:</b><br />
        <v-radio-group
          v-model="notShow.aplica_para"
          row
        >
          <v-radio
            label="Cancelaciones"
            value="cancelaciones"
          ></v-radio>
          <v-radio
            label="No Show"
            value="no_show"
          ></v-radio>
          <v-radio
            label="Salida Anticipada"
            value="salida_anticipada"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="notShow.aplica_para !== 'no_show' && notShow.aplica_para !== 'salida_anticipada'"
        cols="12"
        md="2"
      >
        <v-text-field
          label="Tiempo antes del Check-In"
          class="my-input"
          hide-details
          disabled
        ></v-text-field>
      </v-col>
      <v-col
        v-if="notShow.aplica_para !== 'no_show' && notShow.aplica_para !== 'salida_anticipada'"
        cols="12"
        md="2"
      >
        <v-switch
          v-model="notShow.in_hours"
          label="En horas"
          hide-details
          class="pt-0"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFromDateFormatted"
              :label="$t('lbl.from')"
              :disabled="$store.state.app.onlyShow"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="notShow.from"
            no-title
            :min="minDate"
            locale="es"
            :show-current="false"
            @input="
              menuFrom = false
              activeToDate()
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedToDateFormatted"
              :label="$t('lbl.to')"
              :disabled="notShow.from === null || $store.state.app.onlyShow"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="notShow.to"
            no-title
            locale="es"
            :show-current="false"
            :min="
              notShow.from
                ? $moment(notShow.from)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : ''
            "
            :max="maxDate ? maxDate : null"
            @input="menuTo = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteNotShowRoomSeasonContrateHotel(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="notShow.aplica_para !== 'no_show' && notShow.aplica_para !== 'salida_anticipada'"
        cols="12"
        :md="notShow.time_ante_checkin_range ? 1 : 2"
      >
        <v-text-field
          v-model="notShow.time_ante_checkin_from"
          :disabled="$store.state.app.onlyShow"
          :label="notShow.in_hours ? 'Horas' : 'Días'"
          outlined
          dense
          hide-details
          @keypress="checkCantDiasCheckIn($event, notShow.time_ante_checkin_from)"
          @input="val => (notShow.time_ante_checkin_from = notShow.time_ante_checkin_from.toUpperCase())"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="notShow.aplica_para !== 'no_show' && notShow.aplica_para !== 'salida_anticipada'"
        cols="12"
        md="1"
      >
        <v-switch
          v-model="notShow.time_ante_checkin_range"
          label="Rango"
          hide-details
          class="pt-0"
        ></v-switch>
      </v-col>
      <v-col
        v-if="
          notShow.time_ante_checkin_range &&
            (notShow.aplica_para !== 'no_show' || notShow.aplica_para !== 'salida_anticipada')
        "
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="notShow.time_ante_checkin_to"
          :disabled="$store.state.app.onlyShow"
          :label="notShow.in_hours ? 'Horas' : 'Días'"
          outlined
          dense
          hide-details
          @keypress="checkCantDiasCheckIn($event, notShow.time_ante_checkin_to)"
          @input="val => (notShow.time_ante_checkin_to = notShow.time_ante_checkin_to.toUpperCase())"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="2"
        :offset-md="notShow.aplica_para === 'no_show' || notShow.aplica_para === 'salida_anticipada' ? '0' : '1'"
      >
        <v-text-field
          v-model="notShow.cant_rooms"
          :disabled="notShow.all_cant_rooms || $store.state.app.onlyShow"
          label="Habitaciones"
          outlined
          dense
          hide-details
          class="pt-0 mt-0"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-switch
          v-model="notShow.all_cant_rooms"
          label="Todas"
          hide-details
          class="pt-0"
          @click="setCantRoomsPenalizar()"
        ></v-switch>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-row>
          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              v-model="notShow.noches_penalizar"
              :disabled="notShow.all_noches_penalizar || $store.state.app.onlyShow"
              label="Noches a penalizar"
              outlined
              dense
              hide-details
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="notShow.all_noches_penalizar"
              label="Todas"
              hide-details
              class="pt-0"
              @click="setNochesPenalizar()"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="1"
        offset-md="1"
      >
        <v-switch
          v-model="notShow.pc_penalidad"
          label="%"
          hide-details
          class="pt-0 mt-4"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="notShow.penalidad"
          :disabled="$store.state.app.onlyShow"
          label="Penalidad"
          outlined
          dense
          hide-details
          :prefix="
            notShow.pc_penalidad
              ? ''
              : notShow.penalidad
                ? !notShow.penalidad.includes('N') && !notShow.penalidad.includes('F')
                  ? '$'
                  : ''
                : '$'
          "
          :suffix="
            notShow.pc_penalidad
              ? notShow.penalidad
                ? notShow.penalidad.includes('N') || notShow.penalidad.includes('F')
                  ? ''
                  : '%'
                : '%'
              : ''
          "
          @keypress="checkPrice($event, notShow.penalidad)"
          @input="val => (notShow.penalidad = notShow.penalidad.toUpperCase())"
        ></v-text-field>
      </v-col>

      <v-col
        v-if="notShowSeasonContrateHotel.length !== pos + 1"
        cols="12"
      >
        <v-divider class="mb-2 mt-2"></v-divider>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    notShow: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      room_id: null,
      minDate: null,
      maxDate: null,
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      seasonContrateHotel: state => state.app.seasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      pricesDBLRoomsSeasonContrateHotel: state => state.app.pricesDBLRoomsSeasonContrateHotel,
      notShowSeasonContrateHotel: state => state.app.notShowSeasonContrateHotel,
    }),
    nameRoom() {
      let name = ''
      if (this.room.room_id) {
        if (this.roomsHotel.filter(e => e.id === this.room.room_id).length > 0) {
          name = this.roomsHotel.filter(e => e.id === this.room.room_id)[0].name
        }
      }

      return name
    },
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      /* result.push({
        id: '-1',
        name: 'Todas',
      }) */
      // let estaIncluida = false
      roomsHotel.forEach(element => {
        /* if (this.notShow.rooms_id.length > 0) {
          this.notShowSeasonContrateHotel.forEach(rSeason => {
            if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.notShow.rooms_id[0])) {
              estaIncluida = true
            }
          })

          if (!estaIncluida) {
            result.push({
              ...element,
              disabled: true,
            })
          } else {
            result.push(element)
          }
        } else {
          result.push(element)
        } */

        if (element) {
          result.push(element)
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.pricesDBLRoomsSeasonContrateHotel.length; index++) {
            if (this.pricesDBLRoomsSeasonContrateHotel[index].room_id === element) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
    computedFromDateFormatted() {
      return this.notShow.from ? this.$moment(this.notShow.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.notShow.to ? this.$moment(this.notShow.to).format('D-MMM-YY') : ''
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })
    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.room.room_id = this.roomsSeasonContrateHotel[0].rooms_id[0]
      }
    }
  },
  methods: {
    ...mapMutations([
      'deletePriceDBLRoomsSeasonContrateHotel',
      'addPriceDBLRoomsNullSeasonContrateHotel',
      'changeRoomDBLSeasonContrateHotel',
      'replicaPriceNoMainSeasonContrateHotel',
      'deleteNotShowRoomSeasonContrateHotel',
    ]),
    checkCantDiasCheckIn(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.room.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.room.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0 || model.includes('N') || model.includes('/') || model.includes('A'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (model.includes('N') || model.includes('/') || model.includes('A')) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }

      // VERIFICAR SI ES  CERO DE PRIMERO
      if (elEvento.keyCode === 48) {
        if (model.length === 0) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
    checkPrice(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.notShow.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.notShow.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (elEvento.key === '.' && model.length === 0) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
    setRoomsNative() {
      this.room_id = this.room.room_id
    },
    setRooms() {
      this.changeRoomDBLSeasonContrateHotel({
        room_id_old: this.room_id,
        room_id_new: this.room.room_id,
        pos: this.pos,
      })
    },
    setIsMain() {
      if (this.room.is_main) {
        this.room.in_pc = false
      }
    },
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
      }, 100)
    },
    setNochesPenalizar() {
      if (this.notShow.all_noches_penalizar) {
        this.notShow.noches_penalizar = null
      }
    },
    setCantRoomsPenalizar() {
      if (this.notShow.all_cant_rooms) {
        this.notShow.cant_rooms = null
      } else {
        this.notShow.cant_rooms = 1
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 14px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
