<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th colspan="11">
              <b>Hab.:</b> {{ room.room.name }} <span class="ml-10"></span><b>Plan:</b> {{ room.plan }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td rowspan="2">
              Desde
            </td>
            <td rowspan="2">
              Hasta
            </td>
            <td rowspan="2">
              Cupo
            </td>
            <td rowspan="2">
              Release
            </td>
            <td rowspan="2">
              Min. noches
            </td>
            <td rowspan="2">
              Max. noches
            </td>
            <!--<td>checkin</td>
            <td>checkout</td>-->
            <td
              colspan="5"
              class="text-center"
            >
              Ocupación
            </td>
          </tr>
          <tr class="text-center">
            <td>Adul.</td>
            <td>Mnr.</td>
            <td>Inf.</td>
            <td>Estado</td>
            <td>Precio</td>
          </tr>
          <tr
            v-for="(season, indS) in room.season"
            :key="indS"
            class="text-center"
          >
            <!--<td>{{ $moment(season.from).format('DD MMM Y') }}</td>
            <td>{{ $moment(season.to).format('DD MMM Y') }}</td>-->
            <td>{{ season.from }}</td>
            <td>{{ season.to }}</td>
            <td>{{ season.availableQuota }}</td>
            <td>{{ season.release }}</td>
            <td>{{ season.minimumStay }}</td>
            <td>{{ season.maximumStay }}</td>
            <!--<td>{{ season.checkinRestriction }}</td>
            <td>{{ season.checkoutRestriction }}</td>-->
            <td>{{ season.mealPlan.occupation[0].adults }}</td>
            <td>{{ season.mealPlan.occupation[0].children }}</td>
            <td>{{ season.mealPlan.occupation[0].babies }}</td>
            <td :style="season.mealPlan.occupation[0].status === 'Closed' ? 'color: red !important;' : ''">
              <b v-if="season.mealPlan.occupation[0].status === 'Closed'">{{ season.mealPlan.occupation[0].status }}</b>
              <span v-else>{{ season.mealPlan.occupation[0].status }}</span>
            </td>
            <td>
              <span v-if="season.mealPlan.occupation[0].mnrAmount > 0 && season.mealPlan.occupation[0].mnrAmount < season.mealPlan.occupation[0].amount">
                <span style="color: red; text-decoration: line-through">
                  {{ season.mealPlan.occupation[0].mnrAmount | currency }}
                </span>
              </span>
              <span v-if="season.mealPlan.occupation[0].myrAmount > 0 && season.mealPlan.occupation[0].myrAmount > season.mealPlan.occupation[0].amount">
                <span style="color: red; text-decoration: line-through">
                  {{ season.mealPlan.occupation[0].myrAmount | currency }}
                </span>
              </span>
              {{ season.mealPlan.occupation[0].amount | currency }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      cuposRoomsSeasonContrateHotel: state => state.app.cuposRoomsSeasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
  },
}
</script>

<!--<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>-->
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
