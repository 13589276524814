<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.hotels')} / ${$t('btn.restaurarLote')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-upload-lote' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="processUpload"
                :disabled="!lote"
                @click="acceptUpload()"
              >
                <span>{{ $t('btn.restaurar') }}</span>
                <v-icon>mdi-file-restore-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row>
              <fragment v-if="lote !== null">
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-uppercase">
                            {{ $t('lbl.name') }}
                          </th>
                          <th class="text-uppercase">
                            {{ $t('menu.operador') }}
                          </th>
                          <th class="text-uppercase">
                            {{ $t('menu.marcaAsociada') }}
                          </th>
                          <th class="text-uppercase">
                            {{ $t('menu.typeDestination') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <fragment
                          v-for="(item, index) in lote.data"
                          :key="index"
                        >
                          <tr>
                            <td>
                              {{ item.name }}
                              <span v-if="item.edad !== null">
                                <span v-if="item.edad_min_id !== null && item.edad_min_id > 0">
                                  ({{
                                    edades.filter(e => e.id === item.edad_min_id).length > 0
                                      ? edades.filter(e => e.id === item.edad_min_id)[0].name
                                      : ''
                                  }})
                                </span>
                              </span>
                              <span
                                v-if="item.only_adult === 'Si' || item.only_adult === 'Sí' || item.only_adult === 'si'"
                              >
                                <v-chip
                                  class="ml-2"
                                  color="primary"
                                  text-color="white"
                                >
                                  {{ $t('lbl.only_adult') }}
                                </v-chip>
                              </span>
                              <v-btn
                                icon
                                @click="showItem(index)"
                              >
                                <v-icon>
                                  {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                </v-icon>
                              </v-btn>
                              <span v-if="item.categoria_id">
                                <fragment v-if="categories.filter(e => e.id === item.categoria_id).length > 0">
                                  <v-rating
                                    :length="categories.filter(e => e.id === item.categoria_id)[0].cant_estrellas"
                                    readonly
                                    size="35"
                                    :value="categories.filter(e => e.id === item.categoria_id)[0].cant_estrellas"
                                    color="primary"
                                  ></v-rating>
                                  <span
                                    v-if="categories.filter(e => e.id === item.categoria_id)[0].plus"
                                    class="mr-2 pt-2"
                                  >PLUS</span>
                                </fragment>
                              </span>
                            </td>
                            <td>
                              <span v-if="item.operador_id">
                                {{ allOperadores.filter(e => e.id === item.operador_id)[0].name }}
                              </span>
                            </td>
                            <td>
                              <span v-if="item.marca_asociada_id">
                                {{ listMarcas.filter(e => e.id === item.marca_asociada_id)[0].name }}
                              </span>
                            </td>
                            <td>
                              <span v-if="item.tipo_destino_id">
                                {{ allTipoDestino.filter(e => e.id === item.tipo_destino_id)[0].name }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="showRow && index === indexRow">
                            <td colspan="4">
                              <v-row v-if="item.intereses_id.length > 0">
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <strong>{{ $t('menu.intereses') }}</strong><br />
                                  <span
                                    v-for="(inter, indInter) in item.intereses_id"
                                    :key="indInter"
                                  >
                                    <span>
                                      {{ intereses.filter(e => e.id === inter)[0].name }}
                                    </span>
                                    <span v-if="indInter <= item.intereses_id.length - 2">,</span>
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row v-if="item.servicios_id.length > 0">
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <strong>{{ $t('menu.servicios') }}</strong><br />
                                  <span
                                    v-for="(serv, indServ) in item.servicios_id"
                                    :key="indServ"
                                  >
                                    <span>
                                      {{ servicios.filter(e => e.id === serv)[0].name }}
                                    </span>
                                    <span v-if="indServ <= item.servicios_id.length - 2">,</span>
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row v-if="item.rooms_id.length > 0">
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <strong>{{ $t('menu.habitaciones') }}</strong><br />
                                  <span
                                    v-for="(serv, indServ) in item.rooms_id"
                                    :key="indServ"
                                  >
                                    <span>
                                      {{ allRooms.filter(e => e.id === serv)[0].name }}
                                    </span>
                                    <span v-if="indServ <= item.rooms_id.length - 2">,</span>
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  v-if="item.cant_departament.cant_rooms"
                                  cols="12"
                                  md="2"
                                >
                                  <b>{{ $t('lbl.cantRoom') }}</b>: {{ item.cant_departament.cant_rooms }}
                                </v-col>
                                <v-col
                                  v-if="item.cant_departament.cant_restaurant"
                                  cols="12"
                                  md="2"
                                >
                                  <b>{{ $t('lbl.cantRest') }}</b>: {{ item.cant_departament.cant_restaurant }}
                                </v-col>
                                <v-col
                                  v-if="item.cant_departament.cant_bars"
                                  cols="12"
                                  md="2"
                                >
                                  <b>{{ $t('lbl.cantBars') }}</b>: {{ item.cant_departament.cant_bars }}
                                </v-col>
                                <v-col
                                  v-if="item.cant_departament.cant_pools"
                                  cols="12"
                                  md="2"
                                >
                                  <b>{{ $t('lbl.cantPiscinas') }}</b>: {{ item.cant_departament.cant_pools }}
                                </v-col>
                                <v-col
                                  v-if="item.cant_departament.cant_salon"
                                  cols="12"
                                  md="2"
                                >
                                  <b>{{ $t('lbl.cantSalon') }}</b>: {{ item.cant_departament.cant_salon }}
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <strong>{{ $t('lbl.destino') }}</strong><br />
                                  <span v-if="item.direccion.city">{{ item.direccion.city }}, </span>
                                  <span v-if="item.direccion.state">{{ item.direccion.state }}, </span>
                                  <span v-if="item.direccion.country">{{ item.direccion.country }}</span><br />
                                  <span v-if="item.latitud && item.longitud">
                                    <b>{{ $t('lbl.latitud') }}</b>: {{ ` ${item.latitud}` }} <b>{{ $t('lbl.longitud') }}</b>: {{ ` ${item.longitud}` }}<br />
                                  </span>
                                  <span v-if="item.direccion.direccion">
                                    <strong>{{ $t('lbl.address') }}:</strong>
                                    <span>{{ ` ${item.direccion.direccion}` }}</span><br />
                                  </span>
                                  <span v-if="item.contactos">
                                    <strong>{{ $t('lbl.contacts') }}:</strong><br />
                                    <span
                                      v-for="(contact, indT) in item.contactos"
                                      :key="indT"
                                    >
                                      {{ contact.name }}<br />
                                      <span
                                        v-for="(phone, indP) in contact.phones"
                                        :key="indP"
                                      >
                                        {{ phone.code }} {{ phone.phone }}
                                        <span v-if="phone.exts.length > 0">
                                          <fragment v-if="phone.exts[0].ext">
                                            Ext.
                                            <span
                                              v-for="(ext, indEx) in phone.exts"
                                              :key="indEx"
                                            >
                                              {{ ext.ext }}
                                              <span v-if="indEx < phone.exts.length - 1">, </span>
                                            </span>
                                          </fragment> </span><br />
                                      </span>
                                      <span v-if="contact.emails.length > 0">
                                        <span
                                          v-for="(email, indE) in contact.emails"
                                          :key="indE"
                                        >
                                          {{ email.email }}
                                          <span v-if="indE < contact.emails.length - 1">, </span>
                                        </span>
                                      </span>
                                      <br /><br />
                                    </span>
                                  </span>

                                  <span v-if="item.checkin">
                                    <strong> Checkin:</strong>
                                    <span>{{ ` ${item.checkin}` }}</span><br />
                                  </span>
                                  <span v-if="item.checkout">
                                    <strong> Checkout:</strong>
                                    <span>{{ ` ${item.checkout}` }}</span>
                                  </span>
                                </v-col>
                              </v-row>

                              <v-row v-if="item.descripcion">
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <strong>{{ $t('lbl.description') }}</strong><br />
                                  <p v-html="item.descripcion"></p>
                                </v-col>
                              </v-row>

                              <v-row v-if="item.id_externo">
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <v-chip
                                    class="ma-2"
                                    color="error"
                                  >
                                    <span>Id Externo: {{ item.id_externo }}</span>
                                  </v-chip>
                                </v-col>
                              </v-row>

                              <fragment v-if="item.galery">
                                <v-row v-if="item.galery.length > 0">
                                  <v-col
                                    v-for="(img, indImg) in item.galery"
                                    :key="indImg"
                                    cols="12"
                                    md="3"
                                  >
                                    <v-img
                                      :src="
                                        nameB2B === 'ttttt' || nameB2B === 'wwwww' ? `${rutaS3}${img.image}` : img.image
                                      "
                                      max-height="150"
                                      max-width="250"
                                    >
                                    </v-img>
                                  </v-col>
                                </v-row>
                              </fragment>

                              <fragment v-if="item.videos">
                                <v-row v-if="item.videos.length > 0">
                                  <v-col
                                    v-for="(video, indVid) in item.videos"
                                    :key="indVid"
                                    cols="12"
                                    md="6"
                                  >
                                    <Media
                                      :kind="'video'"
                                      :controls="true"
                                      :muted="false"
                                      :autoplay="false"
                                      :src="[nameB2B === 'ttttt' || nameB2B === 'wwwww' ? `${rutaS3}${video}` : video]"
                                      :style="{ width: '500px' }"
                                    >
                                    </Media>
                                  </v-col>
                                </v-row>
                              </fragment>
                            </td>
                          </tr>
                        </fragment>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.restaurarLote')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>{{ $t('msg.restoreUpload1') }}</p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
  mdiFileCode,
  mdiFileDownload,
} from '@mdi/js'
import Media from '@dongido/vue-viaudio'

export default {
  components: {
    Media,
    AppCardCode,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
        mdiFileCode,
        mdiFileDownload,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      edad_min: {},
      loading: false,
      loadingDelete: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsHotels: [],
      allHotels: [],
      searchHotel: null,

      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsMarcas: [],
      allMarcas: [],
      listMarcas: [],
      searchMarcas: null,

      categories: [],
      itemsTipoDestino: [],
      allTipoDestino: [],
      searchTipoDestino: null,
      intereses: [],
      searchIntereses: null,
      itemsIntereses: [],
      modelIntereses: [],
      servicios: [],
      itemsServicios: [],
      searchServicios: null,
      modelServicios: [],
      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: null,
      uploadingFile: false,
      processUpload: false,
      cancelarUpload: false,
      isChangeOperator: false,
      isChangeHotel: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      isChangeMarca: false,
      isChangeCategoria: false,
      isChangeTipoDestino: false,
      isChangeEdad: false,
      isChangeServicios: false,
      isChangeIntereses: false,
      isChangeRooms: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      hotelsChange: [],
      operatorsChange: [],
      marcasAsociadaChange: [],
      categoriasChange: [],
      tiposDestinosChange: [],
      edadesChange: [],
      serviciosChange: [],
      interesesChange: [],
      roomsChange: [],
      logo: null,
      valueRemplace: null,
      valueCsv: null,
      idRemplace: null,
      itemsRooms: [],
      allRooms: [],
      searchRooms: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,
      existRestautration: false,
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getNomAll()
    this.getRestore()
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    getNomAll() {
      this.getHotels()
      this.getOperadores()
      this.getMarcasAsociadas()
      this.getCategorias()
      this.getTipoDestino()
      this.getEdades()
      this.getIcons()
      this.getServicios()
      this.getIntereses()
      this.getRooms()
    },
    getHotels() {
      const json = {
        per_page: 1000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allHotels = res.data.data
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
    },
    setOperadores() {
      this.allMarcas = []
      if (this.model.marca_asociada.operador_id) {
        if (this.allOperadores.filter(e => e.id === this.model.marca_asociada.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.allOperadores.filter(e => e.id === this.model.marca_asociada.operador_id)[0].marcas
          this.itemsMarcas = this.allMarcas
        }
      }
    },
    getMarcasAsociadas() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allMarcas = res.data.data
          this.listMarcas = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoDestino = res.data.data
          this.itemsTipoDestino = res.data.data
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.intereses = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.servicios = res.data.data
        })
    },
    setServicios() {
      if (this.model.servicios) {
        if (this.model.servicios.id) {
          const fff = this.servicios.filter(e => e.id === this.model.servicios.id)
          if (fff.length > 0) {
            this.itemsIcons.push(this.iconos.filter(e => e.name === fff[0].icon)[0])
            this.model.servicios.icon = fff[0].icon
            this.iconSelect = fff[0].icon
          }
        }
      }
    },
    clearServicio() {
      // this.iconSelect = null
      this.model.servicios.icon = null
    },
    getEdades() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
        })
    },

    getRestore() {
      const json = {
        product: 'hotels',
      }
      this.axios
        .post('lotes-restore/verify', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            if (res.data.data.status === 200) {
              this.existRestautration = true
              this.lote = res.data.data.data
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getIcons() {
      this.axios
        .get('nom_icons/mdi', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iconos = res.data.data
        })
    },
    changeIcons() {
      if (this.model.servicios.icon) {
        this.iconSelect = this.model.servicios.icon
      }
    },
    acceptUpload() {
      this.isDialogUpdate = true
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmUpdateAll() {
      this.cancelarUpload = true
      this.axios
        .post(
          'lotes-restore/restore',
          { id: this.lote.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$router.push({ name: 'hotels-upload-lote' })
          }
        })
        .finally(() => this.resetFile())
    },
    resetFile() {
      this.file = null
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.uploadingFile = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangeOperator = false
      this.isChangeHotel = false
      this.isChangeMarca = false
      this.isChangeCategoria = false
      this.isChangeTipoDestino = false
      this.isChangeEdad = false
      this.isChangeServicios = false
      this.isChangeIntereses = false
      this.isChangeRooms = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchOperador = null
      this.searchMarcas = null
      this.searchTipoDestino = null
      this.searchServicios = null
      this.searchIcons = null
      this.searchIntereses = null
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
