<template>
  <div>
    <!-- CREAR SUPER ADMIN
    <v-dialog
      v-model="isDialogVisibleCreateAdmin"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('lbl.createAdmin') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="email"
            :label="$t('lbl.email')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            outlined
            @click="isDialogVisibleCreateAdmin = !isDialogVisibleCreateAdmin"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="createAdmin"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!-- MOSTRAR PERMISOS DEL ROL -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('menu.permisos')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!isLoad">
          <v-checkbox
            v-for="(perm, indexP) in abilityList"
            :key="indexP"
            v-model="checkPermiso"
            :true-value="1"
            :false-value="0"
            :label="perm"
            value="1"
            hide-details
            disabled
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialog()"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { item: itemDelete.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialogDelete()"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CONFIRM -->
    <v-dialog
      v-model="isDialogVisibleConfirm"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.update')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.confirmChange') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleConfirm = !isDialogVisibleConfirm"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="forceLogin()"
          >
            {{ $t('btn.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      {{ $t('menu.configuration') }} / {{ $t('menu.perfilUser') }}
      <!--<span
        v-if="currentTab === 1"
        class="pl-2"
      > / {{ $t('permiso.views') }}</span>-->
      <span
        v-if="currentTab === 1"
        class="pl-2"
      > / {{ $t('permiso.permiso') }}</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('perfil_afiliado:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            v-if="currentTab === 0"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
          <!--<v-btn
            v-if="currentTab === 1"
            color="primary"
            :loading="loading"
            @click="saveTableros()"
          >
            <span>{{ $t('btn.update') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>-->
          <v-btn
            v-if="currentTab === 1"
            color="primary"
            :loading="loading"
            @click="savePermisos()"
          >
            <span>{{ $t('btn.update') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs v-model="currentTab">
      <v-tab
        v-if="permisos.includes('perfil_afiliado:list') || permisos.includes('*')"
        @click="setViewTabOther"
      >
        {{ $t('menu.perfilUser') }}
      </v-tab>
      <!--<v-tab
        v-if="
          permisos.includes('perfil_afiliado:edit') ||
            permisos.includes('*')
        "
        @click="setViewTabOther"
      >
        {{ $t('permiso.views') }}
      </v-tab>-->
      <v-tab
        v-if="permisos.includes('perfil_afiliado:edit') || permisos.includes('*')"
        @click="setViewTab"
      >
        {{ $t('menu.permisos') }}
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <PerfilesTab v-if="!isLoading" />
      </v-tab-item>
      <!--<v-tab-item>
        <TablerosTab v-if="!isLoading" />
      </v-tab-item> -->
      <v-tab-item>
        <PermisosTab v-if="$store.state.app.viewPermisos === 'perfil'" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import PerfilesTab from '../tabs/Perfiles.vue'
import TablerosTab from '../tabs/Tableros.vue'
import PermisosTab from '../tabs/Permisos.vue'

export default {
  components: {
    PerfilesTab,
    // eslint-disable-next-line vue/no-unused-components
    TablerosTab,
    PermisosTab,
  },
  data() {
    return {
      currentTab: 0,
      loading: false,
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      isDialogVisibleCreateAdmin: false,
      isDialogVisibleConfirm: false,
      email: null,
      admin: {},
      is_nivel_propietario: false,
      is_creator: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
  },
  methods: {
    ...mapMutations(['setPermisosTab']),
    setViewTab() {
      this.$store.state.app.isLoadingPagePermisos = true
      this.setPermisosTab('perfil')
    },
    setViewTabOther() {
      this.$store.state.app.viewPermisos = 'other'
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    newItem() {
      localStorage.removeItem('perfil-afiliado-id')
      this.$router.push({ name: 'perfil-afiliado-update' })
    },
    savePermisos() {
      this.loading = true

      const json = {
        perfiles: this.$store.state.app.sourcePermisos,
      }

      // console.log(json)
      this.isDialogVisibleConfirm = !this.isDialogVisibleConfirm

      this.axios
        .post(`${process.env.VUE_APP_API_URL}perfil_afiliado/update-all-permisos`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
            // eslint-disable-next-line no-empty
          } else {
            // this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    saveTableros() {
      this.loading = true

      const json = {
        perfiles: this.$store.state.app.sourceTableros,
      }

      // console.log(json)

      this.axios
        .post(`${process.env.VUE_APP_API_URL}tableros/update-all`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
            // eslint-disable-next-line no-empty
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    forceLogin() {
      this.$store.commit('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
