<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          hide-details
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-uppercase">
                  {{ $t('lbl.dateOperation') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.typeOperation') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.service') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.countAvailableBefore') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.cantidad') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.countAvailableAfter') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.excecuteBy') }}
                </th>
                <th class="text-uppercase">
                  {{ $t('lbl.observaciones') }}
                </th>
                <!--<th
                  v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
                  class="text-uppercase"
                >
                  {{ $t('lbl.actions') }}
                </th>-->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(iten, index) in items"
                :key="index"
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <!--<td v-if="permisos.includes('user_register:edit') || permisos.includes('*')">
                  <v-tooltip
                    v-if="(permisos.includes('user_register:edit') || permisos.includes('*')) && iten.procesada"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(iten)"
                      >
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.edit') }}</span>
                  </v-tooltip>
                </td>-->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        lg="12"
        cols="12"
        class="d-flex justify-end"
      >
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          total-visible="5"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiCheckboxIntermediate,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    items: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiCheckboxIntermediate,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiInformationOutline,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      rols: [],
      loading: false,
      pagination: {
        current: 1,
        total: 1,
      },
    }
  },
  methods: {
    ...mapMutations(['updateContactsAfiliados']),
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
