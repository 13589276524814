<template>
  <v-col
    cols="12"
    md="11"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="tarifa.name"
          hide-details
          disabled
          :label="$t('lbl.name')"
          class="my-input mr-2"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="tarifa.category_id"
          disabled
          :items="categoriesTarifarios"
          :label="$t('menu.categoriesTarifarios')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          @change="changeCategory"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.categoriesTarifarios') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <!--<v-tooltip
          v-if="tarifasDirectoHotels.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              @click="deleteTarifasContrateDirectoHotel(pos)"
            >
              <v-icon
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addTarifasContrateDirectoHotel"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>-->
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    tarifa: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesTarifarios: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      ruleAdlMin: [v => !!v || 'Campo requerido', v => this.isOneOrZero(v) || 'Valor permitido 0 o 1'],
      ruleMnrMax: [v => !!v || 'Campo requerido', v => this.isInterger(v) || 'Valor debe ser >= 0'],
      rulePaxMax: [v => !!v || 'Campo requerido', v => this.isNumber(v) || 'Valor debe ser > 0'],
      adults: [],
      teens: [],
      childrens: [],
      babies: [],
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      tarifasDirectoHotels: state => state.app.tarifasDirectoHotels,
    }),
  },
  methods: {
    ...mapMutations([
      'addTarifasContrateDirectoHotel',
      'deleteTarifasContrateDirectoHotel',
      'setLoadingTarifarios',
    ]),
    changeCategory() {
      if (this.tarifa.category_id) {
        this.tarifa.category_slug = this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id)[0].slug
      }
    },
  },
}
</script>
