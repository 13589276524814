<template>
  <fragment>
    <v-card
      v-for="(data,index) in apps"
      :key="index"
      width="100%"
      class="ml-2 mr-2 mb-4"
      style="border-left:0.25px solid #e2e2e2;border-top:0.25px solid #e2e2e2; border-bottom:1px solid #e2e2e2;border-right:1px solid #e2e2e2;border-radius: 1rem 1rem 1rem 1rem;"
    >
      <v-card-text>
        <v-list class="pt-0 mt-n1">
          <v-list-item
            class="px-0 mt-2"
          >
            <v-list-item-avatar size="38">
              <v-img
                v-if="data.logo"
                :src="data.logo"
              ></v-img>
              <v-avatar
                v-else
                color="primary"
                size="38"
              >
                <span class="white--text">{{ data.app_name[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title class="text-sm font-weight-medium">
              <v-row>
                <v-col cols="3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >{{ data.app_name | truncate(30) }}</span>
                    </template>
                    <span>{{ data.app_name }}</span>
                  </v-tooltip>
                  <v-row>
                    <v-col cols="2">
                      <!--<v-icon size="30">
                        mdi-weight-lifter
                      </v-icon>-->
                      <span class="text-no-wrap text--primary">
                        {{ data.size_apk }}
                      </span>

                      <v-divider
                        vertical
                        class="ml-2 mr-2"
                      ></v-divider>
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ data.description }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="2">
                  <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    :disabled="(!data.url_apk && !data.url_ios) || !data.active"
                    @click="downloadAPP(data)"
                  >
                    {{ $t('lbl.downloadAPP') }}
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1">
                  <v-switch
                    v-model="data.active"
                    :disabled="showEditSwitchActive()"
                    class="pt-2 mt-1"
                    @change="showEditActive(data)"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-list-item-title>

            <v-spacer></v-spacer>
            <v-menu
              v-if="
                (permisos.includes('app_backoffice:edit') || permisos.includes('app_backoffice:create') || permisos.includes('*'))
              "
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="showEdit()"
                  link
                  @click="editItem(data)"
                >
                  <v-list-item-title>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                    <span>{{ $t('btn.edit') }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="deleteEdit()"
                  link
                  @click="deleteItem(data)"
                >
                  <v-list-item-title>
                    <v-btn icon>
                      <v-icon color="error">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                    <span>{{ $t('btn.delete') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!--<v-btn
              icon
              small
              class="mt-n2 me-n3"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>-->
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <!--<v-card
      v-for="(iten, index) in apps"
      :key="index"
      class="mx-2 my-2"
      width="100%"
      style="border-left:0.25px solid #e2e2e2;border-top:0.25px solid #e2e2e2; border-bottom:1px solid #e2e2e2;border-right:1px solid #e2e2e2;border-radius: 1rem 1rem 1rem 1rem;"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="3"
            style="vertical-align: text-top;"
          >
            <v-avatar
              v-if="iten.logo === null"
              color="primary"
              size="60"
            >
              <span class="white--text text-h5">{{ iten.app_name[0] }}</span>
            </v-avatar>
            <v-img
              v-else
              :src="iten.logo"
              :alt="iten.slug"
              contain
              class="mt-3"
              height="100px"
            ></v-img>
          </v-col>
          <v-col cols="9">
            <div class="d-flex align-center mt-5">
              <QRAPP
                v-if="(iten.url_apk || iten.url_ios) && iten.active"
                class="mt-4"
                :app="iten"
              />
              <v-avatar
                v-else
                size="100"
                rounded
                color="primary"
                class="v-avatar-light-bg primary--text mt-1"
              >
                <v-icon>
                  mdi-qrcode-scan
                </v-icon>
              </v-avatar>

              <div class="ms-3">
                <h4 class="text-base font-weight-semibold">
                  {{ iten.app_name }}
                </h4>
                <p class="text-xs mb-0">
                  {{ iten.description }}
                </p>
              </div>
            </div>

            <i style="font-size: 12px;">
              {{ $t('lbl.downloadQR') }}
            </i>

            <v-divider class="my-2"></v-divider>

            <div class="text-center">
              <v-row>
                <v-col cols="2">
                  <div>
                    <v-icon size="30">
                      mdi-weight-lifter
                    </v-icon>
                  </div>
                  <span class="text-no-wrap text--primary">
                    {{ iten.size_apk }}
                  </span>
                </v-col>
                <v-col cols="2">
                  <div>
                    <v-icon size="30">
                      mdi-download-outline
                    </v-icon>
                  </div>
                  <span class="text-no-wrap text--primary">
                    {{ iten.cant_download }}
                  </span>
                </v-col>

                <v-col
                  cols="2"
                >
                  <DownloadAPP
                    class="mt-4"
                    :app="iten"
                  />
                </v-col>
                <v-col
                  cols="2"
                >
                  <DownloadAPPIOS
                    class="mt-4"
                    :app="iten"
                  />
                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="1">
                  <v-switch
                    v-model="iten.active"
                    :disabled="showEditSwitchActive()"
                    class="pt-0 mt-1"
                    @change="showEditActive(iten)"
                  ></v-switch>
                </v-col>
                <v-col cols="1">
                  <div>
                    <v-tooltip
                      v-if="showEdit()"
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editItem(iten)"
                        >
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col cols="1">
                  <div>
                    <v-tooltip
                      v-if="deleteEdit()"
                      top
                      color="error"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          color="error"
                          v-on="on"
                          @click="deleteItem(iten)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>-->

    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.deleteItem', { item: itemEdit.app_name}) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = false"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DOWNLOAD APP-->
    <v-dialog
      v-if="dialogDownloadApp"
      v-model="dialogDownloadApp"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card>
        <!--<v-toolbar
          color="while"
        >
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            color="primary"
            @click="dialogDownloadApp = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>-->
        <template>
          <v-row class="my-2 mx-2">
            <v-col
              cols="10"
            >
              <span>
                <b>
                  {{ $t('lbl.downloadAPP1', { name: user.name, item: itemEdit.app_name}) }}
                </b>
                <br />
                {{ $t('lbl.downloadAPP2') }}
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-btn
                icon
                dark
                color="primary"
                @click="dialogDownloadApp = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="my-2 mx-2">
            <v-col
              cols="3"
            >
              <v-card
                :style="`border-left:0.25px solid ${$vuetify.theme.themes.light.primary};border-top:0.25px solid ${$vuetify.theme.themes.light.primary}; border-bottom:1px solid ${$vuetify.theme.themes.light.primary};border-right:1px solid ${$vuetify.theme.themes.light.primary};border-radius: 1rem 1rem 1rem 1rem;`"
              >
                <v-card-text class="text-center justify-center align-center">
                  <QRAPP
                    :app="itemEdit"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="6"
              class="justify-center align-center"
            >
              <div
                class="mt-5"
                style="font-size: 14px;"
              >
                <b>{{ $t('lbl.downloadAPP3') }}</b>
                <br />
                <br />
                <ol>
                  <li>{{ $t('lbl.downloadAPP4') }}</li>
                  <li>{{ $t('lbl.downloadAPP5') }}</li>
                  <li>{{ $t('lbl.downloadAPP6') }}</li>
                </ol>
              </div>
            </v-col>
            <v-col cols="3">
              <v-img
                :src="img"
                width="100"
                class="me-2"
              ></v-img>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

    <SidebarDataAPPs
      v-if="isSidebarDataAPPActive"
      v-model="isSidebarDataAPPActive"
      :app="itemEdit"
      :type_app="type_app"
      @confirmEdit="confirmEdit"
    />
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiInformationOutline,
} from '@mdi/js'

import SidebarDataAPPs from './SidebarDataAPPs.vue'
import QRAPP from './QRAPP.vue'
import DownloadAPP from './DownloadAPP.vue'
import DownloadAPPIOS from './DownloadAPPIOS.vue'

export default {
  components: {
    SidebarDataAPPs,
    // eslint-disable-next-line vue/no-unused-components
    QRAPP,
    // eslint-disable-next-line vue/no-unused-components
    DownloadAPP,
    // eslint-disable-next-line vue/no-unused-components
    DownloadAPPIOS,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    apps: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    type_app: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    config: {
      type: Object,
    },
  },
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/11.png'),
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isDialogVisibleDelete: false,
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiInformationOutline,
      },
      isDialogVisible: false,
      isDialogVisibleEdit: false,
      itemId: null,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      loading: false,
      isSidebarDataAPPActive: false,
      itemEdit: {},
      proveedores: [],
      clients: {
        client_id: null,
        active: true,
      },
      dialogDownloadApp: false,
    }
  },
  computed: {
    ...mapState({
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    disabledActive() {
      let result = true

      if (this.type_app === 'manager_database') {
        if (
          this.permisos.includes('app_backoffice:edit')
          || this.permisos.includes('*')
        ) {
          result = false
        }
      }

      return result
    },
    showTr() {
      let result = false

      if (this.type_app === 'manager_database') {
        if (
          this.permisos.includes('app_backoffice:edit')
          || this.permisos.includes('*')
        ) {
          result = true
        }
      }

      return result
    },
  },
  methods: {
    onPageChange() {
      this.load()
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    editItem(item) {
      this.itemEdit = item
      this.isSidebarDataAPPActive = true
    },
    confirmEdit() {
      const json = {
        id: this.itemEdit.id,
        app_name: this.itemEdit.app_name,
        logo: this.itemEdit.logo,
        url_apk: this.itemEdit.url_apk,
        url_ios: this.itemEdit.url_ios,
        description: this.itemEdit.description,
      }
      this.axios
        .post('apps/update', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.isSidebarDataAPPActive = false
          this.itemEdit = {}
          this.$emit('getAPPS')
        })
    },
    showEditActive(iten) {
      this.itemEdit = iten
      this.confirEditActive()
    },
    canceleEditActive() {
      this.itemEdit.active = !this.itemEdit.active
      this.isDialogVisibleEdit = false
    },
    confirEditActive() {
      this.loading = true
      this.axios
        .post(
          'apps/active',
          {
            id: this.itemEdit.id,
            active: this.itemEdit.active,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(res => { })
        .finally(() => {
          this.loading = false
          this.isDialogVisibleEdit = false
          this.itemEdit = {}
        })
    },

    showEditSwitchActive() {
      let result = true

      if (this.permisos.includes('app_backoffice:edit')) {
        result = false
      }

      return result
    },
    showEdit() {
      let result = false

      if (this.permisos.includes('app_backoffice:edit')) {
        result = true
      }

      return result
    },
    deleteEdit() {
      let result = false

      if (this.permisos.includes('app_backoffice:destroy')) {
        result = true
      }

      return result
    },
    deleteItem(item) {
      this.itemEdit = item
      this.isDialogVisibleDelete = true
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .post(
          'apps/delete',
          {
            id: this.itemEdit.id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(res => { })
        .finally(() => {
          this.loading = false
          this.isDialogVisibleDelete = false
          this.itemEdit = {}
          this.$emit('getAPPS')
        })
    },

    downloadAPP(item) {
      this.itemEdit = item
      this.dialogDownloadApp = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
