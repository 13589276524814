<template>
  <div
    v-if="!isLoading"
  >
    <v-simple-table v-if="refundPaginate.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="100px"
              class="text-uppercase"
            >
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.service') }}
            </th>
            <th
              width="15px"
              class="text-uppercase"
            >
              {{ $t('lbl.monto') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.payMethod') }}
            </th>
            <th
              width="15px"
              class="text-uppercase"
            >
              {{ $t('lbl.date') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.client') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.gestor') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.autorice') }}
            </th>
            <th
              width="15px"
              class="text-uppercase"
            >
              {{ $t('lbl.state') }}
            </th>
            <!--<th
              v-if="
                permisos.includes('refund:list') ||
                  permisos.includes('refund:edit') ||
                  permisos.includes('refund:destroy') ||
                  permisos.includes('*')
              "
              width="15px"
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>-->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(refund, index) in refundPaginate"
            :key="index"
          >
            <td>
              <span>
                <fragment v-if="refund.product === 'flights'">
                  <v-icon>{{ icons.mdiAirplaneTakeoff }}</v-icon> {{ $t('lbl.flight') }}
                </fragment>
                <fragment v-else-if="refund.product === 'cars'">
                  <v-icon>{{ icons.mdiCar }}</v-icon> {{ $t('lbl.car') }}
                </fragment>
                <fragment v-else-if="refund.product === 'hotels'">
                  <v-icon>{{ icons.mdiBed }}</v-icon> {{ $t('lbl.hotel') }}
                </fragment>
              </span>
            </td>
            <td>
              <span
                class="d-inline-block text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{ refund.code_item }}
              </span>
            </td>
            <td>
              <span
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ refund.monto_refund | currency(refund.data_service.currencySymbolFacturar) }}
                    </span>
                  </template>
                  <span>
                    {{ $t('lbl.priceTotal') }}: {{ refund.monto_total | currency(refund.data_service.currencySymbolFacturar) }} ({{ refund.data_service.currencyCodeFacturar }})
                  </span>
                </v-tooltip>
              </span>
            </td>
            <td>
              <span v-if="refund.method_pay === 'transfer'">
                {{ $t('lbl.transferBank') }}
              </span>
              <span v-else-if="refund.method_pay === 'credit'">
                {{ $t('lbl.credit') }}
              </span>
              <span v-else-if="refund.method_pay === 'payCard'">
                {{ $t('lbl.payCard') }}
              </span>
              <span v-else-if="refund.method_pay === 'linkPay'">
                {{ $t('lbl.linkPay') }}
              </span>
              <span v-else-if="refund.method_pay === 'checkBank'">
                {{ $t('lbl.checkBank') }}
              </span>
              <span v-else-if="refund.method_pay === 'cash'">
                {{ $t('lbl.cash') }}
              </span>
            </td>
            <td>{{ refund.date_solicitude }}</td>
            <td>
              <span v-if="refund.data_client">
                <template v-if="refund.data_client.name">
                  {{ refund.data_client.name }}
                  {{ refund.data_client.apellidos }}<br />
                  <a
                    v-if="refund.data_client.email"
                    :href="`mailito:${refund.data_client.email}`"
                  >
                    {{ refund.data_client.email }}
                  </a>
                  <a
                    v-else-if="refund.data_client.emails.length > 0"
                    :href="`mailito:${refund.data_client.emails[0]}`"
                  >
                    {{ refund.data_client.emails[0] }}
                  </a>
                  <span v-if="refund.data_client.telefono"> <br />{{ refund.data_client.telefono }} </span>
                </template>
              </span>
            </td>
            <td>{{ refund.gestor }}</td>
            <td></td>
            <td>
              <v-chip
                :color="refund.state === 'pendient' ? 'red' : 'primary'"
                text-color="white"
              >
                {{ refund.state === 'pendient' ? $t('lbl.pendiente') : $t('lbl.reembolsado') }}
              </v-chip>
            </td>
            <!--<td>
              <span v-if="reserva.date_servicio.length > 0">
                {{ reserva.date_servicio[0] | moment('D/MM/Y') }}
              </span>
            </td>
            <td>
              <span
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ reserva.pricePayOnline | currency(reserva.symbolPayOnline) }}
                    </span>
                  </template>
                  <span>
                    {{ $t('lbl.venta') }}: {{ reserva.pricePayOnline | currency(reserva.symbolPayOnline) }} ({{ reserva.codePayOnline }})
                    <br />
                    {{ $t('lbl.neto') }}: {{ reserva.priceNeto | currency(reserva.symbolNeto) }} ({{ reserva.codeNeto }})
                    <br />
                    Utilidad: {{ reserva.priceUtilidad | currency('$') }}
                    <br />
                    Comisión: {{ reserva.priceComision | currency('$') }}
                  </span>
                </v-tooltip>
              </span>
            </td>
            <td width="20%">
              <span class="d-inline-block text-caption">{{ reserva.afiliado.name }}</span><br />
              {{
                reserva.afiliado.company !== null
                  ? reserva.afiliado.company
                  : reserva.afiliado.nivel === 0
                    ? 'Propietario'
                    : ''
              }}
            </td>
            <td
              v-if="permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')"
              width="10%"
            >
              <v-menu
                v-if="
                  (permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*'))
                "
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="permisos.includes('reservas:list')"
                    link
                    @click="showItem(reserva)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.see') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (permisos.includes('reservas:edit') || permisos.includes('*')) &&
                        reserva.state_reserva.slug !== 'cancelada' &&
                        (reserva.afiliado.nivel === user.nivel || user.nivel <= 0)
                    "
                    link
                    @click="editItem(reserva)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>-->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <template v-if="refundPaginate.length === 0">
      <v-row class="mt-5">
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t('msg.noRefund') }}
            {{ $t('msg.changeFilters') }}
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiPackage,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tipos_product: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    names: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    codes: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiPackage,
        mdiDotsVertical,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      refundPaginate: state => state.app.refundPaginate,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    showItem(iten) {
      localStorage.setItem('reservations-show', true)
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },
    editItem(iten) {
      localStorage.removeItem('reservations-show')
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      const json = {
        reserve_id: this.itemId,
      }
      this.loading = true
      this.axios
        .post('reservations/cancele', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            window.location.reload()
          } else {
            this.$toast.error('No ha sido posible reembolsar su pago')
          }
        })
        .finally(() => this.reseat())
    },

    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
