<template>
  <v-navigation-drawer
    :value="isSidebarDataAPPActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-app-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          APP
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-app-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.app_name"
                :label="`${$t('lbl.name')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
                @change="setLogo"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="apk"
                accept=".apk"
                show-size
                outlined
                dense
                label="APP"
                hide-details
                @change="setAPK"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="app.description"
                :label="$t('lbl.description')"
                outlined
                rows="3"
                row-height="3"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-app-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="camposVerify"
                :loading="loadingBtn"
                @click="save()"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    Loading,
  },
  model: {
    prop: 'isSidebarDataAPPActive',
    event: 'update:is-sidebar-data-app-active',
  },
  props: {
    isSidebarDataAPPActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      apk: null,
      isLoading: false,
      app: {
        app_name: '',
        description: '',
        logo: '',
        url_apk: '',
        size_apk: 0,
      },
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    camposVerify() {
      let rest = false
      if (!this.app.app_name) {
        rest = true
      }

      return rest
    },
  },
  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    setLogo() {
      if (this.logo) {
        const formData = new FormData()
        formData.append('logo', this.logo)
        this.isLoading = true
        this.axios
          .post('apps/add-logo', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.app.logo = res.data.data.dir
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    setAPK() {
      if (this.apk) {
        const formData = new FormData()
        formData.append('apk', this.apk)
        this.isLoading = true
        this.axios
          .post('apps/add-apk', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.app.url_apk = res.data.data.dir
              this.app.size_apk = res.data.data.size
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    save() {
      const json = {
        app_name: this.app.app_name,
        logo: this.app.logo,
        url_apk: this.app.url_apk,
        size_apk: this.app.size_apk,
        description: this.app.description,
      }

      this.axios
        .post('apps/create', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.$emit('getAPPS')
        })
    },
  },
}
</script>
