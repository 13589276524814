<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-key -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-simple-table
      class="purchased-items-table"
      dense
      style="background-color: #fff;"
      width="100%"
    >
      <template v-slot:default>
        <tbody>
          <fragment
            v-for="(item, indItem) in car.proveedores"
            :key="indItem"
          >
            <tr class="bgGray">
              <td colspan="11">
                <span class="d-flex flex-row">
                  <v-checkbox
                    v-model="item.check"
                    hide-details
                    class="my-0"
                  ></v-checkbox>
                  <span class="mt-2">
                    {{ `Proveedor #${indItem + 1}: ${item.proveedor.name_comercial}` }}
                  </span>
                </span>
              </td>
              <td
                class="text-right"
                width="10px"
              >
                <v-btn
                  icon
                  @click="showItem(indItem)"
                >
                  <v-icon>
                    {{ showRow && indItem === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>

            <template v-if="showRow && indItem === indexRow">
              <tr class="bgGray">
                <td class="text-uppercase">
                  ID
                </td>
                <td
                  v-if="car.tieneCategory"
                  class="text-uppercase"
                >
                  {{ $t('lbl.cat') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('menu.modelo') }}
                </td>
                <td
                  class="text-uppercase text-left"
                  width="80px"
                >
                  {{ $t('lbl.oferta') }}
                </td>
                <td width="10px"></td>
                <td class="text-uppercase">
                  {{ $t('lbl.from') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.to') }}
                </td>
                <td
                  v-for="(tarifa, indT) in car.tarifas"
                  :key="indT"
                >
                  {{ tarifa.name }}
                </td>
              </tr>
              <template v-if="item.type_contrate === 'marca_modelo'">
                <template v-for="marca in item.marcas">
                  <template v-for="type_offert in marca.type_offert">
                    <template v-for="(season, indS) in type_offert.season">
                      <tr
                        :key="indS"
                        style="border-collapse: collapse;"
                      >
                        <td
                          v-if="indS === 0"
                          :rowspan="item.allRows"
                          class="verticalAlign"
                        >
                          {{ marca.id }}
                        </td>
                        <td
                          v-if="indS === 0"
                          :rowspan="item.allRows"
                          class="verticalAlign"
                        >
                          {{ marca.car.name }}<br />
                          <b>EDAD: </b>{{ marca.edad_min }} <b class="ml-5">DÍAS: </b>{{ marca.days }}
                          <b class="ml-5">RELEASE: </b>{{ marca.release }}
                        </td>
                        <td
                          v-if="indS === 0"
                          :rowspan="type_offert.season.length"
                          class="verticalAlign"
                        >
                          {{ type_offert.type }}
                        </td>
                        <td class="tdBorder">
                          <b>TW:</b><br />
                          <b>BW:</b>
                        </td>
                        <td class="tdBorder">
                          {{ $moment(season.dates[0].from).format('MMM D, YYYY') }} <br />
                          {{ $moment(type_offert.bwFrom).format('MMM D, YYYY') }}
                        </td>
                        <td class="tdBorder">
                          {{ $moment(season.dates[season.dates.length - 1].to).format('MMM D, YYYY') }}<br />
                          {{ $moment(type_offert.bwTo).format('MMM D, YYYY') }}
                        </td>
                        <td
                          v-for="(tarifa, indTar) in car.tarifas"
                          :key="indTar"
                          class="verticalAlign tdBorder"
                        >
                          <span
                            v-for="(price, indPri) in type_offert.prices"
                            :key="indPri"
                          >
                            <span
                              v-for="(priceOne, indPriOne) in price.prices"
                              :key="indPriOne"
                            >
                              <span
                                v-if="
                                  priceOne.tarifa_id === tarifa.id &&
                                    marca.marca_id === price.marca_id &&
                                    season.temporada_id === price.temporada_id
                                "
                              >
                                <span v-if="type_offert.markups">
                                  <span v-if="type_offert.markups.is_pc">
                                    {{
                                      (parseFloat(priceOne.price) +
                                        (parseFloat(priceOne.price) * type_offert.markups.markup) / 100)
                                        | currency
                                    }}
                                  </span>
                                  <span v-else>
                                    {{ (parseFloat(priceOne.price) + type_offert.markups.markup) | currency }}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </template>
              <template v-if="item.type_contrate === 'categoria'">
                <template v-for="category in item.categories">
                  <template v-for="type_offert in category.type_offert">
                    <template v-for="(season, indS) in type_offert.season">
                      <tr
                        :key="indS"
                        style="border-collapse: collapse;"
                      >
                        <td
                          v-if="indS === 0"
                          :rowspan="item.allRows"
                          class="verticalAlign"
                        >
                          {{ category.id }}
                        </td>
                        <td
                          v-if="indS === 0"
                          :rowspan="item.allRows"
                          class="verticalAlign"
                        >
                          {{ category.category_id.name }}
                        </td>
                        <td
                          v-if="indS === 0"
                          :rowspan="item.allRows"
                          class="verticalAlign"
                        >
                          {{ `${category.car.name} ${category.cars.length >= 2 ? $t('lbl.orSimilar') : ''}` }}<br />
                          <b>EDAD: </b>{{ category.edad_min }} <b class="ml-5">DÍAS: </b>{{ category.days }}
                          <b class="ml-5">RELEASE: </b>{{ category.release }}
                        </td>
                        <td
                          v-if="indS === 0"
                          :rowspan="type_offert.season.length"
                          class="verticalAlign"
                        >
                          {{ type_offert.type }}
                        </td>
                        <td class="tdBorder">
                          <b>TW:</b><br />
                          <b>BW:</b>
                        </td>
                        <td class="tdBorder">
                          {{ $moment(season.dates[0].from).format('MMM D, YYYY') }} <br />
                          {{ $moment(type_offert.bwFrom).format('MMM D, YYYY') }}
                        </td>
                        <td class="tdBorder">
                          {{ $moment(season.dates[season.dates.length - 1].to).format('MMM D, YYYY') }}<br />
                          {{ $moment(type_offert.bwTo).format('MMM D, YYYY') }}
                        </td>
                        <td
                          v-for="(tarifa, indTar) in car.tarifas"
                          :key="indTar"
                          class="verticalAlign tdBorder"
                        >
                          <span
                            v-for="(price, indPri) in type_offert.prices"
                            :key="indPri"
                          >
                            <span
                              v-for="(priceOne, indPriOne) in price.prices"
                              :key="indPriOne"
                            >
                              <span
                                v-if="
                                  priceOne.tarifa_id === tarifa.id &&
                                    category.category_id.id === price.category_id &&
                                    season.temporada_id === price.temporada_id
                                "
                              >
                                <span v-if="type_offert.markups">
                                  <span v-if="type_offert.markups.is_pc">
                                    {{
                                      (parseFloat(priceOne.price) +
                                        (parseFloat(priceOne.price) * type_offert.markups.markup) / 100)
                                        | currency
                                    }}
                                  </span>
                                  <span v-else>
                                    {{ (parseFloat(priceOne.price) + type_offert.markups.markup) | currency }}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </template>

              <template v-if="item.suplements.length > 0">
                <tr class="bgGray">
                  <td
                    :colspan="car.tieneCategory ? 3 : 2"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.suplementsCost') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ car.tieneCategory ? 'Categorías' : 'Modelos' }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.from') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.to') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.price') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.modality') }}
                  </td>
                  <td
                    class="text-uppercase"
                    :colspan="car.tarifas.length - 2"
                  >
                    {{ $t('lbl.tipo') }}
                  </td>
                </tr>
                <tr
                  v-for="(suplement, indSup) in item.suplements"
                  :key="indSup"
                >
                  <td :colspan="car.tieneCategory ? 3 : 2">
                    {{ suplement.suplement.name }}
                  </td>
                  <td colspan="2">
                    <span
                      v-for="(mc, indMC) in suplement.marca_or_category"
                      :key="indMC"
                    >
                      {{ `${mc}${indMC === suplement.marca_or_category.length - 1 ? '' : ', '}` }}
                    </span>
                  </td>
                  <td>
                    {{ $moment(suplement.from).format('MMM D, YYYY') }}
                  </td>
                  <td>
                    {{ $moment(suplement.to).format('MMM D, YYYY') }}
                  </td>
                  <td>
                    {{ suplement.price | currency }}
                  </td>
                  <td>
                    {{ suplement.suplement.modalidad }}
                  </td>
                  <td :colspan="car.tarifas.length - 2">
                    {{ suplement.obligatorio ? $t('lbl.obligatorio') : $t('lbl.optional') }}
                  </td>
                </tr>
              </template>

              <template v-if="item.doc_require.length > 0">
                <tr class="bgGray">
                  <td
                    :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.docRequired') }}
                  </td>
                </tr>
                <tr>
                  <td :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6">
                    <ol>
                      <li
                        v-for="(doc, indDoc) in item.doc_require"
                        :key="indDoc"
                      >
                        {{ doc }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.polices.length > 0">
                <tr class="bgGray">
                  <td
                    :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.policesCancele') }}
                  </td>
                </tr>
                <tr>
                  <td :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6">
                    <ol>
                      <li
                        v-for="(poli, indPoli) in item.polices"
                        :key="indPoli"
                      >
                        {{ poli }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.terms.length > 0">
                <tr class="bgGray">
                  <td
                    :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.termsConditions') }}
                  </td>
                </tr>
                <tr>
                  <td :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6">
                    <ol>
                      <li
                        v-for="(term, indTerm) in item.terms"
                        :key="indTerm"
                      >
                        {{ term }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.notes.length > 0">
                <tr class="bgGray">
                  <td
                    :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.notes') }}
                  </td>
                </tr>
                <tr>
                  <td :colspan="car.tieneCategory ? car.tarifas.length + 7 : car.tarifas.length + 6">
                    <ol>
                      <li
                        v-for="(note, indNot) in item.notes"
                        :key="indNot"
                      >
                        {{ note }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>
            </template>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    car: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      menuFrom: false,
      menuTo: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsList: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios']),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss">
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.bgGray {
  background-color: #eee;
}

tbody {
  tr:hover {
    background-color: #eee !important;
  }
}

.verticalAlign {
  vertical-align: top;
}

.tdBorder {
  border-bottom: 1px #eee solid;
}
</style>
