<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :label="$t('lbl.old_password')"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password :hint="$t('lbl.min_length_password')" -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :label="$t('lbl.new_password')"
                outlined
                dense
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="confirmPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :label="$t('lbl.confirmation_password')"
                outlined
                dense
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
              <v-row v-if="!newPassword">
                <v-col
                  cols="12"
                  md="12"
                  class="ml-3"
                >
                  <small><span class="text-caption">{{ $t('password.min_rules') }}</span></small>
                </v-col>
              </v-row>

              <v-row
                v-else-if="(!has_minimum_lenth || !has_lowercase || !has_number || !has_special) && !confirmPassword"
              >
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-icon
                    class="mt-2"
                    color="error"
                  >
                    mdi-information
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  md="11"
                >
                  <small><span
                    class="text-caption"
                    style="color: red"
                  >{{ $t('password.security') }}</span></small>
                </v-col>
              </v-row>

              <v-row v-else-if="confirmPassword && newPassword !== confirmPassword">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-icon
                    class="mt-1 mr-2"
                    color="error"
                  >
                    mdi-information
                  </v-icon>
                  <span
                    class="text-caption mt-3"
                    style="color: red"
                  >{{ $t('password.not_math') }}</span>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                v-if="avatar === null"
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
              <v-avatar
                v-else
                rounded
                size="120"
                class="me-6"
              >
                <img
                  :src="rutaPublic + avatar"
                  class="rounded"
                  alt="avatar"
                />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!--<v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Two factor authentication is not enabled yet.
          </p>
          <p class="text-sm text--primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in. Learn more.
          </p>
        </v-card-text>-->

        <!-- action buttons -->
        <v-card-text>
          <v-btn
            :disabled="
              !has_minimum_lenth || !has_lowercase || !has_number || !has_special || newPassword !== confirmPassword
            "
            color="primary"
            class="me-3 mt-3"
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.acceptChange') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="cancele"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiCamera,
} from '@mdi/js'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: false,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCamera,
        mdiKeyOutline,
        mdiLockOpenOutline,
      },
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      confirmation_password: '',
      currentPassword: null,
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      show: false,
      status: [],
      emailOrigin: null,
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      newPassword: null,
      confirmPassword: null,
    }
  },
  watch: {
    newPassword() {
      this.has_minimum_lenth = this.newPassword.length > 8
      this.has_number = /\d/.test(this.newPassword)
      this.has_lowercase = /[a-z]/.test(this.newPassword)
      this.has_uppercase = /[A-Z]/.test(this.newPassword)
      // eslint-disable-next-line no-useless-escape
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword)
    },
  },
  created() {
    this.avatar = this.accountData.avatar
  },
  methods: {
    save() {
      if (this.newPassword && this.confirmPassword && this.currentPassword) {
        if (this.newPassword === this.confirmPassword) {
          this.loading = true
          const json = {
            current_password: this.currentPassword,
            password: this.newPassword,
          }
          this.axios
            .post('profile-password', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // eslint-disable-next-line no-empty
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  this.$toast.error(this.$t('msg.passwordNotMath'))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
                this.currentPassword = null
                this.newPassword = null
                this.confirmPassword = null
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.$toast.error(this.$t('msg.notMatch'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    cancele() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
