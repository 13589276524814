<template>
  <div
    class="auth-wrapper auth-v1"
  >
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="`${url_public}${configDataGeneral.isotipo ? configDataGeneral.isotipo : configDataGeneral.logo}`"
              alt="logo"
              contain
              class="me-3 logoSize"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ configDataGeneral.name }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('lbl.inactividad3') }}
          </p>
          <p class="mb-2">
            {{ $t('lbl.inactividad4') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-btn
              :loading="loading"
              block
              color="primary"
              @click="$router.push({ name: 'auth-login' })"
            >
              {{ $t('lbl.iniciar') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <WstIzq
      v-if="name_project === 'wwwww'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <WstDerecha
      v-if="name_project === 'wwwww'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <ProIzq
      v-if="name_project === 'ppppp'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <ProDerecha
      v-if="name_project === 'ppppp'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <KroIzq
      v-if="name_project === 'kkkkk'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <KroDerecha
      v-if="name_project === 'kkkkk'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />

    <TravelzunIzq
      v-if="name_project === 'ttttt'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <TravelzunDerecha
      v-if="name_project === 'ttttt'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mdiChevronLeft } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import ProDerecha from '@/views/utils/images/pro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroDerecha from '@/views/utils/images/kro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunIzq from '@/views/utils/images/travelzun_izq.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunDerecha from '@/views/utils/images/travelzun_derecha.vue'

export default {
  components: {
    ProDerecha,
    ProIzq,
    KroDerecha,
    KroIzq,
    WstIzq,
    WstDerecha,
    TravelzunIzq,
    TravelzunDerecha,
  },
  data() {
    return {
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      email: null,
      loading: false,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
  computed: {
    ...mapState({
      configDataGeneral: state => state.app.configDataGeneral,
    }),
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';

.logoSize {
  height: 99px;
  width: 99px;
}
</style>
