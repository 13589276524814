<template>
  <div v-if="!isLoading">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="5%"
              class="text-uppercase"
            >
              <v-checkbox
                v-model="checkAllLocal"
                hide-details
                class="my-0"
                @change="checkinAll"
              ></v-checkbox>
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.code') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.service') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.monto') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.tipo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.state') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.acredor') }}
            </th>
            <th
              v-if="
                permisos.includes('reservas:edit') || permisos.includes('reservas:destroy') || permisos.includes('*')
              "
              class="text-uppercase"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(cta, index) in ctas"
            :key="index"
          >
            <td>
              <v-checkbox
                v-model="cta.check"
                hide-details
                class="my-0"
                :disabled="idPay === cta.status_id"
                @change="checkOne(cta)"
              ></v-checkbox>
            </td>
            <td width="15%">
              <span
                class="d-inline-block text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{ cta.code }}
              </span>
            </td>
            <td>{{ cta.data_service.date_in | moment('D/MM/Y') }}</td>
            <td>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ cta.monto | currency }} </span>
            </td>
            <td>
              <span v-if="payments.filter(e => e.id === cta.method_pay_id).length > 0">
                {{ payments.filter(e => e.id === cta.method_pay_id)[0].name }}
              </span>
            </td>
            <td>
              <v-chip :color="cta.color_state">
                {{ cta.status.name }}
              </v-chip>
            </td>
            <td>
              <!--{{ cta.data_proveedor.company_name }}-->
              {{ cta.company.user_name }}
            </td>
            <td
              v-if="permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')"
              width="5%"
            >
              <v-menu
                v-if="idPay !== cta.status_id"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="permisos.includes('reservas:edit') || permisos.includes('*')"
                    link
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="permisos.includes('reservas:edit') || permisos.includes('*')"
                    link
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.gestion') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiSendOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    ctas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    statesCtas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    idPay: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    payments: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      checkAllLocal: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiSendOutline,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      checkAll: state => state.app.checkAll,
      itemsCheck: state => state.app.itemsCheck,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'setCheckAll', 'addItemCheck', 'deleteItemCheck']),
    showItem(iten) {
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-show' })
    },
    editItem(iten) {
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`reservations/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },

    checkinAll() {
      this.setCheckAll(this.checkAllLocal)
      if (this.checkAllLocal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctas.length; index++) {
          // VERIFICAR Q NO ESTA PAGA
          if (this.ctas[index].status_id !== this.idPay) {
            this.ctas[index].check = true
          }

          // VERIFICAR Q NO DUPLIQUE LA CTA
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheck.length; j++) {
            if (this.itemsCheck[j].code === this.ctas[index].code) {
              esta = true
            }
          }
          if (!esta && this.ctas[index].status_id !== this.idPay) {
            this.addItemCheck(this.ctas[index])
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctas.length; index++) {
          this.ctas[index].check = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheck.length; j++) {
            if (this.itemsCheck[j].code === this.ctas[index].code) {
              this.deleteItemCheck(j)
            }
          }
        }
      }
    },
    checkOne(cta) {
      if (cta.check) {
        this.addItemCheck(cta)

        // VERIFICAR SI TODOS ESTAN MARCADOS
        let countCtas = 0
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctas.length; index++) {
          // VERIFICAR Q NO ESTA PAGA
          if (this.ctas[index].status_id !== this.idPay) {
            // eslint-disable-next-line no-plusplus
            countCtas++
          }
        }
        if (this.itemsCheck.length === countCtas) {
          this.setCheckAll(true)
          this.checkAllLocal = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < this.itemsCheck.length; j++) {
          if (this.itemsCheck[j].code === cta.code) {
            this.deleteItemCheck(j)
          }
        }

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheck.length !== this.ctas.length) {
          this.setCheckAll(false)
          this.checkAllLocal = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
