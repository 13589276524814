<template>
  <div v-if="!isLoading">
    <fragment
      v-for="(car, indCar) in reserva.car_shop"
      :key="indCar"
    >
      <TransactionCar
        v-if="car.entity === 'cars'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
      />
      <TransactionHotel
        v-else-if="car.entity === 'hotels'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
      />
    </fragment>
    <template v-if="countTransactions === 0">
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t('msg.noTransactions') }}
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'

import TransactionCar from './TransactionCar.vue'
import TransactionHotel from './TransactionHotel.vue'

export default {
  components: {
    TransactionCar,
    TransactionHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisibleDelete: false,
      isDialogVisible: false,
      item: {},
      itemId: null,
      loading: false,
      reservando: false,
      permisos: localStorage.getItem('permisos_auth'),
      states: [],
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
    }),
    countTransactions() {
      let cont = 0

      this.reserva.car_shop.forEach(element => {
        if (element.history_transactions) {
          if (element.history_transactions.length > 0) {
            cont = 1
          }
        }
      })

      return cont
    },
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
