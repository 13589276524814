<template>
  <v-row class="match-height">
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.roles')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'roles-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  :placeholder="$t('lbl.name')"
                  hide-details
                ></v-text-field>
                <v-switch
                  v-model="model.active"
                  :label="$t('lbl.active')"
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="model.description"
                  :label="$t('lbl.description')"
                  outlined
                  rows="3"
                  row-height="25"
                ></v-textarea>
              </v-col>

              <!--<v-col cols="12">
                <Permisos
                  :exclude="[]"
                />
              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
// import Permisos from '@/views/utils/permisos/List.vue'
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,

    // Permisos,
  },
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      model: {},
      permisos: [],
      loading: false,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('role-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState(['permisosItem']),
  },
  mounted() {
    this.loadPermisos()
    this.getItem()
  },
  methods: {
    ...mapMutations(['editPermisosItem']),
    getItem() {
      if (localStorage.getItem('role-id') !== null) {
        const id = localStorage.getItem('role-id')
        this.axios
          .get(`role/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.permisos.abilities[0] === '*') {
              // cargar los permisos
              this.axios
                .get('permiso?per_page=1000', {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                  },
                })
                .then(per => {
                  this.permisos = per.data.data
                })
                .finally(() => {
                  const perm = []
                  this.permisos.forEach(element => {
                    perm.push(element.ability)
                  })
                  this.editPermisosItem(perm)
                })
            } else {
              this.loadPermisos()
              this.editPermisosItem(this.model.permisos.abilities)
            }
          })
      } else {
        this.editPermisosItem([])
      }
    },
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
    save() {
      if (this.model.name /* && this.$store.state.app.permisosItem.length > 0 */) {
        this.loading = true
        const json = {
          name: this.model.name,
          description: this.model.description,
          perfil_slug: localStorage.getItem('perfil'),
          active: this.model.active,
          permisos: this.$store.state.app.permisosItem.join(','),
        }
        if (localStorage.getItem('role-id') === null) {
          this.axios
            .post('role', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.roleExist', { identificador: this.model.name }))
                }
              } else {
                localStorage.removeItem('role-id')
                this.$router.push({ name: 'roles-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`role/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('role-id')
                this.$router.push({ name: 'roles-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
