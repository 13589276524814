<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="dataBancaria.tipo_fiscal"
            :items="tipos_fiscal"
            :label="$t('register.tipoFiscal')"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="dataBancaria.cif_nif_facturation"
            :label="$t('register.idFiscal')"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          v-for="(address, indA) in dataBancaria.info_fiscal.address"
          :key="indA"
          cols="12"
          md="12"
        >
          <Address
            :address="address"
            :pos="indA"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="dataBancaria.info_fiscal.code_postal"
            :label="$t('register.codePostal1')"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="dataBancaria.info_fiscal.localidad"
            :label="$t('register.locality')"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="dataBancaria.info_fiscal.country"
            :items="countries"
            :label="$t('register.country')"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import Address from './Address.vue'

export default {
  components: {
    Address,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tipos_fiscal: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    countries: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      dataBancaria: state => state.app.dataBancaria,
    }),
  },

  /* created() {
    if (!this.dataBancaria.info_fiscal) {
      this.dataBancaria.info_fiscal = {
        address: [
          {
            address: null,
          },
        ],
        code_postal: null,
        localidad: null,
        country: null,
      }
    }
  }, */
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
