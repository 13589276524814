<template>
  <v-row class="my-6 mx-1">
    <v-col
      cols="11"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <v-col cols="12">
          <strong>{{ $t('lbl.cat') }}</strong>
          <v-autocomplete
            v-model="cat.category_id"
            class="pt-2"
            :items="itemsCategories"
            :search-input.sync="searchCategory"
            hide-details
            hide-selected
            :label="$t('lbl.cat')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
            return-object
            :disabled="$store.state.app.onlyShow"
            @change="verifyPricesTemporadasContrateCar"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.cat') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <!--<v-select
            v-model="cat.modelo_id"
            class="pt-2"
            :items="modelos"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-detailst
            multiple
          >
          </v-select>-->
          <v-autocomplete
            v-model="cat.cars_id"
            class="pt-2"
            :items="cars"
            :label="$t('menu.modelos')"
            outlined
            hide-details
            dense
            chips
            small-chips
            multiple
            item-text="name"
            item-value="id"
            return-object
            :disabled="$store.state.app.onlyShow"
          >
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="mt-0 pt-0"
        >
          <v-select
            v-model="cat.edad_id"
            class="pt-2"
            :label="$t('lbl.edadConductor')"
            :items="edades"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-detailst
            :disabled="$store.state.app.onlyShow"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-col class="px-4">
          <v-tooltip
            v-if="!$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="error"
                @click="deleteCategoryContrateCar(index)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    cat: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
      },
      categories: [],
      itemsCategories: [],
      searchCategory: null,

      cars: [],
      itemsModelos: [],
      searchModelo: null,
      edades: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchCategory(val) {
      if (val !== null && val.length > 1) {
        this.filterCategory(val.toLowerCase())
      }
    },
    searchModelo(val) {
      if (val !== null && val.length > 1) {
        this.filterModelo(val.toLowerCase())
      }
    },
  },
  mounted() {
    if (this.cat.category_id) {
      this.itemsCategories.push(this.cat.category_id)
    }
  },
  created() {
    this.getCategories()
    this.getEdades()
    this.getCars()
  },
  methods: {
    ...mapMutations(['deleteCategoryContrateCar', 'verifyPricesTemporadasContrateCar']),
    getEdades() {
      this.axios
        .get(`nom_edades_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
        })
    },
    getCategories() {
      this.axios
        .get('nom_categories_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categories = res.data.data
          }
        })
    },
    getCars() {
      const json = {
        per_page: 100000,
        perfil_slug: localStorage.getItem('perfil'),
        search: { marca_id: this.cat.marca_id },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.cars = res.data.data
          }
        })
    },
    filterCategory(v) {
      this.itemsCategories = []
      if (v === '') {
        this.itemsCategories = []
      } else {
        this.itemsCategories = this.categories.filter(e => e.name.toLowerCase())
      }
    },
    filterModelo(v) {
      this.itemsModelos = []
      if (v === '') {
        this.itemsModelos = []
      } else {
        this.itemsModelos = this.cars.filter(e => e.name.toLowerCase())
      }
    },
  },
}
</script>
