<template>
  <div>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('msg.deleteItem', { item: item.name }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_oficina_renta_cars:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('nom_oficina_renta_cars:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="importItem"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.oficinas') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="10%"
              class="text-uppercase"
            >
              {{ $t('lbl.pto') }}
            </th>
            <th
              width="40%"
              class="text-uppercase"
            >
              {{ $t('lbl.oficina') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.locality') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.provinceEstado') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.country') }}
            </th>
            <!--<th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.telefonos') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.horarios') }}
            </th>-->
            <th
              v-if="
                permisos.includes('nom_oficina_renta_cars:edit') ||
                  permisos.includes('nom_oficina_renta_cars:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              width="15%"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <span v-if="iten.pto_recogida">
                <v-icon v-if="iten.punto_recogida.icon">
                  {{ iten.punto_recogida.icon }}
                </v-icon>
              </span>
            </td>
            <td>
              {{ iten.name }}
            </td>
            <td>
              <span v-if="iten.city">{{ iten.city }}</span>
            </td>
            <td>
              <span v-if="iten.state">{{ iten.state }}</span>
            </td>
            <td>
              <span v-if="iten.country">{{ iten.country }}</span>
            </td>
            <!--<td>
              <fragment v-if="iten.telefonos !== null">
                <span
                  v-if="iten.telefonos.length > 0"
                >
                  +{{ iten.telefonos[0].number }}
                </span>
                <v-tooltip
                  v-if="iten.telefonos.length > 1"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiInformation }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-text>
                        <div class="text--primary">
                          <span
                            v-for="(telef, iti) in iten.telefonos"
                            :key="iti"
                          >
                            +{{ telef.number }}
                            <span v-if="telef.ext !== null && telef.ext !== undefined">{{ ` Ext.: ${telef.ext}` }}</span>
                            <br>
                          </span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-tooltip>
              </fragment>
            </td>
            <td>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card
                    class="mx-auto"
                  >
                    <v-card-text>
                      <div class="text--primary">
                        <span
                          v-for="(hor, i) in iten.horarios"
                          :key="i"
                        >
                          <span
                            v-for="(frecuency, f) in hor.modelSemana"
                            :key="`f${f}`"
                          >
                            <span v-if="frecuency === 0">Domingo</span>
                            <span v-else-if="frecuency === 1">Lunes</span>
                            <span v-else-if="frecuency === 2">Martes</span>
                            <span v-else-if="frecuency === 3">Miércoles</span>
                            <span v-else-if="frecuency === 4">Jueves</span>
                            <span v-else-if="frecuency === 5">Viernes</span>
                            <span v-else-if="frecuency === 6">Sábado</span>
                            <span v-if="f !== hor.modelSemana.length - 1">, </span>
                          </span><br>
                          <span
                            v-for="(horar, h) in hor.horarios"
                            :key="`h${h}`"
                          >
                            <span>{{ horar.from }}</span> - <span>{{ horar.to }}</span>
                            <br>
                          </span>
                          <br>
                        </span>
                      </div>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>
            </td>-->
            <td
              v-if="
                permisos.includes('nom_oficina_renta_cars:edit') ||
                  permisos.includes('nom_oficina_renta_cars:list') ||
                  permisos.includes('nom_oficina_renta_cars:destroy') ||
                  permisos.includes('*')
              "
            >
              <v-tooltip
                v-if="permisos.includes('nom_oficina_renta_cars:list') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showItem(iten)"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.see') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="permisos.includes('nom_oficina_renta_cars:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('nom_oficina_renta_cars:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiCloudUpload,
  mdiInformation,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiCloudUpload,
        mdiInformation,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleImport: false,
      loadingImport: false,
      uploadFile: null,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },

  created() {
    this.profile()
    if (localStorage.getItem('current_page_ofices') !== null) {
      this.pagination.current = parseInt(localStorage.getItem('current_page_ofices'), 10)
    } else {
      this.pagination.current = 1
    }
    this.load()
    this.loadAll()
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'resetGeoTag', 'resetOficinaRenta']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(
          `nom_oficina_renta_cars?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${localStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data

            if (localStorage.getItem('current_page_ofices') !== null) {
              this.pagination.current = parseInt(localStorage.getItem('current_page_ofices'), 10)
              localStorage.removeItem('current_page_ofices')
            } else {
              this.pagination.current = res.data.meta.current_page
            }

            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      this.axios
        .get('nom_oficina_renta_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    importItem() {
      this.$router.push({ name: 'oficinas-cars-import' })
    },
    newItem() {
      this.updateGeoTag({ longitud: undefined, latitud: undefined })
      localStorage.removeItem('oficina-renta-id')
      localStorage.removeItem('oficina-renta-show')
      this.$router.push({ name: 'oficinas-cars-update' })
    },
    editItem(item) {
      localStorage.setItem('oficina-renta-id', item.id)
      localStorage.setItem('current_page_ofices', this.pagination.current)
      localStorage.removeItem('oficina-renta-show')
      this.$router.push({ name: 'oficinas-cars-update' })
    },
    showItem(item) {
      localStorage.setItem('oficina-renta-id', item.id)
      localStorage.setItem('oficina-renta-show', true)
      localStorage.setItem('current_page_ofices', this.pagination.current)
      this.resetGeoTag()
      this.resetOficinaRenta()
      this.$router.push({ name: 'oficinas-cars-update' })
    },
    save() {
      if (this.item.name) {
        this.loading = true
        this.item.perfil_slug = localStorage.getItem('perfil')

        if (this.itemId === null) {
          this.axios
            .post('nom_oficina_renta_cars', this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.combustibleExist', { identificador: this.item.name }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .put(`nom_oficina_renta_cars/${this.itemId}`, this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_oficina_renta_cars/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },
    reset() {
      this.loadingImport = false
      this.isDialogVisibleImport = false
      this.uploadFile = null
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
