<template>
  <div
    v-if="!isLoading"
    class="d-none d-md-block"
  >
    <!-- ref="statiCard" style="position: fixed; z-index: 6; width: available"-->
    <v-col
      cols="12"
      md="12"
      class=""
    >
      <v-row
        v-if="showOnlyGestor"
        class="mb-3"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <h1
                  primary-title
                  class="pa-0"
                >
                  PVP
                </h1>
              </v-col>
              <!--CALCULADORA-->
              <v-row class="pt-2">
                <v-col
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="markup"
                    label="Markup"
                    hide-details
                    outlined
                    width="auto"
                    class="descuento"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueMarkup"
                    hide-details
                    dense
                    class="mt-0 pt-0 descuento"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="agregarDescuento"
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="descuento"
                    class="descuento"
                    :label="$t('lbl.descuento')"
                    hide-details
                    outlined
                    width="auto"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="agregarDescuento"
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueDescuento"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10">
                  <v-checkbox
                    v-model="agregarDescuento"
                    :label="$t('lbl.addDescuento')"
                    dense
                    hide-details
                    outlined
                    class="descuento my-0 py-0"
                    :aria-checked="agregarDescuento ? true : false"
                    @change="changeAgregarDescuento"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="2"
                  class="align-self-center align-right"
                >
                  <v-btn
                    color="primary"
                    @click="applyMarkups"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="mt-12"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                  </p>
                </div>
              </v-row>
              <v-row class="mt-6 d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conMarkups') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithMarkup | currency }}
                  </p>
                </div>
              </v-row>
              <v-row
                v-if="agregarDescuento"
                class="mt-6 d-flex justify-center align-center"
              >
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conDescuento') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithDescuento | currency }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <!--MOSTRAR PARA EL GESTOR-->
      <fragment v-if="showOnlyGestor">
        <!--<v-row>
          <v-card
            width="100%"
            class="mx-2 pa-4"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="">
                {{ $t('lbl.rentaCar') }}
              </h4>
              <h4>
                <h4>
                  {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center mt-0">
              <span class="caption">
                {{ $t('lbl.rentaCarByDay') }}
              </span>
              <span class="caption">
                {{ cotizadorCarsResultSelect.tarifaDay | currency }}
              </span>
            </div>
            <div class="d-flex justify-space-between align-center mt-0">
              <h3>
                {{ $t('lbl.total') }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block mb-2">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p class="mb-0 pr-6">Tarifa</p>
                        <p class="mb-0">776,87 $</p>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <p
                          class="mb-0 pr-6"
                          subtitle-2
                        >Impuestos y tasas</p>
                        <p
                          class="mb-0"
                          subtitle-2
                        >776,87 $</p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
              </h3>

              <h3>
                <h4>
                  {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                </h4>
              </h3>
            </div>
          </v-card>
        </v-row>-->
        <v-row class="my-2">
          <v-card
            width="100%"
            class="mx-2 pa-4 pt-0"
          >
            <!--<v-card-title
              primary-title
              class="px-0 py-2"
            >
              {{ $t('lbl.priceByCliente') }}
            </v-card-title>-->
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-1">
                {{ $t('lbl.tarif') }}
              </h4>
              <h4>
                <h4>
                  {{ cotizadorCarsResultSelect.priceTotal | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                <span>
                  {{ $t('lbl.incluyeMargenCom') }}
                </span>
                <fragment
                  v-if="cotizadorCarsSelectMarkups.markup !== 0 && cotizadorCarsSelectMarkups.valueMarkup !== null"
                >
                  ({{ cotizadorCarsSelectMarkups.markup }}
                  <span v-if="cotizadorCarsSelectMarkups.valueMarkup === '%'">%</span>
                  <span v-else-if="cotizadorCarsSelectMarkups.valueMarkup === '$'">USD</span>)
                </fragment>
              </span>
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{
                  cotizadorCarsResultSelect.priceWithMarkup > 0
                    ? cotizadorCarsResultSelect.priceWithMarkup
                    : 0 | currency
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                <span>
                  {{ $t('lbl.incluyeDescuento') }}
                </span>
                <fragment
                  v-if="
                    cotizadorCarsSelectMarkups.descuento !== 0 && cotizadorCarsSelectMarkups.valueDescuento !== null
                  "
                >
                  ({{ cotizadorCarsSelectMarkups.descuento }}
                  <span v-if="cotizadorCarsSelectMarkups.valueDescuento === '%'">%</span>
                  <span v-else-if="cotizadorCarsSelectMarkups.valueDescuento === '$'">USD</span>)
                </fragment>
              </span>
              <span style="font-size: 12px">
                {{
                  cotizadorCarsResultSelect.priceWithDescuento > 0
                    ? cotizadorCarsResultSelect.priceWithDescuento
                    : 0 | currency
                }}
              </span>
            </div>

            <!--SUPLEMENTOS-->
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-1">
                {{ $t('lbl.cargoSuplement') }}
              </h4>
              <h4>
                <h4>
                  {{ (precioSuplementPayOnline + precioSuplementPayRentator) | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ $t('lbl.seguro') }}
              </span>
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ cotizadorCarsResultSelect.tarifaSeguro | currency }}
              </span>
            </div>
            <div
              v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
              :key="Math.random() * (indSupl + 1)"
              class="d-flex justify-space-between align-center ml-2"
            >
              <fragment v-if="!!suplemento.online && !!suplemento.check">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.suplemento.name }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.priceAll | currency }}
                </span>
              </fragment>
            </div>
            <!--PAY EN RENTADORA-->
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ $t('lbl.depositoGarantia') }}
              </span>
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ cotizadorCarsResultSelect.tarifaGarantia | currency }}
              </span>
            </div>
            <div
              v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
              :key="Math.random() * (indSupl + 1)"
              class="d-flex justify-space-between align-center ml-2"
            >
              <fragment v-if="!suplemento.online && !!suplemento.check">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.suplemento.name }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.priceAll | currency }}
                </span>
              </fragment>
            </div>
          </v-card>

          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h3 class="my-2">
                {{ $t('lbl.totalPay') }}
              </h3>
              <h3>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h3>
            </div>
            <fragment v-if="precioSuplementPayOnline > 0">
              <div class="d-flex justify-space-between align-center">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                > {{ $t('lbl.payOnline') }} </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ (precioSuplementPayOnline + cotizadorCarsResultSelect.priceTotal) | currency }}
                </span>
              </div>
            </fragment>

            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.payRent') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ precioSuplementPayRentator | currency }}
                </span>
              </div>
            </fragment>
          </v-card>

          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <v-card-title
              primary-title
              class="px-0 py-2"
            >
              {{ $t('lbl.otherCarge') }}
            </v-card-title>
            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payRent') }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.dayExtra') }} (x {{ $t('lbl.dia') }})
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaDayExtra | currency }}
                </span>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                > {{ $t('lbl.tarifaRetorno') }} (x km) </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ 0 | currency }}
                </span>
              </div>
            </fragment>
          </v-card>
        </v-row>
      </fragment>

      <!--MOSTRAR PARA EL CLIENTE-->
      <v-row>
        <fragment v-if="!showOnlyGestor">
          <v-card
            width="100%"
            class="mx-2 pa-4"
          >
            <div class="d-flex justify-space-between align-center">
              <h1
                primary-title
                class="pl-3"
              >
                PVP
              </h1>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h4 class="mb-4">
                {{ $t('lbl.tarif') }}
              </h4>
              <h4 class="mb-4">
                <h4>
                  {{ cotizadorCarsResultSelect.priceTotal | currency }}
                </h4>
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center">
              <h4>
                {{ $t('lbl.cargoSuplement') }}
              </h4>
              <h4>
                <h4>
                  {{ (precioSuplementPayOnline + precioSuplementPayRentator) | currency }}
                </h4>
              </h4>
            </div>
            <!---->
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ $t('lbl.seguro') }}
              </span>
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ cotizadorCarsResultSelect.tarifaSeguro | currency }}
              </span>
            </div>
            <div
              v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
              :key="Math.random() * (indSupl + 1)"
              class="d-flex justify-space-between align-center ml-2"
            >
              <fragment
                v-if="
                  suplemento.suplemento.slug.toLowerCase() !== 'tarifa-de-retorno' &&
                    suplemento.check === true &&
                    suplemento.priceAll > 0
                "
              >
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.suplemento.name }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ suplemento.priceAll | currency }}
                </span>
              </fragment>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ $t('lbl.depositoGarantia') }}
              </span>
              <span
                class="pb-0 mb-0 pt-0 mt-0"
                style="font-size: 12px"
              >
                {{ cotizadorCarsResultSelect.tarifaGarantia | currency }}
              </span>
            </div>
            <fragment v-if="precioTarifaRetorno > 0">
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.tarifaRetorno') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ precioTarifaRetorno | currency }}
                </span>
              </div>
            </fragment>
          </v-card>
          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h3 class="my-2">
                {{ $t('lbl.totalPay') }}
              </h3>
              <h3>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h3>
            </div>
            <fragment v-if="precioSuplementPayOnline > 0">
              <div class="d-flex justify-space-between align-center">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                > {{ $t('lbl.payOnline') }} </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ (precioSuplementPayOnline + cotizadorCarsResultSelect.priceTotal) | currency }}
                </span>
              </div>
            </fragment>

            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.payRent') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ precioSuplementPayRentator | currency }}
                </span>
              </div>
            </fragment>
          </v-card>
          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <v-card-title
              primary-title
              class="px-0 py-2"
            >
              {{ $t('lbl.otherCarge') }}
            </v-card-title>
            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payRent') }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.dayExtra') }} (x {{ $t('lbl.dia') }})
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaDayExtra | currency }}
                </span>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                > {{ $t('lbl.tarifaRetorno') }} (x km) </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ 0 | currency }}
                </span>
              </div>
            </fragment>
          </v-card>
        </fragment>

        <!--BOTONERA-->
        <v-card
          width="100%"
          class="mx-2 mt-3 pa-4 pt-0"
        >
          <v-container grid-list-xs>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  block
                  :loading="loading"
                  @click="save()"
                >
                  <v-icon class="mr-4">
                    {{ icons.mdiUpdate }}
                  </v-icon>
                  {{ $t('btn.update') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiCartOutline,
  mdiClipboardCheckOutline,
  mdiUpdate,
} from '@mdi/js'

export default {
  data() {
    return {
      nameCompany: '',
      nameUser: '',
      btnName: '',
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
      printCotiItems: null,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiCartOutline,
        mdiClipboardCheckOutline,
        mdiUpdate,
      },
      precioWithMUP: 0,
      precioWithDESC: 0,
      loadingMarkups: true,
      permisos: localStorage.getItem('permisos_auth'),
      user: null,
      showModalPrint: false,
      showModalSend: false,
      textRequiered: [v => !!v || 'Campo requerido'],
      recogida: null,
      entrega: null,
      dateRecogida: null,
      dateEntrega: null,
      entregaDiffRecogida: false,
    }
  },
  computed: {
    ...mapState({
      carItems: state => state.app.carItems,
      driverAgeCumple: state => state.app.driverAgeCumple,
      declineTerms: state => state.app.declineTerms,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
      cotizadorCarsResultSelectEdit: state => state.app.cotizadorCarsResultSelectEdit,
      cotizadorCarsResultSelectEditWith: state => state.app.cotizadorCarsResultSelectEditWith,
      cotizadorCarsSelectMarkups: state => state.app.cotizadorCarsSelectMarkups,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
      noDocumentConductorPrincipalScanner: state => state.app.noDocumentConductorPrincipalScanner,
      noDocumentConductorAditionalScanner: state => state.app.noDocumentConductorAditionalScanner,
      clientCarShop: state => state.app.clientCarShop,
    }),
    precioWithMarkup() {
      let priceTotal = this.cotizadorCarsResultSelect.tarifaAllDay

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = Math.ceil((parseFloat(priceTotal) * parseFloat(this.markup)) / 100)
          // eslint-disable-next-line no-mixed-operators
          priceTotal = Math.ceil(parseFloat(priceTotal) + this.precioWithMUP)
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = Math.ceil(parseFloat(this.markup))
          priceTotal = Math.ceil(parseFloat(priceTotal) + parseFloat(this.markup))
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.cotizadorCarsResultSelect.tarifaAllDay

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = Math.ceil((parseFloat(priceTotal) * parseFloat(this.markup)) / 100)
          // eslint-disable-next-line no-mixed-operators
          priceTotal = Math.ceil(parseFloat(priceTotal) + this.incluyeComition)
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = Math.ceil(parseFloat(this.markup))
          priceTotal = Math.ceil(parseFloat(priceTotal) + this.incluyeComition)
        }
      }

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = Math.ceil(priceTotal * (parseFloat(this.descuento) / 100))

          // this.incluyeDescuento = (this.cotizadorCarsResultSelect.tarifaAllDay * parseFloat(this.descuento)) / 100

          // this.incluyeDescuento = (parseFloat(priceTotal) * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          // eslint-disable-next-line no-mixed-operators
          priceTotal = Math.ceil(parseFloat(priceTotal) - this.incluyeDescuento)
        } // USD
        if (this.valueDescuento === '$') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = Math.ceil(parseFloat(this.descuento))
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          priceTotal = Math.ceil(parseFloat(priceTotal) - this.incluyeDescuento)
        }
      }

      return priceTotal
    },
    precioTotal() {
      let priceTotal = this.cotizadorCarsResultSelect.priceTotal
        + parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioTarifaRetorno() {
      let priceTotal = 0

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
          priceTotal = suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayOnline() {
      let priceTotal = parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaSeguro)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayRentator() {
      let priceTotal = parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },

    generateJsonCar() {
      const suplementos = []
      this.cotizadorCarsResultSelect.suplementos.forEach(element => {
        if (element.check) {
          suplementos.push(element)
        }
      })

      // PAY TOTAL
      let priceTotal = this.cotizadorCarsResultSelect.priceTotal
        + parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaSeguro)
        + parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      })
      const jsonItem = {
        car: this.cotizadorCarsResultSelectEditWith.car,
        category_id: this.cotizadorCarsResultSelectEditWith.category_id,
        marca_id: this.cotizadorCarsResultSelectEditWith.marca_id,
        contrate_id: this.cotizadorCarsResultSelectEditWith.contrate_id,
        cuposDisponibles: this.cotizadorCarsResultSelectEditWith.cuposDisponibles,
        diasReservar: this.cotizadorCarsResultSelectEditWith.diasReservar,
        priceTotal,

        // priceTotal: this.cotizadorCarsResultSelect.priceTotal,
        priceWithDescuento: this.priceWithDescuento,
        priceWithMarkup: this.priceWithMarkup,
        rentadora: this.cotizadorCarsResultSelectEditWith.rentadora,
        tarifaAllDay: this.cotizadorCarsResultSelectEditWith.tarifaAllDay,
        tarifaDay: this.cotizadorCarsResultSelectEditWith.tarifaDay,
        tarifaDayExtra: this.cotizadorCarsResultSelectEditWith.tarifaDayExtra,
        tarifaGarantia: this.cotizadorCarsResultSelectEditWith.tarifaGarantia,
        tarifaSeguro: this.cotizadorCarsResultSelectEditWith.tarifaSeguro,
        suplementos,
      }

      let recogida
      if (this.recogida.type === 'oficina') {
        recogida = this.recogida
      } else if (this.recogida.type === 'destino') {
        recogida = this.recogida
      }

      let entrega
      if (this.entrega) {
        if (this.entrega.type === 'oficina') {
          entrega = this.entrega
        } else if (this.entrega.type === 'destino') {
          entrega = this.entrega
        }
      } else {
        entrega = recogida
      }

      const jsonReserve = {
        entrega,
        dateEntrega: this.dateEntrega,
        recogida,
        dateRecogida: this.dateRecogida,
        entregaDiffRecogida: this.entregaDiffRecogida,
        items: jsonItem,
      }

      let payOnline = parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaSeguro)
        + parseFloat(this.cotizadorCarsResultSelect.priceTotal)
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          payOnline += suplement.priceAll
        }
      })

      let payRentadora = parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaGarantia)
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          payRentadora += suplement.priceAll
        }
      })

      let payRetorno = 0
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
          payRetorno = suplement.priceAll
        }
      })
      const jsonPrices = {
        priceTotal,
        priceTarifa: this.cotizadorCarsResultSelect.priceTotal,
        precioSuplementPayOnline: payOnline,
        precioSuplementPayRentator: payRentadora,
        precioTarifaRetorno: payRetorno,
        precioTarifaxKm: 0,
        diasReservar: this.cotizadorCarsResultSelectEditWith.diasReservar,
        edad: this.cotizadorCarsResultSelectEdit.edad,
        edad_max: this.cotizadorCarsResultSelectEdit.edad_max,
        conditions_id: this.cotizadorCarsResultSelectEdit.conditions_id,
        contrate_id: this.cotizadorCarsResultSelectEdit.contrate_id,
        proveedor_id: this.cotizadorCarsResultSelectEdit.proveedor_id,
        markups: this.cotizadorCarsResultSelectEdit.markups,
        descuento: this.cotizadorCarsResultSelect.priceWithDescuento,
        descuento_v: this.descuento,
        descuento_value: this.valueDescuento,
        markup: this.cotizadorCarsResultSelect.priceWithMarkup,
        markup_v: this.markup,
        markup_value: this.valueMarkup,
        suplementos,
        tarifaAllNeto: this.cotizadorCarsResultSelectEditWith.tarifaAllDay,
        tarifaDayNeto: this.cotizadorCarsResultSelectEditWith.tarifaDay,
        tarifaDayExtra: this.cotizadorCarsResultSelectEditWith.tarifaDayExtra,
        tarifaGarantia: this.cotizadorCarsResultSelectEditWith.tarifaGarantia,
        tarifaSeguro: this.cotizadorCarsResultSelectEditWith.tarifaSeguro,
      }

      const json = {
        check: true,
        product: 'cars',
        data_client: this.cotizadorPaxCarSelect,
        data_markups: jsonPrices,
        data_service: jsonReserve,
        data_reserva: {},
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }

      return json
    },
  },
  created() {
    this.profile()

    let mark = {
      markup: 0,
      valueMarkup: 0,
      descuento: 0,
      valueDescuento: 0,
    }

    const item = JSON.parse(localStorage.getItem('car-shop-item'))
    this.setCotizadorCarsResultSelectEdit(item.item.data_markups)
    this.setCotizadorCarsResultSelectEditWith(item.item.data_service.items)

    this.recogida = item.item.data_service.recogida
    this.dateRecogida = item.item.data_service.dateRecogida
    this.entrega = item.item.data_service.entrega
    this.dateEntrega = item.item.data_service.dateEntrega

    /* if (this.cotizadorCarsResultSelectEdit.markups) {
      this.valueMarkup = '%'
      if (!this.cotizadorCarsResultSelectEdit.markups.is_pc) {
        if (this.cotizadorCarsResultSelectEdit.markups.markup > 0) {
          this.valueMarkup = '$'
        } else {
          this.valueMarkup = null
        }
      }

      if (this.cotizadorCarsResultSelectEdit.markups.markup && this.valueMarkup === '%') {
        this.markup = this.cotizadorCarsResultSelectEdit.markups.markup
      } else if (this.cotizadorCarsResultSelectEdit.markups.markup && this.valueMarkup === '$') {
        this.markup = this.cotizadorCarsResultSelectEdit.markups.markup * this.cotizadorCarsResultSelectEdit.diasReservar
      }
      mark = {
        markup: this.markup,
        valueMarkup: this.valueMarkup,
        descuento: 0,
        valueDescuento: 0,
      }

      this.applyMarkups()

      this.cotizadorCarsResultSelect.priceWithMarkup = this.cotizadorCarsResultSelectEditWith.priceWithMarkup
      this.cotizadorCarsResultSelect.priceWithDescuento = this.cotizadorCarsResultSelectEditWith.priceWithDescuento

      setTimeout(() => {
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.cotizadorCarsResultSelectEditWith.priceWithMarkup = Math.ceil(
            (parseFloat(this.cotizadorCarsResultSelectEditWith.tarifaAllDay) * parseFloat(this.markup)) / 100,
          )
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.cotizadorCarsResultSelectEditWith.priceWithMarkup = Math.ceil(parseFloat(this.markup))
        }
      }, 100)
    } */
    if (this.cotizadorCarsResultSelectEdit.markup || this.cotizadorCarsResultSelectEdit.descuento) {
      this.valueMarkup = this.cotizadorCarsResultSelectEdit.markup_value

      if (this.valueMarkup === '%') {
        this.markup = this.cotizadorCarsResultSelectEdit.markup_v
      } else {
        this.markup = this.cotizadorCarsResultSelectEdit.markup
      }

      if (this.cotizadorCarsResultSelectEdit.descuento > 0) {
        this.agregarDescuento = true
        this.valueDescuento = this.cotizadorCarsResultSelectEdit.descuento_value

        if (this.valueMarkup === '%') {
          this.descuento = this.cotizadorCarsResultSelectEdit.descuento_v
        } else {
          this.descuento = this.cotizadorCarsResultSelectEdit.descuento
        }
      }

      /* if (this.cotizadorCarsResultSelectEdit.markups.markup && this.valueMarkup === '%') {
        this.markup = this.cotizadorCarsResultSelectEdit.markups.markup
      } else if (this.cotizadorCarsResultSelectEdit.markups.markup && this.valueMarkup === '$') {
        this.markup = this.cotizadorCarsResultSelectEdit.markups.markup * this.cotizadorCarsResultSelectEdit.diasReservar
      } */
      mark = {
        markup: this.markup,
        valueMarkup: this.valueMarkup,
        descuento: this.descuento,
        valueDescuento: this.valueDescuento,
      }

      this.applyMarkups()

      this.cotizadorCarsResultSelect.priceWithMarkup = this.cotizadorCarsResultSelectEditWith.priceWithMarkup
      this.cotizadorCarsResultSelect.priceWithDescuento = this.cotizadorCarsResultSelectEditWith.priceWithDescuento
    }

    this.setCotizadorCarsSelectMarkups(mark)

    // PARA FIJAR LA COLUMNA D LA DERECHA
    // window.addEventListener('scroll', this.handleScroll)
    if (this.permisos.includes('pvp:list')) {
      window.addEventListener('keydown', e => {
        if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
          this.changePrice()
        }
      })
    } else {
      this.changePrice()
    }
  },

  /* destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }, */
  methods: {
    ...mapMutations([
      'setCotizadorCarsSelectMarkups',
      'setCotizadorCarSelectPrice',
      'adicionarItemToCar',
      'setCotizadorCarsResult',
      'setSearchingCar',
      'setCotizadorCars',
      'setCotizadorCarsResultSelect',
      'setCotizadorCarsResultSelectEdit',
      'setCotizadorCarsResultSelectEditWith',
      'setCotizadorPaxCarSelect',
      'updateLoadingBtn',
      'updateItemToCar',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data
            this.nameCompany = this.user.company.name
            this.nameUser = `${this.user.name} ${this.user.apellidos}`
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false
        })
    },
    handleScroll() {
      if (window.scrollY >= 60) {
        this.$refs.statiCard.classList.add('topMine')
      } else {
        this.$refs.statiCard.classList.remove('topMine')
      }
    },
    goBack() {
      localStorage.removeItem('car-shop-item')
      this.$router.push({ name: 'car-shop-items' })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
    changeAgregarDescuento() {
      this.descuento = null
      this.valueDescuento = null
    },
    applyMarkups() {
      // console.log(this.precioWithDescuento)
      // console.log(this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].tarifaAllDay)
      if (this.cotizadorCarsResultSelectEditWith.tarifaAllDay <= this.precioWithDescuento) {
        this.setCotizadorCarsSelectMarkups({
          markup: this.markup,
          valueMarkup: this.valueMarkup,
          descuento: this.descuento,
          valueDescuento: this.valueDescuento,
        })

        this.setCotizadorCarSelectPrice({
          priceTotal: this.precioWithDescuento,
          priceWithMarkup: this.precioWithMUP,
          priceWithDescuento: this.precioWithDESC,
        })
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },

    save() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        this.loading = true

        const json = this.generateJsonCar

        const item = JSON.parse(localStorage.getItem('car-shop-item'))
        this.setCotizadorCarsResultSelectEdit(item.pos)

        if (localStorage.getItem('route-redirect') === 'car-shop-items') {
          const cars = JSON.parse(localStorage.getItem('car-shop'))
          cars[item.pos] = {
            ...cars[item.pos],
            ...json,
          }

          localStorage.setItem('car-shop', JSON.stringify(cars))
          this.updateItemToCar(cars)

          setTimeout(() => {
            this.loading = false
          }, 100)

          localStorage.removeItem('car-shop-item')
          this.$router.push({ name: localStorage.getItem('route-redirect') })
        } else if (
          localStorage.getItem('route-redirect') === 'reservations-update'
          || localStorage.getItem('route-redirect') === 'cotizations-update'
        ) {
          const jsonPost = {
            reserve_id:
              // eslint-disable-next-line no-nested-ternary
              localStorage.getItem('route-redirect') === 'reservations-update'
                ? localStorage.getItem('reservations-id')
                : localStorage.getItem('route-redirect') === 'cotizations-update'
                  ? localStorage.getItem('cotizations-id')
                  : '',
            pos: item.pos,
            car_item: json,
          }
          this.axios
            .post('reservations/update-service-item-car', jsonPost, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                localStorage.removeItem('car-shop-item')
              }
            })
            .finally(() => {
              this.loading = false
              this.$router.push({ name: localStorage.getItem('route-redirect') })
            })
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
