// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/apps',
    name: 'apps-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/Apps.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'apps_keys',
    },
  },
  {
    path: '/apps/download/:id',
    name: 'apps-download',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/utils/QRAPPDownload.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      pertenece: 'apps_keys',
    },
  },
]

export default formTable
