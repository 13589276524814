<template>
  <v-row
    no-gutters
    :class="pos > 0 ? 'mt-6' : ''"
  >
    <v-col
      cols="12"
      sm="2"
      md="2"
    >
      <v-autocomplete
        v-model="escala.city"
        :items="itemsCity"
        :search-input.sync="searchCity"
        hide-details
        hide-selected
        :label="$t('lbl.escala') | capitalize"
        outlined
        dense
        item-text="name"
        item-value="id"
        return-object
        :disabled="posFrecuencia > 0"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.citySearch') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
          <span>{{ item.name !== undefined ? `${item.name.slice(0, 12)} ...` : '' }}</span>
        </template>
        <template v-slot:item="{ item }">
          <v-icon left>
            {{ icons.mdiAirplaneTakeoff }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          class="ml-2"
        >
          <vc-date-picker
            v-model="escala.llegada"
            outlined
            dense
            mode="time"
            :model-config="modelTimeConfig"
            is24hr
            @input="calculateDurationEscala"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="$t('lbl.llegada')"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="pt-2 pl-1"
        >
          +
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="escala.llegadaDay"
            :label="$t('lbl.day')"
            outlined
            dense
            hide-details
            type="number"
            min="0"
            @input="changeDaysCalculate(posFrecuencia)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
      class="ml-2"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="escala.llegadaTerminal"
            :label="$t('lbl.terminal')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="ml-3"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="10"
            >
              <v-text-field
                v-model="escala.llegadaDuration"
                :label="$t('lbl.duration')"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-tooltip
                slot="append-outer"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('msg.durationHourMin') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
      class="ml-5"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <vc-date-picker
            v-model="escala.salida"
            outlined
            dense
            mode="time"
            :model-config="modelTimeConfig"
            is24hr
            @input="calculateDurationEscala"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                ref="dateTo"
                :label="$t('lbl.departure')"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="pt-2 pl-1"
        >
          +
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="escala.salidaDay"
            :label="$t('lbl.day')"
            outlined
            dense
            hide-details
            type="number"
            min="0"
            @input="changeDaysCalculate(posFrecuencia)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
      class="ml-2"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="escala.salidaTerminal"
            :label="$t('lbl.terminal')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="ml-3"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="10"
            >
              <v-text-field
                v-model="escala.salidaDuration"
                :label="$t('lbl.duration')"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-tooltip
                slot="append-outer"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('msg.durationEscHourMin') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      v-if="posFrecuencia === 0"
      cols="12"
      sm="1"
      md="1"
      class="ml-3 text-right"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-tooltip
            v-if="pos === 0"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                x-small
                color="primary"
                @click="addEscalaFrecuencia(posFrecuencia)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="frecuencias[posFrecuencia].escalas.length > 1"
          cols="12"
          md="3"
          sm="3"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                x-small
                color="error"
                @click="deleteEscalaFrecuencia({ pos, posFrecuencia })"
              >
                <v-icon
                  v-bind="attrs"
                  small
                  v-on="on"
                >
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    escala: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posFrecuencia: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiInformationOutline,
      },
      loading: false,

      items: [],
      itemsCity: [],
      searchCity: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
    }
  },
  computed: {
    ...mapState({
      frecuencias: state => state.app.frecuencias,
    }),
  },
  watch: {
    searchCity(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCity(val)
      } else {
        this.itemsCity = []
      }
    },
  },
  created() {
    this.isLoading = true
  },
  mounted() {
    this.getAirPorts()
    if (this.escala.city !== null) {
      this.itemsCity.push(this.escala.city)
    }
  },
  methods: {
    ...mapMutations([
      'addEscalaFrecuencia',
      'deleteEscalaFrecuencia',
      'updateEscala',
      'calculateLlegadaEscala',
      'changeDaysCalculate',
    ]),
    filterCity(v) {
      this.itemsCity = []
      if (v === '') {
        this.itemsCity = []
      } else {
        // this.itemsCity = this.items.filter(e => e.iataComodin)
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
              this.itemsCity.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },

    getAirPorts() {
      this.axios
        .post('airports-names', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data.data

          result.forEach(element => {
            this.items.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    activeToDate() {
      setTimeout(() => {
        this.$refs.dateTo.focus()
      }, 100)
    },
    calculateTime() {
      const from = this.$moment(`${this.$moment().format('Y-M-D')} ${this.escala.from}`)
      const to = this.$moment(`${this.$moment().format('Y-M-D')} ${this.escala.to}`)
      const diffHour = to.diff(from, 'hours', true).toLocaleString()

      const split = diffHour.split('.')
      // eslint-disable-next-line camelcase
      let duration_hour = 0
      // eslint-disable-next-line camelcase
      let duration_min = 0
      if (split.length >= 1) {
        // eslint-disable-next-line radix, camelcase
        duration_hour = parseInt(split[0])
      }
      if (split.length >= 2) {
        // eslint-disable-next-line radix, camelcase
        duration_min = parseInt(`0.${split[1]}` * 60)
      }
      // eslint-disable-next-line camelcase
      this.escala.duration_h = duration_hour
      // eslint-disable-next-line camelcase
      this.escala.duration_m = duration_min
    },

    calculateDurationEscala() {
      let origenTime
      let idOrigen
      if (this.pos === 0) {
        origenTime = this.$moment(this.$store.state.app.frecuencias[this.posFrecuencia].origen.salida, 'HH:mm')
        idOrigen = this.$store.state.app.frecuencias[this.posFrecuencia].origen.destino.id
      } else {
        origenTime = this.$moment(
          this.$store.state.app.frecuencias[this.posFrecuencia].escalas[this.pos - 1].salida,
          'HH:mm',
        )
        idOrigen = this.$store.state.app.frecuencias[this.posFrecuencia].escalas[this.pos - 1].city.id
      }
      const beginningTime = this.$moment(this.escala.llegada, 'HH:mm')

      /* if (origenTime.isBefore(beginningTime)) {
        this.escala.llegadaDay = 0
      } else {
        this.escala.llegadaDay = 1
      } */

      const endTime = this.$moment(this.escala.salida, 'HH:mm')
      if (this.escala.salida) {
        if (beginningTime.isBefore(endTime)) {
          this.escala.salidaDay = 0

          // calculate total duration
          const duration = this.$moment.duration(endTime.diff(beginningTime))
          // eslint-disable-next-line radix
          const hours = parseInt(duration.asHours())
          // eslint-disable-next-line radix
          const minutes = parseInt(duration.asMinutes()) % 60
          const totalM = hours * 60 + minutes
          this.escala.salidaDuration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
          this.escala.salidaDurationM = totalM
        } else {
          this.escala.salidaDay = 1

          const newEndTime = this.$moment(this.escala.salida, 'HH:mm').add(1, 'days')

          // calculate total duration
          const duration = this.$moment.duration(newEndTime.diff(beginningTime))
          // eslint-disable-next-line radix
          const hours = parseInt(duration.asHours())
          // eslint-disable-next-line radix
          const minutes = parseInt(duration.asMinutes()) % 60
          const totalM = hours * 60 + minutes
          this.escala.salidaDuration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
          this.escala.salidaDurationM = totalM
        }
      }

      if (this.escala.llegada) {
        const idDestino = this.escala.city.id
        const horaOrigen = origenTime.format('HH:mm')
        const horaDestino = beginningTime.format('HH:mm')

        const json = {
          idOrigen,
          horaOrigen,
          idDestino,
          horaDestino,
        }
        this.axios
          .post(`${process.env.VUE_APP_API_URL}calculate/flights-utc`, json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // console.log(response.data)
            this.escala.llegadaDuration = response.data.duration
            this.escala.llegadaDurationM = response.data.durationM
            this.escala.llegadaDay = response.data.daysLlegada
          })
          .finally(() => this.calculateLlegadaEscala(this.posFrecuencia))

        /* .finally(() => {
            const resulMin = this.escala.llegadaDurationM
            let textHours

            let hours = Math.floor(resulMin / 60)
            const mins = resulMin % 60
            if (hours !== 0 && mins !== 0) {
              if (mins >= 59) {
                hours += 1
                textHours = `${hours}:00`
              } else {
                textHours = `${hours}:${mins.toFixed(0)}`
              }
            } else if (hours === 0 && mins !== 0) {
              textHours = `00:${mins.toFixed(0)}`
            } else if (hours !== 0 && mins === 0) {
              textHours = `${hours}:00`
            }
            this.escala.llegadaDuration = textHours
          }) */
      }
    },
  },
}
</script>
