<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="7"
        sm="7"
      >
        <v-text-field
          v-model="email.email"
          :label="$t('lbl.email')"
          outlined
          dense
          hide-details="auto"
          :rules="emailRules"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-switch
          v-model="email.principal"
          :label="$t('lbl.principal')"
          hide-details
          class="mt-0"
          :disabled="disabledRadio"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="2"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteEmailAdminReserve(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    email: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
      emailRules: [v => this.isEmail(v) || 'Email debe ser válido'],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
    }),
    disabledRadio() {
      let tienePrincipal = false
      this.emailsReserve.forEach(element => {
        if (element.principal) {
          tienePrincipal = true
        }
      })

      let disabled = true
      if (tienePrincipal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.emailsReserve.length; index++) {
          const element = this.emailsReserve[index]

          if (element.principal && element.email === this.email.email && this.pos === index) {
            disabled = false
          }
        }
      } else {
        disabled = false
      }

      return disabled
    },
  },
  methods: {
    ...mapMutations(['deleteEmailAdminReserve']),
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
